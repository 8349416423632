import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { EligibilityQuestionnaireService } from '../services/eligibilty-questionnaire.service';

@Injectable({
  providedIn: null
})
export class HasResultsGuardService {
  private _questionnaireService = inject(EligibilityQuestionnaireService);
  private _router = inject(Router);

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this._questionnaireService.results &&
      this._questionnaireService.results.length > 0
    ) {
      return true;
    } else {
      // Once in Angular >= 14.1.0
      // https://github.com/angular/angular/pull/45877
      // return createUrlTreeFromSnapshot(route, ['..', 'location']);
      return this._router.createUrlTree([
        state.url.slice(0, state.url.lastIndexOf('/'))
      ]);
    }
  }
}
