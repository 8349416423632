export enum WorkflowStepsEnum {
  Verification = 'Verification',
  Forbidden = 'Forbidden',
  ShopRegistration = 'ShopRegistration',
  Authentication = 'Authentication',
  Consent = 'Consent',
  Consent1 = 'Consent1',
  DomainConsent = 'DomainConsent',
  ShippingInformation = 'ShippingInformation',
  WorkflowFailure = 'WorkflowFailure',
  OrderConfirmation = 'OrderConfirmation',
  LimitsReached = 'LimitsReached',
  Questionnaire = 'Questionnaire',
  AssessmentQuestionnaire = 'AssessmentQuestionnaire',
  NotFound = 'NotFound',
  QuantitySelection = 'QuantitySelection',
  Insurance = 'InsuranceCheck',
  PersonalInformation = 'PersonalInformation',
  PCPDetails = 'PrimaryCareProvider',
  Appointment = 'Appointment',
  AppointmentConfirmation = 'AppointmentConfirmation',
  PhysicianForm = 'PhysicianForm',
  PhysicianFormConfirmation = 'PhysicianFormConfirmation',
  PhysicianFormCancelConfirmation = 'PhysicianFormCancelConfirmation',
  ContactDetails = 'ContactDetails',
  Payment = 'Payment',
  Logout = 'Logout',
  Welcome = 'Welcome',
  ImmunizationConsent = 'ImmunizationConsent',
  ImmunizationAppointment = 'ImmunizationAppointment',
  ImmunizationAppointmentConfirmation = 'ImmunizationAppointmentConfirmation',
  AutoVerification = 'AutoVerification',
  StartJourney = 'StartJourney',
  SyncVisitAppointment = 'SyncVisitAppointment',
  SyncVisitAppointmentConfirmation = 'SyncVisitAppointmentConfirmation',
  PatientPhoneNumber = 'PatientPhoneNumber',
  PatientArea = 'PatientArea'
}
