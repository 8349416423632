<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <button
      class="btn btn-inline btn-medium mb-4"
      data-test="dashboard-button"
      slot="aboveHeader"
      (click)="toDashboard()"
    >
      <pofo-icon iconName="chevron-left-sm" [size]="28"></pofo-icon> Back to
      Dashboard
    </button>

    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>

    @if (appointment && selectedLocation) {
    <div class="p-4 mt-6 border rounded-md border-platinum bg-white relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        {{ appointment.modalityType[1] }}
      </div>
      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="diabetes" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Biometric Screening</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>
      <div class="line"></div>
      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{
          context: {
            icon: 'building',
            iconRight: 'lock-locked',
            title: 'Location',
            subTitle: selectedLocation.name,
            content:
              selectedLocation.address +
              selectedLocation.city +
              ', ' +
              selectedLocation.zip
          }
        }"
      ></ng-container>
      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{
          context: {
            icon: 'calendar-date',
            iconRight: undefined,
            title: 'Date and time',
            subTitle: parseDate(),
            content: undefined
          }
        }"
      ></ng-container>
    </div>
    <div class="line"></div>
    <div class="pb-3">
      <pofo-alert type="warning">
        To change appointment location, please cancel this appointment and
        rebook.
      </pofo-alert>
      <button
        class="btn btn-primary btn-medium w-full mb-2"
        [disabled]="isCancel"
        (click)="rescheduleAppointment()"
      >
        Reschedule at this location
      </button>
      <button
        class="btn btn-secondary btn-medium w-full"
        [disabled]="isCancel"
        (click)="cancelAppointment()"
      >
        Cancel this appointment
      </button>
    </div>
    } @else {
    <pofo-alert type="error" data-test="get-appointment-error"
      >Unexpected Error</pofo-alert
    >
    }
  </pofo-container>
</div>

<ng-template #item let-data="context">
  <div class="flex justify-start mt-3 mb-5">
    <div class="w-12 pr-2 pt-1">
      <pofo-icon iconName="{{ data.icon }}" [size]="24"></pofo-icon>
    </div>
    <div class="w-4/5">
      <div class="text-navy text-base font-bold">{{ data.title }}</div>
      <div class="text-storm text-sm font-normal">
        <div>{{ data.subTitle }}</div>
        <div>{{ data.content }}</div>
      </div>
    </div>
    @if (data.iconRight) {
    <div class="w-12 pr-2 pt-1">
      <pofo-icon iconName="{{ data.iconRight }}" [size]="24"></pofo-icon>
    </div>
    }
  </div>
</ng-template>

@if (isCancel) {
<pofo-modal
  confirmText="Cancel the appointment now"
  cancelText="Keep this appointment"
  (canceled)="modalKeepButton()"
  (confirmed)="modalConfirmCancel()"
>
  <div class="flex flex-col text-center justify-center items-center p-16">
    <pofo-icon
      class="mb-2 text-yellow"
      iconName="alert-warning-circle"
      [size]="24"
    ></pofo-icon>
    <div class="md:w-[480px]">
      <h1 class="text-heading-sm">
        Are you sure you want to cancel your appointment?
      </h1>
      <p class="mb-6 text-center text-slate font-light">
        You're about to cancel your appointment for this program and the time
        slot you previously booked will be available for others.
      </p>
    </div>
  </div>
</pofo-modal>
}
