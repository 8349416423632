import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { OrderRequestComponent } from './order-request.component';
import { OrderRequestRoutingModule } from './order-request-routing.module';
import { VerificationComponent } from './verification/verification.component';

@NgModule({
  imports: [
    CommonModule,
    OrderRequestRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    OrderRequestComponent,
    VerificationComponent
  ]
})
export class OrderRequestModule {}
