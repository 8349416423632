<div
  [ngClass]="{
    'relative h-[48px]': true,
    'bg-off-white cursor-not-allowed': isDisabled,
    'bg-white cursor-pointer': !isDisabled
  }"
>
  @if (!isOpen) {
  <div
    class="selected-container"
    (click)="onContainerClick()"
    [ngClass]="{
      searching: isOpen || isFocused,
      error: hasError,
      'disabled select-none cursor-not-allowed': isDisabled
    }"
  >
    {{ selectedLabel || placeholder }}
  </div>
  } @if (isOpen) {
  <pofo-icon
    [ngClass]="{ 'absolute mt-3 ml-3': true }"
    iconName="search-lg"
    [size]="24"
  ></pofo-icon>
  }
  <input
    [name]="name"
    [value]="selectedLabel"
    [disabled]="isDisabled"
    [ngClass]="{ 'closed cdk-visually-hidden': !isOpen, error: hasError }"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="onNavKeydown($event)"
    placeholder="{{ placeholder }}"
    type="text"
    [attr.aria-invalid]="hasError"
    [attr.aria-errormessage]="errorMsgId"
    #searchField
  />
  @if (!isOpen) {
  <label
    [for]="name"
    [ngClass]="{
      'z-0 absolute items-center left-3 transition-top duration-300 top-2 text-input font-bold text-storm': true,
      'text-smoke select-none': isDisabled,
      'text-storm': !isFocused && !isDisabled,
      'text-red': hasError
    }"
    >{{ label }}</label
  >
  } @if (!isOpen) {
  <pofo-icon
    (click)="onContainerClick()"
    [ngClass]="[
      'top-0 mt-3 mr-1 right-0 z-1',
      isDisabled
        ? 'text-smoke cursor-not-allowed'
        : 'text-teal-500 cursor-pointer'
    ]"
    iconName="chevron-down-sm"
    [size]="24"
  ></pofo-icon>
  } @else {
  <pofo-icon
    (click)="onContainerClick()"
    [ngClass]="[
      'top-0 mt-3 mr-1 right-0 z-1',
      isDisabled
        ? 'text-smoke cursor-not-allowed'
        : 'text-teal-500 cursor-pointer'
    ]"
    iconName="chevron-up-sm"
    [size]="24"
  ></pofo-icon>
  }
</div>
<div class="relative w-full">
  @if (isOpen) {
  <div
    class="options-container pl-1 pr-2 py-3 border border-concrete shadow-sm rounded-sm absolute z-1 w-full bg-white"
    data-test="searchable-dropdown-options"
    #optionsContainer
  >
    <span class="pl-3 text-input w-full font-bold text-storm">{{ label }}</span>
    @for ( option of optionItems | optionFilter: 'label':searchField.value;
    track trackFn($index, option)) {
    <pofo-dropdown-item
      [item]="option"
      (mousedown)="onOptionClicked(option)"
      (click)="onOptionKeyUp()"
    >
      {{ option.label }}
    </pofo-dropdown-item>
    }
  </div>
  }
</div>
