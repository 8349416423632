import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { Routes } from '@angular/router';

export function handleProviderRoutes(appointmentService: AppointmentService) {
  const routes: Routes = [];

  switch (true) {
    case appointmentService.modalityType === 'SyncVisit':
      routes.push({
        path: '',
        loadChildren: () =>
          import('./modality/sync-visit/appointment-sync-visit.module').then(
            (m) => m.AppointmentSyncVisitModule
          )
      });
      break;
    case appointmentService.provider === 'LabCorp':
      routes.push({
        path: '',
        loadChildren: () =>
          import('./modality/on-site/labcorp/appointment-labcorp.module').then(
            (m) => m.AppointmentLabcorpModule
          )
      });
      break;

    default:
      routes.push({
        path: '',
        loadChildren: () =>
          import('./modality/on-site/default/appointment-default.module').then(
            (m) => m.AppointmentDefaultModule
          )
      });
      break;
  }

  return routes;
}
