import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { ContactDetailsComponent } from './contact-details.component';
import { ContactDetailsRoutingModule } from './contact-details-routing.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ContactDetailsRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    ContactDetailsComponent
  ]
})
export class ContactDetailsModule {}
