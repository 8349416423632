<header
  class="px-4 md:px-40 w-full h-header bg-white flex justify-between items-center shadow-inset shadow-cloudy"
>
  <div class="flex items-center">
    <img
      src="{{ assetPath }}images/lgc_logo.svg"
      alt="LetsGetChecked Logo"
      class="h-[40px]"
    />
    @if ((showLogo$ | async) && coBrandPath) {
    <div class="bg-cloudy w-[1px] mx-2 h-[40px]"></div>
    <img
      class="h-full"
      alt="Co Brand Logo"
      src="{{ assetPath }}{{ coBrandPath }}"
    />
    }
  </div>
  <div class="flex items-center">
    <pofo-icon class="text-navy" iconName="lock-locked" [size]="24"></pofo-icon>
    <span class="text-sm pl-2 text-slate">SSL Secure</span>
  </div>
</header>
