import * as amplitude from '@amplitude/analytics-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  QuestionnaireAnswer,
  QuestionnaireLabel,
  QuestionnaireQuestion
} from 'src/app/modules/questionnaire/@types/questionnaire.interface';
import { filter, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EligibilityQuestionnaireService } from 'src/app/modules/questionnaire/services/eligibilty-questionnaire.service';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { MultiSelectComponent } from 'src/app/modules/questionnaire/components/multi-select/multi-select.component';
import { QuestionnaireEligibilityAnswer } from 'src/app/modules/questionnaire/@types/eligibility-questionnaire.interface';
import { SingleSelectComponent } from 'src/app/modules/questionnaire/components/single-select/single-select.component';
import { StringToHtmlDirective } from 'src/app/shared/directives/parse-string-html.directive';
import { TextAreaComponent } from 'src/app/modules/questionnaire/components/text-area/textarea.component';

@Component({
  selector: 'pofo-eligibility-questionnaire',
  templateUrl: './eligibility-questionnaire.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    StringToHtmlDirective,
    MultiSelectComponent,
    SingleSelectComponent,
    TextAreaComponent,
    LoadingSpinnerComponent
  ]
})
export class EligibilityQuestionnaireComponent {
  private _questionnaireService = inject(EligibilityQuestionnaireService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  public isLoading = false;
  public errorMessage?: string;
  private _questionnaireAnswers: QuestionnaireEligibilityAnswer[] = [];
  public currentQuestion: QuestionnaireQuestion | undefined;
  public currentQuestionNumber = 1;
  public totalAmountOfQuestions: number;
  public isLastQuestion = false;
  public labels?: QuestionnaireLabel | null;

  constructor() {
    this._questionnaireService
      .getQuestionnaire$()
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.value) {
          amplitude.track('start questionnaire', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          this.currentQuestion =
            this._questionnaireService.buildAndGetFirstQuestion(res.value);

          this.totalAmountOfQuestions =
            this._questionnaireService.questionnaire.questions.length;
          this.labels = this._questionnaireService.questionnaire.labels;
        }
      });
  }

  public onQuestionComplete(
    questionAnswer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): void {
    const eligibilityAnswer = this._isQuestionnaireEligibilityAnswer(
      questionAnswer
    )
      ? questionAnswer
      : undefined;

    if (eligibilityAnswer) {
      this.isLastQuestion =
        this.currentQuestionNumber >= this.totalAmountOfQuestions;
      this._questionnaireAnswers = [
        ...this._questionnaireAnswers,
        eligibilityAnswer
      ];
    }

    this.currentQuestion =
      this._questionnaireService.getNextQuestion(questionAnswer);

    if (this.currentQuestion === undefined) {
      this._questionnaireService
        .submitQuestionnaireAnswers$(this._questionnaireAnswers)
        .pipe(
          take(2),
          tap((res) => {
            if (res.error) {
              this.errorMessage = res.errorMessage ?? 'There was an error';

              amplitude.logEvent('error', {
                location: window?.location.href,
                origin: 'pofo',
                category: 'order forms (pofo)',
                errorMessage: this.errorMessage
              });
            } else {
              this.errorMessage = undefined;
            }

            this.isLoading = res.loading ?? false;
          }),
          filter(() => !this.isLoading)
        )
        .subscribe((res) => {
          amplitude.track('complete questionnaire', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          if (res.onCompleteCallback) {
            res.onCompleteCallback();
          } else if (res.value) {
            this._questionnaireService.results = res.value;

            this._router.navigate(['..', 'results'], {
              relativeTo: this._activatedRoute
            });
          }
        });
    } else {
      this.currentQuestionNumber++;
    }
  }

  private _isQuestionnaireEligibilityAnswer(
    answer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): answer is QuestionnaireEligibilityAnswer {
    return answer && 'selections' in answer;
  }

  public getCurrentProgressBarWidth(): string {
    return (
      (this.currentQuestionNumber / this.totalAmountOfQuestions) * 100 + '%'
    );
  }
}
