import { Component, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pofo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [IconComponent]
})
export class FooterComponent {
  private environmentService = inject(EnvironmentService);

  public assetPath = this.environmentService.properties.assetPath;
  public currentYear = new Date().getFullYear();
}
