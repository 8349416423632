/* eslint-disable @typescript-eslint/no-explicit-any */
/// <reference types="stripe-v3" />
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { NgClass } from '@angular/common';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pofo-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
  standalone: true,
  imports: [IconComponent, NgClass, AlertComponent]
})
export class StripePaymentComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  private elementsComplete: { card: boolean; expiry: boolean; cvc: boolean } = {
    card: false,
    expiry: false,
    cvc: false
  };

  private stripeOptions: StripeCardElementOptions = {
    classes: {
      base: 'control--stripe',
      focus: 'control--stripe--focused',
      complete: 'control--stripe--filled',
      empty: 'control--stripe--empty',
      invalid: 'control--stripe--invalid'
    }
  };

  public card: stripe.elements.Element;
  public expiry: stripe.elements.Element;
  public cvc: stripe.elements.Element;

  public stripe: stripe.Stripe;
  public elements: stripe.elements.Elements;

  @Output() paymentMethodGenerated = new EventEmitter<string>();
  @Output() paymentMethodGenerationError = new EventEmitter();
  @Output() readyStateChange = new EventEmitter();
  @Output() paymentRequestCanMakePayment = new EventEmitter();
  @Output() paymentRequestPaymentMethod = new EventEmitter();
  @Output() paymentRequestShippingAddressChange = new EventEmitter();
  @Output() cardActionHandled = new EventEmitter();
  @Output() cardActionError = new EventEmitter();
  @Output() cardPaymentHandled = new EventEmitter();
  @Output() cardPaymentError = new EventEmitter();
  @Output() confirmCardHandled = new EventEmitter();
  @Output() confirmCardError = new EventEmitter();
  @Input() prConfig: any;

  @ViewChild('txtCardNumber', { static: true }) txtCardNumber: ElementRef;
  @ViewChild('txtExpiry', { static: true }) txtExpiry: ElementRef;
  @ViewChild('txtCvc', { static: true }) txtCvc: ElementRef;

  public txtExpiryFeedback = '';
  public txtCvcFeedback = '';
  public txtCardNumberFeedback = '';
  public errorMessage = '';
  public cardIconHidden = true;
  public cardIconSrc = '';
  public cardIconName = '';
  private paymentRequest: stripe.paymentRequest.StripePaymentRequest;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['prConfig'] && this.prConfig && this.stripe) {
      this.setUpPaymentRequest();
    }
  }

  ngAfterViewInit() {
    this.setUpStripe();
    this.createElements();
    this.bindElementEvents();

    if (this.prConfig) {
      this.setUpPaymentRequest();
    }
  }

  public setUpStripe(): void {
    const region = environment.region();
    const stripeKey = environment.get().stripe[region];

    this.stripe = Stripe(stripeKey);
    this.elements = this.stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro'
        }
      ]
    });
  }

  public createElements(): void {
    this.cvc = this.elements.create('cardCvc', this.stripeOptions);
    this.cvc.mount('#txtCvc');

    this.expiry = this.elements.create('cardExpiry', this.stripeOptions);
    this.expiry.mount('#txtExpiry');

    this.card = this.elements.create('cardNumber', this.stripeOptions);
    this.card.mount('#txtCardNumber');
  }

  public bindElementEvents(): void {
    this.cvc.on('change', this.cvcChange.bind(this));

    this.expiry.on('change', this.expiryChange.bind(this));

    this.card.on('change', this.cardChange.bind(this));
    this.card.on('ready', this.cardReady.bind(this));
  }

  public async setUpPaymentRequest(): Promise<void> {
    this.paymentRequest = this.stripe.paymentRequest(this.prConfig);

    // Check the availability of the Payment Request API first.
    const result = await this.paymentRequest.canMakePayment();
    this.paymentRequestCanMakePayment.emit(result);

    // Callback when a source is created.
    this.paymentRequest.on('paymentmethod', (event) => {
      this.paymentRequestPaymentMethod.emit(event);
    });

    // Callback when shipping address changes.
    this.paymentRequest.on('shippingaddresschange', (event) => {
      this.paymentRequestShippingAddressChange.emit(event);
    });
  }

  public cardReady(): void {
    if (this.card) {
      this.card.focus();
    }
  }

  public cvcChange(event: any): void {
    this.txtCvcFeedback = '';
    this.elementsComplete.cvc = event.complete;
    if (event.error) {
      this.txtCvcFeedback = event.error.message;
    }
    this.updateReadyState();
  }

  public expiryChange(event: any): void {
    this.txtExpiryFeedback = '';
    this.elementsComplete.expiry = event.complete;
    if (event.complete) {
      if (!this.elementsComplete.cvc && this.cvc) {
        this.cvc.focus();
      }
    }
    if (event.error) {
      this.txtExpiryFeedback = event.error.message;
    }
    this.updateReadyState();
  }

  public cardChange(event: any): void {
    this.txtCardNumberFeedback = '';
    this.elementsComplete.card = event.complete;

    if (event.brand) {
      if (event.brand === 'visa') {
        this.cardIconSrc = `visa`;
        this.cardIconName = 'Visa Logo';
      }

      if (event.brand === 'mastercard') {
        this.cardIconSrc = `mastercard`;
        this.cardIconName = 'Mastercard Logo';
      }

      if (event.brand === 'amex') {
        this.cardIconSrc = `american-express`;
        this.cardIconName = 'Amex Logo';
      }

      if (event.brand === 'diners') {
        this.cardIconSrc = `diners-club`;
        this.cardIconName = 'Diners Club Logo';
      }

      if (event.brand === 'discover') {
        this.cardIconSrc = `discover`;
        this.cardIconName = 'Discover Logo';
      }

      if (event.brand === 'jcb') {
        this.cardIconSrc = `jcb`;
        this.cardIconName = 'JCB Logo';
      }

      this.cardIconHidden =
        ['visa', 'mastercard', 'amex', 'diners', 'discover', 'jcb'].indexOf(
          event.brand
        ) === -1;
    }

    if (event.complete) {
      if (!this.elementsComplete.expiry && this.expiry) {
        this.expiry.focus();
      } else if (!this.elementsComplete.cvc && this.cvc) {
        this.cvc.focus();
      }
    }

    if (event.error) {
      this.txtCardNumberFeedback = event.error.message;
    }

    this.updateReadyState();
  }

  ngOnDestroy() {
    if (this.card) {
      this.card.destroy();
    }
    if (this.expiry) {
      this.expiry.destroy();
    }
    if (this.cvc) {
      this.cvc.destroy();
    }
  }

  public async getToken(): Promise<void> {
    const res = await this.stripe.createPaymentMethod('card', this.card);

    if (res.error) {
      this.paymentMethodGenerationError.emit(res.error.message);
    } else if (res.paymentMethod) {
      this.paymentMethodGenerated.emit(res.paymentMethod.id);
    } else {
      const error = 'There was an error processing your card.';
      this.paymentMethodGenerationError.emit(error);
    }
  }

  public paymentRequestShow(): void {
    this.paymentRequest.show();
  }

  public updateReadyState(): void {
    this.readyStateChange.emit(
      this.elementsComplete.card &&
        this.elementsComplete.cvc &&
        this.elementsComplete.expiry
    );
  }

  public async handleCardAction(secret: string): Promise<void> {
    const result = await this.stripe.handleCardAction(secret);

    if (result.error) {
      this.cardActionError.emit(result.error.message);
    } else {
      this.cardActionHandled.emit(result.paymentIntent?.id);
    }
  }

  public async handleCardPayment(secret: string): Promise<void> {
    const result = await this.stripe.handleCardAction(secret);

    if (result.error) {
      this.cardPaymentError.emit(result.error.message);
    } else {
      this.cardPaymentHandled.emit(result.paymentIntent?.id);
    }
  }
}
