export function getCookie(name: string): string | undefined {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts !== undefined && parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
}

export function checkCookieCategoryOptedIn(categoryId: string): boolean {
  const optanonConsentCookie = getCookie('OptanonConsent');
  if (optanonConsentCookie != null) {
    const groupParam = getQueryStringParam(optanonConsentCookie, 'groups');

    if (groupParam !== '') {
      const categories = decodeURIComponent(groupParam).split(',');

      const activegroups: { [key: string]: number } = {};

      categories.forEach((category) => {
        const categoryPair = category.split(':');
        activegroups[categoryPair[0]] = parseInt(categoryPair[1]);
      });

      if (activegroups[categoryId] === 1) return true;
    }
  }
  return false;
}

export function getQueryStringParam(queryString: string, queryParam: string) {
  const params = queryString.split('&');
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');

    if (pair[0] === queryParam) {
      return pair[1];
    }
  }

  return '';
}

export default {
  checkCookieCategoryOptedIn: checkCookieCategoryOptedIn,
  PerformanceCookie: 'C0002',
  FunctionalCookie: 'C0003',
  TargetingCookie: 'C0004'
};
