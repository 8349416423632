import * as amplitude from '@amplitude/analytics-browser';
import { BehaviorSubject, take } from 'rxjs';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { InsuranceService } from 'src/app/services/insurance/insurance.service';
import { LoaderState } from 'src/app/models/loading.model';
import { OptionItem } from 'src/app/components/dropdown/dropdown.component';
import { ParticipantService } from 'src/app/services/participant/participant.service';
import { SearchableDropdownComponent } from 'src/app/components/searchable-dropdown/searchable-dropdown.component';

@Component({
  selector: 'pofo-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    SearchableDropdownComponent,
    AsyncPipe
  ]
})
export class InsuranceComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  private _insuranceService = inject(InsuranceService);
  private _participantService = inject(ParticipantService);

  @ViewChild('insuranceFormElement')
  public insuranceFormElement: ElementRef<HTMLFormElement>;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public carrierOptions: OptionItem[] = [];
  serverError: string | null = null;
  formHasErrors = false;
  insuranceForm = this.fb.group({
    patient: this.fb.group({
      firstName: [
        this._participantService.participantValue.personalData?.firstName || '',
        [Validators.required]
      ],
      lastName: [
        this._participantService.participantValue.personalData?.lastName || '',
        [Validators.required]
      ],
      dateOfBirth: [
        this._participantService.participantValue.personalData?.dateOfBirth ||
          '',
        [Validators.required]
      ]
    }),
    payerId: ['', [Validators.required]],
    memberId: ['', [Validators.required]]
  });

  ngOnInit(): void {
    this._insuranceService.getInsurancePayers().subscribe((response) => {
      if (response?.value?.payers.length) {
        this.carrierOptions = response.value.payers.map((item) => {
          return { label: item.name, value: item.payerId };
        });
      }
    });
  }

  onSubmitForm() {
    const dateOfBirth = this.insuranceForm.get('dateOfBirth')?.value;

    this.serverError = null;
    if (this.insuranceForm.valid) {
      this.formHasErrors = false;
      this._insuranceService
        .submitInsuranceDetails({
          ...this.insuranceForm.value,
          dateOfBirth:
            dateOfBirth instanceof Object
              ? dateOfBirth?.toLocaleDateString('en-US')
              : dateOfBirth
        })
        .pipe(take(2))
        .subscribe((response: LoaderState<boolean>) => {
          amplitude.track('click validate insurance details', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });
          if ('loading' in response && response.loading !== undefined) {
            this.loading$.next(response.loading);
          }

          if (response.error) {
            this.serverError =
              response.errorMessage ||
              'Sorry, something went wrong, please try that again.';
          }

          if (response.onCompleteCallback) {
            amplitude.track('click validate insurance details', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)'
            });
            response.onCompleteCallback();
          }
        });
    } else {
      this.formHasErrors = true;

      const firstInvalid =
        this.insuranceFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }
}
