<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto relative">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>
    <div
      class="flex items-start justify-between mb-2 w-full shadow-inset shadow-cloudy p-4 border rounded-md border-platinum bg-white"
    >
      <div class="flex flex-col">
        <div
          class="flex justify-between text-navy text-base-lg font-normal mb-2"
        >
          <span>{{ selectedLocation.name }}</span>
        </div>
        <div class="text-left text-storm text-sm font-normal">
          <div>
            {{ selectedLocation.address }}
          </div>
          <div>
            <span>{{ selectedLocation.city }}, </span>
            <span>{{ selectedLocation.zip }}</span>
          </div>
        </div>
      </div>
      @if (!isReschedule) {
      <button
        class="btn btn-secondary btn-xsmall"
        data-test="change-button"
        (click)="gotoSelectLocation()"
      >
        Change
      </button>
      }
    </div>

    <pofo-calendar-internal
      [canWalkin]="true"
      [appointmentStartDate]="appointmentStartDate"
      (selectedSlot)="onConfirmSlot($event)"
    ></pofo-calendar-internal>
  </pofo-container>
</div>
