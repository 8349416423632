import { AppointmentComponentModule } from 'src/app/modules/appointment/components/appointment-components.module';
import { AppointmentSyncVisitRoutingModule } from './appointment-sync-visit-routing.module';
import { AppointmentSyncVisitService } from './services/sync-visit.service';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    AppointmentComponentModule,
    AppointmentSyncVisitRoutingModule,
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [AppointmentSyncVisitService]
})
export class AppointmentSyncVisitModule {}
