<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container containerTitle="Physician Results Form">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      Review and confirm all details to generate your form.
    </p>

    <div class="p-4 mt-6 border rounded-md bg-white border-platinum relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        Physician Form
      </div>

      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="diabetes" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Biometric Screening</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>

      @if (!!errorMessage) {
      <pofo-alert type="error" data-test="error-message">{{
        errorMessage
      }}</pofo-alert>
      }

      <div class="line"></div>

      @if ((patient$ | async)?.value; as patient) {
      <div>
        <div class="flex justify-start mt-3 mb-5">
          <div class="w-12 pr-2 pt-1">
            <pofo-icon iconName="people-avatars-one" [size]="24"></pofo-icon>
          </div>
          <div class="w-4/5">
            <div class="text-navy text-base font-bold">Patient details</div>
            <div class="text-storm text-sm font-normal">
              <div>First name: {{ patient.firstName }}</div>
              <div>Last name: {{ patient.lastName }}</div>
              <div>Email: {{ patient.email }}</div>
              <div>Date of birth: {{ patient.parseDOB() }}</div>
              <div>Sex at birth: {{ patient.gender }}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-start mt-3 mb-5">
          <div class="w-12 pr-2 pt-1">
            <pofo-icon iconName="house" [size]="24"></pofo-icon>
          </div>
          <div class="w-4/5">
            <div class="text-navy text-base font-bold">Patient address</div>
            <div class="text-storm text-sm font-normal">
              <div>{{ patient.address.line1 }}</div>
              <div>{{ patient.address.line2 }}</div>
              <div>
                {{ patient.address.city }},
                {{ patient.address.postalCode }}
              </div>
              <div>{{ patient.address.country }}</div>
              <div>{{ patient.phoneWithPrefix }}</div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>

    <div class="line"></div>

    <button
      class="btn btn-primary btn-medium w-full"
      data-test="finish-button"
      [disabled]="isDisabled()"
      (click)="confirm()"
    >
      Generate your form
    </button>
  </pofo-container>
</div>
