import { CONSENT_ROUTE, CONSENT_ROUTE_ADDITIONAL } from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/consent/consent.component').then(
        (m) => m.ConsentComponent
      )
  },
  {
    path: 'account',
    children: [
      {
        path: CONSENT_ROUTE,
        loadComponent: () =>
          import('./pages/account-consent/account-consent.component').then(
            (m) => m.AccountConsentComponent
          )
      },
      {
        path: CONSENT_ROUTE_ADDITIONAL,
        loadComponent: () =>
          import('./pages/account-consent/account-consent.component').then(
            (m) => m.AccountConsentComponent
          )
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsentRoutingModule {}
