import {
  AuthenticationType,
  VerificationForm,
  VerificationFormRequest
} from 'src/app/models/verification.model';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { ParticipantData } from 'src/app/models/participant.model';
import { ParticipantService } from '../participant/participant.service';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
  private httpService = inject(HttpService);
  private participantService = inject(ParticipantService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private verificationFormSubject: BehaviorSubject<VerificationForm | null>;
  public verificationForm: Observable<VerificationForm | null>;
  public isVerification = false;

  constructor() {
    this.verificationFormSubject = new BehaviorSubject<VerificationForm | null>(
      null
    );
    this.verificationForm = this.verificationFormSubject.asObservable();
  }

  verificationFactorResponseParser(response: VerificationForm) {
    return response;
  }

  getVerificationFactors(): Observable<LoaderState<VerificationForm>> {
    const endpoint = `${
      this._workflowService.programType === 'program' ? 'program/' : 'reseller/'
    }${this._workflowService.currentProgram}/verification-configuration`;
    return this.httpService
      .get<VerificationForm, VerificationForm>(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        this.verificationFactorResponseParser
      )
      .pipe(
        map((response: LoaderState<VerificationForm>) => {
          if (!response.loading && response.success && response.value) {
            this.verificationFormSubject.next(response.value);
          }
          return response;
        })
      );
  }

  verifyParticipantResponseParser(response: ParticipantData) {
    return response;
  }

  verifyParticipantForm(
    formData: VerificationFormRequest,
    verificationType: AuthenticationType = 'FILE',
    stepKey?: string
  ): Observable<LoaderState<ParticipantData>> {
    const requestData: VerificationFormRequest = {
      ...formData
    };
    const endpointPrefix =
      this._workflowService.programType === 'client'
        ? 'reseller'
        : 'program-participant';
    const verificationURL = `${endpointPrefix}/${
      this._workflowService.currentProgram
    }/${
      verificationType === 'FILE'
        ? `verify`
        : `verify-code${stepKey ? `?stepKey=${stepKey}` : ''}`
    }`;

    return this.httpService
      .post<ParticipantData, ParticipantData>(
        `${this._environmentService.properties.apiGateway}/${verificationURL}`,
        requestData,
        this.verifyParticipantResponseParser
      )
      .pipe(
        map((response: LoaderState<ParticipantData>) => {
          if (!response.loading && response.success && response.value) {
            this.participantService.updateParticipantData(response.value);
            this.participantService.clearParticipantFormData();

            if (response.onCompleteCallback) {
              response?.onCompleteCallback();
            }
          }
          return response;
        })
      );
  }
}
