<div
  [ngClass]="[
    'rounded mb-4 w-full m-h-10 py-3 px-4 flex items-center',
    background
  ]"
>
  <pofo-icon
    [ngClass]="['mr-4', iconColor]"
    [iconName]="icon"
    [size]="24"
  ></pofo-icon>
  <ng-content></ng-content>
</div>
