import { Component, OnInit, inject } from '@angular/core';
import { of, switchMap, take, tap } from 'rxjs';
import { RecordEvent } from 'src/app/awsRumEvents';
import { AuthComponent } from 'src/app/modules/auth/components/auth/auth.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PatientService } from 'src/app/shared/services/patient/patient.service';

@Component({
  selector: 'pofo-auth-sso',
  template: `<pofo-auth-component
    stepMessage="{{ this.stepMessage }}"
    errorMessage="{{ this.errorMessage }}"
  ></pofo-auth-component>`,
  standalone: true,
  imports: [AuthComponent]
})
export class AuthSSOComponent implements OnInit {
  private _authService = inject(AuthService);
  private _patientService = inject(PatientService);

  public stepMessage = 'Fetching patient profile';
  public errorMessage: string | undefined = undefined;

  ngOnInit(): void {
    this._authService
      .getPatientProfileSSO$()
      .pipe(
        take(2),
        tap((resPatientProfile) => {
          if (resPatientProfile.error) {
            RecordEvent('auth_sso.patient_profile.failed', {
              message: `User was not able to fetch patient profile.`
            });

            this.errorMessage =
              'There was an error fetching your patient profile';
          }
        }),
        switchMap((res) => {
          if (!res.loading && !res.error && res.value) {
            this.stepMessage = 'Setting patient profile';
            this._patientService.setPatientProfileId(res.value[0].id || '');
            this._patientService.setPatientGeo(res.value[0].geo || 'us');

            RecordEvent('auth_sso.patient_profile.success', {
              message: `User was able to set patient profile ID and GEO.`
            });

            return this._authService.getNextStepSSO$().pipe(
              take(2),
              tap((resNextStep) => {
                if (resNextStep.error) {
                  RecordEvent('auth_sso.next_step.failed', {
                    message: `User was not able to fetch NextStep.`
                  });

                  this.errorMessage =
                    'There was an error fetching the next step';
                }

                RecordEvent('auth_sso.next_step.success', {
                  message: `User was able to fetch NextStep. NextStep: ${resNextStep.value}.`
                });
              })
            );
          }

          return of(res);
        })
      )
      .subscribe((res) => {
        if (res.success && res.onCompleteCallback && res.value) {
          sessionStorage.setItem('auth_state', 'logged');

          RecordEvent('auth_sso.auth_state.logged', {
            message: `Authed state: logged`
          });

          RecordEvent('auth_sso.auth.success', {
            message: `User was able to set patient profile ID and next step.`
          });

          res.onCompleteCallback();
        }
      });
  }
}
