import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { OrderConfirmedComponent } from './order-confirmed.component';
import { OrderConfirmedRoutingModule } from './order-confirmed-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    OrderConfirmedRoutingModule,
    AngularSvgIconModule,
    SharedModule,
    OrderConfirmedComponent
  ]
})
export class OrderConfirmedModule {}
