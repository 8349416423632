import { Component, Input, inject } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EnvironmentService } from 'src/environments/environment.service';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [NgClass, AngularSvgIconModule]
})
export class IconComponent {
  private environmentService = inject(EnvironmentService);

  @Input() set iconName(icon: string) {
    this.icon = `${this.environmentService.properties.assetPath}icons/${icon}.svg`;
  }
  @Input() size = 24;
  @Input() outerSize: number;
  public icon = '';

  public get containerClasses() {
    return this.outerSize
      ? `w-[${this.outerSize}px] h-[${this.outerSize}px]`
      : '';
  }
}
