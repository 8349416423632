import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AccordionComponent } from 'src/app/components/accordion/accordion.component';
import { CardComponent } from 'src/app/components/card/card.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { Order } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order/order.service';

@Component({
  selector: 'pofo-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    ContainerComponent,
    CardComponent,
    AccordionComponent
  ]
})
export class OrderConfirmedComponent {
  private _orderService = inject(OrderService);
  private environmentService = inject(EnvironmentService);
  private _document = inject<Document>(DOCUMENT);

  assetPath = this.environmentService.properties.assetPath;
  public order?: Order;
  public nextSteps = [
    {
      title: 'Before your test arrives',
      image: {
        alt: 'Lady looking at the phone',
        src: `${this.assetPath}images/next-step-1.png`
      },
      content:
        'You can log into your account to see when to test as some samples need to be taken at a certain time.'
    },
    {
      title: `When you're ready to collect`,
      image: {
        alt: 'Activation codes on application',
        src: `${this.assetPath}images/next-step-2.png`
      },
      content:
        "Follow your kit's instructions, or watch a video to easily collect your sample."
    },
    {
      title: 'Return your sample to us',
      image: {
        alt: 'LGC packaging',
        src: `${this.assetPath}images/next-step-3.png`
      },
      content:
        'Place your sample in the envelope provided and return by post or check how to return your sample on our website.'
    },
    {
      title: 'Receive your results',
      image: {
        alt: 'Mobile Application',
        src: `${this.assetPath}images/next-step-4.png`
      },
      content: `You'll get an email when your results are available to view on your dashboard. Our nursing team may call you to discuss your results and answer your questions.`
    }
  ];

  public faqs = [
    {
      heading: 'How long does the entire process take?',
      content:
        'Once your order is complete, you will receive your test kit within a few days. This kit includes a free express return shipping envelope to deliver the sample to our lab for processing.'
    },
    {
      heading: 'How accurate is the testing process?',
      content:
        'Your samples are processed in the same labs used by primary care providers and hospitals. LetsGetChecked laboratories are CLIA-certified and CAP-accredited, which are the highest levels of accreditation.<br/><br/>Sample collection kits are manufactured within our ISO 13485 accredited facility, the highest level of accreditation for medical devices.'
    },
    {
      heading: 'Are there medical professionals involved in the process?',
      content:
        'LetsGetChecked has a team of physicians who will review your order and results. Our nursing team is on standby throughout the testing process, and you may receive a call to discuss your results and next steps.'
    },
    {
      heading: 'When should I return my sample back to the lab?',
      content:
        'Please ensure that the sample is collected and returned on the same day using the carrier method provided within the sample collection kit.<br/><br/>Each of our health kits included a prepaid shipping envelope to return your samples to the laboratory.<br/><br/>Please do not collect or return samples on a Friday, Saturday, or Sunday.'
    },
    {
      heading: 'When will my results be ready?',
      content:
        'We aim to have your results ready as soon as possible.<br/><br/>When your results are ready, you will receive an email informing you they are available. You can access your results by signing into your LetsGetChecked account.'
    },
    {
      heading: 'How is my privacy protected?',
      content:
        'Keeping your personal data secure is important to us. We use appropriate technical and organizational measures to ensure security and confidentiality of your information, and only share your information where required to deliver our products and services or where we have a legal basis to do so.<br/><br/>All samples are disposed of following analysis. For further information about our privacy and security practices, please see our privacy policy and T&Cs or feel free to contact our Data Protection Officer at <a href="mailto:DPO@LetsGetChecked.com">DPO@LetsGetChecked.com</a>'
    }
  ];

  constructor() {
    this.order = this._orderService.order;
  }

  public toDashboard(): void {
    this._document.location.href =
      this.environmentService.properties.patientDashboardURL;
  }
}
