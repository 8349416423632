import { Injectable, inject } from '@angular/core';
import {
  InsuranceCarrierResponse,
  InsuranceInfo
} from '../../models/insurance.model';
import { Observable, map, take } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from '../../models/loading.model';
import { ParticipantService } from '../participant/participant.service';
import { PersonalInformation } from 'src/app/models/personal-information.model';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  private _workflowService = inject(WorkflowService);
  private _httpService = inject(HttpService);
  private _participantService = inject(ParticipantService);
  private _environmentService = inject(EnvironmentService);

  getInsurancePayers(): Observable<LoaderState<InsuranceCarrierResponse>> {
    return this._httpService
      .get<InsuranceCarrierResponse, InsuranceCarrierResponse>(
        `${this._environmentService.properties.apiGatewayV2}/insurance/${this._workflowService.currentProgram}/payers`,
        (response: InsuranceCarrierResponse) => response
      )
      .pipe(
        map((response: LoaderState<InsuranceCarrierResponse>) => {
          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }

          return response;
        })
      );
  }

  submitInsuranceDetails(
    form: InsuranceInfo
  ): Observable<LoaderState<boolean>> {
    return this._httpService
      .post(
        `${this._environmentService.properties.apiGatewayV2}/insurance/${this._workflowService.currentProgram}/validation`,
        form,
        (response: boolean) => response
      )
      .pipe(
        take(2),
        map((response) => {
          if (!response.loading && response.success) {
            this._participantService.updateParticipantPersonalData(
              form.patient as PersonalInformation
            );
            if (response.onCompleteCallback) {
              response.onCompleteCallback();
            }
          }

          return response;
        })
      );
  }
}
