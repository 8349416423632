<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ serverError }}
  </pofo-alert>
  }
  <ng-container>
    @switch (progress) { @case ('FORM') {
    <pofo-pcp-search
      [loading]="isLoading"
      [terms]="pcpSearchForm"
      [searchInfoText]="alternativeSearchText"
      [searchInfoUrl]="alternativeSearchURL"
      (search)="onSubmitSearchForm($event)"
      (skip)="skipStep()"
    ></pofo-pcp-search>
    } @case ('LIST') {
    <pofo-pcp-search-list
      [results]="searchResults"
      [selectedPCP]="selectedPCP"
      (selectPCP)="onSelectPCP($event)"
      (back)="goToSearchForm()"
      (skip)="skipStep()"
    ></pofo-pcp-search-list>
    } @case ('CONFIRM') {
    <pofo-pcp-confirm
      [currentPCP]="currentPCP!"
      (search)="goToSearchForm()"
      (confirm)="onConfirmPCP($event)"
      (skip)="skipStep()"
    ></pofo-pcp-confirm>
    } }
  </ng-container>
</div>
