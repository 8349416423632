export interface PersonalInformationForm {
  dateOfBirth: string | Date;
  biologicalSex: string;
  ethnicity: string;
}

export interface PersonalInformation extends Partial<PersonalInformationForm> {
  firstName: string;
  lastName: string;
}

export enum Ethnicity {
  Caucasian = 'Caucasian',
  Black = 'Black',
  African = 'African',
  EastAfrican = 'EastAfrican',
  AfricanAmerican = 'AfricanAmerican',
  Asian = 'Asian',
  Chinese = 'Chinese',
  Indian = 'Indian',
  EastAsian = 'EastAsian',
  SoutheastAsian = 'SoutheastAsian',
  SouthAsian = 'SouthAsian',
  HispanicLatinAmerican = 'HispanicLatinAmerican',
  MiddleEastern = 'MiddleEastern',
  InuitNativeAmerican = 'InuitNativeAmerican',
  PacificIslander = 'PacificIslander',
  MixedEthnicity = 'MixedEthnicity',
  Other = 'Other',
  PreferNotToSay = 'PreferNotToSay'
}
