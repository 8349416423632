import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-quantity-selector',
  styleUrls: ['./quantity-selector.scss'],
  templateUrl: './quantity-selector.component.html',
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class QuantitySelectorComponent implements OnInit {
  @Input() public min = 0;
  @Input() public max = 0;
  @Input() public value = 0;
  @Input() public accLabel = '';
  @Output() valueChange = new EventEmitter<number>();
  canRemove: boolean = this.value > this.min;
  maxReached: boolean = this.value === this.max;

  ngOnInit(): void {
    this.canRemove = this.value > this.min;
    this.maxReached = this.value >= this.max;
  }

  public get btnSharedClasses() {
    return {
      'ring-0': true,
      'outline-none': true,
      flex: true,
      'justify-center': true,
      'items-center': true,
      border: true,
      'w-[32px]': true,
      'h-[32px]': true,
      'rounded-full': true,
      visible: this.min !== this.max,
      invisible: this.min === this.max
    };
  }

  public get removeBtnClasses() {
    return {
      ...this.btnSharedClasses,
      ['text-aqua-darker focus:bg-aqua-darkest focus:border-aqua-darkest hover:bg-aqua-darkest hover:border-aqua-darkest active:bg-aqua-darker active:border-aqua-darker hover:text-white focus:text-white']:
        this.canRemove,
      ['text-concrete hover:border-concrete focus:border-concrete']:
        !this.canRemove
    };
  }

  public get addBtnClasses() {
    return {
      ...this.btnSharedClasses,
      ['text-aqua-darker focus:bg-aqua-darkest focus:border-aqua-darkest hover:bg-aqua-darkest hover:border-aqua-darkest active:bg-aqua-darker active:border-aqua-darker hover:text-white focus:text-white']:
        !this.maxReached,
      ['text-concrete hover:border-concrete focus:border-concrete ']:
        this.maxReached
    };
  }

  public handleRemove(): void {
    if (this.value > this.min) {
      this.value = this.value - 1;
      this.canRemove = this.value > this.min;
      this.maxReached = this.value === this.max;
      this.valueChange.emit(this.value);
    }
  }

  public handleAdd(): void {
    if (this.value < this.max) {
      this.value = this.value + 1;
      this.maxReached = this.value === this.max;
      this.canRemove = this.value > this.min;
      this.valueChange.emit(this.value);
    }
  }
}
