import { Environment, environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

// NOTE: USE /environments/environment.ts instead of this service

// NOTE: This service should not be a service but rather a utulity function.
// This should not have any dependencies and should not be injectable. In the NG ecosystem
// NOTE: USE /environments/environment.ts instead of this service
@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  // NOTE: exists only for legacy reasons
  public get properties(): Environment {
    return environment.get();
  }
}
