import * as amplitude from '@amplitude/analytics-browser';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { PhysicianFormService } from 'src/app/shared/services/physician-form/physician-form.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { Router } from '@angular/router';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';

@Component({
  selector: 'pofo-physician-form-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    AlertComponent,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe
  ]
})
export class PhysicianFormConfirmComponent {
  private readonly _physicianFormService = inject(PhysicianFormService);
  private readonly _urlParamsService = inject(UrlParamsService);
  private readonly _programService = inject(ProgramService);
  private readonly _router = inject(Router);

  public isLoading = false;
  public errorMessage: string | undefined;
  public readonly patient$ = this._physicianFormService.getPatient$();
  private readonly _modalityId = this._urlParamsService.get('modalityId');

  public images: {
    index: number;
    name: string;
    image: string | ArrayBuffer | null | undefined;
    fileType: string;
  }[] = [];

  constructor() {
    this._programService.showSteps$.next(false);
    this._physicianFormService.filesToUpload.forEach((file, index) => {
      const fileType = file.name.match(/\.[0-9a-z]+$/i);

      if (fileType && fileType[0]) {
        if (fileType[0].includes('pdf')) {
          this.images.push({
            index: index,
            name: file.name,
            image: 'pdf-file',
            fileType: fileType[0]
          });
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            this.images.push({
              index: index,
              name: file.name,
              image: e.target?.result,
              fileType: fileType[0]
            });
          };

          reader.readAsDataURL(file);
        }
      }
    });
  }

  public isDisabled(): boolean {
    return this.errorMessage !== undefined || this.isLoading ? true : false;
  }

  public confirm(): void {
    if (!this._modalityId) {
      amplitude.track('click generate form', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)',
        errorMessage: `missing modalityId`
      });

      this._router.navigate(['/something-went-wrong']);
      return;
    }

    amplitude.track('click generate form', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'physician forms (pofo)'
    });

    this._physicianFormService
      .generateForm$()
      .pipe(
        take(2),
        tap((_res) => {
          this.errorMessage = _res.error ? _res.errorMessage : undefined;
          this.isLoading = _res.loading || false;
        })
      )
      .subscribe((res) => {
        if (!res.loading && res.onCompleteCallback) {
          this._programService.showSteps$.next(false);
          res.onCompleteCallback();
        }
      });
  }
}
