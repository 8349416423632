import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { ROUTES } from '@angular/router';
import { WelcomeDefaultComponent } from './pages/default/welcome.component';
import { WelcomeJPMCComponent } from './pages/jpmc/welcome.component';
import { WelcomeService } from './services/welcome.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { handleProviderRoutes } from './welcome-routing';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    WelcomeDefaultComponent,
    WelcomeJPMCComponent
  ],
  providers: [
    WelcomeService,
    {
      provide: ROUTES,
      useFactory: handleProviderRoutes,
      deps: [WorkflowService],
      multi: true
    }
  ]
})
export class WelcomeModule {}
