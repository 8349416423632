import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
  inject
} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  SelectControlValueAccessor
} from '@angular/forms';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { NgClass } from '@angular/common';

export type OptionItem = {
  label: string;
  value: string;
};

@Component({
  selector: 'pofo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [NgClass, FormsModule, IconComponent]
})
export class DropdownComponent
  extends SelectControlValueAccessor
  implements AfterViewInit
{
  private readonly cd = inject(ChangeDetectorRef);

  @ViewChild('selectLabelRef') public selectLabelRef: ElementRef;
  @ViewChild('selectRef') public selectRef: ElementRef;
  @Input() label: string;
  @Input() placeholder?: string;
  @Input() field?: string;
  @Input() optionItems: OptionItem[] = [];
  @Input() override value = '';
  @Input() isDisabled = false;
  @Input() hasError = false;
  @Input() errorMsgId: string;
  @Output() valueChange = new EventEmitter<string>();

  open = false;
  private _selectEl: HTMLElement;
  private _selectLabelEl: HTMLElement;

  constructor() {
    const _renderer = inject(Renderer2);
    const _elementRef = inject(ElementRef);

    super(_renderer, _elementRef);
  }

  ngAfterViewInit(): void {
    this._selectEl = this.selectRef.nativeElement;
    this._selectLabelEl = this.selectLabelRef.nativeElement;
    this._selectEl.style.height = `${this._selectLabelEl.offsetHeight}px`;

    if (this.value || this.placeholder) {
      this._updateSelectHeight();
    }
  }

  override writeValue(obj: string): void {
    this.value = obj;
  }

  handleOnChange(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.value = value;
    this.valueChange.emit(this.value);
    this.open = false;

    if (this.value) {
      this._updateSelectHeight();
    }
  }

  handleOnBlur() {
    this.open = false;
  }

  handleOnKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.open = true;
    }
  }

  handleOnClick() {
    this.open = !this.open;
  }

  get id() {
    return `dp-${this.field}`;
  }

  get testId() {
    return `select-${this.field}`;
  }

  private _updateSelectHeight() {
    this._selectLabelEl.style.paddingTop = '8px';
    this._selectLabelEl.style.paddingBottom = '0';
    this.cd.detectChanges();
    this._selectEl.style.height = `${this._selectLabelEl.offsetHeight * 2.2}px`;
  }
}
