/// <reference types="grecaptcha" />
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { StatesResponse } from '../@types/state-confirmation.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class StateService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  public getStates$(
    modalityId: string
  ): Observable<LoaderState<StatesResponse[]>> {
    return this._httpService.get<StatesResponse[], StatesResponse[]>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/areas`,
      this._responseParser<StatesResponse[]>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
