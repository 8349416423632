<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ serverError }}
  </pofo-alert>
  }

  <pofo-container containerTitle="Personal information">
    <p class="text-base text-slate text-center md:text-left">
      Please answer the following questions about your personal information as
      the answers are required for the lab to accurately report results.
    </p>

    <div>
      <form
        #personalInfoFormElement
        [formGroup]="personalInfoForm"
        (ngSubmit)="onSubmitForm()"
      >
        <div class="mt-4 mb-4 w-full">
          <pofo-input
            name="dateOfBirth"
            label="Date of Birth"
            type="date"
            [hasError]="
              formHasErrors && !!personalInfoForm.get('dateOfBirth')?.errors
            "
            formControlName="dateOfBirth"
            data-test="dateOfBirthInput"
            errorMsgId="dateOfBirthErrorMsg"
          ></pofo-input>

          @if ((formHasErrors && personalInfoForm.get('dateOfBirth')?.errors) ||
          personalInfoForm.get('dateOfBirth')?.touched ||
          personalInfoForm.get('dateOfBirth')?.dirty ) { @if
          (personalInfoForm.get('dateOfBirth')?.errors?.['required'] ||
          personalInfoForm.get('dateOfBirth')?.errors?.['invalidDate']) {
          <pofo-error-message
            errorId="dateOfBirthErrorMsg"
            testId="dateOfBirthReqErrorMsg"
            errorMessage="Date of Birth is required and must be valid."
          ></pofo-error-message>
          } @else if
          (personalInfoForm.get('dateOfBirth')?.errors?.['ageRestriction']) {
          <pofo-error-message
            errorId="dateOfBirthErrorMsg"
            testId="dateOfBirthAgeErrorMsg"
            errorMessage="You must be at least 18 years of age to proceed."
          ></pofo-error-message>
          } }
        </div>

        <div class="mb-4 w-full">
          <pofo-dropdown
            label="What was your assigned biological sex at birth?"
            field="biologicalSex"
            [optionItems]="biologicalSexOptions"
            [hasError]="formHasErrors && personalInfoForm.get('biologicalSex')?.errors?.['required']"
            formControlName="biologicalSex"
            data-test="biologicalSexDropDown"
            errorMsgId="biologicalSexErrorMsg"
          ></pofo-dropdown>

          @if (formHasErrors &&
          personalInfoForm.get('biologicalSex')?.errors?.['required']) {
          <pofo-error-message
            errorId="biologicalSexErrorMsg"
            testId="biologicalSexReqErrorMsg"
            errorMessage="Assigned biological sex at birth is required."
          ></pofo-error-message>
          }
        </div>

        <div class="mb-4 w-full">
          <pofo-dropdown
            label="Which of the following best describes your ethnic origin?"
            field="ethnicity"
            [optionItems]="ethnicityOptions"
            [hasError]="formHasErrors && personalInfoForm.get('ethnicity')?.errors?.['required']"
            formControlName="ethnicity"
            data-test="ethnicityDropDown"
            errorMsgId="ethnicityErrorMsg"
          ></pofo-dropdown>

          @if (formHasErrors &&
          personalInfoForm.get('ethnicity')?.errors?.['required']) {
          <pofo-error-message
            errorId="ethnicityErrorMsg"
            testId="ethnicityReqErrorMsg"
            errorMessage="Ethnicity is required."
          ></pofo-error-message>
          }
        </div>

        <div class="border-t border-platinum my-6 mx-auto"></div>

        <div class="mt-4">
          <button
            class="btn btn-primary btn-medium w-full"
            type="submit"
            data-test="confirmAndContinueBtn"
            [disabled]="loading$ | async"
          >
            Confirm & continue
          </button>
        </div>
      </form>
    </div>
  </pofo-container>
</div>
