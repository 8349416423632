import {
  QUESTIONNAIRE_ELIGIBILITY,
  QUESTIONNAIRE_RESULTS
} from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';

import { HasResultsGuardService } from './guards/has-results.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/questionnaire/questionnaire.component').then(
        (m) => m.QuestionnaireComponent
      )
  },
  {
    path: QUESTIONNAIRE_ELIGIBILITY,
    loadComponent: () =>
      import(
        './pages/eligibility-questionnaire/eligibility-questionnaire.component'
      ).then((m) => m.EligibilityQuestionnaireComponent)
  },
  {
    path: QUESTIONNAIRE_RESULTS,
    canActivate: [HasResultsGuardService],
    loadComponent: () =>
      import('./pages/results/results.component').then(
        (m) => m.QuestionnaireResultsComponent
      )
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuestionnaireRoutingModule {}
