import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { EligibilityQuestionnaireService } from './services/eligibilty-questionnaire.service';
import { HasResultsGuardService } from './guards/has-results.guard';
import { NgModule } from '@angular/core';
import { QuestionnaireRoutingModule } from './questionnaire-routing.module';
import { QuestionnaireService } from './services/questionnaire.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { questionTypesComponents } from './components';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    QuestionnaireRoutingModule,
    ...questionTypesComponents
  ],
  providers: [
    EligibilityQuestionnaireService,
    QuestionnaireService,
    HasResultsGuardService
  ]
})
export class QuestionnaireModule {}
