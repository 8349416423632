import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject
} from '@angular/core';
import { Subject, take } from 'rxjs';
import { AppointmentInterface } from 'src/app/shared/models/appointment.model';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentSyncVisitService } from '../../services/sync-visit.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { CalendarInternalComponent } from 'src/app/modules/appointment/components/calendar-providers/calendar-internal/calendar-internal.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DateList } from 'src/app/components/datetime-picker/@types/datetime-picker.interface';

@Component({
  selector: 'pofo-appointment-sync-visit-select-date',
  templateUrl: './select-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ContainerComponent, CalendarInternalComponent]
})
export class AppointmentSyncVisitSelectDateComponent implements OnDestroy {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _appointmentService = inject(AppointmentService);
  private _syncVisitService = inject(AppointmentSyncVisitService);

  private _destroy$: Subject<void> = new Subject();
  public readonly title = 'Schedule an appointment';
  public readonly description =
    'Select the day and time for your call appointment. Your genetic counselor will call you within the selected two-hour window.';
  public readonly isReschedule = this._appointmentService.isReschedule;
  public selectedLocation: AppointmentType;

  constructor() {
    if (this.isReschedule) {
      this.selectedLocation = this._appointmentService.selectedLocation;
    } else {
      this._syncVisitService
        .getLocations$()
        .pipe(take(2))
        .subscribe((response) => {
          this._appointmentService.setLocation({
            zip: response.value?.address?.postalCode || '',
            city: response.value?.address?.city || '',
            addressLine1: response.value?.address?.addressLine1,
            addressLine2: response.value?.address?.addressLine2,
            name: response.value?.title || '',
            appointmentTypeId:
              this._appointmentService.modalityAppointmentTypeId,
            timezone: undefined,
            calendarProvider: response.value?.calendarProvider
          });
          this.selectedLocation = this._appointmentService.selectedLocation;
        });
    }
  }

  public onConfirmSlot(date: DateList): void {
    const data: AppointmentInterface = {
      startDate: new Date(date.start),
      endDate: new Date(date.end),
      resourceId: date.id
    };

    this._appointmentService.setAppointment(data);

    this._router.navigate(['..', 'confirm'], {
      relativeTo: this._activatedRoute
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
