import * as amplitude from '@amplitude/analytics-browser';
import {
  AppointmentConfirmationResponse,
  AppointmentCreatePayload,
  AppointmentCreateWalkInPayload,
  AppointmentRescheduleResponse
} from 'src/app/shared/@types/appointment.interface';
import { Component, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'pofo-appointment-default-confirm',
  templateUrl: './confirm.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    AlertComponent,
    NgTemplateOutlet,
    RouterLink
  ]
})
export class AppointmentDefaultConfirmComponent {
  private _appointmentService = inject(AppointmentService);

  public readonly title = 'Your Appointment';
  public readonly description = 'Confirm all details about your appointment.';
  public readonly appointment = this._appointmentService.appointment;
  public readonly selectedLocation = this._appointmentService.selectedLocation;
  public readonly parsedDate =
    this._appointmentService.appointment.parsedStartDate(
      this._appointmentService.isWalkin
    );
  public isLoading = false;
  public errorMessage?: string;

  public isBtnDisable(): boolean {
    return this.errorMessage !== undefined || this.isLoading ? true : false;
  }

  public finishBooking(): void {
    amplitude.track('click finish booking', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'appointments (pofo)'
    });

    const appointmentService$ = this._appointmentService.isReschedule
      ? this._appointmentService.rescheduleAppointment$<
          AppointmentCreatePayload,
          AppointmentRescheduleResponse
        >(this._appointmentService.appointment.create())
      : this._appointmentService.appointmentConfirmation$<
          AppointmentCreatePayload | AppointmentCreateWalkInPayload,
          AppointmentConfirmationResponse
        >(
          this._appointmentService.isWalkin
            ? this._appointmentService.appointment.createWalkIn()
            : this._appointmentService.appointment.create()
        );

    appointmentService$
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'appointments (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }
}
