import { WorkflowConfigurationResponse } from '../@types/workflow-configurations.interface';

export interface WorkflowConfigurationInterface {
  schedulerUrl: string;
}

export class WorkflowConfiguration {
  public schedulerUrl;

  constructor(private _data: WorkflowConfigurationResponse) {
    this.schedulerUrl = this._data.schedulerUrl ? this._data.schedulerUrl : '';
  }
}
