import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import {
  CurrentMonth,
  DateList,
  DateTimeList
} from './@types/datetime-picker.interface';
import {
  MatCalendar,
  MatCalendarCellCssClasses
} from '@angular/material/datepicker';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { isAfter, isToday, setDate, setMonth } from 'date-fns';
import { AsyncPipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import { IconComponent } from '../icon/icon.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { format } from 'date-fns-tz';

@Component({
  selector: 'pofo-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCalendar, LoadingSpinnerComponent, IconComponent, AsyncPipe]
})
export class DateTimePickerComponent implements OnInit, OnDestroy {
  private _dateAdapter = inject<DateAdapter<Date>>(DateAdapter);

  // TODO: Deliberate comment, in case it is needed on change of design
  // public DatePickerHeader = DatePickerHeaderComponent;
  private _destroy$: Subject<void> = new Subject();
  private _originAddCalendarMonths: (date: Date, months: number) => Date;
  @ViewChild(MatCalendar) private _calendar!: MatCalendar<Date>;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public availableDateList$: ReplaySubject<DateList[]>;
  @Input() public set initialDate(date: Date | null) {
    if (date) {
      this.selectedDate = date;
      this.startAt = date;
      this.changedDate(date);
    } else {
      this.selectedDate = null;
      this.startAt = null;
    }
  }
  @Input() public isWalkin: boolean;
  @Input() public showParsedTimeStartEnd: boolean;
  @Input() public set selectedDateAvailableSlots(dateTimeList: DateTimeList[]) {
    this.isLoadingSlots = false;
    this.availableSlots = dateTimeList ?? [];
  }
  @Output() public chosenDate: EventEmitter<Date> = new EventEmitter();
  @Output() public chosenMonth: EventEmitter<CurrentMonth> = new EventEmitter();
  @Output() public chosenSlot: EventEmitter<DateTimeList> = new EventEmitter();

  public isLoadingSlots = false;
  public availableDateList: DateList[] = [];
  public startAt: Date | null = null;
  public selectedDate: Date | null = null;
  public availableSlots: DateTimeList[] = [];
  public dateFilter = (_date: Date): boolean => false;
  public dateClass = (_date: Date): MatCalendarCellCssClasses => '';

  constructor() {
    this._originAddCalendarMonths = this._dateAdapter.addCalendarMonths;
    this._dateAdapter.addCalendarMonths = (...args) => {
      this._currentViewingMonth(args[0], args[1]);
      return this._originAddCalendarMonths.apply(this._dateAdapter, args);
    };
  }

  ngOnInit(): void {
    this.availableDateList$
      .pipe(
        takeUntil(this._destroy$),
        tap((res) => (this.availableDateList = res))
      )
      .subscribe((availableDateList) => {
        this.dateFilter = (date: Date): boolean =>
          availableDateList.some((r) => date.getDate() === r.start.getDate());

        this.dateClass = (date: Date): MatCalendarCellCssClasses => {
          const isAvailableDate = availableDateList.some(
            (r) => date.getDate() === r.start.getDate()
          );

          if (
            (isAvailableDate && isToday(date)) ||
            (isAvailableDate && isAfter(date, this.minDate))
          ) {
            return 'data-test-available-date';
          }

          return '';
        };
      });
  }

  private _currentViewingMonth(date: Date, month: number) {
    this.availableDateList$.next([]);
    this.selectedDate = null;
    this.availableSlots = [];

    const updatedMonth = date.getMonth() + month;
    let newDate = setMonth(date, updatedMonth);
    let totalDays = this._dateAdapter.getNumDaysInMonth(newDate);

    newDate = setDate(newDate, 1);

    if (updatedMonth === new Date().getMonth()) {
      newDate = setDate(newDate, new Date().getDate());
      totalDays = Math.abs(totalDays - new Date().getDate() + 1);
    }

    this.chosenMonth.emit({
      dateRaw: newDate,
      date: format(newDate, 'yyyy-MM-dd'),
      nameShort: format(newDate, 'MMM'),
      nameLong: format(newDate, 'MMMM'),
      selectedMonth: updatedMonth,
      totalDays
    });
  }

  public parsedSelectedDate(): string {
    if (this.selectedDate) {
      return format(this.selectedDate, 'EEEE, MMM do');
    }

    return '';
  }

  public changedDate(date: Date | null): void {
    this.chosenSlot.emit();
    this.selectedDateAvailableSlots = [];

    this.isLoadingSlots = true;

    if (date) {
      this.chosenDate.emit(date);
    }
  }

  public changedSlot(date: DateTimeList): void {
    this.chosenSlot.emit(date);
  }

  public trackFn(_i: number, itm: DateTimeList): string {
    return itm.start.toLocaleTimeString();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
