import * as amplitude from '@amplitude/analytics-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ReplaySubject,
  Subject,
  filter,
  map,
  mergeScan,
  takeUntil,
  tap
} from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AmplitudeDirective } from '../../../../../../../shared/amplitude/amplitude.directive';
import { AppointmentDefaultService } from '../../services/default.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { AppointmentTypes } from 'src/app/shared/@types/appointment.interface';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-appointment-default-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    AlertComponent,
    NgClass,
    IconComponent,
    LoadingSpinnerComponent,
    AmplitudeDirective
  ]
})
export class AppointmentDefaultSelectLocationComponent
  implements OnInit, OnDestroy
{
  private _fb = inject(FormBuilder);
  private _appointmentService = inject(AppointmentService);
  private _appointmentInternalService = inject(AppointmentDefaultService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _destroy$: Subject<any> = new Subject();
  public readonly title = 'Schedule an appointment';
  public readonly description =
    'Select the location and date for your appointment.';
  public readonly maxNumberOfItemsPerPage = 5;
  public isLoading = false;
  public isError?: string;
  public selectedIndex = -1;
  public currentShowingListCount = 0;
  public form = this._fb.nonNullable.group<{ search: string }>({ search: '' });
  public locations: AppointmentTypes = { totalCount: 0, results: [] };
  private _currentSearch = '';
  private _searchLocations$: ReplaySubject<string> = new ReplaySubject<string>(
    1
  );

  ngOnInit(): void {
    this._searchLocations$
      .pipe(
        takeUntil(this._destroy$),
        mergeScan(
          (prev, next) =>
            this._appointmentInternalService
              .getLocations$(
                next,
                this.currentShowingListCount,
                this.maxNumberOfItemsPerPage
              )
              .pipe(
                tap((res) => (this.isLoading = res.loading || false)),
                tap(
                  (_res) =>
                    (this.isError = _res.error ? _res.errorMessage : undefined)
                ),
                filter((r) => 'value' in r && r.value !== undefined),
                map((response) => {
                  if (response.value) {
                    if (this.currentShowingListCount === 1) {
                      return response.value;
                    }

                    return {
                      totalCount: response.value.totalCount,
                      results: [...prev.results, ...response.value.results]
                    };
                  }

                  return prev;
                })
              ),
          { totalCount: 0, results: [] } as AppointmentTypes
        )
      )
      .subscribe((res) => {
        if (res.results.length <= 0) {
          this.isError =
            'Sorry, there are no screening events happening in your area at this time';
        }

        this.locations = res;
      });
  }

  public onSelected(location: AppointmentType, selectedIndex: number): void {
    if (selectedIndex === this.selectedIndex) {
      this.selectedIndex = -1;
      this._appointmentService.reset();
    } else {
      this.selectedIndex = selectedIndex;
      this._appointmentService.selectedLocation = location;
    }
  }

  public searchLocations(): void {
    this.locations = { totalCount: 0, results: [] };
    this.isError = undefined;
    this.selectedIndex = -1;
    this._currentSearch = this.form.getRawValue().search;
    this._appointmentService.reset();

    if (this._currentSearch.trim() !== '') {
      this.currentShowingListCount = 1;
      this._searchLocations$.next(this._currentSearch);
    }
  }

  public loadMoreLocations(): void {
    if (this._currentSearch.trim() !== '') {
      this.currentShowingListCount += 1;
      this._searchLocations$.next(this._currentSearch);
      this.isError = undefined;
    }
  }

  public confirmLocation(): void {
    this.selectedIndex = -1;

    amplitude.track('click confirm location', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'appointments (pofo)'
    });

    this._router.navigate(['..', 'date'], {
      relativeTo: this._activatedRoute
    });
  }

  public isSearchBtnDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    return false;
  }

  public isConfirmBtnDisabled(): boolean {
    return (
      !this._appointmentService.selectedLocation?.appointmentTypeIdExists() ||
      this.isLoading
    );
  }

  public locationCanBeBooked(location: AppointmentType): boolean {
    return !location.isFullyBooked || this.walkInsCanBeBooked(location);
  }

  public walkInsCanBeBooked(location: AppointmentType): boolean {
    function formatDate(date: Date): string {
      return new Intl.DateTimeFormat('en-US').format(date);
    }

    if (!location.startDate) return false;

    const isSameDay =
      formatDate(location.startDate.startDate) === formatDate(new Date());

    // walk-ins can only be booked if the current date is the same day or after the startDate
    return location.startDate.startDate <= new Date() || isSameDay;
  }

  public locationCardSubText(location: AppointmentType): string {
    if (!this.locationCanBeBooked(location)) {
      return 'No spots available for this event';
    } else if (location.isFullyBooked && this.walkInsCanBeBooked(location)) {
      return 'Only walk-ins available for this event';
    } else {
      return location.startDate?.parsedStartDate ?? '';
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackFn(i: number, _itm: any): number {
    return i;
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
