/// <reference types="grecaptcha" />
import {
  AppointmentCancelResponse,
  AppointmentTypeResponse,
  AppointmentTypes
} from 'src/app/shared/@types/appointment.interface';
import {
  CreateImmunizationResponse,
  ImmunizationDefinitionResponse
} from '../@types/immunization.interface';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { ImmunizationAppointmentModule } from '../immunization-appointment.module';
import { LoaderState } from 'src/app/models/loading.model';
import { SaveConsentResponse } from '../../consent/@types/consent.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: ImmunizationAppointmentModule
})
export class ImmunizationService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _appointmentService = inject(AppointmentService);
  private _environmentService = inject(EnvironmentService);

  private _immunizationId: string;
  private _immunizationDefinitionId: string;
  private _consentRequirementType: string;

  public reset(): void {
    this._immunizationId = '';
    this._immunizationDefinitionId = '';
    this._consentRequirementType = '';
  }

  public get immunizationId(): string {
    return this._immunizationId;
  }

  public createImmunization$(): Observable<
    LoaderState<CreateImmunizationResponse>
  > {
    return this._httpService
      .post<CreateImmunizationResponse, CreateImmunizationResponse>(
        `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._appointmentService.modalityId}/immunization`,
        {},
        this._responseParser<CreateImmunizationResponse>
      )
      .pipe(
        map((res) => {
          if (res.value && res.value) {
            this._immunizationId = res.value;
          }

          return res;
        })
      );
  }

  public getImmunizationDefinition$(): Observable<
    LoaderState<ImmunizationDefinitionResponse>
  > {
    return this._httpService
      .get<ImmunizationDefinitionResponse, ImmunizationDefinitionResponse>(
        `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._appointmentService.modalityId}/immunization-definition`,
        this._responseParser<ImmunizationDefinitionResponse>
      )
      .pipe(
        map((res) => {
          if (res.value && res.value.id) {
            this._immunizationDefinitionId = res.value.id;
            this._consentRequirementType = res.value.consent[0].requirementType;
          }

          return res;
        })
      );
  }

  public saveImmunizationConsent$(): Observable<
    LoaderState<SaveConsentResponse>
  > {
    return this._httpService.post<SaveConsentResponse, SaveConsentResponse>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/immunization/consent`,
      {
        requirementType: this._consentRequirementType,
        immunizationId: this._immunizationId
      },
      this._responseParser<SaveConsentResponse>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }

  public geAppointmentTypes$(
    searchQ: string,
    page: number,
    numberOfItems: number
  ): Observable<LoaderState<AppointmentTypes>> {
    return this._httpService.get<AppointmentTypeResponse, AppointmentTypes>(
      `${this._environmentService.properties.apiGateway}/program/${
        this._workflowService.currentProgram
      }/appointmentType/modality/${
        this._appointmentService.modalityId
      }/search?type=Immunization&immunizationDefinitionId=${
        this._immunizationDefinitionId
      }&${
        searchQ !== '' ? 'searchAddress=' + searchQ + '&' : ''
      }pageNumber=${page}&pageSize=${numberOfItems}`,
      this._responseAppointmentParser
    );
  }

  private _responseAppointmentParser(
    response: AppointmentTypeResponse
  ): AppointmentTypes {
    return {
      ...response,
      results: response.results.map(
        (result) =>
          new AppointmentType({
            id: result.id,
            zip: result.address.postalCode,
            city: result.address.city,
            addressLine1: result.address.line1 || result.address.addressLine1,
            addressLine2: result.address.addressLine2,
            name: result.title || '',
            appointmentTypeId: result.id,
            timezone: result.address.timeZone ?? result.localTimeZone.ianaCode,
            startDate: result.startDate ?? undefined,
            isFullyBooked: result.isFullyBooked
          })
      )
    };
  }

  public cancelAppointment$(): Observable<
    LoaderState<AppointmentCancelResponse>
  > {
    return this._httpService.delete<
      AppointmentCancelResponse,
      AppointmentCancelResponse
    >(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/appointmentType/${this._appointmentService.selectedLocation.appointmentTypeId}/appointment/immunization/${this._appointmentService.appointmentId}/${this._appointmentService.participationId}`,
      this._responseParser
    );
  }
}
