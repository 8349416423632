<div class="my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto">
  <div class="flex flex-col l:flex-row">
    <div>
      <div
        class="flex flex-col l:flex-row relative {{
          !isCancel ? 'confirmed' : ''
        }}"
      >
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
          iconName="tick-circle"
          [size]="64"
        ></pofo-icon>

        @if (isUpload) {
        <pofo-container
          [border]="false"
          containerTitle="Thank you for submitting your completed form. We will now process your results"
          background="transparent"
        >
          <p class="text-base-lg text-storm text-center mb-8 md:text-left">
            Thank you for updating the biometric screening results from your
            recent visit to your healthcare provider. Your form will be reviewed
            and processed within 3-5 days.
          </p>
          <div class="flex flex-col md:flex-row">
            <button
              class="btn btn-primary btn-medium md:w-2/5"
              data-test="upload-dashboard-button"
              (click)="goToDashboard()"
            >
              Go to Dashboard
            </button>
          </div>
        </pofo-container>
        } @if (isCancel) {
        <pofo-container
          [border]="false"
          containerTitle="Physician results form canceled"
          background="transparent"
        >
          <p class="text-base-lg text-storm text-center mb-8 md:text-left">
            Your physician result form has been canceled. We’ll send the
            confirmation and details to your email address.
          </p>
          <div class="flex flex-col md:flex-row">
            <button
              class="btn btn-primary btn-medium md:w-2/5 mt-8 md:mt-0"
              data-test="goto-dashboard-button"
              (click)="goToDashboard()"
            >
              Go to Dashboard
            </button>
          </div>
        </pofo-container>
        } @if (!isCancel && !isUpload) {
        <pofo-container
          [border]="false"
          containerTitle="Physician Results Form generated"
          background="transparent"
        >
          <div class="min-h-[215px]">
            @if (isDocumentReady) {
            <p class="text-base-lg text-storm text-center mb-8 md:text-left">
              Your Physician Result Form has been generated. We’ll send a
              confirmation and the details to your email address.
            </p>
            <div class="flex flex-col md:flex-row">
              <a
                [href]="selectedFileBLOB"
                id="download-form"
                data-test="download-form-button"
                target="_blank"
                class="btn btn-primary w-full md:w-2/5"
                download="{{ downloadFormFileName }}"
                [pofoAmplitudeButton]="'click download form'"
                [pofoAmplitudeData]="{ category: 'physician forms (pofo)' }"
                >Download</a
              >
              <button
                class="btn btn-secondary btn-medium md:w-2/5 mt-8 md:mt-0 md:ml-8"
                data-test="goto-dashboard-button"
                (click)="goToDashboard()"
              >
                Go to Dashboard
              </button>
            </div>
            } @else {
            <div class="flex justify-center p-4">
              @if (isError) {
              <pofo-alert type="error"
                >An error occurred while generating your form. Please contact
                your organization.</pofo-alert
              >
              } @if (!isError) {
              <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
              }
            </div>
            }
          </div>
        </pofo-container>
        }
      </div>
    </div>

    <img
      class="self-center l:self-start sm:h-[360px]"
      src="{{ assetPath }}images/illustration-thankyou.jpg"
      alt="Thank you Illustration"
    />
  </div>
  @if (!isCancel) {
  <div class="flex flex-col l:flex-row relative">
    <pofo-icon
      class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
      iconName="tick-schedule"
      [size]="64"
    ></pofo-icon>
    <pofo-container
      [border]="false"
      containerTitle="Next steps"
      background="transparent"
    >
      <div
        class="flex flex-col justify-items-center md:justify-items-start md:flex-row"
      >
        @for ( nextStep of isUpload ? nextStepsUpload : nextStepsDownload ;
        track nextStep) {
        <pofo-card
          title="{{ nextStep.title }}"
          textPosition="left"
          [image]="nextStep.image"
          >{{ nextStep.content }}</pofo-card
        >
        }
      </div>
    </pofo-container>
  </div>
  }
</div>
