import * as amplitude from '@amplitude/analytics-browser';
import {
  AccountProgramConsentStepConfig,
  StepConfigResponse
} from 'src/app/shared/@types/step-configuration.interface';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject
} from '@angular/core';
import { Observable, take, tap } from 'rxjs';
import { AccountConsentService } from '../../services/account-consent/account-consent.service';
import { AccountResellerConsent } from '../../@types/consent.interface';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { LoaderState } from 'src/app/models/loading.model';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { ProgramService } from 'src/app/services/program/program.service';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { WorkflowStepsEnum } from 'src/app/services/workflow/workflow-steps.enum';

@Component({
  selector: 'pofo-account-consent',
  templateUrl: './account-consent.component.html',
  styleUrls: ['./account-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AlertComponent, LoadingSpinnerComponent, AsyncPipe, SafePipe]
})
export class AccountConsentComponent {
  private _accountConsentService = inject(AccountConsentService);
  private _workflowService = inject(WorkflowService);
  private _programService = inject(ProgramService);

  @HostListener('window:message', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(event: any) {
    if (event?.data?.iframeHeight) {
      this.iframe.nativeElement.height = event.data.iframeHeight;
    }
  }
  @ViewChild('iFrame') public iframe: ElementRef;
  private workflowStep: WorkflowStepsEnum.Consent | WorkflowStepsEnum.Consent1;
  public readonly programType = this._workflowService.programType;
  public consentIndex = 0;
  public isLoading = true;
  public isSkippable = false;
  public getProgramConsentConfig$: Observable<
    LoaderState<StepConfigResponse<AccountProgramConsentStepConfig>>
  >;
  public getResellerConsentConfig$: Observable<
    LoaderState<AccountResellerConsent[]>
  >;

  constructor() {
    this._workflowService.currentFlow$.subscribe((response) => {
      this.consentIndex = response.includes('consent-') ? 1 : 0; // TODO: <-- this needs to be more dynamic
      this.workflowStep = response.includes('consent-')
        ? WorkflowStepsEnum.Consent1
        : WorkflowStepsEnum.Consent;

      this.getProgramConsentConfig$ =
        this._programService.getStepConfig$<AccountProgramConsentStepConfig>(
          this.workflowStep
        );

      this.getResellerConsentConfig$ =
        this._accountConsentService.getResellerConsent$();
    });

    this.isLoading = false;
  }

  public handleProgramOnClick(
    consent: AccountProgramConsentStepConfig,
    skip = false
  ): void {
    this._accountConsentService
      .saveProgramConsent$([consent.consentName], this.workflowStep, skip)
      .pipe(
        take(2),
        tap((res) => (this.isLoading = res.loading || false))
      )
      .subscribe((response) => {
        if (response.onCompleteCallback) {
          amplitude.track(skip ? 'click decline' : 'click agree', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });
          response.onCompleteCallback();
        }
      });
  }

  public handleResellerOnClick(
    consent: AccountResellerConsent,
    skip = false
  ): void {
    this._accountConsentService
      .saveResellerConsent$(consent.stepKey)
      .pipe(
        take(2),
        tap((res) => (this.isLoading = res.loading || false))
      )
      .subscribe((response) => {
        if (response.onCompleteCallback) {
          amplitude.track(skip ? 'click decline' : 'click agree', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });
          response.onCompleteCallback();
        }
      });
  }
}
