<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>

    <div class="p-4 mt-6 border rounded-md border-platinum bg-white relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        {{ appointment.modalityType[2] }}
      </div>

      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="flu" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Flu Vaccine</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>

      @if (!!errorMessage) {
      <pofo-alert type="error" data-test="error-message">{{
        errorMessage
      }}</pofo-alert>
      }

      <div class="line"></div>

      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{
          context: {
            icon: 'building',
            title: 'Location',
            subTitle: selectedLocation.name,
            content:
              selectedLocation.address +
              selectedLocation.city +
              ', ' +
              selectedLocation.zip
          }
        }"
      ></ng-container>

      <ng-container
        [ngTemplateOutlet]="item"
        [ngTemplateOutletContext]="{
          context: {
            icon: 'calendar-date',
            title: 'Date and time',
            subTitle: parsedDate,
            content: undefined
          }
        }"
      ></ng-container>

      <div class="line"></div>

      <a
        data-test="change-button"
        [routerLink]="['../date']"
        target="_self"
        rel="noopener noreferrer"
        class="flex justify-center md:justify-start pl-2 mb-2"
      >
        <span class="text-sm font-bold">Change appointment</span>
      </a>
    </div>

    <div class="line"></div>

    <div>
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="finish-button"
        (click)="finishBooking()"
        [disabled]="isBtnDisable()"
      >
        Confirm appointment
      </button>
    </div>
  </pofo-container>
</div>

<ng-template #item let-data="context">
  <div class="flex justify-start mt-3 mb-5">
    <div class="w-12 pr-2 pt-1">
      <pofo-icon iconName="{{ data.icon }}" [size]="24"></pofo-icon>
    </div>
    <div class="w-4/5">
      <div class="text-navy text-base font-bold">{{ data.title }}</div>
      <div class="text-storm text-sm font-normal">
        <div>{{ data.subTitle }}</div>
        <div>{{ data.content }}</div>
      </div>
    </div>
  </div>
</ng-template>
