import * as amplitude from '@amplitude/analytics-browser';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  QuestionnaireAnswer,
  QuestionnaireLabel,
  QuestionnaireQuestion
} from '../../@types/questionnaire.interface';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { MultiSelectComponent } from 'src/app/modules/questionnaire/components/multi-select/multi-select.component';
import { QuestionnaireEligibilityAnswer } from 'src/app/modules/questionnaire/@types/eligibility-questionnaire.interface';
import { QuestionnaireService } from 'src/app/modules/questionnaire/services/questionnaire.service';
import { SingleSelectComponent } from 'src/app/modules/questionnaire/components/single-select/single-select.component';
import { StringToHtmlDirective } from 'src/app/shared/directives/parse-string-html.directive';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';
import { TextAreaComponent } from 'src/app/modules/questionnaire/components/text-area/textarea.component';

@Component({
  selector: 'pofo-questionnaire',
  templateUrl: './questionnaire.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    StringToHtmlDirective,
    MultiSelectComponent,
    SingleSelectComponent,
    TextAreaComponent,
    LoadingSpinnerComponent
  ]
})
export class QuestionnaireComponent {
  private _questionnaireService = inject(QuestionnaireService);
  private _syncVisitService = inject(SyncVisitService);

  public isLoading = false;
  public errorMessage?: string;
  private _questionnaireAnswers: QuestionnaireAnswer[] = [];
  public currentQuestion: QuestionnaireQuestion | undefined;
  private _questionnaireResourceId: string;
  public currentQuestionNumber = 1;
  public totalAmountOfQuestions: number;
  public isLastQuestion = false;
  public labels?: QuestionnaireLabel | null;

  constructor() {
    this._questionnaireService
      .getQuestionnaire$(this._syncVisitService.modalityId)
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        }),
        filter((res) => !res.loading),
        switchMap((questionnaire) =>
          this._questionnaireService
            .startQuestionnaire$(
              this._syncVisitService.modalityId,
              this._syncVisitService.syncVisitId,
              questionnaire.value?.questionnaireId
            )
            .pipe(
              map((questionnaireResourceId) => ({
                questionnaire: questionnaire.value,
                questionnaireResourceId: questionnaireResourceId.value
              }))
            )
        )
      )
      .subscribe((res) => {
        if (res.questionnaire && res.questionnaireResourceId) {
          amplitude.track('start questionnaire', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          this.currentQuestion =
            this._questionnaireService.buildAndGetFirstQuestion(
              res.questionnaire
            );

          this._questionnaireResourceId = res.questionnaireResourceId;

          this.totalAmountOfQuestions =
            this._questionnaireService.questionnaire.questions.length;
          this.labels = this._questionnaireService.questionnaire.labels;
        }
      });
  }

  public onQuestionComplete(
    questionAnswer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): void {
    const answer = this._isQuestionnaireAnswer(questionAnswer)
      ? questionAnswer
      : undefined;

    if (answer) {
      this.isLastQuestion =
        this.currentQuestionNumber >= this.totalAmountOfQuestions;
      this._questionnaireAnswers = [...this._questionnaireAnswers, answer];
    }

    this.currentQuestion =
      this._questionnaireService.getNextQuestion(questionAnswer);

    if (this.currentQuestion === undefined) {
      this._questionnaireService
        .submitQuestionnaire$(
          this._syncVisitService.modalityId,
          this._questionnaireResourceId,
          this._questionnaireAnswers
        )
        .pipe(
          take(2),
          tap((res) => {
            if (res.error) {
              this.errorMessage = res.errorMessage ?? 'There was an error';

              amplitude.logEvent('error', {
                location: window?.location.href,
                origin: 'pofo',
                category: 'order forms (pofo)',
                errorMessage: this.errorMessage
              });
            } else {
              this.errorMessage = undefined;
            }

            this.isLoading = res.loading ?? false;
          }),
          filter(() => !this.isLoading)
        )
        .subscribe((res) => {
          amplitude.track('complete questionnaire', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          if (res.onCompleteCallback) {
            res.onCompleteCallback();
          }
        });
    } else {
      this.currentQuestionNumber++;
    }
  }

  private _isQuestionnaireAnswer(
    answer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): answer is QuestionnaireAnswer {
    return answer && 'options' in answer;
  }

  public getCurrentProgressBarWidth(): string {
    return (
      (this.currentQuestionNumber / this.totalAmountOfQuestions) * 100 + '%'
    );
  }
}
