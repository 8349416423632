import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { PersonalInformationComponent } from './personal-information.component';
import { PreActivationRoutingModule } from './personal-information-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    PreActivationRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    PersonalInformationComponent
  ]
})
export class PersonalInformationModule {}
