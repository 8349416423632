<div class="relative mb-2">
  <pofo-icon class="icons" iconName="credit-card" [size]="32"></pofo-icon>
  @if (!!cardIconSrc) {
  <pofo-icon
    class="w-[64px] card-icon"
    [size]="64"
    [ngClass]="{ is_hidden: cardIconHidden }"
    iconName="{{ cardIconSrc }}"
  ></pofo-icon>
  }
  <label class="label" for="txtCardNumber">Card number</label>
  <div id="txtCardNumber" #txtCardNumber>&nbsp;</div>
  @if (txtCardNumberFeedback) {
  <pofo-alert class="mt-1" type="error">
    {{ txtCardNumberFeedback }}
  </pofo-alert>
  }
</div>

<div class="flex flex-col justify-between md:flex-row">
  <div class="relative w-full md:w-2/4 md:mr-1">
    <pofo-icon class="icons" iconName="calendar-date" [size]="32"></pofo-icon>
    <label class="label" for="txtExpiry">Expiry Date</label>
    <div id="txtExpiry" #txtExpiry>&nbsp;</div>
    @if (txtExpiryFeedback) {
    <pofo-alert class="mt-1" type="error">
      {{ txtExpiryFeedback }}
    </pofo-alert>
    }
  </div>
  <div class="relative w-full md:w-2/4 md:ml-1">
    <pofo-icon class="icons" iconName="lock-locked" [size]="32"></pofo-icon>
    <label class="label" for="txtCvc">Security code</label>
    <div id="txtCvc" #txtCvc>&nbsp;</div>
    @if (txtCvcFeedback) {
    <pofo-alert class="mt-1" type="error">
      {{ txtCvcFeedback }}
    </pofo-alert>
    }
  </div>
</div>

@if (errorMessage.length > 0) {
<div class="col-12">
  <div class="alert alert-danger">{{ errorMessage }}</div>
</div>
}
