<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto relative">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>
    <div
      class="flex items-center justify-between mb-2 w-full shadow-inset shadow-cloudy p-4 border rounded-md border-platinum bg-white"
    >
      <div class="flex flex-col">
        <div
          class="flex justify-between text-navy text-base-lg font-normal mb-2"
        >
          <span>{{ selectedLocation.name }}</span>
        </div>
        <div class="text-left text-storm text-sm font-normal">
          <div>
            {{ selectedLocation.address }}
          </div>
          <div>
            <span>{{ selectedLocation.city }}, </span>
            <span>{{ selectedLocation.zip }}</span>
          </div>
        </div>
      </div>

      @if (!isReschedule) {
      <button
        class="btn btn-secondary btn-xsmall"
        data-test="change-button"
        (click)="gotoSelectLocation()"
      >
        Change
      </button>
      }
    </div>

    <div class="line"></div>

    <pofo-datetime-picker
      [minDate]="minDate"
      [availableDateList$]="availableDaysInMonth$"
      [selectedDateAvailableSlots]="selectedDateAvailableSlots"
      [initialDate]="appointmentStartDate"
      [isWalkin]="isWalkin"
      (chosenMonth)="monthHasChanged($event)"
      (chosenDate)="selectedDate($event)"
      (chosenSlot)="selectedSlot($event)"
    ></pofo-datetime-picker>

    <div class="line"></div>

    <button
      class="btn btn-primary btn-medium w-full"
      data-test="confirm-button"
      [disabled]="isConfirmBtnDisabled()"
      (click)="onConfirmSlot()"
      [pofoAmplitudeButton]="'click confirm selection'"
    >
      Confirm selection
    </button>
  </pofo-container>
</div>
