import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';

// NOTE: Remove this service and use OIDC implementation once we upgrade angular and the library
// https://angular-auth-oidc-client.com/docs/documentation/configuration#using-localstorage-instead-of-default-sessionstorage
@Injectable({
  providedIn: 'root'
})
export class DefaultLocalStorageService implements AbstractSecurityStorage {
  public read(key: string): string | null {
    return localStorage.getItem(key);
  }

  public write(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    localStorage.clear();
  }
}
