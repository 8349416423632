import { environment as devEnv } from 'src/environments/environment.dev';
import { environment as prodEnv } from 'src/environments/environment.prod';
import { environment as qaEnv } from 'src/environments/environment.qa';
import { environment as stgEnv } from 'src/environments/environment.staging';

export interface Environment {
  production: boolean;
  origin: string;
  apiGateway: string;
  apiGatewayV2: string;
  assetPath: string;
  oneTrustId: string;
  gtmId: string;
  appIconPath: string;
  recaptchaSiteKey: string;
  healthGateway: string;
  authorityURL: string;
  patientDashboardURL: string;
  geneticsHubURL: string;
  stripe: {
    [key: string]: string;
  };
  amplitudeKey: string;
  isDev: boolean;
}

export const environment: {
  url: () => string;
  region: () => string;
  get: () => Environment;
} = {
  url: () => location.host,
  region: () => {
    const region = location.pathname.split('/')[1];
    return region.length === 2 ? region : 'us';
  },
  get: () => {
    switch (location.host) {
      case 'order.letsgetchecked.com':
        return _setEnvironment(prodEnv);

      case 'order.letsgetchecked-stg2.com':
        return _setEnvironment(stgEnv);

      case 'order.letsgetchecked-qa1.com':
        return _setEnvironment(qaEnv);

      case 'order.letsgetchecked-dev1.com':
        return _setEnvironment(devEnv);

      default:
        return _setEnvironment(devEnv);
    }
  }
};

const _setEnvironment = (env: Environment): Environment => {
  const region = environment.region();
  Object.keys(env)
    .filter((key) => key.includes('apiGateway'))
    .forEach((apiGatewayKey) => {
      (env[apiGatewayKey as keyof Environment] as string) = (
        env[apiGatewayKey as keyof Environment] as string
      ).replace('-<region>', region != 'us' ? '' : `-${region}`);
    });

  return env;
};
