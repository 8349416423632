import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { EndOfJourneyComponent } from './end-of-journey.component';
import { EndOfJourneyRoutingModule } from './end-of-journey-routing.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    EndOfJourneyRoutingModule,
    AngularSvgIconModule,
    TranslateModule,
    EndOfJourneyComponent
  ]
})
export class EndOfJourneyModule {}
