/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import {
  Questionnaire,
  QuestionnaireAnswer
} from 'src/app/modules/questionnaire/@types/questionnaire.interface';
import { CoreQuestionnaire } from './core-questionnaire';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../../../services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class QuestionnaireService extends CoreQuestionnaire {
  private _http = inject(HttpService);
  private _environmentService = inject(EnvironmentService);
  private _workflowService = inject(WorkflowService);

  public getQuestionnaire$(
    modalityId: string
  ): Observable<LoaderState<Questionnaire>> {
    return this._http.get<Questionnaire, Questionnaire>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/questionnaire`,
      this.responseParser<Questionnaire>
    );
  }

  public startQuestionnaire$(
    modalityId: string,
    domainSpecificId: string,
    questionnaireKey?: number
  ): Observable<LoaderState<string>> {
    return this._http.post(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/questionnaire/start`,
      {
        questionnaireKey,
        domainSpecificId
      },
      this.responseParser<string>
    );
  }

  public submitQuestionnaire$(
    modalityId: string,
    resourceId: string,
    answers: QuestionnaireAnswer[]
  ): Observable<LoaderState<boolean>> {
    return this._http.post<boolean, boolean>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/questionnaire/finish`,
      { resourceId, answers },
      this.responseParser<boolean>
    );
  }
}
