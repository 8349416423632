import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { PcpConfirmComponent } from './pcp-confirm/pcp-confirm.component';
import { PcpDetailsComponent } from './pcp-details.component';
import { PcpDetailsItemComponent } from './pcp-confirm/pcp-details-item/pcp-details-item.component';
import { PcpDetailsRoutingModule } from './pcp-details-routing.module';
import { PcpItemComponent } from './pcp-search-list/pcp-item/pcp-item.component';
import { PcpSearchComponent } from './pcp-search/pcp-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchListComponent } from './pcp-search-list/pcp-search-list.component';

@NgModule({
  imports: [
    CommonModule,
    PcpDetailsRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    PcpDetailsComponent,
    SearchListComponent,
    PcpConfirmComponent,
    PcpDetailsItemComponent,
    PcpItemComponent,
    PcpSearchComponent
  ]
})
export class PcpDetailsModule {}
