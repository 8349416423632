import { AuthComponent } from './components/auth/auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { OidcAuthCheckGuard } from './guard/oidc-auth.guard';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    AuthComponent,
    AuthRoutingModule,
    CommonModule,
    ComponentsModule,
    SharedModule
  ],
  providers: [OidcAuthCheckGuard, AuthService]
})
export class AuthModule {}
