import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { ParticipantService } from '../participant/participant.service';
import { PersonalInformationForm } from 'src/app/models/personal-information.model';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalInformationService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _participantService = inject(ParticipantService);
  private _environmentService = inject(EnvironmentService);

  phrResponseParser(response: boolean) {
    return response;
  }

  public sendPersonalInformation(
    formData: PersonalInformationForm
  ): Observable<LoaderState<boolean>> {
    const endpoint = `phr/${
      this._workflowService.programType === 'program' ? '' : 'reseller/'
    }${this._workflowService.currentProgram}`;
    return this._httpService
      .post<boolean, boolean>(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        formData,
        this.phrResponseParser
      )
      .pipe(
        take(2),
        map((response: LoaderState<boolean>) => {
          if (!response.loading && response.success) {
            this._participantService.updateParticipantPersonalData(formData);
          }
          return response;
        })
      );
  }
}
