import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RedirectToExternalWellFargoUrl {
  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean {
    window.location.replace('https://www.letsgetchecked.com/wf-covid/');
    return false;
  }
}
