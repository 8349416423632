<div class="my-12 md:my-16 md:w-[540px] l:w-[900px] mx-4 md:mx-auto">
  <div class="flex flex-col l:flex-row l:justify-between l:items-start">
    <pofo-container
      [border]="false"
      [containerTitle]="'Welcome to LetsGetChecked'"
      background="transparent"
    >
      <div
        class="mb-3 flex flex-col justify-center items-center md:items-start md:flex-row md:flex-wrap md:justify-start"
      >
        <p class="mb-3 font-bold">
          We combine diverse disciplines to deliver a seamless expert experience
          across all aspects of our health.
        </p>
        <p class="mb-3">
          Identify indicators of potential health issues and get support to help
          you change lifestyle habits and decrease your health risk.
        </p>
      </div>
      <div class="mb-3">
        @if (!!errorMessage) {
        <pofo-alert type="error">{{ errorMessage }} </pofo-alert>
        }

        <button
          class="btn btn-primary btn-medium"
          data-test="start-button"
          (click)="onStart()"
          [disabled]="isLoading"
        >
          Start here
        </button>
      </div>
    </pofo-container>
    <img
      src="{{ assetPath }}images/welcome.png"
      alt="Welcome to LetsGetChecked"
      class="flex"
    />
  </div>
</div>
