import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ProgramService } from 'src/app/services/program/program.service';
import { StartJourneyService } from '../services/start-journey.service';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';
import { take } from 'rxjs';

@Component({
  selector: 'pofo-start-journey',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class StartJourneyComponent {
  private _startJourneyService = inject(StartJourneyService);
  private _programService = inject(ProgramService);
  private _syncVisitService = inject(SyncVisitService);

  constructor() {
    this._programService.showSteps$.next(false);

    this._startJourneyService
      .startJourney$()
      .pipe(take(2))
      .subscribe((res) => {
        if (!res.loading && res.onCompleteCallback) {
          this._syncVisitService.syncVisitId = res.value ?? '';
          this._programService.showSteps$.next(true);
          res.onCompleteCallback();
        }
      });
  }
}
