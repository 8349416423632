<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>
    <form [formGroup]="form" (ngSubmit)="searchLocations()">
      <pofo-input
        data-test="location-search-input"
        type="search"
        icon="search-lg"
        name="search-bar"
        label="Search by location or ZIP code"
        [isRoundedSides]="true"
        maxlen="255"
        formControlName="search"
        (inputCleared)="searchLocations()"
      ></pofo-input>
    </form>

    @if (!isLoading) { @if (locations) { @if (!isError) {
    <div class="pl-1 py-3 text-navy text-base font-bold">
      Showing
      {{ locations.results.length }} of {{ locations.totalCount }} results based
      on your search.
    </div>
    } @else {
    <pofo-alert type="error" class="mt-4">
      {{ isError }}
    </pofo-alert>
    }
    <ul>
      @for ( location of locations.results; track trackFn(i, location); let i =
      $index) {
      <li class="flex items-center justify-start mb-2">
        <button
          class="w-full shadow-inset shadow-cloudy p-4 border rounded-md border-platinum"
          type="button"
          data-test="location-list-button"
          [ngClass]="{
            selected: selectedIndex === i,
            'bg-off-white !cursor-default': !this.locationCanBeBooked(location),
            'bg-white': this.locationCanBeBooked(location)
          }"
          (click)="
            this.locationCanBeBooked(location) && onSelected(location, i)
          "
        >
          <div class="flex justify-between text-navy mb-2">
            <span
              class="text-base-lg font-light text-navy text-left"
              [ngClass]="{
                'text-storm': !this.locationCanBeBooked(location)
              }"
              >{{ location.name }}</span
            >
            @if (selectedIndex === i) {
            <span
              ><pofo-icon
                class="text-teal-300"
                iconName="check-tick-lg"
                [size]="24"
              ></pofo-icon
            ></span>
            }
          </div>
          <div
            class="flex flex-col text-left text-sm font-normal mb-2 text-storm"
          >
            <div [ngClass]="{ 'text-storm': selectedIndex === i }">
              {{ location.address }}
            </div>
            <div [ngClass]="{ 'text-storm': selectedIndex === i }">
              <span>{{ location.city }}, </span>
              <span>{{ location.zip }}</span>
            </div>
          </div>
          <div
            class="text-left text-sm font-normal text-dark-green"
            [ngClass]="{
              'text-storm': !this.locationCanBeBooked(location)
            }"
          >
            {{ this.locationCardSubText(location) }}
          </div>
        </button>
      </li>
      }
    </ul>
    @if ( locations.totalCount > maxNumberOfItemsPerPage &&
    locations.results.length < locations.totalCount ) {
    <div class="flex justify-center">
      <button
        type="button"
        class="flex items-center text-teal-600 ml-2"
        data-test="load-more-button"
        (click)="loadMoreLocations()"
        [disabled]="isLoading"
      >
        Load More
        <pofo-icon iconName="chevron-down-sm" [size]="20"></pofo-icon>
      </button>
    </div>
    } } } @else {
    <div class="flex justify-center p-4">
      <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
    </div>
    }

    <div class="line"></div>

    @if (selectedIndex === -1 || isError || isLoading) {
    <button
      class="btn btn-primary btn-medium w-full"
      data-test="search-button"
      [disabled]="isSearchBtnDisabled()"
      (click)="searchLocations()"
      [pofoAmplitudeButton]="'click search location'"
    >
      Search Location
    </button>
    } @if (!isError && !isLoading && selectedIndex !== -1) {
    <button
      class="btn btn-primary btn-medium w-full"
      data-test="confirm-button"
      [disabled]="isConfirmBtnDisabled()"
      (click)="confirmLocation()"
    >
      Confirm Location
    </button>
    }
  </pofo-container>
</div>
