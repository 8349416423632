import { CALENDAR_PROVIDERS } from './calendar-providers';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  exports: [...CALENDAR_PROVIDERS],
  imports: [CommonModule, SharedModule, ComponentsModule, ...CALENDAR_PROVIDERS]
})
export class AppointmentComponentModule {}
