import * as amplitude from '@amplitude/analytics-browser';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AppSettings } from './shared/app.settings';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from 'src/environments/environment.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowService } from './services/workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private _document = inject<Document>(DOCUMENT);
  private _envService = inject(EnvironmentService);
  private _rendererFactory = inject(RendererFactory2);
  private _gtmService = inject(GoogleTagManagerService);
  private _i18nService = inject(TranslateService);
  private _router = inject(Router);
  private _oidcSecurityService = inject(OidcSecurityService);
  private _workflowService = inject(WorkflowService);

  private _renderer: Renderer2;

  constructor() {
    this._renderer = this._rendererFactory.createRenderer(null, null);

    this._i18nService.addLangs(['en-en']);
    this._i18nService.setDefaultLang('en-en');
    this._i18nService.use('en-en');
  }

  public async load(): Promise<void> {
    try {
      await this.addGTMtoDom();
      this._router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const parsedUrl = item.url.split('/');
          const gtmTag = {
            event: 'pofoPageView',
            pofoPageUrl: item.url,
            pofoPageTitle: parsedUrl[parsedUrl.length - 1 || 0]
          };

          this._gtmService.pushTag(gtmTag);
          amplitude.track('page_view', {
            location: window?.location.href,
            origin: 'pofo'
          });
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {}
    this._addOneTrustScript();
    this._favIcon();
    this._addRecaptchaScript();
    this._addAmplitude();
  }

  public async addGTMtoDom(): Promise<void> {
    await this._gtmService.addGtmToDom();
  }

  public _addRecaptchaScript(): void {
    const recaptchaKey = this._envService.properties.recaptchaSiteKey;
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${recaptchaKey}`;
    recaptchaScript.type = 'text/javascript';
    document.body.appendChild(recaptchaScript);

    // Add hidden label for recaptcha response (for accessibility)
    const label = document.createElement('label');
    label.innerHTML = 'Google Recaptcha';
    label.className = 'hidden';
    label.htmlFor = 'g-recaptcha-response-100000';
    document.body.appendChild(label);
  }

  public _addOneTrustScript(): void {
    const oneTrustId = this._envService.properties.oneTrustId;
    const script = this._renderer.createElement('script');
    script.src = this.getOneTrustUrl();
    script.type = 'text/javascript';
    script.setAttribute('data-domain-script', oneTrustId);

    this._renderer.appendChild(this._document.body, script);
  }

  public getOneTrustUrl(): string {
    return 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  }

  public _addAmplitude(): void {
    if (AppSettings.performanceCookie) {
      amplitude.init(this._envService.properties.amplitudeKey, {
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false
        }
      });
    }
  }

  public _favIcon(): void {
    const favIconEl = this._document.getElementById(
      'appIcon'
    ) as HTMLLinkElement | null;

    if (favIconEl !== null) {
      this._renderer.setAttribute(
        favIconEl,
        'href',
        `${this._envService.properties.appIconPath}favicon.ico`
      );
    }
  }

  public checkAuth$(): Observable<LoginResponse> {
    return this._oidcSecurityService.checkAuth().pipe(
      tap((auth) => {
        this._workflowService.programId = auth.userData?.tenant || '';
      })
    );
  }
}
