<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container containerTitle="Authorization">
    @if (!errorMessage) {
    <div>
      <div class="flex flex-col items-center justify-between">
        <pofo-loading-spinner></pofo-loading-spinner>
        <p class="mt-4">{{ stepMessage }}</p>
      </div>
    </div>
    } @else {
    <pofo-alert type="error">{{ errorMessage }} </pofo-alert>
    <button class="btn btn-primary btn-medium w-full mb-4" (click)="login()">
      Try again
    </button>
    }
  </pofo-container>
</div>
