import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class PatientInterceptor {
  private _cookieService = inject(CookieService);
  private _patientService = inject(PatientService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this._patientService.patientProfileId = this._cookieService.get('x-lgc-pp');
    this._patientService.patientGeo =
      this._cookieService.get('x-lgc-patient-geo');

    const req = request.clone({
      headers: request.headers
        .set('x-lgc-pp', this._patientService.patientProfileId)
        .set('x-lgc-patient-geo', this._patientService.patientGeo)
    });

    return next.handle(req);
  }
}
