import * as amplitude from '@amplitude/analytics-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { skip, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AmplitudeDirective } from '../../../../shared/amplitude/amplitude.directive';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PhysicianFormService } from 'src/app/shared/services/physician-form/physician-form.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';

@Component({
  selector: 'pofo-physician-manage',
  templateUrl: './manage.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    AlertComponent,
    AmplitudeDirective,
    LoadingSpinnerComponent,
    ModalComponent,
    AsyncPipe
  ]
})
export class PhysicianManageComponent {
  private _document = inject<Document>(DOCUMENT);
  private _environmentService = inject(EnvironmentService);
  private _physicianFormService = inject(PhysicianFormService);
  private _programService = inject(ProgramService);
  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _sanitizer = inject(DomSanitizer);
  private readonly _urlParamsService = inject(UrlParamsService);

  public readonly title = 'Physician results form';
  public readonly description =
    'Review and confirm all details about your physician form.';
  public readonly patient$ = this._physicianFormService.getPatient$();
  public readonly downloadFormFileName = 'physicianForm.pdf';
  public isCancel = false;
  public isLoading = false;
  public errorMessage?: string;
  public selectedFileBLOB: SafeUrl;
  private readonly _modalityId = this._urlParamsService.get('modalityId');

  constructor() {
    this._programService.showSteps$.next(false);

    this._setupForm();
  }

  public uploadForm(): void {
    this._programService.showSteps$.next(true);
    this._router.navigate(['..', 'upload'], { relativeTo: this._route });
  }

  private _setupForm(): void {
    if (!this._modalityId || !this._physicianFormService.getPhysicianFormId()) {
      amplitude.track('setup form', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)',
        errorMessage: 'missing modalityId or physicianFormId'
      });

      this._router.navigate(['/something-went-wrong']);
      return;
    }

    this._physicianFormService
      .getForm$()
      .pipe(skip(1), take(2))
      .subscribe((res) => {
        if (res.value && res.value.content && res.value.contentType) {
          const byteArray = new Uint8Array(
            atob(res.value.content)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          const blob = new Blob([byteArray], { type: res.value.contentType });
          const url = window.URL.createObjectURL(blob);

          this.selectedFileBLOB = this._sanitizer.bypassSecurityTrustUrl(url);
        }
      });
  }

  public cancelForm(): void {
    this.isCancel = true;
  }

  public modalConfirmCancel() {
    if (!this._modalityId || !this._physicianFormService.getPhysicianFormId()) {
      amplitude.track('click confirm cancel', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)',
        errorMessage: 'missing modalityId or physicianFormId'
      });

      this._router.navigate(['/something-went-wrong']);
      return;
    }

    this.errorMessage = undefined;
    this._physicianFormService.isCancel = this.isCancel = true;

    this._physicianFormService
      .cancelForm$()
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this._physicianFormService.isCancel = this.isCancel = false;
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'physician form (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        amplitude.track('click confirm cancel', {
          location: window?.location.href,
          origin: 'pofo',
          category: 'physician form (pofo)'
        });

        if (!res.loading && res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }

  public toDashboard(): void {
    this._document.location.href =
      this._environmentService.properties.patientDashboardURL;
  }

  public modalKeepButton() {
    this.isCancel = false;
  }

  public isDisabled(): boolean {
    let isDisabled = false;

    if (this.errorMessage !== undefined || this.isLoading) {
      isDisabled = true;
    }

    return isDisabled;
  }
}
