import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  QuestionnaireAnswer,
  QuestionnaireQuestion
} from '../../@types/questionnaire.interface';
import { QuestionnaireEligibilityAnswer } from '../../@types/eligibility-questionnaire.interface';

@Directive()
export abstract class CoreQuestionType<T> {
  @Input() public isLastQuestion: boolean;
  @Output() public questionCompleted: EventEmitter<
    QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  > = new EventEmitter<QuestionnaireAnswer | QuestionnaireEligibilityAnswer>();

  protected _question: QuestionnaireQuestion;
  protected submitButtonDisabled = true;
  protected _questionAnswer:
    | QuestionnaireAnswer
    | QuestionnaireEligibilityAnswer
    | undefined;
  protected choices: T[] = [];
}
