import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';
import { VerificationService } from 'src/app/services/verification/verification.service';
import { RecordEvent } from 'src/app/awsRumEvents';

@Injectable({
  providedIn: null
})
export class OidcAuthCheckGuard {
  private _oidcService = inject(OidcSecurityService);
  private _urlParamService = inject(UrlParamsService);
  private _verificationService = inject(VerificationService);

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._oidcService.checkAuth().pipe(
      take(1),
      map((auth) => {
        const sessionAuthState = sessionStorage.getItem('auth_state');
        const params = {
          customParams: {
            prompt: 'none'
          }
        };

        if (!sessionAuthState || sessionAuthState !== 'idp_valid') {
          sessionStorage.setItem('auth_state', 'logging_in');

          RecordEvent('oidc_auth.auth_state.logging_in', {
            message: `Authed state: logging_in`
          });
        }

        if (auth.isAuthenticated && sessionAuthState === 'idp_valid') {
          RecordEvent('oidc_auth.authorize.idp.success', {
            message: `User had a valid IDP session.`
          });

          return true;
        }

        if (this._verificationService.isVerification) {
          sessionStorage.setItem(
            'idpReturnUrl',
            `${
              window.location.origin
            }${this._urlParamService.recreateUrlWithParams(state)}`
          );

          params.customParams.prompt = 'create';
        }

        RecordEvent('auth_auth.authorize.idp.fail', {
          message: `User was sent to IDP to auth. For not having a valid IDP session.`
        });

        this._oidcService.authorize(undefined, params);

        return false;
      })
    );
  }
}
