export enum QuestionTypes {
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
  TextArea = 'TextArea',
  Text = 'Text',
  Input = 'Input'
}

export type QuestionTypesString =
  | 'MultiSelect'
  | 'SingleSelect'
  | 'TextArea'
  | 'Text'
  | 'Input';

export enum AnswerType {
  NotSet = 0,
  RadioButton = 1,
  CheckBox = 2,
  Date = 3,
  DateTime = 4,
  Height = 5,
  Weight = 6,
  Integer = 7,
  Decimal = 8,
  String = 9,
  BloodPressure = 10,
  CheckBoxNone = 11,
  Lookup = 12,
  HearthRate = 13,
  RadioButtonWithItems = 14,
  TextArea = 16,
  Size = 999999999
}

export type AnswerTypeString =
  | 'RadioButton'
  | 'CheckBox'
  | 'Date'
  | 'DateTime'
  | 'Weight'
  | 'Height'
  | 'Integer'
  | 'Decimal'
  | 'String'
  | 'BloodPressure'
  | 'CheckBoxNone'
  | 'Lookup'
  | 'HeartRate'
  | 'RadioButtonWithItems'
  | 'Size'
  | 'TextArea';
