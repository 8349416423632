import * as amplitude from '@amplitude/analytics-browser';
import { Component, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ProgramService } from 'src/app/services/program/program.service';
import { WelcomeService } from '../../services/welcome.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'pofo-welcome',
  templateUrl: './welcome.component.html',
  standalone: true,
  imports: [ContainerComponent, AlertComponent]
})
export class WelcomeJPMCComponent {
  private _programService = inject(ProgramService);
  private _welcomeService = inject(WelcomeService);

  public assetPath = environment.get().assetPath;
  public isLoading = false;
  public errorMessage?: string;

  constructor() {
    this._programService.showSteps$.next(false);
    this._programService.showLogo$.next(true);
  }

  public onStart(): void {
    this.errorMessage = undefined;

    amplitude.track('click welcome start button', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'idp onboarding'
    });

    this._welcomeService
      .welcome$()
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'idp onboarding (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((response) => {
        if (!response.loading && !response.error && response.success) {
          this._programService.showSteps$.next(true);
          this._programService.showLogo$.next(false);
        }

        if (response.onCompleteCallback) {
          response.onCompleteCallback();
        }
      });
  }
}
