import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import {
  DropdownComponent,
  OptionItem
} from 'src/app/components/dropdown/dropdown.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CheckboxComponent } from 'src/app/components/checkbox/checkbox.component';
import { ContactDetailsService } from 'src/app/services/contact-details/contact-details.service';
import { ContactDetailsWithConsent } from 'src/app/models/contact-details.model';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoaderState } from 'src/app/models/loading.model';
import { ParticipantService } from 'src/app/services/participant/participant.service';
import { ShippingDetails } from 'src/app/models/shipping-config.model';
import { ViewportScroller } from '@angular/common';
import { countryCodes } from 'src/app/utils/country-codes';
import { states } from 'src/app/utils/states';
import { take } from 'rxjs';

@Component({
  selector: 'pofo-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    DropdownComponent,
    CheckboxComponent
  ]
})
export class ContactDetailsComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  private participantService = inject(ParticipantService);
  private contactDetailsService = inject(ContactDetailsService);
  private viewportScroller = inject(ViewportScroller);

  @ViewChild('contactDetailsFormElement')
  public contactDetailsFormElement: ElementRef<HTMLFormElement>;

  public readonly title = 'Contact details';
  public readonly countryCodesOptions: OptionItem[] = countryCodes.map(
    (entry) => ({
      label: `${entry.name} (${entry.dialCode})`,
      value: entry.dialCode
    })
  );
  public states: OptionItem[];
  public isLoading = false;
  public programParticipantId?: number;
  public serverError: string | null = null;
  public formHasErrors = false;
  public contactDetailsForm = this.fb.group({
    fullName: ['', [Validators.required]],
    addressLine1: ['', [Validators.required]],
    addressLine2: [''],
    countryName: ['United States', [Validators.required]],
    countryCode: ['US', [Validators.required]],
    postCode: ['', [Validators.required]],
    state: ['', [Validators.required]],
    city: ['', [Validators.required]],
    phoneCountryCode: ['+1', [Validators.required]],
    phoneNumber: [
      '',
      [
        Validators.required,
        Validators.pattern(/^[0-9]*$/),
        Validators.minLength(7)
      ]
    ],
    consent: [false]
  });

  ngOnInit(): void {
    this.contactDetailsService
      .getContactDetails()
      .pipe(take(2))
      .subscribe((res) => {
        if (res.value) {
          const details = res.value.shippingDetails;
          const countries = res.value.countries ?? [
            { code: 'US', name: 'United States', states }
          ];
          const shippingDetails: Partial<ShippingDetails> = {
            ...details,
            countryCode:
              details && details.countryCode ? details.countryCode : 'US',
            countryName:
              details && details.countryName
                ? details.countryName
                : 'United States',
            phoneNumber:
              details && details.phoneNumber
                ? details.phoneNumber.replace(details.phoneCountryCode, '')
                : '',
            phoneCountryCode:
              details && details.phoneCountryCode
                ? details.phoneCountryCode
                : '+1'
          };

          for (const country of countries) {
            if (country.code === shippingDetails.countryCode) {
              this.states = country.states.map((entry) => ({
                label: entry.label,
                value: entry.value
              }));

              break;
            }
          }

          this.contactDetailsForm.setValue({
            ...this.contactDetailsForm.getRawValue(),
            ...shippingDetails,
            consent: false
          });
        }
      });
  }

  onSubmitForm() {
    this.serverError = null;
    const details: ContactDetailsWithConsent = {
      programParticipantId:
        this.participantService.participantValue.pofoData?.participantId,
      ...this.contactDetailsForm.value
    };

    if (this.contactDetailsForm.valid) {
      this.formHasErrors = false;
      this.isLoading = true;
      this.contactDetailsService
        .submitDetails(details)
        .pipe(take(2))
        .subscribe((response: LoaderState<null>) => {
          if ('loading' in response && response.loading !== undefined) {
            this.isLoading = response.loading;
          }

          if (response.error) {
            this.serverError =
              response.errorMessage ||
              'Sorry, something went wrong, please try that again.';
            this.viewportScroller.scrollToPosition([0, 0]);
          }

          if (response.onCompleteCallback) {
            amplitude.track('click confirm and continue on verification', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)'
            });
            response.onCompleteCallback();
          }
        });
    } else {
      this.formHasErrors = true;

      const firstInvalid =
        this.contactDetailsFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }
}
