<ul class="w-full">
  @for (d of data; track trackFn(i, d); let i = $index) {
  <li>
    <button
      class="w-full text-left focus-visible:outline focus-visible:outline-2 focus-visible:outline-[theme('colors.teal.600')]"
      (click)="toggleOpen(i)"
      [ngClass]="{
        shadow: !d.isOpen
      }"
      [attr.aria-label]="d.heading + ' - ' + (d.isOpen ? 'Open' : 'Close')"
    >
      <div
        class="flex flex-row items-start justify-between text-navy py-4 px-3 font-medium"
      >
        <h2 class="font-bold">{{ d.heading }}</h2>
        <div class="text-teal-500">
          <pofo-icon
            class="mb-6"
            iconName="{{ d.isOpen ? 'chevron-up-sm' : 'chevron-down-sm' }}"
            [size]="24"
          ></pofo-icon>
        </div>
      </div>
    </button>
    @if (d.isOpen) {
    <p class="text-left text-slate pb-4 px-3 shadow" [str2Html]="d.content"></p>
    }
  </li>
  }
</ul>
