<div class="relative">
  @if (availableDateList$ | async) {
  <mat-calendar
    #calendar
    class="bg-white p-4 rounded-md border-cloudy border mb-5"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [dateFilter]="dateFilter"
    [dateClass]="dateClass"
    [startAt]="startAt"
    [(selected)]="selectedDate"
    (selectedChange)="changedDate($event)"
  ></mat-calendar>
  } @if (selectedDate) {
  <p class="font-bold mb-2 text-black">
    {{ parsedSelectedDate() }}
  </p>
  @if (isWalkin) {
  <p class="font-bold">
    Click below to book a walk-in appointment for today’s event.
  </p>
  <p>
    Walk-in time slots are limited, so we cannot guarantee that you will be
    seen.
  </p>
  } @if (!isWalkin && isLoadingSlots) {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner></pofo-loading-spinner>
  </div>
  } @if (!isWalkin && !isLoadingSlots && availableSlots.length > 0) {
  <ul class="flex flex-col gap-1 overflow-scroll h-[500px]">
    @for (time of availableSlots; track trackFn($index, time)) {
    <li class="flex items-center justify-start mb-1">
      <button
        class="w-full shadow-inset shadow-cloudy p-4 border rounded-sm border-platinum bg-white text-slate"
        type="button"
        data-test="datetime-list-button"
        [class.selected]="
          time.start.getTime() === (chosenSlot | async)?.start?.getTime()
        "
        (click)="changedSlot(time)"
      >
        <div class="flex justify-between text-base">
          <span>{{
            showParsedTimeStartEnd ? time.parsedTimeStartEnd : time.parsedTime
          }}</span>
          @if ( time.start.getTime() === (chosenSlot | async)?.start?.getTime()
          ) {
          <pofo-icon
            class="text-teal-300"
            iconName="check-tick-lg"
            [size]="24"
          ></pofo-icon>
          }
        </div>
      </button>
    </li>
    }
  </ul>
  } }
</div>
