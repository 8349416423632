import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountConsentService } from './services/account-consent/account-consent.service';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { ConsentRoutingModule } from './consent-routing.module';
import { ConsentService } from './services/consent/consent.service';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ConsentRoutingModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [ConsentService, AccountConsentService]
})
export class ConsentModule {}
