import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ShippingRoutingModule } from './phone-number-confirmation-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ShippingRoutingModule,
    ComponentsModule,
    ReactiveFormsModule
  ]
})
export class ShippingModule {}
