import { AppInitService } from './app.init.service';
import { LoginResponse } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { UrlParamsService } from './shared/services/url-params/url-params.service';

export function initializeAppFactory(
  appInitService: AppInitService
): () => Promise<void> {
  return () => appInitService.load();
}

export function getUrlParamsFactory(
  urlParamsService: UrlParamsService
): () => Promise<void> {
  return () => urlParamsService.init();
}

export function updateBearerTokenFactory(
  appInitService: AppInitService
): () => Observable<LoginResponse> {
  return () => appInitService.checkAuth$();
}
