<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>
    <form [formGroup]="form" (ngSubmit)="searchLocations()">
      <pofo-input
        data-test="location-search-input"
        type="search"
        icon="search-lg"
        name="search-bar"
        label="Search by ZIP code."
        [isRoundedSides]="true"
        maxlen="5"
        formControlName="search"
        (inputCleared)="searchLocations()"
      ></pofo-input>
    </form>

    @if (!hasNoLocations) { @if (!isError) {
    <div class="pl-1 py-3 text-navy text-base font-bold">
      Showing
      {{
        locations.results.slice(
          0,
          maxNumberOfItemsPerPage * currentShowingListCount
        ).length
      }}
      of {{ locations.totalCount }} results based on your search.
    </div>
    } @else {
    <pofo-alert type="error" class="mt-4">
      {{ isError }}
    </pofo-alert>
    } }

    <ul>
      @for ( location of locations.results.slice( 0, maxNumberOfItemsPerPage *
      currentShowingListCount ); track trackFn(i, location); let i = $index) {
      <li class="flex items-center justify-start mb-2">
        <button
          class="w-full shadow-inset shadow-cloudy p-4 border rounded-md border-platinum bg-white"
          type="button"
          data-test="location-list-button"
          [ngClass]="{ selected: selectedIndex === i }"
          (click)="onSelected(location, i)"
        >
          <div class="flex justify-between text-navy text-base font-bold mb-2">
            <span>{{ location.name }}</span>
            @if (selectedIndex === i) {
            <span
              ><pofo-icon
                class="text-teal-300"
                iconName="check-tick-lg"
                [size]="24"
              ></pofo-icon
            ></span>
            }
          </div>
          <div class="flex justify-between">
            <div class="flex flex-col text-left text-storm text-sm font-normal">
              <div>
                {{ location.address }}
              </div>
              <div>
                <span>{{ location.city }}, </span>
                <span>{{ location.zip }}</span>
              </div>
            </div>
            <img
              class="self-end"
              width="120"
              height="60"
              src="{{ assetPath }}images/{{
                location.locationType === 'Walgreens'
                  ? 'labcorp-logo-walgreens@2x.png'
                  : 'labcorp-logo@2x.png'
              }}"
              alt="location image"
            />
          </div>
        </button>
      </li>
      }
    </ul>

    @if (!isLoading) { } @else {
    <div class="flex justify-center p-4">
      <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
    </div>
    } @if ( (locations.totalCount > maxNumberOfItemsPerPage &&
    locations.results.length < locations.totalCount) || locations.results.slice(
    0, maxNumberOfItemsPerPage * currentShowingListCount ).length <
    locations.totalCount ) {
    <div class="flex justify-center">
      <button
        type="button"
        class="flex items-center text-teal-600 ml-2"
        data-test="load-more-button"
        (click)="loadMoreLocations()"
        [disabled]="isLoading"
      >
        Load More
        <pofo-icon iconName="chevron-down-sm" [size]="20"></pofo-icon>
      </button>
    </div>
    }

    <div class="line"></div>

    @if (hasNoLocations || isError || isLoading) {
    <button
      class="btn btn-primary btn-medium w-full"
      data-test="search-button"
      [disabled]="isSearchBtnDisabled()"
      (click)="searchLocations()"
      [pofoAmplitudeButton]="'click search location'"
      [pofoAmplitudeData]="{ category: 'appointments (pofo)' }"
    >
      Search Location
    </button>
    } @if (!hasNoLocations && !isError && !isLoading) {
    <button
      class="btn btn-primary btn-medium w-full"
      data-test="confirm-button"
      [disabled]="isConfirmBtnDisable()"
      (click)="confirmLocation()"
    >
      Confirm Location
    </button>
    }
  </pofo-container>
</div>
