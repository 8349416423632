import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import {
  DropdownComponent,
  OptionItem
} from 'src/app/components/dropdown/dropdown.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { CareProviderSearchRequest } from 'src/app/models/care-provider.model';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { states } from 'src/app/utils/states';

@Component({
  selector: 'pofo-pcp-search',
  templateUrl: './pcp-search.component.html',
  styleUrls: ['./pcp-search.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    DropdownComponent,
    ErrorMessageComponent,
    FormsModule,
    IconComponent,
    InputComponent,
    LoadingSpinnerComponent,
    ReactiveFormsModule
  ]
})
export class PcpSearchComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);

  @Input() terms: CareProviderSearchRequest;
  @Input() loading = false;
  @Input() public searchInfoText = '';
  @Input() public searchInfoUrl = '';
  @Output() search = new EventEmitter<CareProviderSearchRequest>();
  @Output() skip = new EventEmitter();

  @ViewChild('pcpSearchFormElement')
  public pcpSearchFormElement: ElementRef<HTMLFormElement>;

  readonly states: OptionItem[] = states;
  public formHasErrors = false;
  public pcpSearchForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.minLength(2)]],
    state: ['', [Validators.required]]
  });

  ngOnInit() {
    if (this.terms) {
      this.pcpSearchForm.setValue(this.terms);
    }
  }

  onSubmitSearchForm() {
    if (this.pcpSearchForm.valid) {
      amplitude.track('click search provider details', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'order forms (pofo)'
      });
      this.search.emit(this.pcpSearchForm.getRawValue());
      this.formHasErrors = false;
    } else {
      this.formHasErrors = true;

      const firstInvalid =
        this.pcpSearchFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }

  skipStep() {
    amplitude.track('click skip step on input fields', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'order forms (pofo)'
    });
    this.skip.emit();
  }
}
