import * as amplitude from '@amplitude/analytics-browser';
import { Component, inject } from '@angular/core';
import { filter, map, skip, switchMap, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DropdownComponent } from 'src/app/components/dropdown/dropdown.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { PatientService } from 'src/app/shared/services/patient/patient.service';
import { StateService } from '../services/state-confirmation.service';
import { StatesResponse } from '../@types/state-confirmation.interface';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';
import { statesMapping } from 'src/app/utils/states';

@Component({
  selector: 'pofo-state-confirmation',
  templateUrl: './state-confirmation.component.html',
  styleUrls: ['./state-confirmation.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    DropdownComponent,
    LoadingSpinnerComponent,
    AsyncPipe
  ]
})
export class StateComponent {
  private _patientService = inject(PatientService);
  private _stateService = inject(StateService);
  private _syncVisit = inject(SyncVisitService);

  public isLoading = false;
  public errorMessage?: string;
  private _states: StatesResponse[] = [];
  public data$ = this._patientService
    .getState$(this._syncVisit.modalityId)
    .pipe(
      skip(1),
      switchMap((patient) =>
        this._stateService.getStates$(this._syncVisit.modalityId).pipe(
          filter((res) => !!res.value),
          map((res) => {
            this._states = res.value ?? [];

            const states = statesMapping(this._states.map((s) => s.stateIso));
            const patientStateIso =
              states.find((s) => s.label === patient.value?.state)?.value ?? '';

            this._syncVisit.stateIso = patientStateIso;
            this._syncVisit.countryIso = patient.value?.countryIso ?? 'US';

            return {
              patientStateIso,
              states
            };
          })
        )
      )
    );

  public selectedState(value: string): void {
    const foundStateCountryIso = this._states.find((s) => s.stateIso === value);

    this._syncVisit.countryIso = foundStateCountryIso?.countryIso
      ? foundStateCountryIso.countryIso
      : 'US';

    this._syncVisit.stateIso = foundStateCountryIso
      ? foundStateCountryIso.stateIso
      : '';
  }

  public onSubmit() {
    this.isLoading = true;

    this._patientService
      .setState$(
        this._syncVisit.modalityId,
        this._syncVisit.syncVisitId,
        this._syncVisit.countryIso,
        this._syncVisit.stateIso
      )
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'state (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }
}
