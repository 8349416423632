import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  PDFResponse,
  PhysicianFormId
} from '../../@types/physician-forms.interface';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Patient } from '../../models/patient.model';
import { PatientDetailsResponse } from 'src/app/shared/@types/patient.interface';
import { UrlParamsService } from '../url-params/url-params.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicianFormService {
  private _http = inject(HttpService);
  private _urlParamsService = inject(UrlParamsService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private _patient: Patient;
  private readonly _modalityId = this._urlParamsService.get('modalityId');
  private _physicianFormId = this._urlParamsService.get('physicianFormId');
  public filesToUpload: File[] = [];
  public isCancel = false;

  public setUploadForm(uploadedFiles: File[]): void {
    this.filesToUpload = uploadedFiles;
  }

  public getForm$(): Observable<LoaderState<PDFResponse>> {
    return this._http.get<PDFResponse, PDFResponse>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._modalityId}/physicianform/${this._physicianFormId}/pdf`,
      this._responseParser<PDFResponse>
    );
  }

  public getPatient$(): Observable<LoaderState<Patient>> {
    return this._http.get<PatientDetailsResponse, Patient>(
      `${this._environmentService.properties.apiGatewayV2}/patient`,
      (res) => {
        this._patient = new Patient(res);
        return this._patient;
      }
    );
  }

  public generateForm$(): Observable<LoaderState<PhysicianFormId>> {
    return this._http
      .post<PhysicianFormId, PhysicianFormId>(
        `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._modalityId}/physicianform/create`,
        this._patient.generateFormPayload(),
        this._responseParser<PhysicianFormId>
      )
      .pipe(
        map((res) => {
          if (res.value) {
            this._physicianFormId = res.value;
          }

          return res;
        })
      );
  }

  public uploadForm$(): Observable<LoaderState<unknown>> {
    const formData = new FormData();
    formData.append('file', this.filesToUpload[0], this.filesToUpload[0].name);

    return this._http.post<unknown, unknown>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._modalityId}/physicianform/${this._physicianFormId}/upload`,
      formData,
      this._responseParser<unknown>,
      new HttpHeaders()
    );
  }

  public cancelForm$(): Observable<LoaderState<Record<PropertyKey, never>>> {
    return this._http.delete<
      Record<PropertyKey, never>,
      Record<PropertyKey, never>
    >(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._modalityId}/physicianform/${this._physicianFormId}/cancel`,
      this._responseParser<Record<PropertyKey, never>>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }

  public getPhysicianFormId() {
    return this._physicianFormId;
  }
}
