import { AmplitudeDirective } from './amplitude/amplitude.directive';
import { DateMaskDirective } from './date-mask/date-mask.directive';
import { HasModalityIdGuard } from './guards/has-modality/has-modality-id.guard';
import { NgModule } from '@angular/core';
import { OptionFilterPipe } from './pipes/option-filter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { StringToHtmlDirective } from './directives/parse-string-html.directive';

@NgModule({
  imports: [
    SafePipe,
    OptionFilterPipe,
    StringToHtmlDirective,
    AmplitudeDirective,
    DateMaskDirective
  ],
  exports: [
    SafePipe,
    OptionFilterPipe,
    StringToHtmlDirective,
    AmplitudeDirective,
    DateMaskDirective
  ],
  providers: [HasModalityIdGuard]
})
export class SharedModule {}
