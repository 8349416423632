<pofo-container containerTitle="Primary care provider">
  <div class="content">
    <p class="text-base text-slate text-center md:text-left">
      Search for your provider details here so that a copy of your results can
      be shared.
    </p>

    @if (searchInfoText && searchInfoUrl) {
    <p class="mt-4">
      <a
        [href]="searchInfoUrl"
        target="_blank"
        rel="noopener"
        data-test="pcpSearchInfoLink"
        class="flex items-center text-aqua-darker"
      >
        {{ searchInfoText }}
        <pofo-icon
          class="text-aqua-darker"
          iconName="chevron-right-sm"
          [size]="20"
        ></pofo-icon>
      </a>
    </p>
    }

    <div>
      <form
        #pcpSearchFormElement
        [formGroup]="pcpSearchForm"
        (ngSubmit)="onSubmitSearchForm()"
      >
        <div
          class="flex flex-col md:flex-row items-center justify-between mt-4"
        >
          <div class="w-full md:w-1/2 mb-3 md:mr-3">
            <pofo-input
              name="firstName"
              label="Provider first name"
              type="text"
              [hasError]="
                formHasErrors &&
                !!pcpSearchForm.get('firstName')?.errors?.['required']
              "
              formControlName="firstName"
              data-test="firstNameInput"
              errorMsgId="firstNameErrorMsg"
            ></pofo-input>

            @if ( formHasErrors &&
            pcpSearchForm.get('firstName')?.errors?.['required']) {
            <pofo-error-message
              errorId="firstNameErrorMsg"
              testId="firstNameReqErrorMsg"
              errorMessage="First name is required."
            ></pofo-error-message>
            } @else if ( formHasErrors &&
            pcpSearchForm.get('firstName')?.errors?.['minlength']) {
            <pofo-error-message
              errorId="firstNameErrorMsg"
              testId="firstNameLengthErrorMsg"
              errorMessage="First name must have at least 2 characters."
            ></pofo-error-message>
            }
          </div>

          <div class="w-full md:w-1/2 mb-3">
            <pofo-input
              name="lastName"
              label="Provider last name"
              type="text"
              minlength="1"
              [hasError]="formHasErrors && pcpSearchForm.get('lastName')?.errors?.['required']"
              formControlName="lastName"
              data-test="lastNameInput"
              errorMsgId="lastNameErrorMsg"
            ></pofo-input>

            @if ( formHasErrors &&
            pcpSearchForm.get('lastName')?.errors?.['required']) {
            <pofo-error-message
              errorId="lastNameErrorMsg"
              testId="lastNameReqErrorMsg"
              errorMessage="Last name is required."
            ></pofo-error-message>
            } @else if ( formHasErrors &&
            pcpSearchForm.get('lastName')?.errors?.['minlength']) {
            <pofo-error-message
              errorId="lastNameErrorMsg"
              testId="lastNameLengthErrorMsg"
              errorMessage="Last name must have at least 2 characters."
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="mb-4 w-full">
          <pofo-dropdown
            label="State/Province/County"
            field="state"
            [optionItems]="states"
            formControlName="state"
            [hasError]="formHasErrors && pcpSearchForm.get('state')?.errors?.['required']"
            data-test="pcpStateDropDown"
            errorMsgId="pcpStateErrorMsg"
          ></pofo-dropdown>

          @if (formHasErrors &&
          pcpSearchForm.get('state')?.errors?.['required']) {
          <pofo-error-message
            errorId="pcpStateErrorMsg"
            testId="pcpStateReqErrorMsg"
            errorMessage="Providers State/Province/County is required."
          ></pofo-error-message>
          }
        </div>

        <div class="border-t border-platinum my-6 mx-auto"></div>

        <div class="sticky bottom-0 bg-off-white">
          <div class="mt-4">
            <button
              type="submit"
              class="btn btn-primary btn-medium w-full"
              data-test="searchBtn"
              [disabled]="loading"
            >
              @if (loading) {
              <pofo-loading-spinner size="small"></pofo-loading-spinner>
              <span class="ml-4">Searching...</span>
              } @else { Search physician detail }
            </button>
          </div>

          <div class="pt-4 px-1 mx-[-4px]">
            <button
              type="button"
              class="btn btn-secondary btn-medium w-full"
              data-test="skipStepBtn"
              (click)="skipStep()"
            >
              Skip this step
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</pofo-container>
