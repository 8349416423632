<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container>
    @if (!hasError) {
    <div>
      <div class="flex flex-col items-center justify-between">
        @if (isLoading) {
        <pofo-loading-spinner></pofo-loading-spinner>
        }
        <p class="mt-4">{{ logoutStatus }}</p>
      </div>
    </div>
    } @else {
    <pofo-alert type="error">{{ logoutStatus }} </pofo-alert>
    }
  </pofo-container>
</div>
