import { Injectable, inject } from '@angular/core';
import { Params, Router, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UrlParamsService {
  private _location = inject(Location);
  private _router = inject(Router);

  private _params: Params = {};

  public init(): Promise<void> {
    return new Promise((resolve) => {
      const url = this._router.parseUrl(this._location.path());
      this._params = url.queryParams;
      resolve();
    });
  }

  public get(key: string): string {
    return this._params[key];
  }

  public getAll(): Params {
    return this._params;
  }

  public recreateUrlWithParams(state: RouterStateSnapshot): string {
    return state.url.includes('?')
      ? state.url
      : this._router
          .createUrlTree([state.url], { queryParams: this._params })
          .toString();
  }
}
