<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!configurationErrorMessage) {
  <pofo-alert type="error" data-test="configurationErrorMsg">
    {{ configurationErrorMessage }}
  </pofo-alert>
  } @if (participantVerificationError) {
  <pofo-alert type="error">
    {{ participantVerificationError }}
  </pofo-alert>
  }

  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>

    <div>
      @if (verificationFactors | async; as factors) {
      <form
        #verificationFormElement
        (ngSubmit)="onSubmitForm(verificationForm, factors)"
        #verificationForm="ngForm"
      >
        @if (factors.factor1) {
        <div class="mb-3">
          <pofo-input
            #factor1="ngModel"
            name="factor1"
            [label]="factors.factor1.label || ''"
            [type]="getType(factors.factor1.fieldTypeCode)"
            [pattern]="
              factors.factor1.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor1"
            [hasError]="formHasErrors && (factor1.errors?.['required'] || factor1.errors?.['pattern'])"
            data-test="factor1Input"
            errorMsgId="factor1ErrorMsg"
          ></pofo-input>

          @if (formHasErrors && factor1.errors?.['required']) {
          <pofo-error-message
            errorId="factor1ErrorMsg"
            testId="factor1ReqErrorMsg"
            [errorMessage]="factors.factor1.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor1.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor1ErrorMsg"
            testId="factor1PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor1.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        } @if (factors.factor2) {
        <div class="mb-3">
          <pofo-input
            #factor2="ngModel"
            name="factor2"
            [label]="factors.factor2.label"
            [type]="getType(factors.factor2.fieldTypeCode)"
            [pattern]="
              factors.factor2.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor2"
            [hasError]="formHasErrors && (factor2.errors?.['required'] || factor2.errors?.['pattern'])"
            data-test="factor2Input"
            errorMsgId="factor2ErrorMsg"
          ></pofo-input>
          @if (formHasErrors && factor2.errors?.['required']) {
          <pofo-error-message
            errorId="factor2ErrorMsg"
            testId="factor2ReqErrorMsg"
            [errorMessage]="factors.factor2.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor2.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor2ErrorMsg"
            testId="factor2PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor2.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        } @if (factors.factor3) {
        <div class="mb-3">
          <pofo-input
            #factor3="ngModel"
            name="factor3"
            [label]="factors.factor3.label"
            [type]="getType(factors.factor3.fieldTypeCode)"
            [pattern]="
              factors.factor3.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor3"
            [hasError]="formHasErrors && (factor3.errors?.['required'] || factor3.errors?.['pattern'])"
            data-test="factor3Input"
            errorMsgId="factor3ErrorMsg"
          ></pofo-input>
          @if (formHasErrors && factor3.errors?.['required']) {
          <pofo-error-message
            errorId="factor3ErrorMsg"
            testId="factor3ReqErrorMsg"
            [errorMessage]="factors.factor3.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor3.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor3ErrorMsg"
            testId="factor3PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor3.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        } @if (factors.factor4) {
        <div class="mb-3">
          <pofo-input
            #factor4="ngModel"
            name="factor4"
            [label]="factors.factor4.label"
            [type]="getType(factors.factor4.fieldTypeCode)"
            [pattern]="
              factors.factor4.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor4"
            [hasError]="formHasErrors && (factor4.errors?.['required'] || factor4.errors?.['pattern'])"
            data-test="factor4Input"
            errorMsgId="factor4ErrorMsg"
          ></pofo-input>
          @if (formHasErrors && factor4.errors?.['required']) {
          <pofo-error-message
            errorId="factor4ErrorMsg"
            testId="factor4ReqErrorMsg"
            [errorMessage]="factors.factor4.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor4.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor4ErrorMsg"
            testId="factor4PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor4.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        } @if (factors.factor5) {
        <div class="mb-3">
          <pofo-input
            #factor5="ngModel"
            name="factor5"
            [label]="factors.factor5.label"
            [type]="getType(factors.factor5.fieldTypeCode)"
            [pattern]="
              factors.factor5.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor5"
            [hasError]="formHasErrors && (factor5.errors?.['required'] || factor5.errors?.['pattern'])"
            data-test="factor5Input"
            errorMsgId="factor5ErrorMsg"
          ></pofo-input>
          @if (formHasErrors && factor5.errors?.['required']) {
          <pofo-error-message
            errorId="factor5ErrorMsg"
            testId="factor5ReqErrorMsg"
            [errorMessage]="factors.factor5.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor5.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor5ErrorMsg"
            testId="factor5PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor5.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        } @if (factors.factor6) {
        <div class="mb-3">
          <pofo-input
            #factor6="ngModel"
            name="factor6"
            [label]="factors.factor6.label"
            [type]="getType(factors.factor6.fieldTypeCode)"
            [pattern]="
              factors.factor6.fieldTypeCode === 'NUMBER' ? '[0-9]*' : ''
            "
            [required]="true"
            [(ngModel)]="participantForm.factor6"
            [hasError]="formHasErrors && (factor6.errors?.['required'] || factor6.errors?.['pattern'])"
            data-test="factor6Input"
            errorMsgId="factor6ErrorMsg"
          ></pofo-input>
          @if (formHasErrors && factor6.errors?.['required']) {
          <pofo-error-message
            errorId="factor6ErrorMsg"
            testId="factor6ReqErrorMsg"
            [errorMessage]="factors.factor6.label + ' is required.'"
          ></pofo-error-message>
          } @if (formHasErrors && factor6.errors?.['pattern']) {
          <pofo-error-message
            errorId="factor6ErrorMsg"
            testId="factor6PatternErrorMsg"
            [errorMessage]="'Invalid ' + factors.factor6.label + ' pattern.'"
          ></pofo-error-message>
          }
        </div>
        }
        <div class="border-t border-platinum my-4 mx-auto"></div>
        <div class="mt-4">
          <button
            type="submit"
            class="btn btn-primary btn-medium w-full"
            data-test="confirmVerificationBtn"
            [disabled]="loading$ | async"
          >
            Confirm & continue
          </button>
        </div>
      </form>
      }
    </div>
  </pofo-container>
</div>
