/// <reference types="grecaptcha" />
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { WelcomeResponse } from '../@types/welcome.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class WelcomeService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private _handleResponse<T>(response: T): T {
    return response;
  }

  public welcome$(): Observable<LoaderState<WelcomeResponse>> {
    return this._httpService.get<WelcomeResponse, WelcomeResponse>(
      `${this._environmentService.properties.apiGateway}/reseller/${this._workflowService.currentProgram}/welcome`,
      this._handleResponse<WelcomeResponse>
    );
  }
}
