<div
  class="dropdown-wrapper {{ (this.value || this.placeholder) && 'selected' }}"
>
  <select
    #selectRef
    [ngClass]="{
      'text-smoke bg-off-white': isDisabled,
      'text-slate bg-white': !isDisabled,
      error: hasError
    }"
    (click)="handleOnClick()"
    (keypress)="handleOnKeyPress($event)"
    (blur)="handleOnBlur()"
    (change)="handleOnChange($event)"
    [id]="id"
    [attr.data-testid]="testId"
    [disabled]="isDisabled"
    [attr.aria-invalid]="hasError"
    [attr.aria-errormessage]="errorMsgId"
  >
    @if (placeholder) {
    <option hidden>
      {{ placeholder }}
    </option>
    } @else { @if (label && value === '') {
    <option hidden></option>
    } } @for (option of optionItems; track option) {
    <option
      [value]="option.value"
      [selected]="option.value === value"
      attr.data-test="{{ option.value }}SelectOption"
    >
      {{ option.label }}
    </option>
    }
  </select>

  <label
    #selectLabelRef
    class="{{ isDisabled ? 'text-smoke' : 'text-storm' }}"
    [for]="id"
  >
    {{ label }}
  </label>

  <pofo-icon
    [ngClass]="[
      'absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer pointer-events-none sm:m-0',
      isDisabled ? 'text-smoke' : 'text-teal-500'
    ]"
    iconName="{{ open ? 'chevron-up-sm' : 'chevron-down-sm' }}"
    [size]="24"
  ></pofo-icon>
</div>
