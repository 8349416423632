<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container containerTitle="Check your information">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      Review and confirm the below details to send us your results.
    </p>

    <div class="p-4 mt-6 border rounded-md bg-white border-platinum relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        Physician Form
      </div>

      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="diabetes" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Biometric Screening</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>

      @if (!!errorMessage) {
      <pofo-alert type="error" data-test="error-message">{{
        errorMessage
      }}</pofo-alert>
      }

      <div class="line"></div>

      <div class="flex justify-start mt-4 mb-3 text-storm text-sm font-normal">
        <div class="w-12 pr-2 pt-1">
          <pofo-icon iconName="image" [size]="24"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Selected file</div>
          @for (file of images; track file) {
          <div
            class="flex justify-between items-center w-full mt-2 first:mt-0 p-4 border-cloudy border-2 rounded-md"
          >
            <div class="flex items-center">
              <div
                class="w-[44px] border-cloudy border-2 rounded-sm overflow-hidden"
              >
                @switch (file.fileType) { @case ('.pdf') {
                <pofo-icon iconName="{{ file.image }}" [size]="32"></pofo-icon>
                } @default {
                <div>
                  <img
                    class="w-[44px] border-cloudy border-2 rounded-sm overflow-hidden"
                    alt="thumbnail of file that will be uploaded"
                    [src]="file.image"
                  />
                </div>
                } }
              </div>
              <div class="pl-4">{{ file.name }}</div>
            </div>
          </div>
          }
        </div>

        <a class="font-bold" [routerLink]="['..', 'upload']">Edit</a>
      </div>
    </div>

    <form class="pb-3" [formGroup]="contactDetailsForm">
      <div class="line"></div>

      <pofo-checkbox formControlName="consent" iconName="check-tick-sm"
        >I confirm that these results are correct and were provided by a
        licensed medical professional (whose signature is visible on the
        uploaded image).</pofo-checkbox
      >
    </form>

    <div class="line"></div>

    <button
      class="btn btn-primary btn-medium w-full"
      data-test="finish-button"
      [disabled]="isDisabled()"
      (click)="confirm()"
    >
      Confirm & send my result
    </button>
  </pofo-container>
</div>
