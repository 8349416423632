<div class="my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto">
  <div class="flex flex-col l:flex-row justify-center items-center">
    <div>
      <div class="flex flex-col l:flex-row relative confirmed">
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
          iconName="tick-circle"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          containerTitle="{{ title }}"
          background="transparent"
        >
          <p class="text-base-lg text-slate text-center mb-8 l:text-left">
            {{ titleText }}
          </p>
        </pofo-container>
      </div>

      <div class="flex flex-col l:flex-row relative details">
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
          iconName="tick-schedule"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          containerTitle="{{ subTitle }}"
          background="transparent"
        >
          @if (location && appointment && appointment.startDate) {
          <div class="rounded-md bg-off-white">
            <div class="p-5 pb-0 text-navy text-base font-bold">
              Flu Vaccine
            </div>
            <div class="line"></div>
            <div class="p-5 pt-0 mb-5 text-storm text-sm font-normal">
              <div class="flex">
                <span class="px-1">Location: </span>
                <p>{{ location.name }}</p>
              </div>
              <div class="flex">
                <span class="px-1">Address: </span>
                <p>
                  {{ location.address }}, {{ location.city }} -
                  {{ location.zip }}
                </p>
              </div>
              <div class="flex">
                <span class="px-1">Date and Time: </span>
                <p>{{ parsedDate }}</p>
              </div>
            </div>
          </div>
          }
          <button
            class="btn btn-primary btn-medium w-full md:w-1/2"
            data-test="dashboard-button"
            (click)="toDashboard()"
          >
            Go to Dashboard
          </button>
        </pofo-container>
      </div>
    </div>

    <img
      class="sm:h-[380px]"
      src="{{ assetPath }}images/illustration-thankyou.jpg"
      alt="Thank you Illustration"
    />
  </div>
</div>
