import { Component, OnInit, inject } from '@angular/core';
import { AuthComponent } from 'src/app/modules/auth/components/auth/auth.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import { RecordEvent } from 'src/app/awsRumEvents';

@Component({
  selector: 'pofo-auth-callback',
  template: `<pofo-auth-component
    stepMessage="{{ this.stepMessage }}"
    errorMessage="{{ this.errorMessage }}"
  ></pofo-auth-component>`,
  standalone: true,
  imports: [AuthComponent]
})
export class AuthCallbackComponent implements OnInit {
  private _oidcService = inject(OidcSecurityService);

  public stepMessage = 'Signing in...';
  public errorMessage: string | undefined = undefined;

  ngOnInit(): void {
    this._oidcService
      .checkAuth()
      .pipe(take(1))
      .subscribe((auth) => {
        if (auth.isAuthenticated) {
          const sessionAuthState = sessionStorage.getItem('auth_state');
          const idpReturnUrl = sessionStorage.getItem('idpReturnUrl') || '/';

          RecordEvent(`auth_callback.auth_state.${sessionAuthState}`, {
            message: `Initial authed state: ${sessionAuthState} with access method: ${auth.userData.accessMethod}.`
          });

          if (sessionAuthState === 'logging_in_direct') {
            sessionStorage.removeItem('idpReturnUrl');
            sessionStorage.setItem('auth_state', 'logged');

            RecordEvent('auth_callback.auth_state.logged', {
              message: `Authed state: logged`
            });
          } else {
            sessionStorage.setItem('auth_state', 'idp_valid');

            RecordEvent('auth_callback.auth_state.idp_valid', {
              message: `Authed state: idp_valid`
            });
          }

          if (auth.userData.accessMethod === 'direct') {
            RecordEvent('auth_callback.auth.success', {
              message: `User was authed.`
            });

            this._redirectTo(idpReturnUrl);
          } else if (auth.userData.accessMethod === 'sso') {
            RecordEvent('auth_callback.auth.success', {
              message: `User was authed.`
            });

            if (sessionAuthState === 'logging_in_direct') {
              this._redirectTo(idpReturnUrl);
            } else {
              this._redirectTo(`${window.location.origin}/auth/sso`);
            }
          } else {
            RecordEvent('auth_callback.auth.failed', {
              message: `User was authed. but had a incorrect accessMethod.`
            });

            this.errorMessage = 'A problem occurred while signing in';
          }
        } else {
          RecordEvent('auth_callback.auth.failed', {
            message: `User was not authed. There was a problem authenticating in IDP.`
          });

          this.errorMessage = 'There was a problem signing in';
        }
      });
  }

  private _redirectTo(url: string): void {
    window.location.href = url;
  }
}
