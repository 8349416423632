import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function invalidDateValidator(minYear: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = new Date(control.value);

    if (!date || date.getUTCFullYear() < minYear) {
      return { invalidDate: true };
    } else {
      return null;
    }
  };
}
