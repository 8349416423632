import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerOption,
  QuestionnaireQuestion,
  QuestionnaireQuestionChoice
} from '../../@types/questionnaire.interface';
import {
  QuestionnaireEligibilityAnswer,
  QuestionnaireEligibilityAnswerOption
} from '../../@types/eligibility-questionnaire.interface';
import { CoreQuestionType } from '../core/core-question-type';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { NgClass } from '@angular/common';
import { QuestionType } from '../../@types/question-type.interface';

export interface MultiSelectChoice extends QuestionnaireQuestionChoice {
  isChecked: boolean;
}

@Component({
  selector: 'pofo-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class MultiSelectComponent
  extends CoreQuestionType<MultiSelectChoice>
  implements QuestionType<MultiSelectChoice>
{
  @Input() public isEligibilityQuestionnaire = false;
  @Input() public set question(value: QuestionnaireQuestion) {
    this._question = value;
    this.choices = this._question.choices.map((choice) => ({
      ...choice,
      isChecked: false
    }));

    this._checkboxNoneOfAboveIndex = this.choices.findIndex(
      (choice) => choice.answerType === this._checkboxNoneOfAbove
    );

    this._questionAnswer = undefined;
    this.submitButtonDisabled = true;
  }

  public get question(): QuestionnaireQuestion {
    return this._question;
  }

  private _checkboxNoneOfAbove = 'CheckBoxNone';
  private _checkboxNoneOfAboveIndex = -1;

  public handleOnChange(choice: MultiSelectChoice, i: number): void {
    if (choice.isChecked) {
      this.choices[i].isChecked = false;
    } else {
      if (choice.answerType === this._checkboxNoneOfAbove) {
        this.choices = this.choices.map((ch) => ({
          ...ch,
          isChecked: false
        }));
      } else if (this._checkboxNoneOfAboveIndex > -1) {
        this.choices[this._checkboxNoneOfAboveIndex].isChecked = false;
      }

      this.choices[i].isChecked = true;
    }

    this._questionAnswer = this.isEligibilityQuestionnaire
      ? this._eligibilityCreatePayload()
      : this.createPayload();
    this.submitButtonDisabled = !this.validateQuestion();
  }

  public createPayload(): QuestionnaireAnswer {
    const options: QuestionnaireAnswerOption[] = [];

    this.choices.forEach((choice) => {
      if (choice.isChecked) {
        options.push({
          choiceIndex: choice.index,
          value: choice.text,
          fieldValue: choice.supportingFieldText
        });
      }
    });

    return {
      questionId: this.question.id,
      questionExternalId: this.question.externalId,
      questionKey: this.question.key,
      options
    };
  }

  private _eligibilityCreatePayload(): QuestionnaireEligibilityAnswer {
    const selections: QuestionnaireEligibilityAnswerOption[] = [];

    this.choices.forEach((choice) => {
      if (choice.isChecked) {
        selections.push({
          choiceIndex: choice.index,
          answerValue: choice.text
        });
      }
    });

    return {
      questionExternalId: this.question.externalId,
      questionKey: this.question.key,
      selections
    };
  }

  public validateQuestion(): boolean {
    if (this._questionAnswer !== undefined) {
      return this._isQuestionnaireAnswer(this._questionAnswer)
        ? this._questionAnswer.options.length > 0
        : this._questionAnswer.selections.length > 0;
    }

    return false;
  }

  public onSubmit(): void {
    this.questionCompleted.emit(this._questionAnswer);
  }

  private _isQuestionnaireAnswer(
    answer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): answer is QuestionnaireAnswer {
    return !this.isEligibilityQuestionnaire;
  }

  public trackFn(_i: number, itm: MultiSelectChoice): number {
    return itm.index;
  }
}
