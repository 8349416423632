import { AbstractLoggerService } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import { RecordEvent } from 'src/app/awsRumEvents';

@Injectable()
export class OIDCLoggerService implements AbstractLoggerService {
  logError(message: any): void {
    RecordEvent('oidc_error', { message });
  }

  logWarning(message: any): void {
    RecordEvent('oidc_warning', { message });
  }

  logDebug(message: any): void {
    console.log(message);
  }
}
