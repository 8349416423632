import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject
} from '@angular/core';
import {
  CreateImmunizationResponse,
  ImmunizationDefinitionResponse
} from '../../@types/immunization.interface';
import { iif, of, skip, switchMap, take } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ImmunizationService } from '../../services/immunization.service';
import { LoaderState } from 'src/app/models/loading.model';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';

@Component({
  selector: 'pofo-immunization-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  standalone: true,
  imports: [AlertComponent, LoadingSpinnerComponent, AsyncPipe, SafePipe]
})
export class ImmunizationAppointmentConsentComponent {
  private _immunizationService = inject(ImmunizationService);

  public isLoading = false;
  public errorMessage: string | undefined;
  public errorMessageFallback =
    'Sorry, something went wrong, please try that again.';
  public immunizationResponse$ = this._immunizationService
    .createImmunization$()
    .pipe(
      skip(1),
      switchMap((res) =>
        iif(
          () => !!res.error,
          of(res),
          this._immunizationService.getImmunizationDefinition$()
        )
      )
    );
  @ViewChild('iFrame') iframe: ElementRef;

  @HostListener('window:message', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(event: any) {
    if (event?.data?.iframeHeight) {
      this.iframe.nativeElement.height = event.data.iframeHeight;
    }
  }

  public isGetImmunizationDefinition(
    result:
      | LoaderState<CreateImmunizationResponse>
      | LoaderState<ImmunizationDefinitionResponse>
  ): result is LoaderState<ImmunizationDefinitionResponse> {
    return 'value' in result && result.value !== undefined && !result.error;
  }

  handleAgreeOnClick() {
    this.errorMessage = undefined;
    this.isLoading = true;

    this._immunizationService
      .saveImmunizationConsent$()
      .pipe(take(2))
      .subscribe((res) => {
        amplitude.track('click i agree');

        if ('loading' in res && res.loading !== undefined) {
          this.isLoading = res.loading;
        }

        if (res.error) {
          this.errorMessage = res.errorMessage || this.errorMessageFallback;
        }

        if (res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }
}
