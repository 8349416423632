import { Component, OnInit, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { RecordEvent } from 'src/app/awsRumEvents';
import { AuthComponent } from 'src/app/modules/auth/components/auth/auth.component';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PatientService } from 'src/app/shared/services/patient/patient.service';

@Component({
  selector: 'pofo-auth-direct-url',
  template: `<pofo-auth-component
    stepMessage="{{ this.stepMessage }}"
    errorMessage="{{ this.errorMessage }}"
  ></pofo-auth-component>`,
  standalone: true,
  imports: [AuthComponent]
})
export class AuthDirectUrlComponent implements OnInit {
  private _authService = inject(AuthService);
  private _patientService = inject(PatientService);

  public stepMessage = 'Fetching patient profile';
  public errorMessage: string | undefined = undefined;

  ngOnInit(): void {
    this._authService
      .getOrCreatePatientProfile$()
      .pipe(
        take(2),
        tap((resPatientProfile) => {
          if (resPatientProfile.error) {
            RecordEvent('auth_direct.patient_profile.failed', {
              message: `User was not able to fetch patient profile.`
            });

            this.errorMessage =
              'There was an error fetching your patient profile';
          }
        })
      )
      .subscribe((res) => {
        if (res.success && res.onCompleteCallback && res.value) {
          sessionStorage.setItem('auth_state', 'logged');

          RecordEvent('auth_direct.auth_state.logged', {
            message: `Authed state: logged`
          });

          sessionStorage.removeItem('idpReturnUrl');
          this._patientService.setPatientProfileId(res.value.patientProfileId);
          this._patientService.setPatientGeo(res.value.geo);
          RecordEvent('auth_direct.patient_profile.success', {
            message: `User was able to set patient profile ID and GEO`
          });

          RecordEvent('auth_direct.auth.success', {
            message: `User was able to set patient profile ID and next step.`
          });

          res.onCompleteCallback();
        }
      });
  }
}
