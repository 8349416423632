<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ serverError }}
  </pofo-alert>
  }

  <pofo-container [containerTitle]="title">
    <form
      #contactDetailsFormElement
      [formGroup]="contactDetailsForm"
      (ngSubmit)="onSubmitForm()"
    >
      <div>
        <div class="mb-3">
          <p class="mb-2 text-sm text-slate text-center md:text-left">
            Name that will appear on delivery:
          </p>
          <pofo-input
            name="fullName"
            label="Full name"
            type="text"
            formControlName="fullName"
            [hasError]="
              formHasErrors &&
              !!contactDetailsForm.get('fullName')?.errors?.['required']
            "
            data-test="fullNameInput"
            errorMsgId="fullNameErrorMsg"
          ></pofo-input>

          @if ( formHasErrors &&
          contactDetailsForm.get('fullName')?.errors?.['required'] ) {
          <pofo-error-message
            errorId="fullNameErrorMsg"
            testId="fullNameReqErrorMsg"
            errorMessage="Full name is required."
          ></pofo-error-message>
          }
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="mb-3">
          <pofo-input
            name="addressLine1"
            label="Address line 1"
            type="text"
            formControlName="addressLine1"
            [hasError]="
              formHasErrors &&
              !!contactDetailsForm.get('addressLine1')?.errors?.['required']
            "
            data-test="addressLine1Input"
            errorMsgId="addressErrorMsg"
          ></pofo-input>

          @if ( formHasErrors &&
          contactDetailsForm.get('addressLine1')?.errors?.['required'] ) {
          <pofo-error-message
            errorId="addressErrorMsg"
            testId="addressReqErrorMsg"
            errorMessage="Address is required."
          ></pofo-error-message>
          }
        </div>

        <div class="mb-3">
          <pofo-input
            name="addressLine2"
            label="Address line 2 (optional)"
            type="text"
            formControlName="addressLine2"
            [hasError]="false"
            data-test="addressLine2Input"
          ></pofo-input>
        </div>

        <div class="mb-3">
          <pofo-input
            name="city"
            label="City/Town"
            type="text"
            formControlName="city"
            [hasError]="
              formHasErrors && !!contactDetailsForm.get('city')?.errors?.['required']
            "
            data-test="cityInput"
            errorMsgId="cityErrorMsg"
          ></pofo-input>

          @if ( formHasErrors &&
          contactDetailsForm.get('city')?.errors?.['required'] ) {
          <pofo-error-message
            errorId="cityErrorMsg"
            testId="cityReqErrorMsg"
            errorMessage="City is required."
          ></pofo-error-message>
          }
        </div>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="mb-3 md:mb-0 w-full md:w-1/2 md:mr-3">
            <pofo-dropdown
              label="County / State / Province"
              field="state"
              [optionItems]="states"
              formControlName="state"
              [hasError]="formHasErrors && contactDetailsForm.get('state')?.errors?.['required']"
              data-test="statesDropDown"
              errorMsgId="stateErrorMsg"
            ></pofo-dropdown>

            @if (formHasErrors &&
            contactDetailsForm.get('state')?.errors?.['required']) {
            <pofo-error-message
              errorId="stateErrorMsg"
              testId="stateReqErrorMsg"
              errorMessage="County/State/Province is required."
            ></pofo-error-message>
            }
          </div>

          <div class="w-full md:w-1/2">
            <pofo-input
              name="postCode"
              label="ZIP code"
              type="text"
              formControlName="postCode"
              [hasError]="formHasErrors && contactDetailsForm.get('postCode')?.errors?.['required']"
              data-test="postCodeInput"
              errorMsgId="postCodeErrorMsg"
            ></pofo-input>

            @if (formHasErrors &&
            contactDetailsForm.get('postCode')?.errors?.['required']) {
            <pofo-error-message
              errorId="postCodeErrorMsg"
              testId="postCodeReqErrorMsg"
              errorMessage="ZIP code is required."
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <p class="text-sm mb-2 md:mb-3">Contact Information</p>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="mb-3 md:mb-0 w-full md:w-1/2 md:mr-3">
            <pofo-dropdown
              label="Country code"
              placeholder="Select Country Code"
              field="phoneCountryCode"
              [value]="contactDetailsForm.get('phoneCountryCode')?.value"
              [optionItems]="countryCodesOptions"
              formControlName="phoneCountryCode"
              [hasError]="formHasErrors && contactDetailsForm.get('phoneCountryCode')?.errors?.['required']"
              data-test="phoneCountryCodeDropDown"
              errorMsgId="phoneCountryCodeErrorMsg"
            ></pofo-dropdown>

            @if (formHasErrors&&
            contactDetailsForm.get('phoneCountryCode')?.errors?.['required']) {
            <pofo-error-message
              errorId="phoneCountryCodeErrorMsg"
              testId="phoneCountryCodeReqErrorMsg"
              errorMessage="Country code is required."
            ></pofo-error-message>
            }
          </div>

          <div class="w-full md:w-1/2">
            <pofo-input
              name="phoneNumber"
              label="Cell phone number"
              type="tel"
              formControlName="phoneNumber"
              [hasError]="
                !!(formHasErrors && contactDetailsForm.get('phoneNumber')?.errors) ||
                !!(
                  contactDetailsForm.get('phoneNumber')?.touched &&
                  contactDetailsForm.get('phoneNumber')?.errors?.['minlength']
                )
              "
              data-test="phoneNumberInput"
              errorMsgId="phoneNumberErrorMsg"
            ></pofo-input>

            @if (formHasErrors &&
            contactDetailsForm.get('phoneNumber')?.errors?.['required']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberReqErrorMsg"
              errorMessage="Cell phone number is required."
            ></pofo-error-message>
            } @else if (formHasErrors &&
            contactDetailsForm.get('phoneNumber')?.errors?.['minlength']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberLengthErrorMsg"
              errorMessage="Must have at least 7 digits."
            ></pofo-error-message>
            } @else if (formHasErrors &&
            contactDetailsForm.get('phoneNumber')?.errors?.['pattern']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberNoSpecialCharsErrorMsg"
              errorMessage="Please do not use any special characters or spaces."
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <pofo-checkbox
          name="consent"
          formControlName="consent"
          identifier="consent"
          iconName="check-tick-sm"
        >
          <p class="text-sm">
            I'd like to receive reminders and other informational or
            account-related information by text. Message and data rates may
            apply. Message frequency varies. Please review the SMS Terms of
            Service in our
            <a
              class="underline"
              href="https://www.letsgetchecked.com/privacy-policy/"
              target="_blank"
              rel="noopener"
              data-test="privacy"
              >Privacy Policy</a
            >
          </p>
        </pofo-checkbox>

        <div class="mt-4">
          <button
            type="submit"
            class="btn btn-primary btn-medium w-full"
            data-test="confirmAndContinueBtn"
            [disabled]="isLoading"
          >
            Confirm & continue
          </button>
        </div>
      </div>
    </form>
  </pofo-container>
</div>
