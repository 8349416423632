import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
  signal
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { EnvironmentService } from 'src/environments/environment.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCalendar } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

@Component({
  selector: 'pofo-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatePickerHeaderComponent<D> implements OnDestroy, AfterViewInit {
  readonly _environmentService = inject(EnvironmentService);
  readonly _calendar = inject<MatCalendar<D>>(MatCalendar);
  readonly _dateAdapter = inject<DateAdapter<D>>(DateAdapter);
  readonly _dateFormats = inject(MAT_DATE_FORMATS);
  readonly _destroyed = new Subject<void>();
  readonly periodLabel = signal('');

  public assetPath = this._environmentService.properties.assetPath;

  handlePeriodLabel() {
    this.periodLabel.set(
      this._dateAdapter
        .format(this._calendar.activeDate, this.displayFormat())
        .toLocaleUpperCase()
    );
  }

  constructor() {
    this._calendar.stateChanges
      .pipe(startWith(null), takeUntil(this._destroyed))
      .subscribe(() => {
        this.handlePeriodLabel();
      });
  }

  getMultiYearRange(): string {
    const activeDate = this._calendar.activeDate as Date;
    const startYear = Math.floor(activeDate.getFullYear() / 24) * 24;
    const endYear = startYear + 23;

    return `${startYear} - ${endYear}`;
  }

  lowerCaseMonth(calendarElement: Element) {
    const monthElements = calendarElement?.querySelectorAll(
      '.mat-calendar-body-cell-content'
    );

    monthElements?.forEach((el) => {
      const currentText = el.textContent?.toLowerCase() ?? '';
      el.textContent = currentText;
    });
  }

  handleClick(event: Event, calendarElement: Element): void {
    this.lowerCaseMonth(calendarElement);
    const target = event.target as HTMLElement;
    if (target.classList.contains('mat-calendar-body-cell-content')) {
      this.handlePeriodLabel();
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      setTimeout(() => {
        this.handlePeriodLabel();
      }, 500);
    }
  }

  ngAfterViewInit(): void {
    const calendarElement = document.querySelector('mat-calendar');
    if (calendarElement) {
      setTimeout(() => {
        this.lowerCaseMonth(calendarElement);
      }, 1);

      calendarElement.addEventListener('click', (event: Event) => {
        this.handleClick(event, calendarElement);
      });

      calendarElement.addEventListener('keydown', (event: Event) => {
        this.handleKeyDown(event as KeyboardEvent);
      });
    }
  }

  toggleView() {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.currentView = 'year';
    } else if (this._calendar.currentView === 'year') {
      this._calendar.currentView = 'month';
    } else {
      this._calendar.currentView = 'multi-year';
    }

    this.handlePeriodLabel();
  }

  displayFormat() {
    if (this._calendar.currentView === 'multi-year') {
      return this.getMultiYearRange();
    } else if (this._calendar.currentView === 'year') {
      return this._dateFormats.display.dateA11yLabel;
    } else {
      return this._dateFormats.display.monthYearA11yLabel;
    }
  }

  ngOnDestroy() {
    const calendarElement = document.querySelector('mat-calendar');
    if (calendarElement) {
      calendarElement.removeEventListener('click', (event: Event) => {
        this.handleClick(event, calendarElement);
      });
      calendarElement.removeEventListener(
        'keydown',
        this.handleKeyDown as EventListener
      );
    }
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousClicked() {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        -24
      );
    } else if (this._calendar.currentView === 'year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        -1
      );
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
        this._calendar.activeDate,
        -1
      );
    }
  }

  nextClicked() {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        24
      );
    } else if (this._calendar.currentView === 'year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        1
      );
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
        this._calendar.activeDate,
        1
      );
    }
  }
}
