import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { OrderService } from 'src/app/services/order/order.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _http = inject(HttpService);
  private _workflow = inject(WorkflowService);
  private _orderService = inject(OrderService);
  private _environmentService = inject(EnvironmentService);

  makePaymentResponseParser<T>(response: T) {
    return response;
  }

  public makePayment<T>(paymentMethodId: string): Observable<LoaderState<T>> {
    return this._http.post<T, T>(
      `${this._environmentService.properties.apiGateway}/order/${this._workflow.currentProgram}/payment`,
      {
        paymentMethodId,
        orderId: this._orderService.orderRef
      },
      this.makePaymentResponseParser<T>
    );
  }

  confirmedPaymentResponseParser<T>(response: T) {
    return response;
  }

  public confirmedPayment<T>(paymentId: string): Observable<LoaderState<T>> {
    return this._http.put<T, T>(
      `${this._environmentService.properties.apiGateway}/order/${this._workflow.currentProgram}/payment/${paymentId}`,
      {
        orderId: this._orderService.order.orderRef
      },
      this.confirmedPaymentResponseParser<T>
    );
  }
}
