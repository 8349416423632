import * as amplitude from '@amplitude/analytics-browser';
import { Directive, HostListener, Input } from '@angular/core';
import {
  eventInput,
  eventProperties
} from 'src/app/shared/@types/amplitude.interface';

type category = 'nav' | 'kit activation' | 'e-commerce' | 'results';

@Directive({
  selector: '[pofoAmplitude], [pofoAmplitudeButton], [pofoAmplitudeData]',
  standalone: true
})
export class AmplitudeDirective {
  @Input() pofoAmplitudeButton: eventInput;
  @Input() amplitudeCategory: category;
  @Input() pofoAmplitudeData: eventProperties;
  disabled = false;

  logEvent(message: eventInput, data: eventProperties) {
    amplitude.track(message, {
      location: window?.location.href,
      origin: 'pofo',
      ...data
    });
  }

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.pofoAmplitudeButton && !this.disabled) {
      this.logEvent(this.pofoAmplitudeButton, this.pofoAmplitudeData);
    }
  }
}
