import { RouterModule, Routes } from '@angular/router';
import { GetLabCorpAppointmentResolver } from './resolvers/get-appointment.resolver';
import { NgModule } from '@angular/core';
import { SelectedLocationGuardService } from 'src/app/shared/guards/appointment/selected-location.guard';

const routes: Routes = [
  {
    path: 'location',
    loadComponent: () =>
      import('./pages/select-location/select-location.component').then(
        (m) => m.AppointmentLabcorpSelectLocationComponent
      )
  },
  {
    path: 'date',
    canActivate: [SelectedLocationGuardService],
    loadComponent: () =>
      import('./pages/select-date/select-date.component').then(
        (m) => m.AppointmentLabcorpSelectDateComponent
      )
  },
  {
    path: 'confirm',
    canActivate: [SelectedLocationGuardService],
    loadComponent: () =>
      import('./pages/confirm/confirm.component').then(
        (m) => m.AppointmentLabcorpConfirmComponent
      )
  },
  {
    path: 'manage',
    resolve: { data: GetLabCorpAppointmentResolver },
    loadComponent: () =>
      import('./pages/manage/manage.component').then(
        (m) => m.AppointmentLabcorpManageComponent
      )
  },
  { path: '', redirectTo: 'location', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentLabcorpRoutingModule {}
