import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { PreActivationRoutingModule } from './state-confirmation-routing.module';
import { StateService } from './services/state-confirmation.service';

@NgModule({
  imports: [CommonModule, PreActivationRoutingModule, ComponentsModule],
  providers: [StateService]
})
export class StateModule {}
