import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppointmentGuardService } from '../appointment/guards/appointment.guard';
import { AuthCheckGuard } from '../auth/guard/auth-check.guard';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FlowContainerComponent } from './flow-container.component';
import { FlowContainerRoutingModule } from './flow-container-routing.module';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    FlowContainerRoutingModule,
    AngularSvgIconModule,
    FlowContainerComponent
  ],
  providers: [AppointmentGuardService, AuthCheckGuard]
})
export class FlowContainerModule {}
