import { Environment } from './environment';

declare const APPVEYOR_BUILD_VERSION: string;

export const environment: Environment = {
  production: true,
  origin: 'https://www.letsgetchecked-dev1.com',
  apiGateway: 'https://order-flow-<region>.letsgetchecked-dev1.com/api/v1',
  apiGatewayV2: 'https://order-flow-<region>.letsgetchecked-dev1.com/api/v2',
  healthGateway:
    'https://health-gateway-mobile.letsgetchecked-dev2.com/us/api/v2/patient/ensure-created',
  assetPath: getAssetPath(),
  oneTrustId: '198b51f6-5b37-4b63-a601-810a86d648f0-test',
  gtmId: 'GTM-WVQ23Q',
  appIconPath: getAppIconPath(),
  recaptchaSiteKey: '6Le4niIjAAAAAGNa1ixxChgqnrmEgOuHdE9BGNPA',
  authorityURL: 'https://identityprovider.letsgetchecked-dev1.com',
  patientDashboardURL: 'https://www.letsgetchecked-dev1.com/account',
  geneticsHubURL: 'https://www.letsgetchecked-dev1.com/genetics-hub',
  stripe: {
    us: 'pk_test_wKYuvwGuBHZZn20Y6KXdziDY',
    eu: 'pk_test_tyXK8WbhysyEGhSc3KiJxuoW'
  },
  amplitudeKey: '2c90be2e2e8e846e8c7223284c8b3a44',
  isDev: true
};

function getAssetPath() {
  try {
    return decodeURIComponent(`${APPVEYOR_BUILD_VERSION}/assets/`).replace(
      /\s/g,
      ''
    );
  } catch {
    return 'unittests'; // hacky fix  - wasted hours trying to get testing framework to work with webpack. instead made it default to this
  }
}

function getAppIconPath() {
  try {
    return decodeURIComponent(`${APPVEYOR_BUILD_VERSION}/`).replace(/\s/g, '');
  } catch {
    return 'unittests'; // hacky fix  - wasted hours trying to get testing framework to work with webpack. instead made it default to this
  }
}
