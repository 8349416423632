import * as amplitude from '@amplitude/analytics-browser';
import {
  AuthenticationType,
  FieldType,
  VerificationForm,
  VerificationFormRequest
} from 'src/app/models/verification.model';
import { BehaviorSubject, Observable, take } from 'rxjs';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { VerificationService } from 'src/app/services/verification/verification.service';

@Component({
  selector: 'pofo-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    ErrorMessageComponent,
    FormsModule,
    InputComponent,
    AsyncPipe
  ]
})
export class VerificationComponent implements OnInit {
  private verificationService = inject(VerificationService);

  @ViewChild('verificationFormElement')
  public verificationFormElement: ElementRef<HTMLFormElement>;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  title = 'Enter your details';
  description =
    'Please enter your details to confirm your eligibility to participate in this program';
  authenticationType: AuthenticationType = 'FILE';
  verificationFactors: Observable<VerificationForm | null> =
    this.verificationService.verificationForm;
  participantForm: VerificationFormRequest = {
    factor1: null,
    factor2: null,
    factor3: null,
    factor4: null,
    factor5: null,
    factor6: null
  };
  configurationErrorMessage: string | undefined;
  participantVerificationError: string | undefined;
  formHasErrors = false;

  constructor() {
    this.verificationService.isVerification = true;
  }

  ngOnInit(): void {
    this.verificationService
      .getVerificationFactors()
      .pipe(take(2))
      .subscribe((response) => {
        if ('loading' in response && response.loading !== undefined) {
          this.loading$.next(response.loading);
        }

        if (response.error) {
          this.configurationErrorMessage = response.errorMessage;
        }

        if (response.success && response.value) {
          this.configurationErrorMessage = undefined;

          Object.entries(response.value).forEach((factor) => {
            switch (factor[0]) {
              case 'heading':
                this.title = factor[1];
                break;
              case 'intro':
                this.description = factor[1];
                break;
              default:
                break;
            }
          });

          if (response.value.authenticationType) {
            this.authenticationType = response.value.authenticationType;
          }
        }
      });
  }

  getType(factorType: FieldType) {
    switch (factorType) {
      case 'DATE':
        return 'date';
      case 'NUMBER':
        return 'tel';
      default:
        return 'text';
    }
  }

  convertDates(factors: VerificationForm): VerificationFormRequest {
    const parsedForm = { ...this.participantForm };
    const dateFactors = Object.entries(factors)
      .filter(
        (entry) =>
          entry[1] &&
          entry[1].fieldTypeCode &&
          entry[1].fieldTypeCode === 'DATE'
      )
      .map((entry) => entry[0]);

    dateFactors.forEach((factor) => {
      parsedForm[factor as keyof VerificationFormRequest] = (
        parsedForm[factor as keyof VerificationFormRequest] as unknown as Date
      )?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    });

    return parsedForm;
  }

  onSubmitForm(form: NgForm, factors: VerificationForm) {
    this.participantVerificationError = undefined;

    if (form.valid) {
      this.formHasErrors = false;
      this.verificationService
        .verifyParticipantForm(
          this.convertDates(factors),
          this.authenticationType
        )
        .subscribe((response) => {
          if ('loading' in response && response.loading !== undefined) {
            this.loading$.next(response.loading);
          }

          if (!response.loading && response.error) {
            this.participantVerificationError =
              response.errorMessage ||
              `Sorry, please check your details and try again or contact the LetsGetChecked
    support team.`;
          }

          if (response.onCompleteCallback) {
            amplitude.track('click confirm and continue on verification', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)'
            });
            response?.onCompleteCallback();
          }
        });
    } else {
      this.formHasErrors = true;

      const firstInvalid =
        this.verificationFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }
}
