/* eslint-disable prettier/prettier */
import { AwsRum, type AwsRumConfig } from 'aws-rum-web';

declare const window: Window &
  typeof globalThis & {
    cwr: AwsRum;
  };

export const awsRumIds = new Map<
  string,
  { identityPoolId: string; applicationId: string }
>([
  [
    'order.letsgetchecked.com',
    {
      identityPoolId: 'us-east-1:562e1e07-cb06-4513-8eeb-bfc4da30df12',
      applicationId: '544b2d63-5744-40eb-9ed3-839a782b78f3'
    }
  ],
  [
    'order.letsgetchecked-stg2.com',
    {
      identityPoolId: 'us-east-1:9a16b975-71f9-4330-b626-5548d6073627',
      applicationId: '719d26a5-9c99-4cd1-a069-b5b24d6b5d6a'
    }
  ],
  [
    'order.letsgetchecked-dev1.com',
    {
      identityPoolId: 'us-east-1:9523906b-6b36-46a1-a919-74e75c6e93ba',
      applicationId: 'ff3259b2-b82a-4786-a46b-9dc045958edd'
    }
  ]
]);

const getApplicationVersion = () => {
  try {
    const metaElements = document.getElementsByName('application-version');
    if (metaElements.length === 0) {
      return 'local';
    }
    const element = metaElements[0] as HTMLMetaElement;
    return element.content === '' ||
      element.content === '{{applicationVersion}}'
      ? 'local'
      : element.content;
  } catch {
    return 'local';
  }
};

export function initializeAwsRum(): void {
  const applicationVersion = getApplicationVersion();
  const isLocal = applicationVersion === 'local';
  const awsRumEnvConfig = {
    applicationId: awsRumIds.get(location.host)?.applicationId || '',
    applicationVersion: isLocal ? 'local' : applicationVersion,
    applicationRegion: 'us-east-1',
    config: {
      sessionSampleRate: 1,
      identityPoolId: awsRumIds.get(location.host)?.identityPoolId || '',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: [
        'errors',
        [
          'http',
          {
            urlsToExclude: [
              /https:\/\/api2\.amplitude\.com/,
              /https:\/\/perfalytics\.com/,
              /https:\/\/api\.perfalytics\.com/,
              /https:\/\/cdn\.cookielaw\.org/,
              /https:\/\/www\.gstatic\.com/,
              /https:\/\/m\.stripe\.com/,
              /https:\/\/js\.stripe\.com/,
              /\/?(pwui-orderflow-frontend|undefined|assets)[0-9a-zA-Z-]*\//
            ],
            addXRayTraceIdHeader: [
              /https:\/\/health-gateway-mobile\.letsgetchecked(-dev1|-dev2|-stg2)?\.com/,
              /https:\/\/order-flow(-eu|-us)?\.letsgetchecked(-dev1|-dev2|-stg2)\.com/,
              /https:\/\/identityprovider\.letsgetchecked(-dev1|-dev2|-stg2)\.com/
            ]
          }
        ]
      ],
      allowCookies: true,
      enableXRay: true,
      cookieAttributes: {
        unique: true
      }
    } as AwsRumConfig
  };

  try {
    const awsRum = new AwsRum(
      awsRumEnvConfig.applicationId,
      awsRumEnvConfig.applicationVersion,
      awsRumEnvConfig.applicationRegion,
      awsRumEnvConfig.config
    );
    window.cwr = awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
