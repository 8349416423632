import {
  PatientAddress,
  PatientDetailsResponse
} from '../@types/patient.interface';
import { format, parse } from 'date-fns';
import { GenerateFormRequest } from '../@types/physician-forms.interface';
import { PatientPhoneNumberResponse } from 'src/app/modules/phone-number/@types/phone-number.interface';

export class Patient {
  externalId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dateOfBirth: Date;
  gender: string;
  address: PatientAddress;
  phoneNumber: PatientPhoneNumberResponse;
  phoneWithPrefix: string;

  constructor(res: PatientDetailsResponse) {
    this.externalId = res.externalId;
    this.firstName = res.firstName;
    this.middleName = res.middleName;
    this.lastName = res.lastName;
    this.email = res.email;
    this.dateOfBirth = parse(res.dateOfBirth, 'yyyy-MM-dd', new Date());
    this.gender = res.gender;
    this.address = res.address;
    this.phoneNumber = res.phoneNumber;
    this.phoneWithPrefix = this.parsePhoneNumber();
  }

  public parsePhoneNumber(): string {
    const prefix = this.phoneNumber.phonePrefix.includes('+')
      ? this.phoneNumber.phonePrefix
      : `+${this.phoneNumber.phonePrefix}`;
    return `${prefix}|${this.phoneNumber.phoneNumber}`;
  }

  public parseDOB(): string {
    return format(this.dateOfBirth, 'MM / dd / yyyy');
  }

  public generateFormPayload(): GenerateFormRequest {
    return {
      externalId: this.externalId,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      normalizedEmail: this.email,
      dob: this.dateOfBirth.toDateString(),
      gender: this.gender,
      address: {
        fullName: this.address.fullName,
        phone: this.parsePhoneNumber(),
        city: this.address.city,
        line1: this.address.line1,
        line2: this.address.line2,
        postalCode: this.address.postalCode,
        countryIsoAlpha2Code: this.address.countryIso,
        country: this.address.country,
        areaTitle: this.address.area
      }
    };
  }
}
