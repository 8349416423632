<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row text-base">
      Upload a picture of your completed form that has been filled and signed by
      your physician. We will review your results and release them on your
      account dashboard in 3-5 days.<br /><br />
      We will notify you when your results are ready to view.
    </p>

    <pofo-alert class="text-base" type="warning">
      The picture must be of the entire form. Your results and the provider's
      signature must be clearly visible.
    </pofo-alert>

    <div class="p-4 mt-6 border rounded-md border-platinum bg-white relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        Physician Form
      </div>

      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="diabetes" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Biometric Screening</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>

      <div class="line"></div>

      <pofo-upload
        label="Upload your Lab report"
        icon="image"
        [isMultiple]="false"
        (selectedFiles)="chosenFiles($event)"
      ></pofo-upload>
    </div>

    <div class="line"></div>

    <div class="pb-3">
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="confirm-button"
        [disabled]="isDisabled()"
        (click)="onConfirm()"
      >
        Confirm & continue
      </button>
    </div>
  </pofo-container>
</div>
