import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { InsuranceComponent } from './insurance.component';
import { InsuranceRoutingModule } from './insurance-routing.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    InsuranceRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    InsuranceComponent
  ]
})
export class InsuranceModule {}
