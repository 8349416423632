import { NOT_FOUND, VERIFICATION_ROUTE } from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: VERIFICATION_ROUTE,
    loadComponent: () =>
      import('./verification/verification.component').then(
        (m) => m.VerificationComponent
      )
  },
  { path: '', redirectTo: VERIFICATION_ROUTE, pathMatch: 'full' },
  { path: '**', redirectTo: `/${NOT_FOUND}`, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRequestRoutingModule {}
