import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { PhysicianFormService } from '../../services/physician-form/physician-form.service';
import { UrlParamsService } from '../../services/url-params/url-params.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicianFormGuardService {
  private _physicianFormService = inject(PhysicianFormService);
  private _urlParamsService = inject(UrlParamsService);
  private _router = inject(Router);

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this._physicianFormService.filesToUpload.length <= 0 &&
      this._urlParamsService.get('physicianFormId')
    ) {
      if (
        (state.url.includes('/upload') &&
          !state.url.includes('/upload-confirm')) ||
        state.url.includes('/manage')
      ) {
        return true;
      }

      // TODO: Once in Angular >= 14.1.0
      // https://github.com/angular/angular/pull/45877
      // return createUrlTreeFromSnapshot(route, ['..', 'upload']);
      return this._router.createUrlTree([
        state.url.slice(0, state.url.lastIndexOf('/')) + '/upload'
      ]);
    } else {
      return true;
    }
  }
}
