import {
  CONSENT_ROUTE,
  IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE,
  IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE
} from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';

import { GetAppointmentResolver } from 'src/app/shared/resolvers/get-appointment.resolver';
import { HasAppointmentIdGuard } from './guards/has-appointment-id.guard';
import { HasImmunizationIdGuard } from './guards/has-immunization-id.guard';
import { HasModalityIdGuard } from 'src/app/shared/guards/has-modality/has-modality-id.guard';
import { NgModule } from '@angular/core';
import { SelectedLocationGuardService } from 'src/app/shared/guards/appointment/selected-location.guard';

const routes: Routes = [
  {
    path: CONSENT_ROUTE,
    loadComponent: () =>
      import('./pages/consent/consent.component').then(
        (m) => m.ImmunizationAppointmentConsentComponent
      ),
    canActivate: [HasModalityIdGuard]
  },
  {
    path: IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE,
    canActivate: [HasImmunizationIdGuard],
    children: [
      {
        path: 'location',
        loadComponent: () =>
          import('./pages/select-location/select-location.component').then(
            (m) => m.AppointmentSelectLocationComponent
          )
      },
      {
        path: 'date',
        canActivate: [SelectedLocationGuardService],
        loadComponent: () =>
          import('./pages/select-date/select-date.component').then(
            (m) => m.AppointmentSelectDateComponent
          )
      },
      {
        path: 'confirm',
        canActivate: [SelectedLocationGuardService],
        loadComponent: () =>
          import('./pages/confirm/confirm.component').then(
            (m) => m.AppointmentConfirmComponent
          )
      },
      {
        path: 'manage',
        canActivate: [HasAppointmentIdGuard],
        resolve: { data: GetAppointmentResolver },
        loadComponent: () =>
          import('./pages/manage/manage.component').then(
            (m) => m.AppointmentManageComponent
          )
      },
      { path: '', redirectTo: 'location', pathMatch: 'full' }
    ]
  },
  {
    path: IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE,
    loadComponent: () =>
      import('./pages/confirmed/confirmed.component').then(
        (m) => m.AppointmentConfirmedComponent
      )
  },
  {
    path: '',
    redirectTo: 'consent',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImmunizationAppointmentRoutingModule {}
