import { BehaviorSubject, Observable, map, take } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { Order, OrderRequest } from '../../models/order.model';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from '../../models/loading.model';
import { Router } from '@angular/router';
import { ShippingConfigResponse } from 'src/app/models/shipping-config.model';
import { WorkflowService } from '../workflow/workflow.service';

const defaultOrder = {
  orderRef: ''
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private router = inject(Router);
  private httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private orderSubject: BehaviorSubject<Order> = new BehaviorSubject<Order>(
    defaultOrder
  );

  get order(): Order {
    return this.orderSubject.value;
  }

  get orderRef(): string {
    return this.order.orderRef
      ? this.order.orderRef
      : sessionStorage.getItem('orderRef') || defaultOrder.orderRef;
  }

  createOrderResponseParser(response: Order) {
    return response;
  }

  createOrder(formData: OrderRequest): Observable<LoaderState<Order>> {
    return this.httpService
      .post<Order, Order>(
        `${this._environmentService.properties.apiGateway}/order/${this._workflowService.currentProgram}/order`,
        formData,
        this.createOrderResponseParser
      )
      .pipe(
        take(2),
        map((response) => {
          if (!response.loading && response.success && response.value) {
            sessionStorage.setItem('orderRef', response.value.orderRef);
            this.orderSubject.next(response.value);

            if (response.onCompleteCallback) {
              response?.onCompleteCallback();
            }
          }

          return response;
        })
      );
  }

  getShippingConfigurationResponseParser(response: ShippingConfigResponse) {
    return response;
  }

  getShippingConfiguration(): Observable<LoaderState<ShippingConfigResponse>> {
    return this.httpService
      .get<ShippingConfigResponse, ShippingConfigResponse>(
        `${this._environmentService.properties.apiGateway}/order/${this._workflowService.currentProgram}/shipping-configuration`,
        this.getShippingConfigurationResponseParser
      )
      .pipe(
        map((response: LoaderState<ShippingConfigResponse>) => {
          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }

          return response;
        })
      );
  }
}
