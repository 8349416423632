import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DropdownComponent } from 'src/app/components/dropdown/dropdown.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { PatientService } from 'src/app/shared/services/patient/patient.service';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';
import { countryPhoneCodeMapping } from 'src/app/utils/country-codes';

@Component({
  selector: 'pofo-phone-number',
  templateUrl: './phone-number.component.html',
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    DropdownComponent,
    ErrorMessageComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule
  ]
})
export class PhoneNumberComponent implements OnInit {
  private _fb = inject(UntypedFormBuilder);
  private _syncVisitService = inject(SyncVisitService);
  private _patientService = inject(PatientService);

  @ViewChild('phoneNumberFormElement')
  public phoneNumberFormElement: ElementRef<HTMLFormElement>;

  public readonly title = 'Contact information';
  public readonly phoneNumberLabel = 'Phone number';
  public readonly countryCodes = countryPhoneCodeMapping();
  public isLoading = false;
  public errorMessage?: string;

  public phoneNumberForm = this._fb.nonNullable.group({
    phoneCountryCode: ['+1', [Validators.required]],
    phoneNumber: [
      '',
      [
        Validators.required,
        Validators.pattern('^\\d*$'),
        Validators.minLength(10),
        Validators.maxLength(10)
      ]
    ]
  });

  ngOnInit(): void {
    this._patientService
      .getPhoneNumber$(this._syncVisitService.modalityId)
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'phone number (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.value?.phonePrefix) {
          this.phoneNumberForm
            .get('phoneCountryCode')
            ?.setValue(
              res.value?.phonePrefix.includes('+')
                ? res.value?.phonePrefix
                : `+${res.value?.phonePrefix}`
            );
        }

        if (res.value?.phoneNumber) {
          this.phoneNumberForm
            .get('phoneNumber')
            ?.setValue(res.value?.phoneNumber);
        }
      });
  }

  public onPhoneCountryCodeChange(phoneCountryCode: string): void {
    this.phoneNumberForm.get('phoneCountryCode')?.setValue(phoneCountryCode);

    this.phoneNumberForm.get('phoneNumber')?.clearValidators();
    const lengthValidator =
      this.phoneNumberForm.get('phoneCountryCode')?.value === '+1'
        ? [Validators.minLength(10), Validators.maxLength(10)]
        : [Validators.minLength(7), Validators.maxLength(7)];

    this.phoneNumberForm
      .get('phoneNumber')
      ?.setValidators([
        Validators.required,
        ...lengthValidator,
        Validators.pattern('^\\d*$')
      ]);

    this.phoneNumberForm.get('phoneNumber')?.updateValueAndValidity();
  }

  public onSubmitForm() {
    this.errorMessage = undefined;

    if (this.phoneNumberForm.valid) {
      const data = this.phoneNumberForm.getRawValue();

      this._patientService
        .setPhoneNumber$(
          this._syncVisitService.modalityId,
          this._syncVisitService.syncVisitId,
          data.phoneCountryCode,
          data.phoneNumber
        )
        .pipe(
          take(2),
          tap((res) => {
            if (res.error) {
              this.errorMessage = res.errorMessage ?? 'There was an error';

              amplitude.logEvent('error', {
                location: window?.location.href,
                origin: 'pofo',
                category: 'phone number (pofo)',
                errorMessage: this.errorMessage
              });
            } else {
              this.errorMessage = undefined;
            }

            this.isLoading = res.loading ?? false;
          })
        )
        .subscribe((res) => {
          this._syncVisitService.phoneNumber = `${data.phoneCountryCode}${data.phoneNumber}`;

          if (res.onCompleteCallback) {
            res.onCompleteCallback();
          }
        });
    } else {
      const firstInvalid =
        this.phoneNumberFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }
}
