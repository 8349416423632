import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pofo-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  standalone: true,
  imports: [IconComponent, AlertComponent]
})
export class FileUploadComponent {
  @ViewChild('fileInput') private fileInput: ElementRef;
  @Input() public label = '';
  @Input() public icon = '';
  @Input() public acceptedFileTypes = ['.png', '.pdf', '.jpg', '.jpeg'];
  @Input() public sizeLimit = 20;
  @Input() public isMultiple = true;
  @Output() selectedFiles: EventEmitter<File[]> = new EventEmitter<File[]>();

  public errorMessage?: string;
  public chosenFiles: File[] = [];
  public thumbnails: {
    index: number;
    name: string;
    image: string | ArrayBuffer | null | undefined;
    fileType: string;
  }[] = [];

  /* istanbul ignore next */
  public selectFiles(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.chosenFiles = Array.from<File>(input.files || []);
    this.thumbnails = [];

    if (this.chosenFiles && this.chosenFiles.length > 0) {
      for (let i = 0; i < this.chosenFiles.length; i++) {
        let isSuccess = false;
        const fileType = this.chosenFiles[i].name.match(/\.[0-9a-z]+$/i);

        if (fileType && fileType[0]) {
          switch (true) {
            case this.acceptedFileTypes.indexOf(fileType[0]) <= -1:
              this._errorHandle('Invalid format');
              break;
            case this.chosenFiles[i].size > this.sizeLimit * 1024 * 1024:
              this._errorHandle(
                `File size is to big, limit: ${this.sizeLimit}MB`
              );
              break;

            default:
              isSuccess = true;
              this.errorMessage = undefined;
              break;
          }

          if (isSuccess) {
            if (fileType && fileType[0].includes('pdf')) {
              this.thumbnails.push({
                index: i,
                name: this.chosenFiles[i].name,
                image: 'pdf-file',
                fileType: fileType[0]
              });
            } else {
              const reader = new FileReader();

              reader.onload = (e) => {
                this.thumbnails.push({
                  index: i,
                  name: this.chosenFiles[i].name,
                  image: e.target?.result,
                  fileType: fileType[0]
                });
              };

              reader.readAsDataURL(this.chosenFiles[i]);
            }
          }
        }
      }
    }

    this.selectedFiles.emit(this.chosenFiles);
  }

  private _errorHandle(text: string): void {
    this.errorMessage = text;
    this.chosenFiles = [];
    this.fileInput.nativeElement.value = null;
  }

  public removeFile(fileIndex: number): void {
    this.thumbnails = this.thumbnails.filter((thumbnail) => {
      return thumbnail.index !== fileIndex;
    });

    this.chosenFiles = this.chosenFiles.filter(
      (f: File, index: number) => fileIndex !== index
    );

    if (this.chosenFiles.length === 0) {
      this.fileInput.nativeElement.value = null;
    }

    this.selectedFiles.emit(this.chosenFiles);
  }

  public triggerFileInput() {
    this.fileInput.nativeElement.click();
  }
}
