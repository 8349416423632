<pofo-container
  background="{{ isSelected ? 'bg-teal-100' : 'bg-white' }} {{
    tabable ? 'focus:bg-teal-100' : ''
  }}"
  [border]="true"
  [tabable]="tabable"
  borderStyling="
    border rounded-md ring-0 outline-none {{
    isSelected ? 'border-teal-400' : 'border-white'
  }} {{ tabable ? 'hover:border-teal-400 focus:border-teal-400' : '' }}
  "
  shadowStyling="shadow-xs"
  spacingStyles="mb-3 p-4"
>
  <div class="mb-3">
    <div class="flex align-center justify-between">
      <h1 class="text-navy text-base-lg capitalize">
        {{ parsedName }}
      </h1>
      @if (isSelected) {
      <pofo-icon class="text-teal-400" iconName="check-tick-lg"></pofo-icon>
      } @if (showChangeButton) {
      <button
        class="btn btn-secondary btn-xsmall"
        data-test="changePCPBtn"
        (click)="changeClick()"
      >
        Change
      </button>
      }
    </div>
  </div>
  <div class="mb-1 text-storm text-sm">
    <p>{{ locationAddress.address1 }}</p>
    <p>
      {{ locationAddress.city }}, {{ locationAddress.state }}
      {{ locationAddress.postalCode }}
    </p>
  </div>
  <div class="text-storm text-sm">
    <p>
      <span>Phone: {{ locationAddress.telephoneNumber }}</span>
      <span>{{
        locationAddress.faxNumber ? ' | Fax: ' + locationAddress.faxNumber : ''
      }}</span>
    </p>
  </div>
</pofo-container>
