<div class="my-12 md:my-16 md:w-[540px] l:w-[550px] mx-4 md:mx-auto">
  @if (!!errorMessage) {
  <pofo-alert type="error" data-test="error-message">{{
    errorMessage
  }}</pofo-alert>
  } @if (currentQuestion; as question) {
  <pofo-container
    [attr.data-test]="'questionnaire-pofo-container'"
    containerTitle="{{
      (labels && labels['questionnaireTitle']) || 'Questionnaire'
    }}"
  >
    <p
      class="pb-4 mb-4 shadow-inset shadow-cloudy"
      [str2Html]="
        (labels && labels['questionnaireSubtitle']) ||
        'Take this short questionnaire to help choose the right testing kit for you. <strong>Your answer will be anonymous.</strong>'
      "
    ></p>
    @if (totalAmountOfQuestions > 1) {
    <div class="flex-column justify-between mt-6 mb-5">
      <div class="w-full bg-cloudy h-0.5 mb-2">
        <div
          class="bg-teal-400 h-0.5"
          [style.width]="getCurrentProgressBarWidth()"
        ></div>
      </div>
      <span class="text-base font-medium text-teal-600"
        >Step {{ currentQuestionNumber }} of {{ totalAmountOfQuestions }}</span
      >
    </div>
    } @switch (question.questionType) { @case ('MultiSelect') {
    <pofo-multi-select
      [isLastQuestion]="isLastQuestion"
      [question]="question"
      [isEligibilityQuestionnaire]="true"
      (questionCompleted)="onQuestionComplete($event)"
    ></pofo-multi-select>
    } @case ('SingleSelect') {
    <pofo-single-select
      [isLastQuestion]="isLastQuestion"
      [question]="question"
      [isEligibilityQuestionnaire]="true"
      (questionCompleted)="onQuestionComplete($event)"
    ></pofo-single-select>
    } @case ('TextArea') {
    <pofo-textarea
      [isLastQuestion]="isLastQuestion"
      [question]="question"
      [isEligibilityQuestionnaire]="true"
      (questionCompleted)="onQuestionComplete($event)"
    ></pofo-textarea>
    } }
  </pofo-container>
  } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  }
</div>
