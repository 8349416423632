import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dateMask]',
  standalone: true
})
export class DateMaskDirective implements OnInit {
  private el: HTMLInputElement;
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    this.el = this.elementRef.nativeElement;
  }
  ngOnInit() {
    this.formatValue();
  }
  @HostListener('input', ['$event'])
  onInput() {
    let value = this.el.value.replace(/\D/g, '');
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + '/' + value.slice(5);
    }
    value = value.substring(0, 10);
    this.el.value = value;
  }
  @HostListener('blur')
  onBlur() {
    this.formatValue();
  }
  private formatValue() {
    const raw = this.el.value.replace(/\D/g, '');
    if (raw.length === 8) {
      const dd = raw.slice(0, 2);
      const mm = raw.slice(2, 4);
      const yyyy = raw.slice(4, 8);
      this.el.value = `${dd}/${mm}/${yyyy}`;
    }
  }
}
