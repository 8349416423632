import { Component, Input } from '@angular/core';

@Component({
  selector: 'pofo-error-message',
  templateUrl: './error-message.component.html',
  standalone: true
})
export class ErrorMessageComponent {
  @Input() errorId: string;
  @Input() testId: string;
  @Input() errorMessage: string;
}
