/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import {
  Questionnaire,
  QuestionnaireResults
} from 'src/app/modules/questionnaire/@types/questionnaire.interface';
import { CoreQuestionnaire } from './core-questionnaire';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../../../services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { QuestionnaireEligibilityAnswer } from '../@types/eligibility-questionnaire.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class EligibilityQuestionnaireService extends CoreQuestionnaire {
  private _http = inject(HttpService);
  private _environmentService = inject(EnvironmentService);
  private _workflowService = inject(WorkflowService);

  public getQuestionnaire$(): Observable<LoaderState<Questionnaire>> {
    return this._http.get<Questionnaire, Questionnaire>(
      `${this._environmentService.properties.apiGateway}/questionnaire/${this._workflowService.currentProgram}`,
      this.responseParser<Questionnaire>
    );
  }

  public submitQuestionnaireAnswers$(
    questionnaireAnswers: QuestionnaireEligibilityAnswer[]
  ): Observable<LoaderState<QuestionnaireResults[]>> {
    return this._http.post<QuestionnaireResults[], QuestionnaireResults[]>(
      `${this._environmentService.properties.apiGateway}/questionnaire/${this._workflowService.currentProgram}/submit?countryIsoAlpha2=us`,
      questionnaireAnswers,
      this.responseParser<QuestionnaireResults[]>
    );
  }

  public confirmResults$(): Observable<LoaderState<unknown>> {
    return this._http.get(
      `${this._environmentService.properties.apiGateway}/questionnaire/${this._workflowService.currentProgram}/confirm`,
      this.responseParser<unknown>
    );
  }
}
