import { Injectable, inject } from '@angular/core';
import { Observable, map, of, skip, take } from 'rxjs';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { NOT_FOUND } from 'src/app/app-routes';

@Injectable({
  providedIn: null
})
export class AppointmentGuardService {
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);

  public canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean | UrlTree> {
    if (!this._appointmentService.modalityId) {
      return of(this._router.createUrlTree([NOT_FOUND]));
    }

    return this._appointmentService.getAppointmentConfigs$().pipe(
      take(2),
      skip(1),
      map(() => {
        const path = this._router
          .getCurrentNavigation()
          ?.extractedUrl.toString();

        if (!path) {
          return false;
        }

        if (this._appointmentService.appointmentId) {
          return this._urlSegmentsContain(
            'manage',
            segments,
            path,
            '/',
            '/appointment'
          );
        }

        if (this._appointmentService.modalityType === 'SyncVisit') {
          return this._urlSegmentsContain('date', segments, path);
        }

        return this._urlSegmentsContain('location', segments, path);
      })
    );
  }

  private _urlSegmentsContain(
    segmentPath: string,
    segments: UrlSegment[],
    path: string,
    slice = 'appointment',
    appendPath = 'appointment'
  ): UrlTree | boolean {
    return segments.find((segment) => segment.path === segmentPath) ===
      undefined
      ? this._router.createUrlTree([
          path.slice(0, path.lastIndexOf(slice)) +
            `${appendPath}/${segmentPath}`
        ])
      : true;
  }
}
