<div
  class="flex w-full h-header justify-center items-center bg-off-white px-4"
  role="list"
>
  @for (step of steps; track step; let i = $index) {
  <div
    [ngClass]="[
      'flex items-center text-sm text-storm',
      'focus-visible:outline focus-visible:outline-2 focus-visible:rounded-lex-100',
      'focus-visible:outline-offset-2 focus-visible:outline-[theme(\'colors.teal.600\')]'
    ]"
    attr.data-test="flowStep{{ i + 1 }}"
    [tabindex]="isCurrentStep(step) ? 0 : -1"
    [attr.aria-label]="step.label"
    role="listitem"
  >
    <div
      class="w-6 h-6 text-lex-body-sm content-center text-center rounded-full"
      [ngClass]="[
        isCurrentStep(step)
          ? 'bg-lex-color-bg-dark-tertiary text-lex-neutrals-white font-lex-bold'
          : 'bg-lex-color-bg-selected-hover text-lex-color-text-light'
      ]"
      [attr.aria-current]="isCurrentStep(step) ? 'step' : null"
    >
      <span class="sr-only">Step&nbsp;</span>
      {{ i + 1 }}
    </div>
    <div
      class="hidden md:block text-lex-body-sm text-lex-color-text-light ml-2"
      [ngClass]="[isCurrentStep(step) ? 'font-lex-bold' : 'font-lex-normal']"
      data-test="flowStepLabel"
    >
      {{ step.label }}
    </div>
  </div>

  @if (i < steps.length - 1) {
  <pofo-icon class="text-concrete mx-3" iconName="chevron-right-sm" [size]="20">
  </pofo-icon>
  } }
</div>
