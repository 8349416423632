import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, inject } from '@angular/core';
import {
  CurrentMonth,
  DateList,
  DateTimeList
} from 'src/app/components/datetime-picker/@types/datetime-picker.interface';
import {
  ReplaySubject,
  Subject,
  filter,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import { format, isBefore, isToday } from 'date-fns';
import { AmplitudeDirective } from '../../../../shared/amplitude/amplitude.directive';
import { AppointmentInterface } from 'src/app/shared/models/appointment.model';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DateTimePickerComponent } from 'src/app/components/datetime-picker/datetime-picker.component';

@Component({
  selector: 'pofo-immunization-appointment-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
  standalone: true,
  imports: [ContainerComponent, DateTimePickerComponent, AmplitudeDirective]
})
export class AppointmentSelectDateComponent implements OnDestroy {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _appointmentService = inject(AppointmentService);

  private _destroy$: Subject<void> = new Subject();
  private _currentDateSelected: DateList;
  private _selectedDate$: ReplaySubject<[string, string]> = new ReplaySubject<
    [string, string]
  >(1);
  public readonly title = 'Schedule an appointment';
  public readonly description = 'Select the day and time for your appointment.';
  public readonly selectedLocation = this._appointmentService.selectedLocation;
  public readonly isReschedule = this._appointmentService.isReschedule;
  public readonly minDate = new Date();
  public availableDaysInMonth$: ReplaySubject<DateList[]> = new ReplaySubject<
    DateList[]
  >(1);
  public readonly selectedLocationTimezone =
    this._appointmentService.selectedLocation.timezone;

  public selectedDateAvailableSlots: DateTimeList[] = [];
  public appointmentStartDate = this._setInitialAppointmentStartDate();
  public isWalkin = false;
  public isLoading = false;

  constructor() {
    this._selectedDate$
      .pipe(
        takeUntil(this._destroy$),
        switchMap(([year, month]) =>
          this._appointmentService.getAvailableDaysInMonth$(year, month)
        )
      )
      .subscribe((res) => {
        const daysInMonth: DateList[] = [];

        if (res.value) {
          Object.entries(res.value).forEach(([key, value]) => {
            if (value) {
              daysInMonth.push({
                id: key,
                start: new Date(key),
                end: new Date(key)
              });
            }
          });
        }

        this.availableDaysInMonth$.next(daysInMonth);
      });

    this._selectedDate$.next([
      format(this.appointmentStartDate, 'yyyy'),
      format(this.appointmentStartDate, 'MM')
    ]);
  }

  public gotoSelectLocation(): void {
    this._appointmentService.reset();

    this._router.navigate(['..', 'location'], {
      relativeTo: this._activatedRoute,
      replaceUrl: true
    });
  }

  public selectedSlot(date: DateList): void {
    this._currentDateSelected = date;
  }

  public monthHasChanged(selectedMonth: CurrentMonth) {
    this._selectedDate$.next([
      format(selectedMonth.dateRaw, 'yyyy'),
      format(selectedMonth.dateRaw, 'MM')
    ]);
  }

  public selectedDate(selectedDate: Date): void {
    if (isToday(selectedDate)) {
      const time =
        this._appointmentService.selectedLocation.startDate?.startDate;

      if (time) {
        selectedDate.setHours(
          time.getHours(),
          time.getMinutes(),
          time.getSeconds()
        );
      }

      this.selectedSlot({
        start: selectedDate,
        end: selectedDate
      });
      this.isWalkin = this._appointmentService.isWalkin = true;
      return;
    } else {
      this.isWalkin = this._appointmentService.isWalkin = false;
    }

    this.isLoading = true;

    this._appointmentService
      .getAvailableSlotsInDay$(
        format(selectedDate, 'yyyy'),
        format(selectedDate, 'MM'),
        format(selectedDate, 'dd')
      )
      .pipe(
        take(2),
        filter((r) => r.value !== undefined)
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.selectedDateAvailableSlots = res.value
          ? res.value.map((slot) => {
              const parsedStart = this._appointmentService.parseSlotTime(
                new Date(slot.start),
                'h:mmaaa'
              );
              const parsedEnd = this._appointmentService.parseSlotTime(
                new Date(slot.end),
                'h:mmaaa'
              );

              return {
                start: new Date(slot.start),
                end: new Date(slot.end),
                parsedTime: parsedStart,
                parsedTimeStartEnd: `${parsedStart} - ${parsedEnd}`
              };
            })
          : [];
      });
  }

  public onConfirmSlot(): void {
    const data: AppointmentInterface = {
      startDate: new Date(this._currentDateSelected.start),
      endDate: new Date(this._currentDateSelected.end),
      resourceId: this._currentDateSelected.id
    };

    this._appointmentService.setAppointment(data);

    this._router.navigate(['..', 'confirm'], {
      relativeTo: this._activatedRoute
    });
  }

  public isConfirmBtnDisabled(): boolean {
    return !this._currentDateSelected;
  }

  private _setInitialAppointmentStartDate(): Date {
    if (
      this.selectedLocation.startDate !== undefined &&
      !isBefore(this.selectedLocation.startDate.startDate, new Date())
    ) {
      return this.selectedLocation.startDate.startDate;
    }

    return new Date();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
