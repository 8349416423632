<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto relative">
  <pofo-container [containerTitle]="title">
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>

    <div>
      <div class="line"></div>
      @if (selectedLocation) {
      <pofo-calendar-internal
        [showParsedTimeStartEnd]="true"
        (selectedSlot)="onConfirmSlot($event)"
      ></pofo-calendar-internal>
      }
    </div>
  </pofo-container>
</div>
