import { Pipe, PipeTransform } from '@angular/core';
import { OptionItem } from 'src/app/components/dropdown/dropdown.component';

@Pipe({
  name: 'optionFilter',
  standalone: true
})
export class OptionFilterPipe implements PipeTransform {
  transform(
    items: OptionItem[],
    field: 'label' | 'value',
    value: string
  ): OptionItem[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter((it) =>
      it[field].toLowerCase().includes(value.toLowerCase())
    );
  }
}
