import { AsyncPipe, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from '../icon/icon.component';
import { ProgramService } from 'src/app/services/program/program.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Component({
  selector: 'pofo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [IconComponent, AsyncPipe]
})
export class HeaderComponent {
  private _workflowService = inject(WorkflowService);
  private _programService = inject(ProgramService);
  private environmentService = inject(EnvironmentService);
  private location = inject(Location);

  public assetPath = this.environmentService.properties.assetPath;
  public showLogo$ = this._programService.showLogo$;
  public coBrandPath = this._logoBrand();

  private _logoBrand(): string {
    switch (true) {
      case this.location.path().includes('goodrx'):
        return 'images/goodrx-logo.png';
        break;
      case this._workflowService.isJpmc():
        return 'images/jp-logo.png';
        break;
      default:
        return '';
        break;
    }
  }
}
