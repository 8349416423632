/// <reference types="grecaptcha" />
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class StartJourneyService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);
  private _syncVisitService = inject(SyncVisitService);

  public startJourney$(): Observable<LoaderState<string>> {
    return this._httpService.post<string, string>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._syncVisitService.modalityId}/start`,
      {},
      this._responseParser<string>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
