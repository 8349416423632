import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  QuestionnaireAnswer,
  QuestionnaireAnswerOption,
  QuestionnaireQuestion,
  QuestionnaireQuestionChoice
} from '../../@types/questionnaire.interface';
import {
  QuestionnaireEligibilityAnswer,
  QuestionnaireEligibilityAnswerOption
} from '../../@types/eligibility-questionnaire.interface';
import { CoreQuestionType } from '../core/core-question-type';
import { QuestionType } from '../../@types/question-type.interface';

type TextAreaChoice = QuestionnaireQuestionChoice;

@Component({
  selector: 'pofo-textarea',
  styleUrls: ['./textarea.component.scss'],
  templateUrl: './textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class TextAreaComponent
  extends CoreQuestionType<TextAreaChoice>
  implements QuestionType<TextAreaChoice>
{
  @Input() public isEligibilityQuestionnaire = false;
  @Input() public set question(value: QuestionnaireQuestion) {
    this._question = value;
    this.choices = this._question.choices.map((choice) => ({
      ...choice
    }));
    this._questionAnswer = undefined;
    this.submitButtonDisabled = this.validateQuestion();
    this.textValue = '';
  }

  public get question(): QuestionnaireQuestion {
    return this._question;
  }

  public textValue = '';

  public createPayload(): QuestionnaireAnswer {
    const options: QuestionnaireAnswerOption[] = [];

    this.choices.forEach((choice) => {
      options.push({
        choiceIndex: choice.index,
        value: this.textValue,
        fieldValue: choice.supportingFieldText
      });
    });

    return {
      questionId: this.question.id,
      questionExternalId: this.question.externalId,
      questionKey: this.question.key,
      options
    };
  }

  private _eligibilityCreatePayload(): QuestionnaireEligibilityAnswer {
    const selections: QuestionnaireEligibilityAnswerOption[] = [];

    this.choices.forEach((choice) => {
      selections.push({
        choiceIndex: choice.index,
        answerValue: this.textValue
      });
    });

    return {
      questionExternalId: this.question.externalId,
      questionKey: this.question.key,
      selections
    };
  }

  public validateQuestion(): boolean {
    return false;
  }

  public onSubmit(): void {
    this._questionAnswer = this.isEligibilityQuestionnaire
      ? this._eligibilityCreatePayload()
      : this.createPayload();
    this.questionCompleted.emit(this._questionAnswer);
  }
}
