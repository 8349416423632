import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { ProgressBarStep } from 'src/app/models/progress-bar-step.model';

@Component({
  selector: 'pofo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class ProgressBarComponent {
  @Input() steps: ProgressBarStep[] = [];
  @Input() currentStep: string | null;

  public isCurrentStep(step?: ProgressBarStep): boolean {
    return (
      step !== undefined &&
      step.highlight &&
      step.route.some((p) => {
        return this.currentStep && this.currentStep === p;
      })
    );
  }
}
