import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  filter,
  map,
  switchMap,
  takeUntil
} from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  inject
} from '@angular/core';
import {
  CurrentMonth,
  DateList,
  DateTimeList
} from 'src/app/components/datetime-picker/@types/datetime-picker.interface';
import {
  differenceInDays,
  endOfMonth,
  format,
  getDate,
  getMonth,
  isAfter,
  isSameDay,
  startOfMonth
} from 'date-fns';
import { AmplitudeDirective } from '../../../../../shared/amplitude/amplitude.directive';
import { AppointmentLabcorpService } from '../../../modality/on-site/labcorp/services/labcorp.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AsyncPipe } from '@angular/common';
import { CalendarProvider } from '../../../@types/calendar-provider';
import { DateAdapter } from '@angular/material/core';
import { DateTimePickerComponent } from 'src/app/components/datetime-picker/datetime-picker.component';

@Component({
  selector: 'pofo-calendar-labcorp',
  templateUrl: './calendar-labcorp.component.html',
  styleUrls: ['./calendar-labcorp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DateTimePickerComponent, AmplitudeDirective, AsyncPipe]
})
export class CalendarLabcorpComponent implements OnDestroy, CalendarProvider {
  private _appointmentService = inject(AppointmentService);
  private _appointmentLabcorpService = inject(AppointmentLabcorpService);
  private _dateAdapter = inject<DateAdapter<Date>>(DateAdapter);

  @Output() public selectedSlot: EventEmitter<DateList> =
    new EventEmitter<DateList>();

  public readonly selectedLocation = this._appointmentService.selectedLocation;
  public readonly selectedLocationTimezone =
    this._appointmentService.selectedLocation.timezone;
  public availableSlots$: ReplaySubject<DateList[]> = new ReplaySubject<
    DateList[]
  >(1);
  public selectedDateAvailableSlots$: BehaviorSubject<DateTimeList[]> =
    new BehaviorSubject<DateTimeList[]>([]);
  public minDate = this._dateAdapter.today();
  public maxDate = this._dateAdapter.addCalendarDays(
    this._dateAdapter.today(),
    90
  );

  private _destroy$: Subject<void> = new Subject();
  private _currentDateSelected: DateList;
  private _availableSlots: DateList[] = [];
  private _selectedDate: BehaviorSubject<[string, number]> =
    new BehaviorSubject<[string, number]>([
      format(new Date(), 'yyyy-MM-dd'),
      this._differenceInDaysIncludingCurrentDay()
    ]);

  constructor() {
    this._selectedDate
      .pipe(
        takeUntil(this._destroy$),
        switchMap(([date, daysInMonth]) =>
          this._appointmentLabcorpService.getAvailableDaysAndSlots$(
            date,
            daysInMonth
          )
        ),
        filter((res) => !!res.value),
        map((res) => res.value)
      )
      .subscribe((res) => {
        this.availableSlots$.next(res || []);
        this._availableSlots = res || [];
      });
  }

  public selectedDate(date: Date): void {
    const availableSlots = this._availableSlots
      .filter((df: DateList): df is DateList =>
        Boolean(
          getMonth(df.start) === getMonth(date) &&
            getDate(df.start) === getDate(date)
        )
      )
      .map((selAvailTimes) => {
        const parsedStart = this._appointmentService.parseSlotTime(
          new Date(selAvailTimes.start),
          'h:mmaaa'
        );
        const parsedEnd = this._appointmentService.parseSlotTime(
          new Date(selAvailTimes.end),
          'h:mmaaa'
        );

        return {
          ...selAvailTimes,
          parsedTime: parsedStart,
          parsedTimeStartEnd: `${parsedStart} - ${parsedEnd}`
        };
      });

    this.selectedDateAvailableSlots$.next(availableSlots);
  }

  public chosenSlot(date: DateList): void {
    this._currentDateSelected = date;
  }

  public monthHasChanged(selectedMonth: CurrentMonth) {
    if (this.maxDate.getMonth() === selectedMonth.dateRaw.getMonth()) {
      selectedMonth.totalDays = differenceInDays(
        this.maxDate,
        startOfMonth(this.maxDate)
      );
    } else if (this.minDate.getMonth() === selectedMonth.dateRaw.getMonth()) {
      selectedMonth.date = format(this.minDate, 'yyyy-MM-dd');
      selectedMonth.totalDays = this._differenceInDaysIncludingCurrentDay();
    }

    if (
      isAfter(selectedMonth.dateRaw, this.maxDate) ||
      isSameDay(selectedMonth.dateRaw, this.maxDate)
    ) {
      this.availableSlots$.next([]);
    } else {
      this._selectedDate.next([selectedMonth.date, selectedMonth.totalDays]);
    }
  }

  private _differenceInDaysIncludingCurrentDay(): number {
    return (
      Math.abs(differenceInDays(this.minDate, endOfMonth(this.minDate))) + 1
    );
  }

  public onConfirmSlot(): void {
    this.selectedSlot.emit(this._currentDateSelected);
  }

  public isConfirmBtnDisabled(): boolean {
    return !this._currentDateSelected;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
