import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[str2Html]',
  standalone: true
})
export class StringToHtmlDirective implements OnInit {
  private _elementRef = inject(ElementRef);

  @Input() public str2Html: string;

  ngOnInit() {
    this._elementRef.nativeElement.innerHTML = this.str2Html; // this should be a pipe parser... but for time specific ill leave it
  }
}
