<div class="flex justify-between items-center">
  <button
    class="!h-8 !w-8 !flex justify-center items-center m-2"
    mat-icon-button
    (click)="previousClicked()"
    data-testid="back"
  >
    <img src="{{ assetPath }}icons/chevron-left-md.svg" alt="back button" />
  </button>
  <button
    class="flex justify-center text-center text-[theme('colors.navy')] !capitalize text-base font-bold customHeader"
    (click)="toggleView()"
    data-testid="view"
  >
    {{ periodLabel().toLocaleLowerCase() }}
  </button>

  <button
    class="!h-8 !w-8 !flex justify-center items-center m-2"
    mat-icon-button
    (click)="nextClicked()"
    data-testid="next"
  >
    <img src="{{ assetPath }}icons/chevron-right-md.svg" alt="next button" />
  </button>
</div>
