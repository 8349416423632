<footer
  class="w-full bg-ink py-6 px-4 l:px-40 flex flex-col l:flex-row justify-center l:justify-between"
>
  <div class="w-full">
    <div
      class="flex flex-col justify-center items-center l:flex-row l:justify-between"
    >
      <div class="m-auto text-teal-400 l:m-0">
        <pofo-icon iconName="logo" [size]="48"></pofo-icon>
      </div>
      <div class="flex justify-center items-center mt-8">
        <img
          src="{{ assetPath }}images/ce-24h.png"
          alt="CE Logo"
          class="h-[24px] mr-6 l:mr-8"
        />
        <img
          src="{{ assetPath }}images/ivd-24h.png"
          alt="IVD Logo"
          class="h-[24px] mr-6 l:mr-8"
        />
        <img
          src="{{ assetPath }}images/comodo-24h.png"
          alt="Comodo Secure Logo"
          class="h-[24px]"
        />
      </div>
    </div>

    <div
      class="text-sm flex flex-col-reverse items-center justify-center pt-14 mt-8 l:flex-row l:justify-between"
    >
      <div data-test="copyright" class="text-concrete text-xs mt-3">
        © {{ currentYear }} Copyright LetsGetChecked
      </div>
      <ul
        class="flex justify-around items-center flex-wrap l:justify-end list-none p-0 m-0"
      >
        <li class="p-1 l:mt-3">
          <a
            href="https://www.letsgetchecked.com/terms-of-use/"
            target="_blank"
            rel="noopener"
            data-test="terms"
            class="text-white hover:text-aqua-dark"
            >Terms of Use</a
          >
        </li>
        <li class="p-1 l:mt-3">
          <a
            href="https://www.letsgetchecked.com/conditions-of-sales-and-services/"
            target="_blank"
            rel="noopener"
            data-test="cond-sales"
            class="text-white hover:text-aqua-dark"
            >Conditions of Sale and Services</a
          >
        </li>
        <li class="p-1 l:mt-3">
          <a
            href="https://www.letsgetchecked.com/privacy-policy/"
            target="_blank"
            rel="noopener"
            data-test="privacy"
            class="text-white hover:text-aqua-dark"
            >Privacy Policy</a
          >
        </li>
        <li class="p-1 l:mt-3">
          <a
            href="https://www.letsgetchecked.com/cookies/"
            target="_blank"
            rel="noopener"
            data-test="cookies"
            class="text-white hover:text-aqua-dark"
            >Cookie Policy</a
          >
        </li>
      </ul>
    </div>
  </div>
</footer>
