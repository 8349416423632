/// <reference types="grecaptcha" />
import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { PatientPhoneNumberResponse } from 'src/app/modules/phone-number/@types/phone-number.interface';
import { PatientStateResponse } from 'src/app/modules/state/@types/state-confirmation.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);
  private _cookieService = inject(CookieService);

  public patientProfileId: string;
  public patientGeo: string;

  public setPatientProfileId(patientProfileId: string): void {
    this.patientProfileId = patientProfileId;
    this._cookieService.set('x-lgc-pp', patientProfileId, {
      expires: 365,
      sameSite: 'Lax',
      path: '/',
      domain: document.location.hostname.replace('order', '')
    });
  }

  public setPatientGeo(patientGeo = 'us'): void {
    this.patientGeo = patientGeo;
    this._cookieService.set('x-lgc-patient-geo', patientGeo, {
      expires: 365,
      sameSite: 'Lax',
      path: '/',
      domain: document.location.hostname.replace('order', '')
    });
  }

  public getState$(
    modalityId: string
  ): Observable<LoaderState<PatientStateResponse>> {
    return this._httpService.get<PatientStateResponse, PatientStateResponse>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/patient/area`,
      this._responseParser<PatientStateResponse>
    );
  }

  public setState$(
    modalityId: string,
    syncVisitId: string,
    countryIso: string,
    stateIso: string
  ): Observable<LoaderState<boolean>> {
    return this._httpService.post<boolean, boolean>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/patient/area`,
      {
        domainSpecificId: syncVisitId,
        area: {
          countryIso,
          stateIso
        }
      },
      this._responseParser<boolean>
    );
  }

  public getPhoneNumber$(
    modalityId: string
  ): Observable<LoaderState<PatientPhoneNumberResponse>> {
    return this._httpService.get<
      PatientPhoneNumberResponse,
      PatientPhoneNumberResponse
    >(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/patient/phone`,
      this._responseParser<PatientPhoneNumberResponse>
    );
  }

  public setPhoneNumber$(
    modalityId: string,
    syncVisitId: string,
    phoneCountryCode: string,
    phoneNumber: string
  ): Observable<LoaderState<PatientPhoneNumberResponse>> {
    return this._httpService.post<
      PatientPhoneNumberResponse,
      PatientPhoneNumberResponse
    >(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/patient/phone`,
      {
        domainSpecificId: syncVisitId,
        phoneNumber: `${phoneCountryCode}${phoneNumber}`
      },
      this._responseParser<PatientPhoneNumberResponse>
    );
  }

  private _responseParser<T>(response: T) {
    return response;
  }
}
