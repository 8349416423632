<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container [containerTitle]="title">
    <div class="text-l font-bold">Products</div>
    @if (tests.length > 0) { @for (test of tests; track test) {
    <div class="p-2 mt-6 border rounded-md border-platinum bg-white relative">
      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2 flex flex-col justify-center">
          <pofo-icon iconName="cholesterol" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">
            {{ test.testKit.title }}
          </div>
          <div class="text-storm text-sm font-normal">Qty: 1</div>
        </div>
        <div class="flex flex-col justify-center font-bold">
          {{
            test.price | currency : test.currencyISO || 'USD' : 'symbol-narrow'
          }}
        </div>
      </div>
    </div>
    } } @else {
    <div class="flex flex-col items-center justify-between">
      <pofo-loading-spinner></pofo-loading-spinner>
    </div>
    }

    <div class="line"></div>

    <div class="flex flex-row justify-between mb-3">
      <div class="text-l font-bold">Payment Details</div>
      <pofo-icon iconName="secure-badge" [size]="64"></pofo-icon>
    </div>

    <pofo-stripe-payment
      #stripePayment
      (readyStateChange)="stripeReadyStateChange($event)"
      (paymentMethodGenerated)="paymentMethodGenerated($event)"
      (paymentMethodGenerationError)="errorHandling($event)"
      (cardPaymentHandled)="cardPaymentHandled()"
      (cardPaymentError)="errorHandling($event)"
    ></pofo-stripe-payment>

    <div class="line"></div>

    <div class="text-l font-bold mb-3">Order Summary</div>
    @if (tests.length > 0) {
    <div class="p-2">
      <div class="flex justify-between text-sm">
        <div class="text-navy">Products</div>
        <div class="text-storm font-bold">
          {{
            orderPrice
              | currency : tests[0].currencyISO || 'USD' : 'symbol-narrow'
          }}
        </div>
      </div>
      <div class="flex justify-between text-sm">
        <div class="text-navy">Shipping</div>
        <div class="text-green font-bold">Free</div>
      </div>
      <div class="line"></div>
      <div class="flex justify-between text-sm mb-6">
        <div class="text-navy font-bold">Total</div>
        <div class="text-storm font-bold">
          {{
            orderPrice
              | currency : tests[0].currencyISO || 'USD' : 'symbol-narrow'
          }}
        </div>
      </div>
    </div>
    } @else {
    <div class="p-2 flex flex-col items-center justify-between">
      <pofo-loading-spinner></pofo-loading-spinner>
    </div>
    }

    <div>
      @if (!!errorMessage) {
      <pofo-alert type="error" data-test="error-message">{{
        errorMessage
      }}</pofo-alert>
      } @if (isLoading) {
      <div class="w-full flex justify-center">
        <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
      </div>
      } @if (!isLoading) {
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="finish-button"
        [disabled]="isBtnDisable()"
        (click)="onPay()"
      >
        Pay and confirm order
      </button>
      }

      <div
        class="flex justify-center items-center text-xs font-bold text-navy mb-4 mt-4"
      >
        <pofo-icon iconName="shield-tick-check_v2" [size]="32"></pofo-icon>
        <span>HSA/FSA Cards accepted</span>
      </div>
      <div class="text-xs text-navy text-center">
        By clicking on Complete order now you are agreed with our
        <a
          class="text-teal-600 border-b-2"
          href="https://www.letsgetchecked.com/conditions-of-sales-and-services"
          target="_blank"
          >terms & conditions</a
        >
      </div>
    </div>
  </pofo-container>
</div>
