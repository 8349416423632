import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { CurrencyPipe } from '@angular/common';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { PaymentService } from 'src/app/shared/services/payment/payment.service';
import { ProductQuantityConfiguration } from 'src/app/models/quantity.model';
import { QuantityService } from 'src/app/services/quantity/quantity.service';
import { StripePayment } from 'src/app/shared/@types/payment.interface';
import { StripePaymentComponent } from 'src/app/modules/payments/methods/stripe-payment/stripe-payment.component';
import { take } from 'rxjs';

@Component({
  selector: 'pofo-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    LoadingSpinnerComponent,
    StripePaymentComponent,
    AlertComponent,
    CurrencyPipe
  ]
})
export class PaymentsComponent implements OnDestroy {
  private _paymentService = inject(PaymentService);
  private _quantityService = inject(QuantityService);

  @ViewChild('stripePayment')
  stripePayment: StripePaymentComponent;

  public readonly title = 'Your payment details';
  public isLoading = false;
  public errorMessage?: string;
  private _isCardDetailsFilled = false;
  private _paymentId: string;
  public tests: ProductQuantityConfiguration[] = [];
  public orderPrice = 0;

  constructor() {
    this._quantityService
      .getProducts()
      .pipe(take(2))
      .subscribe((tests) => {
        this.tests = tests;
        this.orderPrice = tests.reduce(
          (total, currentValue) => total + (currentValue.price || 0),
          0
        );
      });
  }

  public isBtnDisable(): boolean {
    return !this._isCardDetailsFilled || this.isLoading ? true : false;
  }

  public stripeReadyStateChange(event: boolean): void {
    this._isCardDetailsFilled = !!event;
  }

  public errorHandling(error?: string): void {
    this.errorMessage = error || undefined;
  }

  public async onPay(): Promise<void> {
    if (!this.isBtnDisable()) {
      this.isLoading = true;
      amplitude.track('click pay and confirm order', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'order forms (pofo)'
      });
      await this.stripePayment.getToken();
    }
  }

  public paymentMethodGenerated(methodId: string): void {
    this.errorHandling();
    this._paymentService
      .makePayment<StripePayment>(methodId)
      .pipe(take(2))
      .subscribe((res) => {
        this.isLoading = res.loading ?? false;

        if (res.error && res.errorMessage) {
          this.errorHandling(res.errorMessage);
        }

        this._paymentId = res.value?.paymentId || '';

        if (res.status === 201) {
          const providerData = res?.value?.providerData;
          const clientSecret: string = providerData
            ? providerData['clientSecret']
            : '';
          this.stripePayment.handleCardPayment(clientSecret);
        } else {
          if (!this.errorMessage && !res.loading && res.onCompleteCallback) {
            res.onCompleteCallback();
          }
        }
      });
  }

  public cardPaymentHandled(): void {
    this.errorHandling();
    this._paymentService
      .confirmedPayment<StripePayment>(this._paymentId)
      .pipe(take(2))
      .subscribe((res) => {
        this.isLoading = res.loading ?? false;

        if (res.error && res.errorMessage) {
          this.errorHandling(res.errorMessage);
        }

        if (!this.errorMessage && !res.loading && res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }

  ngOnDestroy(): void {
    this._paymentId = '';
  }
}
