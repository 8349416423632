import * as amplitude from '@amplitude/analytics-browser';
import {
  CareProviderInfo,
  CareProvidersSearchResponse
} from 'src/app/models/care-provider.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { PcpItemComponent } from './pcp-item/pcp-item.component';

@Component({
  selector: 'pofo-pcp-search-list',
  templateUrl: './pcp-search-list.component.html',
  styleUrls: ['./pcp-search-list.component.scss'],
  standalone: true,
  imports: [ContainerComponent, PcpItemComponent]
})
export class SearchListComponent {
  @Input() results: CareProvidersSearchResponse;
  @Input() selectedPCP: CareProviderInfo | null;
  @Output() selectPCP = new EventEmitter<CareProviderInfo | null>();
  @Output() back = new EventEmitter();
  @Output() skip = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackFn(i: number, _itm: any): number {
    return i;
  }

  onGoBack() {
    amplitude.track('click change details', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'order forms (pofo)'
    });
    this.back.emit();
  }

  public togglePCPSelect(details: CareProviderInfo) {
    this.selectedPCP =
      this.selectedPCP && details.number === this.selectedPCP.number
        ? null
        : details;
  }

  public confirmPCP() {
    amplitude.track('click confirm and continue on available pcps', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'order forms (pofo)'
    });
    this.selectPCP.emit(this.selectedPCP);
  }

  skipStep() {
    amplitude.track('click skip step on available pcps', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'order forms (pofo)'
    });
    this.skip.emit();
  }
}
