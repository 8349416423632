import {
  AppointmentCreatePayload,
  AppointmentCreateWalkInPayload
} from '../@types/appointment.interface';
import { format, utcToZonedTime } from 'date-fns-tz';

export interface AppointmentInterface {
  externalSlotId?: string;
  startDate: Date;
  endDate: Date;
  resourceId?: string;
}

export class Appointment {
  public externalSlotId?: string;
  public startDate?: Date;
  public endDate?: Date;
  public resourceId?: string;
  public timezone?: string;
  // TODO: rm hardcoded modalityType
  public modalityType = [
    'on-site screening',
    'lab screening',
    'on-site immunization',
    'Genetic Counseling'
  ];

  constructor(private _data?: AppointmentInterface) {
    this.externalSlotId = this._data?.externalSlotId;
    this.startDate = this._data?.startDate;
    this.endDate = this._data?.endDate;
    this.resourceId = this._data?.resourceId;
  }

  public setTimezone(timezone?: string): void {
    this.timezone = timezone;
  }

  public parsedStartDate(isWalkin = false): string {
    if (this.startDate) {
      let startDate = this.startDate;

      if (isWalkin) {
        return format(startDate, "EEEE, MMM do 'starting at' h:mmaaa");
      }

      if (this.timezone) {
        startDate = utcToZonedTime(this.startDate, this.timezone);
      }

      return format(startDate, "EEEE, MMM do, 'at' h:mmaaa", {
        timeZone: this.timezone
      });
    }

    return '';
  }

  public create(domainId?: string): AppointmentCreatePayload {
    if (this.startDate && this.endDate) {
      return {
        ...(this.externalSlotId && { externalSlotId: this.externalSlotId }),
        ...(domainId && { domainId }),

        startDate: this.startDate.toISOString(),
        endDate: this.endDate.toISOString(),
        resourceId: this.resourceId || null
      };
    }

    throw new Error('Something went wrong. on appointment create');
  }

  public createWalkIn(domainId?: string): AppointmentCreateWalkInPayload {
    return { ...(domainId && { domainId }), isWalkIn: true };
  }
}
