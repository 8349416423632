import {
  AppointmentTypeResponse,
  AppointmentTypes
} from 'src/app/shared/@types/appointment.interface';
import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentDefaultService {
  private _http = inject(HttpService);
  private _appointmentService = inject(AppointmentService);
  private _environmentService = inject(EnvironmentService);

  public getLocations$(
    searchQ: string,
    page: number,
    numberOfItems: number
  ): Observable<LoaderState<AppointmentTypes>> {
    return this._http.get<AppointmentTypeResponse, AppointmentTypes>(
      `${
        this._environmentService.properties.apiGateway
      }/appointmenttype/program/${
        this._appointmentService.currentProgram
      }/modality/${this._appointmentService.modalityId}/search?${
        searchQ !== '' ? 'searchAddress=' + searchQ + '&' : ''
      }pageNumber=${page}&pageSize=${numberOfItems}`,
      this._responseParser
    );
  }

  private _responseParser(response: AppointmentTypeResponse): AppointmentTypes {
    return {
      ...response,
      results: response.results.map(
        (result) =>
          new AppointmentType({
            id: result.id,
            zip: result.address.postalCode,
            city: result.address.city,
            addressLine1: result.address.line1 || result.address.addressLine1,
            addressLine2: result.address.addressLine2,
            name: result.title || '',
            appointmentTypeId: result.id,
            timezone: result.address.timeZone ?? result.localTimeZone.ianaCode,
            calendarProvider: result.calendarProvider,
            startDate: result.startDate ?? undefined,
            isFullyBooked: result.isFullyBooked
          })
      )
    };
  }
}
