declare const window: Window &
  typeof globalThis & {
    cwr: {
      recordEvent: <
        T extends {
          /** */
        }
      >(
        eventType: string,
        eventData: T
      ) => void;
    };
  };

export const RecordEvent = <
  T extends {
    /** */
  }
>(
  eventType: string,
  eventData: T
): void => {
  if (window && window.cwr) {
    window.cwr.recordEvent(eventType, eventData);
  }
};
