/// <reference types="grecaptcha" />
import {
  AuthPatientProfileResponse,
  AuthPatientProfileSSONextStepResponse,
  AuthPatientProfileSSOResponse
} from '../@types/profile.interface';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpHeaders } from '@angular/common/http';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class AuthService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private _handleResponse<T>(response: T): T {
    return response;
  }

  public getOrCreatePatientProfile$(): Observable<
    LoaderState<AuthPatientProfileResponse>
  > {
    const programOrReseller =
      this._workflowService.programType === 'program' ? 'program' : 'reseller';

    return this._httpService.put<
      AuthPatientProfileResponse,
      AuthPatientProfileResponse
    >(
      `${this._environmentService.properties.apiGateway}/${programOrReseller}/${this._workflowService.currentProgram}/patient-profile`,
      {},
      this._handleResponse<AuthPatientProfileResponse>
    );
  }

  public getPatientProfileSSO$(): Observable<
    LoaderState<AuthPatientProfileSSOResponse>
  > {
    return this._httpService.put<
      AuthPatientProfileSSOResponse,
      AuthPatientProfileSSOResponse
    >(
      this._environmentService.properties.healthGateway,
      {},
      this._handleResponse,
      new HttpHeaders({
        'x-lgc-geo': 'us'
      })
    );
  }

  public getNextStepSSO$(): Observable<
    LoaderState<AuthPatientProfileSSONextStepResponse>
  > {
    return this._httpService.get<
      AuthPatientProfileSSONextStepResponse,
      AuthPatientProfileSSONextStepResponse
    >(
      `${this._environmentService.properties.apiGateway}/reseller/next-step`,
      this._handleResponse
    );
  }
}
