<div class="card text-{{ textPosition }} mb-6 {{ lastItem ? '' : 'md:mr-8' }}">
  @if (image !== undefined && image.src !== undefined && image.src !== '') {
  <div class="img-container flex mb-4 justify-center">
    <img
      class="rounded-md"
      src="{{ image.src }}"
      alt="{{ image.alt !== undefined ? image.alt : '' }}"
    />
  </div>
  } @if (title !== undefined) {
  <div class="title-container mb-2">
    <h2 class="text-base font-bold">{{ title }}</h2>
  </div>
  }

  <div class="content-container">
    <p class="text-sm text-storm"><ng-content></ng-content></p>
  </div>
</div>
