@if (question) {
<p class="mb-4 font-medium text-base">
  {{ question.text }}
</p>
<label
  class="label-container"
  for="{{ question.choices[0].index }}"
  attr.data-test="questionnaireLabel{{ question.choices[0].index }}"
>
  <textarea
    attr.aria-label="text area for question {{ question.choices[0].index }}"
    rows="3"
    id="{{ question.choices[0].index }}"
    [value]="textValue"
    (keyup)="textValue = $any($event.target).value"
  ></textarea>
</label>
<div class="line"></div>
<button
  class="btn btn-primary btn-medium w-full"
  data-test="questionnaire-submit-button"
  [disabled]="submitButtonDisabled"
  (click)="onSubmit()"
>
  @if (isLastQuestion) {
  <span>Confirm & continue</span>
  } @if (!isLastQuestion) {
  <span>Next</span>
  }
</button>
}
