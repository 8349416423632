import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject
} from '@angular/core';
import { AppointmentInterface } from 'src/app/shared/models/appointment.model';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { CalendarLabcorpComponent } from 'src/app/modules/appointment/components/calendar-providers/calendar-labcorp/calendar-labcorp.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DateList } from 'src/app/components/datetime-picker/@types/datetime-picker.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'pofo-appointment-labcorp-select-date',
  templateUrl: './select-date.component.html',
  styleUrls: ['./select-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ContainerComponent, CalendarLabcorpComponent]
})
export class AppointmentLabcorpSelectDateComponent implements OnDestroy {
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _appointmentService = inject(AppointmentService);

  private _destroy$: Subject<void> = new Subject();
  public readonly title = 'Schedule an appointment';
  public readonly description = 'Select the day and time for your appointment.';
  public readonly selectedLocation = this._appointmentService.selectedLocation;
  public readonly isReschedule = this._appointmentService.isReschedule;

  public gotoSelectLocation(): void {
    this._appointmentService.reset();

    this._router.navigate(['..', 'location'], {
      relativeTo: this._activatedRoute,
      replaceUrl: true
    });
  }

  public onConfirmSlot(date: DateList): void {
    const data: AppointmentInterface = {
      startDate: new Date(date.start),
      endDate: new Date(date.end),
      resourceId: this.selectedLocation.locationId,
      externalSlotId: date.id
    };

    this._appointmentService.setAppointment(data);

    this._router.navigate(['..', 'confirm'], {
      relativeTo: this._activatedRoute
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
