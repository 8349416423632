import * as amplitude from '@amplitude/analytics-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ReplaySubject,
  Subject,
  filter,
  map,
  mergeScan,
  takeUntil,
  tap
} from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AmplitudeDirective } from '../../../../../../../shared/amplitude/amplitude.directive';
import { AppointmentLabcorpService } from '../../services/labcorp.service';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { AppointmentTypes } from 'src/app/shared/@types/appointment.interface';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-appointment-labcorp-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    AlertComponent,
    NgClass,
    IconComponent,
    LoadingSpinnerComponent,
    AmplitudeDirective
  ]
})
export class AppointmentLabcorpSelectLocationComponent
  implements OnInit, OnDestroy
{
  private environmentService = inject(EnvironmentService);
  private _fb = inject(FormBuilder);
  private _appointmentLabcorpService = inject(AppointmentLabcorpService);
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _destroy$: Subject<any> = new Subject();
  public assetPath = this.environmentService.properties.assetPath;
  public readonly title = 'Schedule an appointment';
  public provider = this._appointmentService.provider;
  public readonly description =
    'Enter a ZIP code to find a patient service center.';
  public readonly maxNumberOfItemsPerPage = 5;
  public readonly radius = 50;
  public isLoading = false;
  public isError?: string;
  public selectedIndex = -1;
  public currentShowingListCount = 0;
  public form = this._fb.nonNullable.group<{ search: string }>({ search: '' });
  public locations: AppointmentTypes = {
    totalCount: 0,
    results: []
  };
  public hasNoLocations = true;
  private _searchLocations$: ReplaySubject<[string, number]> =
    new ReplaySubject<[string, number]>(1);

  ngOnInit(): void {
    this._searchLocations$
      .pipe(
        takeUntil(this._destroy$),
        mergeScan(
          (prev, next) =>
            this._appointmentLabcorpService
              .getLocations$(next[0], next[1])
              .pipe(
                tap((res) => (this.isLoading = res.loading || false)),
                tap(
                  (_res) =>
                    (this.isError = _res.error ? _res.errorMessage : undefined)
                ),
                filter((r) => 'value' in r && r.value !== undefined),
                map((response) => {
                  if (response.value) {
                    if (
                      this.currentShowingListCount === 0 ||
                      this.hasNoLocations
                    ) {
                      return response.value;
                    }

                    return {
                      totalCount: response.value.totalCount,
                      results: [...prev.results, ...response.value.results]
                    };
                  }

                  return prev;
                })
              ),
          { totalCount: 0, results: [] } as AppointmentTypes
        )
      )
      .subscribe((res) => {
        this.currentShowingListCount = 1;

        if (!this.hasNoLocations && res.results.length <= 0) {
          this.isError =
            'Sorry, there are no screening events happening in your area at this time';
        }

        this.locations = res;
      });
  }

  public onSelected(location: AppointmentType, selectedIndex: number): void {
    if (selectedIndex === this.selectedIndex) {
      this.selectedIndex = -1;
      this._appointmentService.reset();
    } else {
      this.selectedIndex = selectedIndex;
      this._appointmentService.selectedLocation = location;
    }
  }

  public searchLocations(): void {
    this.locations = { totalCount: 0, results: [] };
    this.currentShowingListCount = 0;

    if (this.form.getRawValue().search.trim() === '') {
      this.hasNoLocations = true;
    } else {
      this.hasNoLocations = false;
    }

    this._searchErrors();

    if (this.isError === undefined) {
      this.selectedIndex = -1;
      this._appointmentService.reset();
      this._searchLocations$.next([
        this.form.getRawValue().search,
        this.radius
      ]);
    }
  }

  private _searchErrors(): void {
    switch (true) {
      case this.form.getRawValue().search.trim().length !== 5:
        this.isError = 'Please enter a valid 5-digit ZIP code.';
        break;
      case !/^\d+$/.test(this.form.getRawValue().search.trim()):
        this.isError = 'Please enter a valid 5-digit ZIP code.';
        break;

      default:
        this.isError = undefined;
        break;
    }
  }

  public loadMoreLocations(): void {
    this.currentShowingListCount = this.currentShowingListCount + 1;
    this.isError = undefined;
  }

  public confirmLocation(): void {
    this.selectedIndex = -1;

    amplitude.track('click confirm location', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'appointments (pofo)'
    });

    this._router.navigate(['..', 'date'], {
      relativeTo: this._activatedRoute
    });
  }

  public isSearchBtnDisabled(): boolean {
    if (this.isLoading) {
      return true;
    }

    return false;
  }

  public isConfirmBtnDisable(): boolean {
    return !this._appointmentService.selectedLocation ||
      !this._appointmentService.selectedLocation.appointmentTypeIdExists() ||
      this.isLoading
      ? true
      : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackFn(i: number, _itm: any): number {
    return i;
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
