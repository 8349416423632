import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { Router } from '@angular/router';

@Component({
  selector: 'pofo-end-of-journey',
  templateUrl: './end-of-journey.component.html',
  styleUrls: ['./end-of-journey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, ContainerComponent]
})
export class EndOfJourneyComponent {
  private _router = inject(Router);
  private environmentService = inject(EnvironmentService);

  public assetPath = this.environmentService.properties.assetPath;
  public currentPage?: {
    title: string;
    subtitle: string;
    cta?: { label: string; url: string };
  };
  private _assets = {
    'not-found': {
      title: 'Sorry, the link you used is out of action',
      subtitle: ''
    },
    ended: {
      title: 'Sorry, looks like this program has ended',
      subtitle: `Please contact your organization’s support team for help.`
    },
    'limits-reached': {
      title: 'Sorry, something has changed',
      subtitle: `Looks like this program has ended. Please contact your organization's support team for help.`
    },
    unauthorized: {
      title: `Sorry, something's missing`,
      subtitle:
        'Looks like you may not be authorized to access this page. Make sure the link you used is correct and try again.'
    },
    'order-failed': {
      title: `Sorry, your order didn’t go through`,
      subtitle:
        'Please contact LetsGetChecked’s care team or  your organization’s support team for assistance.'
    },
    'something-went-wrong': {
      title: `Oops, something went wrong.`,
      subtitle:
        'Looks like something went wrong. Please return to your dashboard and try again.',
      cta: {
        label: 'Back to dashboard',
        url: this.environmentService.properties.patientDashboardURL
      }
    }
  };

  public quickLinksGrid = [
    [
      { title: 'About us', url: 'https://www.letsgetchecked.com/about-us/' },
      {
        title: 'How it works: ',
        url: 'https://www.letsgetchecked.com/how-it-works/'
      }
    ],
    [
      {
        title: 'Activate your test',
        url: `${this.environmentService.properties.patientDashboardURL}/activate/`
      },
      {
        title: 'Your Account',
        url: this.environmentService.properties.patientDashboardURL
      }
    ],
    [
      {
        title: 'LetsGetChecked App',
        url: 'https://www.letsgetchecked.com/letsgetchecked-app/'
      }
    ]
  ];

  constructor() {
    const slug = this.getSlug(this._router.routerState.snapshot.url) as
      | 'not-found'
      | 'ended'
      | 'limits-reached'
      | 'unauthorized'
      | 'something-went-wrong';

    this.currentPage = this._assets[slug];
  }

  public getSlug(url?: string): string | undefined {
    if (url !== undefined) {
      return url.split('/')[1];
    }

    return undefined;
  }
}
