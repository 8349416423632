<div class="consent-component">
  @if (consent$ | async; as res) { @if (res.error || errorMessage) {
  <pofo-alert type="error" data-test="acceptConsentErrorMsg">{{
    res.errorMessage || 'Sorry, something went wrong, please try that again.'
  }}</pofo-alert>
  } @if (!res.error && res.value && res.value.length > 0) {
  <iframe
    [src]="res.value[0].url | safe"
    sandbox="allow-scripts allow-same-origin allow-popups"
    title="Consent form"
    aria-labelledby="Consent form Iframe"
    #iFrame
  >
  </iframe>
  <div class="sticky bottom-0 bg-white">
    <div class="toolbar-container">
      <div class="toolbar-inner">
        <button
          class="btn btn-primary btn-medium w-full"
          data-test="consentAgreementBtn"
          [disabled]="isLoading"
          (click)="
            handleAgreeOnClick(res.value[0].key, res.value[0].requirementType)
          "
        >
          I accept
        </button>
      </div>
    </div>
  </div>
  } } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  }
</div>
