import { Injectable, inject } from '@angular/core';
import { Observable, skip, take } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppointmentResponse } from '../@types/appointment.interface';
import { AppointmentService } from '../services/appointment/appointment.service';
import { LoaderState } from 'src/app/models/loading.model';

@Injectable({
  providedIn: 'root'
})
export class GetAppointmentResolver {
  private _appointmentService = inject(AppointmentService);

  resolve(
    _route: ActivatedRouteSnapshot
  ): Observable<LoaderState<AppointmentResponse>> {
    return this._appointmentService.getAppointment$().pipe(take(2), skip(1));
  }
}
