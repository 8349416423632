import { Component, HostBinding, Input } from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { OptionItem } from '../../dropdown/dropdown.component';

@Component({
  selector: 'pofo-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  standalone: true
})
export class DropdownItemComponent implements Highlightable {
  @Input() item: OptionItem;
  private _isActive = false;

  @HostBinding('class.active') get isActive() {
    return this._isActive;
  }

  setActiveStyles() {
    this._isActive = true;
  }

  setInactiveStyles() {
    this._isActive = false;
  }

  getLabel(): string {
    return this.item.label;
  }
}
