<pofo-datetime-picker
  [minDate]="minDate"
  [maxDate]="maxDate"
  [availableDateList$]="availableSlots$"
  [selectedDateAvailableSlots]="(selectedDateAvailableSlots$ | async) || []"
  (chosenDate)="selectedDate($event)"
  (chosenMonth)="monthHasChanged($event)"
  (chosenSlot)="chosenSlot($event)"
></pofo-datetime-picker>

<button
  class="btn btn-primary btn-medium w-full"
  data-test="confirm-button"
  [disabled]="isConfirmBtnDisabled()"
  (click)="onConfirmSlot()"
  [pofoAmplitudeButton]="'click confirm selection'"
  [pofoAmplitudeData]="{ category: 'appointments (pofo)' }"
>
  Confirm selection
</button>
