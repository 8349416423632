<div class="flex flex-col md:flex-row md:justify-between md:items-center">
  <input
    #fileInput
    id="upload-btn"
    type="file"
    accept="{{ acceptedFileTypes.join(', ') }}"
    [multiple]="isMultiple"
    hidden
    (change)="selectFiles($event)"
  />

  <div class="flex justify-start mb-4 md:mb-0">
    @if (icon) {
    <div class="pr-2">
      <pofo-icon iconName="{{ icon }}" [size]="32"></pofo-icon>
    </div>
    }
    <div>
      <div class="text-navy text-base font-bold">{{ label }}</div>
      <div class="text-storm text-sm font-normal">
        @if (chosenFiles.length <= 0) { File supported:
        {{ acceptedFileTypes.join(', ') }}. } @if (chosenFiles.length > 0) {
        {{ chosenFiles.length }} files selected. }
      </div>
    </div>
  </div>

  <label
    for="upload-btn"
    [tabIndex]="0"
    (keydown.enter)="triggerFileInput()"
    class="btn btn-secondary btn-xsmall min-w-100 w-full md:w-[100px] md:h-[32px]"
  >
    Choose file
  </label>
</div>

@if (!!errorMessage) {
<pofo-alert class="mt-4" type="error" data-test="error-message">{{
  errorMessage
}}</pofo-alert>
} @if (chosenFiles.length > 0) {
<div class="line"></div>
@for (file of thumbnails; track file) {
<div
  class="flex justify-between items-center w-full mb-4 p-4 bg-platinum rounded-md"
>
  <div class="flex">
    @switch (file.fileType) { @case ('.pdf') {
    <pofo-icon iconName="{{ file.image }}" [size]="32"></pofo-icon>
    } @default {
    <div>
      <img
        alt="selected image"
        class="w-[44px] border-cloudy border-2 rounded-sm overflow-hidden"
        [src]="file.image"
      />
    </div>
    } }
    <div class="pl-4 flex items-center font-bold">
      {{ file.name }}
    </div>
  </div>
  <button aria-label="cancel" (click)="removeFile(file.index)">
    <pofo-icon iconName="cross-cancel-lg" [size]="24"></pofo-icon>
  </button>
</div>
} }
