import {
  ACCOUNT_ROUTE_LEGACY,
  APPOINTMENT_CONFIRMATION_ROUTE,
  APPOINTMENT_ROUTE,
  AUTH_ROUTE,
  AUTO_VERIFICATION_ROUTE,
  CONSENT_ROUTE,
  CONSENT_ROUTE_ADDITIONAL,
  CONTACT_DETAILS_ROUTE,
  IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE,
  IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE,
  IMMUNIZATIONAPPOINTMENT_ROUTE,
  INSURANCE_ROUTE,
  ORDER_CONFIRMED_ROUTE,
  ORDER_REQUEST_ROUTE,
  PAYMENT_ROUTE,
  PCP_DETAILS_ROUTE,
  PERSONAL_INFORMATION_ROUTE,
  PHONE_NUMBER_ROUTE,
  PHYSICIANFORM_CONFIRMATION_ROUTE,
  PHYSICIANFORM_ROUTE,
  PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE,
  PHYSICIANFORM_UPLOAD_ROUTE,
  QUANTITY_SET_ROUTE,
  QUESTIONNAIRE,
  QUESTIONNAIRE_ELIGIBILITY,
  QUESTIONNAIRE_RESULTS,
  SHIPPING_INFORMATION_ROUTE,
  START_JOURNEY_ROUTE,
  STATE_CONFIRMATION_ROUTE,
  WELCOME_ROUTE
} from 'src/app/app-routes';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { ProgressBarStep } from 'src/app/models/progress-bar-step.model';
import { StepConfigResponse } from 'src/app/shared/@types/step-configuration.interface';
import { WorkflowConfiguration } from 'src/app/shared/models/workflow-configurations.model';
import { WorkflowConfigurationResponse } from 'src/app/shared/@types/workflow-configurations.interface';
import { WorkflowService } from '../workflow/workflow.service';
import { WorkflowStepsEnum } from '../workflow/workflow-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  public showSteps$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showLogo$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public programSteps: ProgressBarStep[] = [];
  public workflowConfig: WorkflowConfiguration;

  public getWorkflowConfigurations$(): Observable<
    LoaderState<WorkflowConfiguration>
  > {
    return this._httpService
      .get<WorkflowConfigurationResponse, WorkflowConfiguration>(
        `${this._environmentService.properties.apiGateway}/workflow/${this._workflowService.currentProgram}/configurations`,
        (response) => new WorkflowConfiguration(response)
      )
      .pipe(
        map((res) => {
          if (!res.loading && res.success && res.value) {
            this.workflowConfig = res.value;
          }

          return res;
        })
      );
  }

  public getProgramSteps$(): Observable<LoaderState<ProgressBarStep[]>> {
    const endpoint = `${
      this._workflowService.programType === 'client' ? 'reseller' : 'workflow'
    }/${this._workflowService.currentProgram}/steps`;

    return this._httpService
      .get<WorkflowStepsEnum[], ProgressBarStep[]>(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        (response: WorkflowStepsEnum[]) =>
          response
            .map((entry) => this.handleStepLabel(entry))
            .filter((step) => step.showStep)
      )
      .pipe(
        map((response) => {
          if (!response.loading && response.success && response.value) {
            this.programSteps = response.value;

            if (response.onCompleteCallback) {
              response.onCompleteCallback();
            }
          }

          return response;
        })
      );
  }

  handleStepLabel(route: WorkflowStepsEnum): ProgressBarStep {
    switch (route) {
      case WorkflowStepsEnum.AssessmentQuestionnaire:
        return {
          id: route,
          label: 'Questionnaire',
          highlight: true,
          showStep: true,
          route: [
            `${QUESTIONNAIRE}/${QUESTIONNAIRE_ELIGIBILITY}`,
            `${QUESTIONNAIRE}/${QUESTIONNAIRE_RESULTS}`
          ]
        };
      case WorkflowStepsEnum.Questionnaire:
        return {
          id: route,
          label: 'Questionnaire',
          highlight: true,
          showStep: true,
          route: [`${QUESTIONNAIRE}`]
        };
      case WorkflowStepsEnum.Consent:
      case WorkflowStepsEnum.ImmunizationConsent:
        return {
          id: route,
          label: 'Consent',
          highlight: true,
          showStep: true,
          route: [
            `${IMMUNIZATIONAPPOINTMENT_ROUTE}/${CONSENT_ROUTE}`,
            `${CONSENT_ROUTE}/${ACCOUNT_ROUTE_LEGACY}/${CONSENT_ROUTE}`,
            `${CONSENT_ROUTE}/${ACCOUNT_ROUTE_LEGACY}/${CONSENT_ROUTE_ADDITIONAL}`
          ]
        };
      case WorkflowStepsEnum.Consent1:
      case WorkflowStepsEnum.DomainConsent:
        return {
          id: route,
          label: 'Consent',
          highlight: false,
          showStep: false,
          route: [`${CONSENT_ROUTE}`]
        };
      case WorkflowStepsEnum.ShopRegistration:
        return {
          id: route,
          label: 'Account',
          highlight: true,
          showStep: true,
          route: [`${AUTH_ROUTE}/sso`, `${AUTH_ROUTE}/direct`]
        };
      case WorkflowStepsEnum.ShippingInformation:
        return {
          id: route,
          label: 'Shipping',
          highlight: true,
          showStep: true,
          route: [SHIPPING_INFORMATION_ROUTE]
        };
      case WorkflowStepsEnum.OrderConfirmation:
        return {
          id: route,
          label: 'Confirmation',
          highlight: true,
          showStep: true,
          route: [ORDER_CONFIRMED_ROUTE]
        };
      case WorkflowStepsEnum.Verification:
        return {
          id: route,
          label: 'Verify your details',
          highlight: true,
          showStep: true,
          route: [`${ORDER_REQUEST_ROUTE}/verification`]
        };
      case WorkflowStepsEnum.QuantitySelection:
        return {
          id: route,
          label: 'Your order',
          highlight: true,
          showStep: true,
          route: [QUANTITY_SET_ROUTE]
        };
      case WorkflowStepsEnum.PersonalInformation:
        return {
          id: route,
          label: 'Personal information',
          highlight: true,
          showStep: true,
          route: [PERSONAL_INFORMATION_ROUTE]
        };
      case WorkflowStepsEnum.PCPDetails:
        return {
          id: route,
          label: 'Your physician',
          highlight: true,
          showStep: true,
          route: [PCP_DETAILS_ROUTE]
        };
      case WorkflowStepsEnum.Insurance:
        return {
          id: route,
          label: 'Insurance details',
          highlight: true,
          showStep: true,
          route: [INSURANCE_ROUTE]
        };
      case WorkflowStepsEnum.Appointment:
        return {
          id: route,
          label: 'Schedule Appointment',
          highlight: true,
          showStep: true,
          route: [
            `${APPOINTMENT_ROUTE}/location`,
            `${APPOINTMENT_ROUTE}/date`,
            `${APPOINTMENT_ROUTE}/confirm`
          ]
        };
      case WorkflowStepsEnum.AppointmentConfirmation:
        return {
          id: route,
          label: 'Appointment Confirmation',
          highlight: true,
          showStep: true,
          route: [APPOINTMENT_CONFIRMATION_ROUTE]
        };
      case WorkflowStepsEnum.ContactDetails:
        return {
          id: route,
          label: 'Contact details',
          highlight: true,
          showStep: true,
          route: [CONTACT_DETAILS_ROUTE]
        };
      case WorkflowStepsEnum.PhysicianForm:
        return {
          id: route,
          label: 'Upload your form',
          highlight: true,
          showStep: true,
          route: [
            `${PHYSICIANFORM_ROUTE}/${PHYSICIANFORM_UPLOAD_ROUTE}`,
            `${PHYSICIANFORM_ROUTE}/${PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE}`
          ]
        };
      case WorkflowStepsEnum.PhysicianFormConfirmation:
        return {
          id: route,
          label: 'Confirmation',
          highlight: false,
          showStep: true,
          route: [`${PHYSICIANFORM_ROUTE}/${PHYSICIANFORM_CONFIRMATION_ROUTE}`]
        };
      case WorkflowStepsEnum.Welcome:
        return {
          id: route,
          label: 'Welcome',
          highlight: true,
          showStep: true,
          route: [WELCOME_ROUTE]
        };
      case WorkflowStepsEnum.ImmunizationAppointment:
        return {
          id: route,
          label: 'Schedule Appointment',
          highlight: true,
          showStep: true,
          route: [
            `${IMMUNIZATIONAPPOINTMENT_ROUTE}/${IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE}/location`,
            `${IMMUNIZATIONAPPOINTMENT_ROUTE}/${IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE}/date`,
            `${IMMUNIZATIONAPPOINTMENT_ROUTE}/${IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE}/confirm`
          ]
        };
      case WorkflowStepsEnum.ImmunizationAppointmentConfirmation:
        return {
          id: route,
          label: 'Appointment Confirmation',
          highlight: true,
          showStep: true,
          route: [
            `${IMMUNIZATIONAPPOINTMENT_ROUTE}/${IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE}`
          ]
        };
      case WorkflowStepsEnum.AutoVerification:
        return {
          id: route,
          label: 'AutoVerification',
          highlight: true,
          showStep: false,
          route: [AUTO_VERIFICATION_ROUTE]
        };
      case WorkflowStepsEnum.Payment:
        return {
          id: route,
          label: 'Payment',
          highlight: true,
          showStep: true,
          route: [PAYMENT_ROUTE]
        };
      case WorkflowStepsEnum.StartJourney:
        return {
          id: route,
          label: 'Start Journey',
          highlight: false,
          showStep: false,
          route: [START_JOURNEY_ROUTE]
        };
      case WorkflowStepsEnum.PatientPhoneNumber:
        return {
          id: route,
          label: 'Contact Information',
          highlight: true,
          showStep: true,
          route: [PHONE_NUMBER_ROUTE]
        };
      case WorkflowStepsEnum.PatientArea:
        return {
          id: route,
          label: 'State selection',
          highlight: true,
          showStep: true,
          route: [STATE_CONFIRMATION_ROUTE]
        };
      default:
        return {
          id: route,
          label: route,
          highlight: true,
          showStep: true,
          route: [route]
        };
    }
  }

  public getStepConfig$<T>(
    stepKey: WorkflowStepsEnum
  ): Observable<LoaderState<StepConfigResponse<T>>> {
    return this._httpService.get<StepConfigResponse<T>, StepConfigResponse<T>>(
      `${this._environmentService.properties.apiGateway}/workflow/${this._workflowService.currentProgram}/step-config/${stepKey}`,
      this._handleResponse<StepConfigResponse<T>>
    );
  }

  private _handleResponse<T>(response: T): T {
    return response;
  }
}
