<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  <pofo-container containerTitle="Verifying">
    @if (!hasError) {
    <div>
      @if (!isLoading) {
      <div>
        @if (isVerified) {
        <div class="flex flex-col items-center">
          <pofo-loading-spinner></pofo-loading-spinner>
          <p class="mt-4">
            Verified successfully. You will be redirected shortly...
          </p>
        </div>
        }
      </div>
      } @else {
      <div class="flex flex-col items-center justify-between">
        <pofo-loading-spinner></pofo-loading-spinner>
        <p class="mt-4">{{ loadingMessage }}</p>
      </div>
      }
    </div>
    } @else {
    <pofo-alert type="error">{{ errorMessage }} </pofo-alert>
    }
  </pofo-container>
</div>
