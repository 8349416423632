import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  filter,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import {
  CurrentMonth,
  DateList,
  DateTimeList
} from 'src/app/components/datetime-picker/@types/datetime-picker.interface';
import { format, isToday } from 'date-fns';
import { AmplitudeDirective } from 'src/app/shared/amplitude/amplitude.directive';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AsyncPipe } from '@angular/common';
import { CalendarProvider } from '../../../@types/calendar-provider';
import { DateTimePickerComponent } from 'src/app/components/datetime-picker/datetime-picker.component';

@Component({
  selector: 'pofo-calendar-internal',
  templateUrl: './calendar-internal.component.html',
  styleUrls: ['./calendar-internal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [DateTimePickerComponent, AmplitudeDirective, AsyncPipe]
})
export class CalendarInternalComponent
  implements OnInit, OnDestroy, CalendarProvider
{
  private _appointmentService = inject(AppointmentService);

  @Output() public selectedSlot: EventEmitter<DateList> =
    new EventEmitter<DateList>();

  @Input() public appointmentStartDate: Date;
  @Input() public canWalkin = false;
  @Input() public showParsedTimeStartEnd = false;

  private _destroy$: Subject<void> = new Subject();
  private _currentDateSelected: DateList;
  private _selectedDate$: ReplaySubject<[string, string]> = new ReplaySubject<
    [string, string]
  >(1);
  public readonly minDate = new Date();
  public availableDateList$: ReplaySubject<DateList[]> = new ReplaySubject<
    DateList[]
  >(1);

  public selectedDateAvailableSlots$: BehaviorSubject<DateTimeList[]> =
    new BehaviorSubject<DateTimeList[]>([]);

  public isWalkin = false;

  constructor() {
    this._selectedDate$
      .pipe(
        takeUntil(this._destroy$),
        switchMap(([year, month]) =>
          this._appointmentService.getAvailableDaysInMonth$(year, month)
        )
      )
      .subscribe((res) => {
        const daysInMonth: DateList[] = [];

        if (res.value) {
          Object.entries(res.value).forEach(([key, value]) => {
            if (value) {
              daysInMonth.push({
                id: key,
                start: new Date(key),
                end: new Date(key)
              });
            }
          });
        }

        this.availableDateList$.next(daysInMonth);
      });
  }

  ngOnInit(): void {
    this._selectedDate$.next([
      format(this.appointmentStartDate ?? new Date(), 'yyyy'),
      format(this.appointmentStartDate ?? new Date(), 'MM')
    ]);
  }

  public chosenSlot(date: DateList): void {
    this._currentDateSelected = date;
  }

  public monthHasChanged(selectedMonth: CurrentMonth) {
    this._selectedDate$.next([
      format(selectedMonth.dateRaw, 'yyyy'),
      format(selectedMonth.dateRaw, 'MM')
    ]);
  }

  public selectedDate(selectedDate: Date): void {
    if (this.canWalkin && isToday(selectedDate)) {
      const time =
        this._appointmentService.selectedLocation.startDate?.startDate;

      if (time) {
        selectedDate.setHours(
          time.getHours(),
          time.getMinutes(),
          time.getSeconds()
        );
      }

      this.chosenSlot({
        start: selectedDate,
        end: selectedDate
      });
      this.isWalkin = this._appointmentService.isWalkin = true;
      return;
    } else {
      this.isWalkin = this._appointmentService.isWalkin = false;
    }

    this._appointmentService
      .getAvailableSlotsInDay$(
        format(selectedDate, 'yyyy'),
        format(selectedDate, 'MM'),
        format(selectedDate, 'dd')
      )
      .pipe(
        take(2),
        filter((r) => r.value !== undefined)
      )
      .subscribe((res) => {
        const selectedDateAvailableSlots = res.value
          ? res.value.map((slot) => {
              const parsedStart = this._appointmentService.parseSlotTime(
                new Date(slot.start),
                'h:mmaaa'
              );
              const parsedEnd = this._appointmentService.parseSlotTime(
                new Date(slot.end),
                'h:mmaaa'
              );

              return {
                start: new Date(slot.start),
                end: new Date(slot.end),
                parsedTime: parsedStart,
                parsedTimeStartEnd: `${parsedStart} - ${parsedEnd}`
              };
            })
          : [];

        this.selectedDateAvailableSlots$.next(selectedDateAvailableSlots);
      });
  }

  public onConfirmSlot(): void {
    this.selectedSlot.emit(this._currentDateSelected);
  }

  public isConfirmBtnDisabled(): boolean {
    return !this._currentDateSelected;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._destroy$.unsubscribe();
  }
}
