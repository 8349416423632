/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AnswerType,
  QuestionTypes
} from 'src/app/modules/questionnaire/@types/answerTypes-enum';
import {
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireQuestion,
  QuestionnaireQuestionFlow,
  QuestionnaireResults
} from 'src/app/modules/questionnaire/@types/questionnaire.interface';
import { QuestionnaireEligibilityAnswer } from '../@types/eligibility-questionnaire.interface';

export abstract class CoreQuestionnaire {
  private _questionnaire: Questionnaire;
  public results: QuestionnaireResults[];

  public get questionnaire(): Questionnaire {
    return this._questionnaire;
  }

  public getNextQuestion(
    currentQuestionAnswer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): QuestionnaireQuestion | undefined {
    let nextQuestion: QuestionnaireQuestion | undefined = undefined;

    const questionFlow = this._questionnaire.questionFlow.find((qflow) => {
      if (currentQuestionAnswer.questionExternalId === qflow.questionId) {
        if (this._isQuestionnaireEligibilityAnswer(currentQuestionAnswer)) {
          return currentQuestionAnswer.selections.some(
            (selection) => selection.choiceIndex === qflow.choiceIndex
          );
        } else {
          return currentQuestionAnswer.options.some(
            (selection) => selection.choiceIndex === qflow.choiceIndex
          );
        }
      }

      return undefined;
    });

    if (questionFlow?.nextQuestionId !== null) {
      nextQuestion = this._questionnaire.questions.find((q) => {
        return q.externalId === questionFlow?.nextQuestionId;
      });
    }

    return nextQuestion;
  }

  public buildAndGetFirstQuestion(
    questionnaire: Questionnaire
  ): QuestionnaireQuestion | undefined {
    this._questionnaire = questionnaire;

    this._questionnaire.questions.forEach(
      (question) => (question.questionType = this._getQuestionType(question))
    );

    return this._findFirstQuestion(this._questionnaire.questionFlow);
  }

  private _findFirstQuestion(
    questionsFlow: QuestionnaireQuestionFlow[]
  ): QuestionnaireQuestion | undefined {
    const nextQuestionsIds = questionsFlow.reduce(
      (prev: (string | null)[], next: QuestionnaireQuestionFlow) => {
        return [...new Set([...prev, next.nextQuestionId])].filter(
          (c) => c !== null
        );
      },
      []
    );

    const foundFirstQuestionFlow = questionsFlow.find(
      (question) => nextQuestionsIds.indexOf(question.questionId) === -1
    );

    const foundFirstQuestion = this._questionnaire.questions.find(
      (question) => question.externalId === foundFirstQuestionFlow?.questionId
    );

    return foundFirstQuestion;
  }

  private _getQuestionType(
    question: QuestionnaireQuestion
  ): QuestionnaireQuestion['questionType'] {
    if (question.questionType === 'SingleSelect') {
      return QuestionTypes.SingleSelect;
    }

    if (question.questionType === 'MultiSelect') {
      return QuestionTypes.MultiSelect;
    }

    if (
      question.questionType === QuestionTypes.Input &&
      question.choices.every((c) => c.answerType === AnswerType.TextArea)
    ) {
      return QuestionTypes.TextArea;
    }

    throw new Error('No valid question type found');
  }

  private _isQuestionnaireEligibilityAnswer(
    answer: QuestionnaireAnswer | QuestionnaireEligibilityAnswer
  ): answer is QuestionnaireEligibilityAnswer {
    return answer && 'selections' in answer;
  }

  protected responseParser<T>(response: T): T {
    return response;
  }
}
