import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import differenceInYears from 'date-fns/differenceInYears';

export function ageRestrictionValidator(minimumAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const today = new Date();
    const birthDate = new Date(control.value);

    return differenceInYears(today, birthDate) < minimumAge
      ? { ageRestriction: { value: control.value } }
      : null;
  };
}
