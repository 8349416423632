<div class="my-12 md:my-16 md:w-[540px] l:w-[900px] mx-4 md:mx-auto">
  <div class="flex flex-col l:flex-row l:justify-between l:items-start">
    <pofo-container
      [border]="false"
      [containerTitle]="'Welcome to LetsGetChecked'"
      background="transparent"
    >
      <div
        class="mb-3 flex flex-col justify-center items-center md:items-start md:flex-row md:flex-wrap md:justify-start"
      >
        <p class="mb-3 font-bold">
          For employees enrolled in the JPMC U.S Medical Plan who live in
          Columbus, OH, age 45+.
        </p>

        <p class="mb-3">
          JPMorganChase has partnered with LetsGetChecked to provide you with an
          at-home colon cancer screening, called a fecal immunochemical test
          (FIT), at no cost to you.
        </p>

        <p class="mb-3">
          Register and complete a quick questionnaire to see if you meet the
          criteria for this screening test.
        </p>
      </div>
      <div class="mb-3">
        @if (!!errorMessage) {
        <pofo-alert type="error">{{ errorMessage }} </pofo-alert>
        }

        <button
          class="btn btn-primary btn-medium"
          data-test="start-button"
          (click)="onStart()"
          [disabled]="isLoading"
        >
          Get Started
        </button>
      </div>
    </pofo-container>
    <img
      src="{{ assetPath }}images/welcome.png"
      alt="Welcome to LetsGetChecked"
      class="flex"
    />
  </div>
</div>
