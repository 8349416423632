import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';

@Injectable({
  providedIn: 'root'
})
export class IsManageAppointmentGuardService {
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this._appointmentService.appointmentId &&
      this._appointmentService.participationId
    ) {
      if (state.url.includes('manage')) {
        return true;
      } else {
        return this._router.createUrlTree([
          state.url.slice(0, state.url.lastIndexOf('/')) + '/manage'
        ]);
      }
    }

    return true;
  }
}
