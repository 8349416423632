import { ActivatedRoute, Router } from '@angular/router';
import { AsyncPipe, DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { skip, take, tap } from 'rxjs';
import { Appointment } from 'src/app/shared/models/appointment.model';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ProgramService } from 'src/app/services/program/program.service';

@Component({
  selector: 'pofo-appointment-sync-visit-manage',
  templateUrl: './manage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    NgTemplateOutlet,
    LoadingSpinnerComponent,
    ModalComponent,
    AsyncPipe
  ]
})
export class AppointmentSyncVisitManageComponent {
  private _document = inject<Document>(DOCUMENT);
  private _environmentService = inject(EnvironmentService);
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);
  private _programService = inject(ProgramService);

  public readonly title = 'Your appointment';
  public readonly description =
    'Review and confirm all details about your appointment.';
  public isCancel = false;
  public selectedLocation: AppointmentType;
  public appointment: Appointment;
  public getAppointment$ = this._appointmentService.getAppointment$(false).pipe(
    skip(1),
    tap(() => {
      this.selectedLocation = this._appointmentService.selectedLocation;
      this.appointment = this._appointmentService.appointment;
    })
  );

  constructor() {
    this._programService.showSteps$.next(false);
  }

  rescheduleAppointment(): void {
    this._appointmentService.isReschedule = true;
    this._programService.showSteps$.next(true);
    this._router.navigate(['..', 'date'], {
      relativeTo: this._activatedRoute
    });
  }

  cancelAppointment(): void {
    this.isCancel = true;
    this._appointmentService.isCancel = true;
  }

  public modalConfirmCancel() {
    this._appointmentService
      .cancelAppointment$()
      .pipe(take(2))
      .subscribe((res) => {
        if (!res.loading && res.onCompleteCallback) {
          res.onCompleteCallback();
        }
      });
  }

  public toDashboard(): void {
    this._document.location.href =
      this._environmentService.properties.patientDashboardURL;
  }

  public modalKeepButton() {
    this._appointmentService.isCancel = false;
    this.isCancel = false;
  }

  public parseDate() {
    return this.appointment.parsedStartDate();
  }
}
