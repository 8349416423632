import { Injectable, inject } from '@angular/core';
import { skip, take } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { ProgramService } from 'src/app/services/program/program.service';
import { WorkflowConfiguration } from '../models/workflow-configurations.model';

@Injectable({
  providedIn: 'root'
})
export class GetWorkflowConfigResolverService {
  private _programService = inject(ProgramService);

  resolve(
    _route: ActivatedRouteSnapshot
  ): Observable<LoaderState<WorkflowConfiguration>> {
    return this._programService
      .getWorkflowConfigurations$()
      .pipe(take(2), skip(1));
  }
}
