import * as amplitude from '@amplitude/analytics-browser';
import { Component, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { EligibilityQuestionnaireService } from '../../services/eligibilty-questionnaire.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { StringToHtmlDirective } from '../../../../shared/directives/parse-string-html.directive';

@Component({
  selector: 'pofo-questionnaire-results',
  templateUrl: './results.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    StringToHtmlDirective,
    IconComponent,
    AlertComponent
  ]
})
export class QuestionnaireResultsComponent {
  private _questionnaireService = inject(EligibilityQuestionnaireService);

  public isLoading = false;
  public errorMessage?: string;
  public results = this._questionnaireService.results;
  public labels = this._questionnaireService.questionnaire.labels;

  public onResults(): void {
    this._questionnaireService
      .confirmResults$()
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.onCompleteCallback) {
          amplitude.track('click complete order now', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          res.onCompleteCallback();
        }
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackFn(i: number, _itm: any): number {
    return i;
  }
}
