/// <reference types="grecaptcha" />
import {
  AccountResellerConsent,
  SaveConsentResponse
} from '../../@types/consent.interface';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class AccountConsentService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  public getResellerConsent$(): Observable<
    LoaderState<AccountResellerConsent[]>
  > {
    return this._httpService.get<
      AccountResellerConsent[],
      AccountResellerConsent[]
    >(
      `${this._environmentService.properties.apiGateway}/reseller/${this._workflowService.currentProgram}/consent-configuration`,
      this._responseParser<AccountResellerConsent[]>
    );
  }

  public saveProgramConsent$(
    consents: string[],
    stepKey: string,
    isSkipped = false
  ): Observable<LoaderState<SaveConsentResponse>> {
    const endpoint = `${this._environmentService.properties.apiGateway}/shop/${this._workflowService.currentProgram}/save-consent`;
    return this._httpService.post<SaveConsentResponse, SaveConsentResponse>(
      endpoint,
      {
        consents,
        stepKey,
        isSkipped
      },
      this._responseParser<SaveConsentResponse>
    );
  }

  public saveResellerConsent$(
    stepKey: string
  ): Observable<LoaderState<SaveConsentResponse>> {
    return this._httpService.post<SaveConsentResponse, SaveConsentResponse>(
      `${this._environmentService.properties.apiGateway}/shop/reseller/${this._workflowService.currentProgram}/save-consent?stepKey=${stepKey}`,
      undefined,
      this._responseParser<SaveConsentResponse>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
