import { AppointmentGuardService } from './guards/appointment.guard';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { NgModule } from '@angular/core';
import { ROUTES } from '@angular/router';
import { handleProviderRoutes } from './appointment-routing';

@NgModule({
  providers: [
    AppointmentGuardService,
    {
      provide: ROUTES,
      useFactory: handleProviderRoutes,
      deps: [AppointmentService],
      multi: true
    }
  ]
})
export class AppointmentModule {}
