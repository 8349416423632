<div class="my-12 md:my-16 md:w-[540px] l:w-[550px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="configurationErrorMsg">
    {{ serverError }}
  </pofo-alert>
  } @if (tests$ | async; as tests) {
  <pofo-container
    [attr.data-test]="'results-pofo-container'"
    [containerTitle]="title"
  >
    <p class="pb-4 mb-4 shadow-inset shadow-cloudy">
      {{
        tests[0] && tests[0].price !== null
          ? priceDescription
          : quantityDescription
      }}
    </p>
    <div class="flex justify-between pr-10">
      <p class="mb-4 text-storm text-xs font-bold uppercase">Order test(s)</p>
      @if (tests[0] && tests[0].price === null) {
      <p class="mb-4 text-storm text-xs font-bold uppercase">Quantity</p>
      }
    </div>
    @if (tests.length > 0) {
    <ul class="p-4 border rounded-md border-platinum bg-white">
      @for (test of tests; track trackFn($index, test)) {
      <li
        class="flex items-center justify-center mb-4 shadow-inset shadow-cloudy pb-4 last:shadow-transparent last:pb-0 last:mb-0"
      >
        <div class="w-12 pr-2">
          <img
            src="{{
              test.testKit.image
                ? test.testKit.image
                : '/assets/icons/testkit.svg'
            }}"
            alt="{{ test.testKit.title }}"
          />
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">
            {{ test.testKit.title }}
          </div>
          <div class="text-storm text-sm font-normal">
            @for (biomarker of test.testKit.biomarkers; track trackFn(i,
            biomarker); let i = $index) {
            <span>
              {{
                i < test.testKit.biomarkers.length - 1
                  ? biomarker + ', '
                  : biomarker
              }}
            </span>
            }
          </div>
          <!-- Learn more temporarily hidden -->
          <!-- <a
                  *ngIf="!!test.testKit.url"
                  href="{{ test.testKit.url }}"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="flex items-center text-sm font-boldd mt-1"
                  >
                  <span class="block">Learn more</span>
                </a> -->
        </div>
        <div
          class="w-24 flex items-center"
          [ngClass]="[test.price === null ? 'justify-center' : 'justify-end']"
        >
          @if (test.price === null) {
          <pofo-quantity-selector
            [max]="test.maxLimit"
            [value]="
              test.maxLimit >= test.minLimit ? test.minLimit : test.maxLimit
            "
            [min]="test.minLimit"
            (valueChange)="updateTestQuantity($event, test.testKit.code)"
            [attr.data-test]="test.testKit.code + 'QtyContainer'"
            [accLabel]="test.testKit.title"
          >
          </pofo-quantity-selector>
          } @else {
          <p class="text-base text-navy">
            {{
              test.price
                | currency : test.currencyISO || 'USD' : 'symbol-narrow'
            }}
          </p>
          }
        </div>
      </li>
      }
    </ul>
    }
    <div class="line"></div>
    <button
      class="btn btn-primary btn-medium w-full"
      data-test="quantity-submit-button"
      [disabled]="loading$ | async"
      (click)="onQuantitySet()"
    >
      Order now
    </button>
  </pofo-container>
  }
</div>
