import { format, utcToZonedTime } from 'date-fns-tz';
import { CalenderProvider } from '../@types/appointment.interface';

export interface AppointmentTypeInterface {
  id?: string | number;
  zip: string;
  city: string;
  state?: string;
  addressLine1?: string;
  line1?: string;
  addressLine2?: string;
  name: string;
  appointmentTypeId: string;
  isFullyBooked?: boolean;
  timezone?: string;
  calendarProvider?: CalenderProvider;
  locationType?: 'LabCorp' | 'Walgreens';
  startDate?: string;
}
export class AppointmentType {
  public locationId?: string;
  public zip?: string;
  public city?: string;
  public state?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public name?: string;
  public isFullyBooked?: boolean;
  public appointmentTypeId?: string;
  public timezone?: string;
  public calendarProvider?: CalenderProvider;
  public locationType?: 'LabCorp' | 'Walgreens';
  public startDate?: { startDate: Date; parsedStartDate: string };

  constructor(private _data?: AppointmentTypeInterface) {
    this.locationId = this._data?.id?.toString();
    this.name = this._data?.name;
    this.isFullyBooked = this._data?.isFullyBooked;
    this.zip = this._data?.zip;
    this.city = this._data?.city;
    this.state = this._data?.state;
    this.addressLine1 = this._data?.addressLine1;
    this.addressLine2 = this._data?.addressLine2;
    this.appointmentTypeId = this._data?.appointmentTypeId;
    this.timezone = this._data?.timezone;
    this.calendarProvider = this._data?.calendarProvider;
    this.locationType = this._data?.locationType;
    this.startDate = this._data?.startDate
      ? this._parsedStartDate(this._data?.startDate)
      : undefined;
  }

  public get address(): string {
    return `${this.addressLine1}, ${this.addressLine2 || ''}`;
  }

  private _parsedStartDate(date: string): {
    startDate: Date;
    parsedStartDate: string;
  } {
    const startDate = this.timezone
      ? utcToZonedTime(date, this.timezone)
      : new Date(date);

    const parsedStartDate = this.timezone
      ? format(startDate, "d MMMM, 'starting at' h:mmaaa", {
          timeZone: this.timezone
        })
      : '';

    return { startDate, parsedStartDate };
  }

  public appointmentTypeIdExists(): boolean {
    return (
      this.appointmentTypeId !== undefined || this.locationId !== undefined
    );
  }
}
