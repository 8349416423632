import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedLocationGuardService {
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this._appointmentService.selectedLocation &&
      this._appointmentService.selectedLocation.appointmentTypeIdExists()
    ) {
      return true;
    } else {
      // TODO: Once in Angular >= 14.1.0
      // https://github.com/angular/angular/pull/45877
      // return createUrlTreeFromSnapshot(route, ['..', 'location']);
      return this._router.createUrlTree([
        state.url.slice(0, state.url.lastIndexOf('/'))
      ]);
    }
  }
}
