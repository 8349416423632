import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppointmentConfirmedRoutingModule } from './appointment-confirmed-routing.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    AngularSvgIconModule,
    AppointmentConfirmedRoutingModule,
    CommonModule,
    ComponentsModule,
    SharedModule
  ]
})
export class AppointmentConfirmedModule {}
