import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { PaymentsRoutingModule } from './payments-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { StripePaymentComponent } from './methods/stripe-payment/stripe-payment.component';

export const PAYMENT_METHOD_COMPONENTS = [StripePaymentComponent];

@NgModule({
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule,
    ...PAYMENT_METHOD_COMPONENTS
  ]
})
export class PaymentsModule {}
