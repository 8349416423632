import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentTypeResultResponse } from 'src/app/shared/@types/appointment.interface';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: null
})
export class AppointmentSyncVisitService {
  private _http = inject(HttpService);
  private _appointmentService = inject(AppointmentService);
  private _environmentService = inject(EnvironmentService);

  public getLocations$(): Observable<
    LoaderState<AppointmentTypeResultResponse>
  > {
    return this._http.get<
      AppointmentTypeResultResponse,
      AppointmentTypeResultResponse
    >(
      `${this._environmentService.properties.apiGateway}/program/${this._appointmentService.currentProgram}/modality/${this._appointmentService.modalityId}/appointmentType/${this._appointmentService.modalityAppointmentTypeId}`,
      this._responseParser<AppointmentTypeResultResponse>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
