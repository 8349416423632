import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { HasAppointmentIdGuard } from './guards/has-appointment-id.guard';
import { HasImmunizationIdGuard } from './guards/has-immunization-id.guard';
import { ImmunizationAppointmentRoutingModule } from './immunization-appointment-routing.module';
import { ImmunizationService } from './services/immunization.service';
import { NgModule } from '@angular/core';
import { SelectedLocationGuardService } from 'src/app/shared/guards/appointment/selected-location.guard';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    ImmunizationAppointmentRoutingModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    HasImmunizationIdGuard,
    ImmunizationService,
    HasAppointmentIdGuard,
    SelectedLocationGuardService
  ]
})
export class ImmunizationAppointmentModule {}
