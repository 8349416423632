import { Component, OnInit, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ProgramService } from 'src/app/services/program/program.service';
import { ProgressBarComponent } from 'src/app/components/progress-bar/progress-bar.component';
import { ProgressBarStep } from 'src/app/models/progress-bar-step.model';
import { RouterOutlet } from '@angular/router';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { take } from 'rxjs';

@Component({
  selector: 'pofo-flow-container',
  templateUrl: './flow-container.component.html',
  styleUrls: ['./flow-container.component.scss'],
  standalone: true,
  imports: [ProgressBarComponent, RouterOutlet, AsyncPipe]
})
export class FlowContainerComponent implements OnInit {
  private _programService = inject(ProgramService);
  private _workflow = inject(WorkflowService);

  public currentFlow$ = this._workflow.currentFlow$;
  public showSteps$ = this._programService.showSteps$;
  public flowSteps: ProgressBarStep[];

  ngOnInit(): void {
    this._programService
      .getProgramSteps$()
      .pipe(take(2))
      .subscribe((res) => {
        this.flowSteps = res.value && res.value.length > 0 ? res.value : [];
      });
  }
}
