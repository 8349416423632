import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { StringToHtmlDirective } from 'src/app/shared/directives/parse-string-html.directive';

@Component({
  selector: 'pofo-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  standalone: true,
  imports: [IconComponent, StringToHtmlDirective, NgClass]
})
export class AccordionComponent {
  public data: { heading: string; content: string; isOpen: boolean }[] = [];
  @Input() public set list(value: { heading: string; content: string }[]) {
    this.data = value.map((item) => ({ ...item, isOpen: false }));
  }

  public toggleOpen(index: number): void {
    this.data[index].isOpen = !this.data[index].isOpen;
  }
  public trackFn(i: number, _itm: unknown): number {
    return i;
  }
}
