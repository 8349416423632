<div class="my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto">
  <div
    class="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start"
  >
    <pofo-icon
      class="mb-6 text-center text-teal-400"
      iconName="tick-circle"
      [size]="64"
    ></pofo-icon>
    <pofo-container
      class="md:w-[470px]"
      [border]="false"
      containerTitle="Order confirmed"
      background="transparent"
    >
      <p class="text-base text-slate text-center mb-8 md:text-left">
        Your test is being prepared and will be shipped shortly. We'll send
        order confirmation and details to
        {{
          order && order.customerEmailAddress
            ? ' ' + order.customerEmailAddress
            : 'your email address'
        }}.
      </p>
      <button
        class="btn btn-primary btn-medium w-full md:w-[209px]"
        (click)="toDashboard()"
        data-test="dashboard-button"
      >
        Go to Dashboard
      </button>
    </pofo-container>
    <img
      class="flex md:w-[350px]"
      src="{{ assetPath }}images/illustration-thankyou.jpg"
      alt="Thank you Illustration"
    />
  </div>
  <div
    class="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start"
  >
    <pofo-icon
      class="mb-6 text-center text-teal-400"
      iconName="tick-schedule"
      [size]="64"
    ></pofo-icon>
    <pofo-container
      [border]="false"
      containerTitle="Next steps"
      background="transparent"
    >
      <div
        class="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start"
      >
        @for (nextStep of nextSteps; track nextStep; let i = $index) {
        <pofo-card
          [lastItem]="i === nextSteps.length - 1"
          title="{{ nextStep.title }}"
          [image]="nextStep.image"
          >{{ nextStep.content }}</pofo-card
        >
        }
      </div>
    </pofo-container>
  </div>
</div>
<div class="bg-off-white">
  <div class="my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto">
    <div
      class="flex flex-col justify-center items-center md:flex-row md:justify-start md:items-start"
    >
      <div class="w-full md:w-1/2">
        <pofo-icon
          class="flex items-center justify-center md:justify-start text-teal-400"
          iconName="questionmark-bubble"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          spacingStyles="pt-6 md:pt-8"
          containerTitle="Still have questions?"
          background="off-white"
        >
          <p class="text-base text-slate text-center md:text-left mb-8">
            Here are some questions we usually get about the order proccess.
          </p>
        </pofo-container>
      </div>
      <pofo-accordion [list]="faqs"> </pofo-accordion>
    </div>
  </div>
</div>
