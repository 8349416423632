import {
  CareProviderDetails,
  CareProviderInfo,
  CareProviderSearchRequest,
  CareProviderSubmitRequest,
  CareProvidersSearchResponse
} from 'src/app/models/care-provider.model';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class CareProviderService {
  private _workflowService = inject(WorkflowService);
  private _httpService = inject(HttpService);
  private _environmentService = inject(EnvironmentService);

  searchResponseParser(response: CareProvidersSearchResponse) {
    return response;
  }

  searchCareProvider(
    searchTerms: CareProviderSearchRequest
  ): Observable<LoaderState<CareProvidersSearchResponse>> {
    const endpoint = `pcp/${
      this._workflowService.programType === 'client' ? 'reseller/' : ''
    }${this._workflowService.currentProgram}/search`;

    return this._httpService
      .post<CareProvidersSearchResponse, CareProvidersSearchResponse>(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        searchTerms,
        this.searchResponseParser
      )
      .pipe(
        map((response: LoaderState<CareProvidersSearchResponse>) => {
          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }

          return response;
        })
      );
  }

  getResponseParser(response: CareProviderDetails) {
    return response;
  }

  getCareProvider(): Observable<LoaderState<CareProviderDetails>> {
    const endpoint = `pcp/${this._workflowService.currentProgram}/pcp-details`;
    return this._httpService
      .get<CareProviderDetails, CareProviderDetails>(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        this.getResponseParser
      )
      .pipe(
        map((response: LoaderState<CareProviderDetails>) => {
          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }

          return response;
        })
      );
  }

  addPCPResponseParser(response: boolean) {
    return response;
  }

  addCareProvider(
    details: CareProviderInfo | CareProviderDetails
  ): Observable<LoaderState<boolean>> {
    // TODO to refactor to make a correct inference of the type
    const pcpDetailsRequest: CareProviderSubmitRequest =
      this.isInstanceOfCareProviderInfo(details)
        ? this.createCareProviderSubmitRequestFromCareProviderInfo(
            details as CareProviderInfo
          )
        : this.createCareProviderSubmitRequestFromCareProviderDetails(
            details as CareProviderDetails
          );
    const endpoint = `pcp/${
      this._workflowService.programType === 'client' ? 'reseller/' : ''
    }${this._workflowService.currentProgram}/primary-care-provider`;
    return this._httpService
      .put(
        `${this._environmentService.properties.apiGateway}/${endpoint}`,
        pcpDetailsRequest,
        this.addPCPResponseParser
      )
      .pipe(
        map((response: LoaderState<boolean>) => {
          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }

          return response;
        })
      );
  }

  isInstanceOfCareProviderInfo(
    details: CareProviderInfo | CareProviderDetails
  ): boolean {
    return 'number' in details;
  }

  createCareProviderSubmitRequestFromCareProviderInfo(
    details: CareProviderInfo
  ): CareProviderSubmitRequest {
    const checkMailingAddress = details.addresses.find(
      (address) => address.purpose === 'Mailing'
    );
    const mailingAddress =
      details.addresses.length === 1 || !checkMailingAddress
        ? details.addresses[0]
        : checkMailingAddress;

    const pcpDetailsRequest: CareProviderSubmitRequest = {
      primaryCareProvider: {
        id: details.number,
        name: `${details.basic.firstName} ${details.basic.lastName}`,
        address: {
          line1: mailingAddress.address1,
          ...(mailingAddress.address2 && {
            line2: mailingAddress.address2
          }),
          city: mailingAddress.city,
          country: 'US',
          postalCode: mailingAddress.postalCode,
          areaTitle: mailingAddress.state
        },
        ...(details.endpoint && {
          email: details.endpoint
        }),
        ...(mailingAddress.telephoneNumber && {
          phone: mailingAddress.telephoneNumber
        }),
        ...(mailingAddress.faxNumber && { fax: mailingAddress.faxNumber })
      }
    };

    return pcpDetailsRequest;
  }

  createCareProviderSubmitRequestFromCareProviderDetails(
    details: CareProviderDetails
  ) {
    const pcpDetailsRequest: CareProviderSubmitRequest = {
      primaryCareProvider: {
        id: details.providerId,
        name: `${details.providerFirstName} ${details.providerLastName}`,
        address: {
          line1: details.providerAddressLine1,
          ...(details.providerAddressLine2 && {
            line2: details.providerAddressLine2
          }),
          city: details.providerCity,
          country: 'US',
          postalCode: details.providerZipCode,
          areaTitle: details.providerState
        },
        ...(details.providerEmail && {
          email: details.providerEmail
        }),
        ...(details.providerPhone && {
          phone: details.providerPhone
        }),
        ...(details.providerFax && { fax: details.providerFax })
      }
    };

    return pcpDetailsRequest;
  }
}
