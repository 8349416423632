import { Ethnicity } from '../models/personal-information.model';
import { OptionItem } from '../components/dropdown/dropdown.component';

export const ethnicities: OptionItem[] = [
  {
    label: 'Caucasian/White',
    value: Ethnicity.Caucasian
  },
  {
    label: 'African',
    value: Ethnicity.African
  },
  {
    label: 'African American',
    value: Ethnicity.AfricanAmerican
  },
  {
    label: 'Asian',
    value: Ethnicity.Asian
  },
  {
    label: 'Black',
    value: Ethnicity.Black
  },
  {
    label: 'Chinese',
    value: Ethnicity.Chinese
  },
  {
    label: 'East African',
    value: Ethnicity.EastAfrican
  },
  {
    label: 'East Asian',
    value: Ethnicity.EastAsian
  },
  {
    label: 'Hispanic/Latin American',
    value: Ethnicity.HispanicLatinAmerican
  },
  {
    label: 'Indian',
    value: Ethnicity.Indian
  },
  {
    label: 'Middle Eastern',
    value: Ethnicity.MiddleEastern
  },
  {
    label: 'Mixed-Ethnicity',
    value: Ethnicity.MixedEthnicity
  },
  {
    label: 'Native American or Inuit',
    value: Ethnicity.InuitNativeAmerican
  },
  {
    label: 'Pacific Islander',
    value: Ethnicity.PacificIslander
  },
  {
    label: 'South Asian',
    value: Ethnicity.SouthAsian
  },
  {
    label: 'Southeast Asian',
    value: Ethnicity.SoutheastAsian
  },
  {
    label: 'Other',
    value: Ethnicity.Other
  },
  {
    label: 'Prefer not to say',
    value: Ethnicity.PreferNotToSay
  }
];
