import * as amplitude from '@amplitude/analytics-browser';
import { BehaviorSubject, take } from 'rxjs';
import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import {
  DropdownComponent,
  OptionItem
} from 'src/app/components/dropdown/dropdown.component';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoaderState } from 'src/app/models/loading.model';
import { ParticipantService } from 'src/app/services/participant/participant.service';
import { PersonalInformationService } from 'src/app/services/personal-information/personal-information.service';
import { ageRestrictionValidator } from 'src/app/utils/age-confirmation.validator';
import { ethnicities } from 'src/app/utils/ethnicity';
import { invalidDateValidator } from 'src/app/utils/invalid-date.validator';

@Component({
  selector: 'pofo-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    AsyncPipe,
    ContainerComponent,
    DropdownComponent,
    ErrorMessageComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule
  ]
})
export class PersonalInformationComponent {
  private _fb = inject(FormBuilder);
  private _personalInfoService = inject(PersonalInformationService);
  private _participantService = inject(ParticipantService);

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public serverError: string | null = null;
  public formHasErrors = false;
  public ethnicityOptions = ethnicities;
  public biologicalSexOptions: OptionItem[] = [
    {
      label: 'Male',
      value: 'Male'
    },
    {
      label: 'Female',
      value: 'Female'
    }
  ];
  public personalInfoForm = this._fb.nonNullable.group({
    dateOfBirth: [
      this._participantService.participantValue.personalData?.dateOfBirth,
      [
        Validators.required,
        invalidDateValidator(1900),
        ageRestrictionValidator(18)
      ]
    ],

    biologicalSex: [
      this._participantService.participantValue.personalData?.biologicalSex ||
        '',
      [Validators.required]
    ],
    ethnicity: [
      this._participantService.participantValue.personalData?.ethnicity || '',
      [Validators.required]
    ]
  });

  @ViewChild('personalInfoFormElement')
  public personalInfoFormElement: ElementRef<HTMLFormElement>;

  onSubmitForm() {
    const dateOfBirth = this.personalInfoForm.get('dateOfBirth')?.value;

    if (this.personalInfoForm.valid && dateOfBirth) {
      this.formHasErrors = false;
      this._personalInfoService
        .sendPersonalInformation({
          ...this.personalInfoForm.getRawValue(),
          dateOfBirth:
            dateOfBirth instanceof Object
              ? dateOfBirth?.toLocaleDateString('en-US')
              : dateOfBirth
        })
        .pipe(take(2))
        .subscribe((response: LoaderState<boolean>) => {
          if ('loading' in response && response.loading !== undefined) {
            this.loading$.next(response.loading);
          }

          if (response.error) {
            this.formHasErrors = true;
            this.serverError =
              response.errorMessage ||
              'Sorry, something went wrong, please try that again.';
          }

          if (response.onCompleteCallback) {
            amplitude.track('click confirm and continue on phr', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)'
            });
            response.onCompleteCallback();
          }
        });
    } else {
      this.formHasErrors = true;

      const firstInvalid =
        this.personalInfoFormElement.nativeElement.querySelector(
          'form .ng-invalid input, form .ng-invalid select'
        ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }
}
