import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CareProviderDetails } from 'src/app/models/care-provider.model';
import { ContainerComponent } from 'src/app/components/container/container.component';
@Component({
  selector: 'pofo-pcp-details-item',
  templateUrl: './pcp-details-item.component.html',
  styleUrls: ['./pcp-details-item.component.scss'],
  standalone: true,
  imports: [ContainerComponent]
})
export class PcpDetailsItemComponent implements OnInit {
  @Input() details: CareProviderDetails;
  @Input() tabable = true;
  @Output() changePCP = new EventEmitter();
  public parsedName = '';

  ngOnInit() {
    this.parsedName = `${this.details.providerFirstName.toLowerCase()} ${this.details.providerLastName.toLowerCase()}`;
  }

  changeClick() {
    this.changePCP.emit();
  }
}
