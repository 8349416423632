import {
  CareProviderInfo,
  PCPAddress
} from 'src/app/models/care-provider.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';

@Component({
  selector: 'pofo-pcp-item',
  templateUrl: './pcp-item.component.html',
  styleUrls: ['./pcp-item.component.scss'],
  standalone: true,
  imports: [ContainerComponent, IconComponent]
})
export class PcpItemComponent implements OnInit {
  @Input() details: CareProviderInfo;
  @Input() isSelected = false;
  @Input() showChangeButton = false;
  @Input() tabable = true;
  @Output() changePCP = new EventEmitter();
  public locationAddress: PCPAddress;
  public parsedName = '';

  ngOnInit() {
    const findLocationAddress = this.details.addresses.find(
      (address) => address.purpose === 'Location'
    );

    this.locationAddress =
      this.details.addresses.length === 1 || !findLocationAddress
        ? this.details.addresses[0]
        : findLocationAddress;

    this.parsedName = `${this.details.basic.firstName.toLowerCase()} ${this.details.basic.lastName.toLowerCase()}`;
  }

  changeClick() {
    this.changePCP.emit();
  }
}
