import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CareProviderDetails } from 'src/app/models/care-provider.model';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { PcpDetailsItemComponent } from './pcp-details-item/pcp-details-item.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'pofo-pcp-confirm',
  templateUrl: './pcp-confirm.component.html',
  styleUrls: ['./pcp-confirm.component.scss'],
  standalone: true,
  imports: [ContainerComponent, PcpDetailsItemComponent]
})
export class PcpConfirmComponent {
  private fb = inject(UntypedFormBuilder);

  @Input() currentPCP: CareProviderDetails;
  @Output() confirm = new EventEmitter<CareProviderDetails | null>();
  @Output() search = new EventEmitter();
  @Output() skip = new EventEmitter();

  confirmPCP() {
    this.confirm.emit(this.currentPCP);
  }

  changePCP() {
    this.search.emit();
  }

  skipStep() {
    this.skip.emit();
  }
}
