<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if ((patient$ | async)?.value; as patient) {
  <pofo-container [containerTitle]="title">
    <button
      class="btn btn-inline btn-medium mb-4"
      data-test="dashboard-button"
      slot="aboveHeader"
      (click)="toDashboard()"
    >
      <pofo-icon iconName="chevron-left-sm" [size]="28"></pofo-icon> Back to
      Dashboard
    </button>
    <p class="mb-3 flex flex-col items-center md:flex-row">
      {{ description }}
    </p>
    <div class="p-4 mt-6 border rounded-md bg-white border-platinum relative">
      <div
        class="bg-light-yellow rounded-full px-1 text-navy text-xs font-bold inline-block absolute -top-2.5 left-7 uppercase"
      >
        Physician Form
      </div>
      <div class="flex justify-start mt-3 mb-5">
        <div class="w-12 pr-2">
          <pofo-icon iconName="diabetes" [size]="32"></pofo-icon>
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">Biometric Screening</div>
          <div class="text-storm text-sm font-normal">Optum Biometrics</div>
        </div>
      </div>
      @if (!!errorMessage) {
      <pofo-alert type="error" data-test="error-message">{{
        errorMessage
      }}</pofo-alert>
      }
      <div class="line"></div>
      <div>
        <div class="flex justify-start mt-3 mb-5">
          <div class="w-12 pr-2 pt-1">
            <pofo-icon iconName="people-avatars-one" [size]="24"></pofo-icon>
          </div>
          <div class="w-4/5">
            <div class="text-navy text-base font-bold">Patient details</div>
            <div class="text-storm text-sm font-normal">
              <div>First name: {{ patient.firstName }}</div>
              <div>Last name: {{ patient.lastName }}</div>
              <div>Email: {{ patient.email }}</div>
              <div>Date of birth: {{ patient.parseDOB() }}</div>
              <div>Sex at birth: {{ patient.gender }}</div>
            </div>
          </div>
        </div>
        <div class="flex justify-start mt-3 mb-5">
          <div class="w-12 pr-2 pt-1">
            <pofo-icon iconName="house" [size]="24"></pofo-icon>
          </div>
          <div class="w-4/5">
            <div class="text-navy text-base font-bold">Patient address</div>
            <div class="text-storm text-sm font-normal">
              <div>{{ patient.address.line1 }}</div>
              <div>{{ patient.address.line2 }}</div>
              <div>
                {{ patient.address.city }},
                {{ patient.address.postalCode }}
              </div>
              <div>{{ patient.address.country }}</div>
              <div>Phone: {{ patient.phoneWithPrefix }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="flex flex-col items-center">
      <button
        class="btn btn-primary btn-medium mb-2 w-full"
        [disabled]="isCancel"
        (click)="uploadForm()"
      >
        Upload your form
      </button>
      <a
        [href]="selectedFileBLOB"
        id="download-form"
        data-test="download-form-button"
        target="_blank"
        class="btn btn-secondary w-full mb-2"
        download="{{ downloadFormFileName }}"
        [pofoAmplitudeButton]="'click download form'"
        [pofoAmplitudeData]="{ category: 'physician forms (pofo)' }"
        >Download your form</a
      >
      <button
        class="btn btn-inline btn-medium p-2"
        [disabled]="isCancel"
        (click)="cancelForm()"
      >
        or Cancel your form
      </button>
    </div>
    <ng-template #errorAppointment>
      <pofo-alert type="error" data-test="get-appointment-error"
        >Unexpected Error</pofo-alert
      >
    </ng-template>
  </pofo-container>
  } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  }
</div>

@if (isCancel) {
<pofo-modal
  confirmText="Cancel this physician form"
  cancelText="Keep this form"
  (canceled)="modalKeepButton()"
  (confirmed)="modalConfirmCancel()"
>
  <div class="flex flex-col text-center justify-center items-center p-16">
    <pofo-icon
      class="mb-2 text-yellow"
      iconName="alert-warning-circle"
      [size]="24"
    ></pofo-icon>
    <div class="md:w-[480px]">
      <h1 class="text-heading-sm">
        Are you sure you want to cancel your physician result form?
      </h1>
      <p class="mb-6 text-center text-smoke">
        You're about to cancel your physician result form for this program and
        you will be able to select other way to do your Biometric Screening.
      </p>
    </div>
  </div>
</pofo-modal>
}
