<div class="my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto">
  <div class="flex flex-col l:flex-row justify-center items-center">
    <div>
      <div class="flex flex-col l:flex-row relative confirmed">
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
          iconName="tick-circle"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          containerTitle="{{ title }}"
          background="transparent"
        >
          <p class="text-base-lg text-slate text-center mb-8 l:text-left">
            {{ text.titleText }}
          </p>
        </pofo-container>
      </div>

      <div class="flex flex-col l:flex-row relative details">
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center line-middle dotted"
          iconName="tick-schedule"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          containerTitle="{{ text.subTitle }}"
          background="transparent"
        >
          @if (location && appointment && appointment.startDate) {
          <div class="rounded-md bg-off-white">
            <div class="p-5 pb-0 text-navy text-base font-bold">
              {{ text.modalityType }}
            </div>
            <div class="line"></div>
            <div class="p-5 pt-0 mb-5 text-storm text-sm font-normal">
              @if (modalityType !== 'SyncVisit') {
              <div class="flex">
                <span class="px-1">Location: </span>
                <p>{{ location.name }}</p>
              </div>
              <div class="flex">
                <span class="px-1">Address: </span>
                <p>
                  {{ location.address }}, {{ location.city }} -
                  {{ location.zip }}
                </p>
              </div>
              }
              <div class="flex">
                <span class="px-1">Date and Time: </span>
                <p>{{ parsedDate }}</p>
              </div>
              @if (modalityType === 'SyncVisit') { @if (phoneNumber) {
              <div class="flex">
                <span class="px-1">Phone number: </span>
                <p>{{ phoneNumber }}</p>
              </div>
              } }
            </div>
          </div>
          } @if (modalityType !== 'SyncVisit') {
          <button
            class="btn btn-primary btn-medium w-full md:w-2/5"
            (click)="toDashboard()"
            data-test="dashboard-button"
          >
            Go to Dashboard
          </button>
          } @if (modalityType === 'SyncVisit') {
          <div class="flex flex-col md:flex-row">
            <button
              class="btn btn-primary btn-medium mb-4 md:mb-0 md:mr-4"
              data-test="visit-knowledge-hub-button"
              (click)="toHub()"
            >
              Visit our Knowledge Hub
            </button>
            <button
              class="btn btn-secondary btn-medium"
              data-test="dashboard-button"
              (click)="toDashboard()"
            >
              Return to Dashboard
            </button>
          </div>
          }
        </pofo-container>
      </div>
    </div>

    <img
      class="sm:h-[380px]"
      src="{{ assetPath }}images/illustration-thankyou.jpg"
      alt="Thank you Illustration"
    />
  </div>
</div>

@if (modalityType !== 'SyncVisit') {
<div class="bg-off-white">
  @if (!isCancel) {
  <div
    class="pt-5 l:pt-0 my-12 md:my-16 md:w-[768px] l:w-[992px] mx-4 md:mx-auto"
  >
    <div
      class="flex flex-col l:flex-row justify-center items-center l:justify-start l:items-start"
    >
      <div class="flex flex-col l:flex-row">
        <pofo-icon
          class="pt-6 l:pt-8 mb-6 text-center"
          iconName="questionmark-bubble"
          [size]="64"
        ></pofo-icon>
        <pofo-container
          [border]="false"
          containerTitle="Still have questions?"
          background="off-white"
        >
          <p class="text-base-lg text-slate text-center md:text-left l-8">
            Here are some questions we usually get about the order proccess.
          </p>
        </pofo-container>
      </div>
      <div>
        <pofo-accordion [list]="faqs"> </pofo-accordion>
      </div>
    </div>
  </div>
  }
</div>
}
