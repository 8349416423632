import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  inject
} from '@angular/core';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AsyncPipe } from '@angular/common';
import { ConsentService } from '../../services/consent/consent.service';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { ProgramService } from 'src/app/services/program/program.service';
import { SafePipe } from 'src/app/shared/pipes/safe.pipe';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';

@Component({
  selector: 'pofo-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  standalone: true,
  imports: [AlertComponent, LoadingSpinnerComponent, AsyncPipe, SafePipe]
})
export class ConsentComponent {
  private _syncVisitService = inject(SyncVisitService);
  private _consentService = inject(ConsentService);
  private _programService = inject(ProgramService);

  public isLoading = false;
  public errorMessage: string | undefined;
  public consent$ = this._consentService.getConsent$(
    this._syncVisitService.modalityId
  );
  @ViewChild('iFrame') iframe: ElementRef;

  constructor() {
    this._programService.showSteps$.next(false);
  }

  @HostListener('window:message', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMessage(event: any) {
    if (event?.data?.iframeHeight) {
      this.iframe.nativeElement.height = event.data.iframeHeight;
    }
  }

  public handleAgreeOnClick(consentKey: string, requirementType: string) {
    this.errorMessage = undefined;
    this.isLoading = true;

    this._consentService
      .setConsent$(
        this._syncVisitService.modalityId,
        this._syncVisitService.syncVisitId,
        consentKey,
        requirementType ?? 'consent'
      )
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.onCompleteCallback) {
          amplitude.track('click i agree consent', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });

          this._programService.showSteps$.next(true);
          res.onCompleteCallback();
        }
      });
  }
}
