import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule
} from '@angular/material/datepicker';
import { DateMaskDirective } from 'src/app/shared/date-mask/date-mask.directive';
import { DatePickerHeaderComponent } from '../datepicker-header/datepicker-header.component';
import { IconComponent } from '../icon/icon.component';
import { MY_DATE_FORMATS } from 'src/app/shared/date-formats';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';
import { enUS } from 'date-fns/locale';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';

type InputTypes =
  | 'text'
  | 'password'
  | 'email'
  | 'date'
  | 'number'
  | 'tel'
  | 'search';
@Component({
  selector: 'pofo-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS() },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    provideDateFnsAdapter(MY_DATE_FORMATS())
  ],
  standalone: true,
  imports: [
    NgClass,
    IconComponent,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    DateMaskDirective
  ]
})
export class InputComponent implements ControlValueAccessor {
  @Input() name = '';
  @Input() label = '';
  @Input() icon = '';
  @Input() type: InputTypes = 'text';
  @Input() hasError = false;
  @Input() isDisabled = false;
  @Input() isRoundedSides = false;
  @Input() minlen: string;
  @Input() maxlen: string;
  @Input() errorMsgId: string;

  @Output() public inputCleared: EventEmitter<void> = new EventEmitter<void>();
  public dateFormats = MY_DATE_FORMATS();
  readonly datePickerHeaderComponent = DatePickerHeaderComponent;

  public getDateMinus18Years = () => {
    const today = new Date();
    const pastDate = new Date();

    pastDate.setFullYear(today.getFullYear() - 18);

    return pastDate;
  };

  value = '';
  isFocused = false;
  hidePassword = true;

  /* istanbul ignore next */
  onChange = (_: string) => {};
  /* istanbul ignore next */
  onTouch = () => {};

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  onValueChanged() {
    this.onChange(this.value.trim());
  }

  onFocus(): void {
    this.isFocused = true;
  }

  onBlur(): void {
    this.isFocused = false;

    this.onTouch();
  }

  togglePassword(): void {
    this.hidePassword = !this.hidePassword;
    this.type = this.hidePassword ? 'password' : 'text';
  }

  inputClear(): void {
    this.value = '';
    this.onChange('');
    this.inputCleared.emit();
  }

  preventBlur(event: Event) {
    event.preventDefault();
  }

  onInputDateChange() {
    this.onChange(this.value);
  }

  onDatepickerChange(event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.onChange(event.value as unknown as string);
    }
  }
}
