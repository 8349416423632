import {
  CareProviderDetails,
  CareProviderInfo,
  CareProviderSearchRequest,
  CareProvidersSearchResponse
} from 'src/app/models/care-provider.model';
import { Component, OnInit, inject } from '@angular/core';
import {
  PCPStepConfig,
  StepConfigResponse
} from 'src/app/shared/@types/step-configuration.interface';
import { forkJoin, take } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CareProviderService } from 'src/app/services/care-provider/care-provider.service';
import { LoaderState } from 'src/app/models/loading.model';
import { OptionItem } from 'src/app/components/dropdown/dropdown.component';
import { PcpConfirmComponent } from './pcp-confirm/pcp-confirm.component';
import { PcpSearchComponent } from './pcp-search/pcp-search.component';
import { ProgramService } from 'src/app/services/program/program.service';
import { SearchListComponent } from './pcp-search-list/pcp-search-list.component';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { WorkflowStepsEnum } from 'src/app/services/workflow/workflow-steps.enum';
import { states } from 'src/app/utils/states';

@Component({
  selector: 'pofo-pcp-details',
  templateUrl: './pcp-details.component.html',
  standalone: true,
  imports: [
    AlertComponent,
    PcpSearchComponent,
    SearchListComponent,
    PcpConfirmComponent
  ]
})
export class PcpDetailsComponent implements OnInit {
  private _careProviderService = inject(CareProviderService);
  private _programService = inject(ProgramService);
  private _workflowService = inject(WorkflowService);

  readonly defaultServerError =
    'Sorry, something went wrong, please try that again.';
  readonly noResultsErrorMessage =
    'No Care Providers found. Search again with different criteria.';
  readonly states: OptionItem[] = states;
  public isLoading = false;
  public serverError: string | null = null;
  public pcpSearchForm: CareProviderSearchRequest;
  public searchResults: CareProvidersSearchResponse;
  public currentPCP: CareProviderDetails | null = null;
  public selectedPCP: CareProviderInfo | null = null;
  public progress: 'FORM' | 'LIST' | 'CONFIRM' | null = null;
  public alternativeSearchURL = '';
  public alternativeSearchText = '';

  ngOnInit() {
    if (this._workflowService.programType === 'program') {
      forkJoin([
        this._programService
          .getStepConfig$<PCPStepConfig>(WorkflowStepsEnum.PCPDetails)
          .pipe(take(2)),
        this._careProviderService.getCareProvider().pipe(take(2))
      ]).subscribe(([step, careProvider]) => {
        this.isLoading = false;

        if (step.error || careProvider.error) {
          this.serverError =
            step.errorMessage ||
            careProvider.errorMessage ||
            this.defaultServerError;
          return;
        }

        this._stepConfig(step.value);
        this._careProviderConfig(careProvider.value);

        this.progress = this.currentPCP ? 'CONFIRM' : 'FORM';
      });
    } else {
      this.goToSearchForm();
    }
  }

  private _stepConfig(step?: StepConfigResponse<PCPStepConfig>): void {
    if (step) {
      this.alternativeSearchText = step.stepConfiguration.pcpSearchInfoText;
      this.alternativeSearchURL = step.stepConfiguration.pcpSearchInfoUrl;
    }
  }

  private _careProviderConfig(careProvider?: CareProviderDetails): void {
    if (careProvider?.providerId) {
      this.pcpSearchForm = {
        firstName: careProvider.providerFirstName,
        lastName: careProvider.providerLastName,
        state: careProvider.providerState
      };

      this.currentPCP = careProvider;
    }
  }

  public goToSearchForm() {
    this.progress = 'FORM';
  }

  onSubmitSearchForm(searchForm: CareProviderSearchRequest) {
    this.isLoading = true;
    this.serverError = null;
    this.selectedPCP = null;
    this.pcpSearchForm = searchForm;
    this._careProviderService
      .searchCareProvider(this.pcpSearchForm)
      .pipe(take(2))
      .subscribe((response: LoaderState<CareProvidersSearchResponse>) => {
        if ('loading' in response && response.loading !== undefined) {
          this.isLoading = response.loading;
        }

        if (response.error) {
          this.serverError = response.errorMessage || this.defaultServerError;
          this.isLoading = false;
        } else if (response.value) {
          if (response.value.resultCount > 0) {
            this.searchResults = response.value;
            this.progress = 'LIST';
          } else {
            this.serverError = this.noResultsErrorMessage;
          }
          this.isLoading = false;
        }

        if (response.onCompleteCallback) {
          response.onCompleteCallback();
        }
      });
  }

  onSelectPCP(details: CareProviderInfo | null) {
    this.selectedPCP = details;
    if (details) {
      this.onSubmitPCP(details);
    }
  }

  onConfirmPCP(details: CareProviderDetails | null) {
    this.currentPCP = details;
    if (details) {
      this.onSubmitPCP(details);
    }
  }

  onSubmitPCP(details: CareProviderInfo | CareProviderDetails | null) {
    this.serverError = null;
    if (details) {
      this.isLoading = true;
      this._careProviderService
        .addCareProvider(details)
        .pipe(take(2))
        .subscribe((response: LoaderState<boolean>) => {
          if ('loading' in response && response.loading !== undefined) {
            this.isLoading = response.loading;
          }

          if (response.error) {
            this.serverError =
              response.errorMessage ||
              'Sorry, something went wrong, please try that again.';
          }

          if (response.onCompleteCallback) {
            response.onCompleteCallback();
          }
        });
    }
  }

  skipStep() {
    const currentStepIndex = this._programService.programSteps.findIndex(
      (step) => step.id === WorkflowStepsEnum.PCPDetails
    );
    if (
      currentStepIndex >= 0 &&
      this._programService.programSteps.length > currentStepIndex + 1
    ) {
      const nextStepId = this._programService.programSteps[currentStepIndex + 1]
        .id as WorkflowStepsEnum;
      this._workflowService.redirectToRoute(nextStepId);
    }
  }
}
