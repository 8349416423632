import * as amplitude from '@amplitude/analytics-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, inject } from '@angular/core';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { FileUploadComponent } from 'src/app/components/file-upload/file-upload.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE } from 'src/app/app-routes';
import { PhysicianFormService } from 'src/app/shared/services/physician-form/physician-form.service';

@Component({
  selector: 'pofo-physician-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  standalone: true,
  imports: [
    ContainerComponent,
    AlertComponent,
    IconComponent,
    FileUploadComponent
  ]
})
export class PhysicianUploadComponent {
  private _physicianFormService = inject(PhysicianFormService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  public readonly title = 'Upload your form';
  public selectedFiles: File[] = [];

  public chosenFiles($event: File[]): void {
    this.selectedFiles = $event;
  }

  public isDisabled(): boolean {
    return this.selectedFiles.length === 0;
  }

  public onConfirm(): void {
    if (this.selectedFiles.length > 0) {
      amplitude.track('click confirm and continue', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)'
      });
      this._physicianFormService.setUploadForm(this.selectedFiles);

      this._router.navigate(['..', PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE], {
        relativeTo: this._activatedRoute
      });
    }
  }
}
