import { Injectable, inject } from '@angular/core';
import { Observable, skip, take } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AppointmentLabCorpResponse } from '../@types/appointment.interface';
import { AppointmentLabcorpService } from '../services/labcorp.service';
import { LoaderState } from 'src/app/models/loading.model';

@Injectable({
  providedIn: 'root'
})
export class GetLabCorpAppointmentResolver {
  private _appointmentService = inject(AppointmentLabcorpService);

  resolve(
    _route: ActivatedRouteSnapshot
  ): Observable<LoaderState<AppointmentLabCorpResponse>> {
    return this._appointmentService.getAppointment$().pipe(take(2), skip(1));
  }
}
