import * as amplitude from '@amplitude/analytics-browser';
import { AsyncPipe, CurrencyPipe, NgClass } from '@angular/common';
import { BehaviorSubject, Observable, take, takeLast } from 'rxjs';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  ProductQuantityConfiguration,
  QuantitySubmit
} from 'src/app/models/quantity.model';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { LoaderState } from 'src/app/models/loading.model';
import { QuantitySelectorComponent } from 'src/app/components/quantity-selector/quantity-selector.component';
import { QuantityService } from 'src/app/services/quantity/quantity.service';

@Component({
  selector: 'pofo-quantity-set',
  templateUrl: './order-review.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AlertComponent,
    ContainerComponent,
    NgClass,
    QuantitySelectorComponent,
    AsyncPipe,
    CurrencyPipe
  ]
})
export class OrderReviewComponent {
  private _quantityService = inject(QuantityService);

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  title = 'Finishing your order';
  quantityDescription =
    'Please note, you must be 18 or over to qualify for a test.';
  priceDescription = 'Review the items you are ordering.';
  serverError = '';
  tests$: Observable<ProductQuantityConfiguration[]> = this._quantityService
    .getProducts()
    .pipe(take(2), takeLast(1));
  quantities: QuantitySubmit = this._quantityService.testKits;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public trackFn(i: number, _itm: any): number {
    return i;
  }

  public updateTestQuantity(value: number, id: string) {
    const index = this.quantities.testQuantities.findIndex(
      (test) => test.testKitCode === id
    );

    if (index >= 0) {
      amplitude.track(
        this.quantities.testQuantities[index].quantity < value
          ? 'click plus'
          : 'click minus',
        {
          location: window?.location.href,
          origin: 'pofo',
          category: 'order forms (pofo)'
        }
      );
      this.quantities.testQuantities[index].quantity = value;
    }
  }

  public onQuantitySet(): void {
    this._quantityService
      .submitQuantities(this.quantities)
      .pipe(take(2))
      .subscribe((response: LoaderState<unknown>) => {
        if ('loading' in response && response.loading !== undefined) {
          this.loading$.next(response.loading);
        }

        if (response.error) {
          this.serverError =
            response.errorMessage ||
            'Sorry, something went wrong, please try that again.';
        }

        if (response.onCompleteCallback) {
          amplitude.track('click complete order now', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'order forms (pofo)'
          });
          response.onCompleteCallback();
        }
      });
  }
}
