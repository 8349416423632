import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { AccordionComponent } from 'src/app/components/accordion/accordion.component';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';

@Component({
  selector: 'pofo-appointment-confirmed',
  templateUrl: './appointment-confirmed.component.html',
  styleUrls: ['./appointment-confirmed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, ContainerComponent, AccordionComponent]
})
export class AppointmentConfirmedComponent implements OnInit {
  private _appointmentService = inject(AppointmentService);
  private _environmentService = inject(EnvironmentService);
  private _synvVisitService = inject(SyncVisitService);
  private _document = inject<Document>(DOCUMENT);

  public readonly modalityType = this._appointmentService.modalityType;
  public assetPath = this._environmentService.properties.assetPath;
  public faqs = [
    {
      heading: 'How long does the entire testing process take?',
      content:
        'Once your order is complete, you will receive your test within a few  days. This test includes a free express return shipping envelope to deliver the sample to our laboratory for processing. Once your sample reaches the lab you will typically receive your results within 2-5 days.'
    },
    {
      heading: 'How accurate is the testing process?',
      content:
        'Your samples are processed in the same labs used by primary care providers and hospitals. LetsGetChecked laboratories are CLIA approved and CAP-accredited, which are the highest levels of accreditation. Sample collection kits are manufactured within our ISO 13485 accredited facility, the highest level of accreditation for medical devices.'
    },
    {
      heading: 'Why do I need to activate my test?',
      content:
        'Activating the test connects you to the unique alphanumeric barcode within your LetsGetChecked test kit. The laboratory cannot process your sample if the test kit is not activated and linked to you.'
    },
    {
      heading: 'Are there medical professionals involved in the process?',
      content:
        'LetsGetChecked has a team of physicians who will review your order and results. Our nursing team is on standby throughout the testing process, and you may receive a call to discuss your results and next steps.'
    },
    {
      heading: 'When should I return my sample back to the lab?',
      content:
        'Please ensure that the sample is collected and returned on the same day using the carrier method provided within the sample collection kit. Each of our health tests includes a prepaid shipping envelope to return your samples to the laboratory. Please do not collect or return samples on a Friday, Saturday or Sunday.'
    },
    {
      heading: 'When will my results be ready?',
      content:
        'We aim to have your results ready in 2-5 business days, from when the lab receives your sample. When your results are ready, you will receive an email to let you know your results are available. You can access your results by signing into your LetsGetChecked account.'
    },
    {
      heading: 'How is my privacy protected?',
      content:
        'Keeping your personal data secure is important to us. We use appropriate technical and organizational measures to ensure security and confidentiality of your information, and only share your information where required to deliver our products and services or where we have a legal basis to do so. All samples are disposed of following analysis.For further information about our privacy and security practices, please see our privacy policy and T&Cs or feel free to contact our Data Protection Officer at <a href="mailto:DPO@LetsGetChecked.com">DPO@LetsGetChecked.com</a>'
    }
  ];
  public appointment = this._appointmentService.appointment;
  public location = this._appointmentService.selectedLocation;

  public isCancel = this._appointmentService.isCancel;
  public readonly title = `Appointment ${
    this.isCancel ? 'canceled' : 'confirmed'
  }`;
  public readonly text = this._setText();
  public readonly phoneNumber = this._synvVisitService.phoneNumber;
  public readonly parsedDate = this.appointment.parsedStartDate(
    this._appointmentService.isWalkin
  );

  private _setText(): {
    titleText: string;
    subTitle: string;
    modalityType: string;
  } {
    if (this._appointmentService.modalityType === 'SyncVisit') {
      return {
        titleText: `Your genetic counseling appointment has been ${
          this.isCancel ? 'canceled' : 'confirmed'
        }. We’ll send your appointment details to your email address.`,
        subTitle: `Your appointment details`,
        modalityType: `Genetic Counseling`
      };
    }

    return {
      titleText: `Your onsite appointment has been ${
        this.isCancel ? 'canceled' : 'confirmed'
      }. We’ll send the confirmation and details to your email address.`,
      subTitle: `Details about your ${
        this.isCancel ? 'canceled' : ''
      } appointment`,
      modalityType: `Biometric Screening`
    };
  }

  ngOnInit(): void {
    this._appointmentService.reset();
    this._appointmentService.modalityId = undefined;
    this._appointmentService.appointmentId = undefined;
  }

  public toDashboard(): void {
    this._navigateTo(this._environmentService.properties.patientDashboardURL);
  }

  public toHub(): void {
    this._navigateTo(this._environmentService.properties.geneticsHubURL);
  }

  private _navigateTo(url: string): void {
    this._document.location.href = url;
  }
}
