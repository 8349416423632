import { Routes } from '@angular/router';
import { WelcomeDefaultComponent } from './pages/default/welcome.component';
import { WelcomeJPMCComponent } from './pages/jpmc/welcome.component';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

export function handleProviderRoutes(workflowService: WorkflowService): Routes {
  const routes: Routes = [];

  switch (true) {
    case workflowService.isJpmc():
      routes.push({
        path: '',
        component: WelcomeJPMCComponent
      });
      break;

    default:
      routes.push({
        path: '',
        component: WelcomeDefaultComponent
      });
      break;
  }

  return routes;
}
