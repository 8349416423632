import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { NOT_FOUND } from 'src/app/app-routes';
import { SharedModule } from '../../shared.module';

@Injectable({
  providedIn: SharedModule
})
export class HasModalityIdGuard {
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (this._appointmentService.modalityId) {
      return true;
    } else {
      return this._router.createUrlTree([NOT_FOUND]);
    }
  }
}
