<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ serverError }}
  </pofo-alert>
  }

  <pofo-container [containerTitle]="title">
    <form
      #shippingFormElement
      [formGroup]="shippingForm"
      (ngSubmit)="onSubmitForm()"
    >
      @if (allowedCountries.length > 1) {
      <div class="mb-3 w-full">
        <pofo-dropdown
          label="Shipping Country"
          placeholder="Select Country"
          field="countryCode"
          [optionItems]="allowedCountriesDropdownOptions"
          [hasError]="shippingForm.dirty &&
            shippingForm.get('countryCode')?.errors?.['required']"
          (valueChange)="onShipingCountryChange($event)"
          formControlName="countryCode"
          data-test="countriesDropDown"
          errorMsgId="CountryCodeErrorMsg"
        ></pofo-dropdown>

        @if (shippingForm.dirty &&
        shippingForm.get('countryCode')?.errors?.['required']) {
        <pofo-error-message
          errorId="CountryCodeErrorMsg"
          testId="CountryCodeReqErrorMsg"
          errorMessage="Shipping Country is required."
        ></pofo-error-message>
        }
      </div>
      }

      <div>
        <div class="mb-3">
          <p class="mb-2 text-sm text-slate text-center md:text-left">
            Name that will appear on delivery:
          </p>

          <pofo-input
            name="fullName"
            label="Full name"
            type="text"
            [hasError]="
              shippingForm.dirty &&
              !!shippingForm.get('fullName')?.errors?.['required']
            "
            formControlName="fullName"
            data-test="fullNameInput"
            errorMsgId="fullNameErrorMsg"
          ></pofo-input>

          @if (shippingForm.dirty &&
          shippingForm.get('fullName')?.errors?.['required']) {
          <pofo-error-message
            errorId="fullNameErrorMsg"
            testId="fullNameReqErrorMsg"
            errorMessage="Full name is required."
          ></pofo-error-message>
          }
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="mb-3">
          <pofo-input
            name="addressLine1"
            label="Address line 1"
            type="text"
            [hasError]="
              shippingForm.dirty &&
              !!shippingForm.get('addressLine1')?.errors?.['required']
            "
            formControlName="addressLine1"
            data-test="addressLine1Input"
            errorMsgId="addressErrorMsg"
          ></pofo-input>

          @if (shippingForm.dirty &&
          shippingForm.get('addressLine1')?.errors?.['required']) {
          <pofo-error-message
            errorId="addressErrorMsg"
            testId="addressReqErrorMsg"
            errorMessage="Address is required."
          ></pofo-error-message>
          }
        </div>

        <div class="mb-3">
          <pofo-input
            name="addressLine2"
            label="Address line 2 (optional)"
            type="text"
            [hasError]="false"
            formControlName="addressLine2"
            data-test="addressLine2Input"
          ></pofo-input>
        </div>

        <div class="mb-3">
          <pofo-input
            name="city"
            label="City/Town"
            type="text"
            [hasError]="
              shippingForm.dirty && !!shippingForm.get('city')?.errors?.['required']
            "
            formControlName="city"
            data-test="cityInput"
            errorMsgId="cityErrorMsg"
          ></pofo-input>

          @if (shippingForm.dirty &&
          shippingForm.get('city')?.errors?.['required']) {
          <pofo-error-message
            errorId="cityErrorMsg"
            testId="cityReqErrorMsg"
            errorMessage="City is required."
          ></pofo-error-message>
          }
        </div>

        <div class="flex flex-col md:flex-row justify-between">
          @if (stateOptions.length > 0) {
          <div class="mb-3 md:mb-0 w-full md:w-1/2 md:mr-3">
            <pofo-dropdown
              label="County / State / Province"
              field="state"
              [optionItems]="stateOptions"
              [hasError]="shippingForm.dirty && shippingForm.get('state')?.errors?.['required']"
              [isDisabled]="stateOptions.length === 1"
              formControlName="state"
              data-test="statesDropDown"
              errorMsgId="stateErrorMsg"
            ></pofo-dropdown>

            @if (shippingForm.dirty &&
            shippingForm.get('state')?.errors?.['required']) {
            <pofo-error-message
              errorId="stateErrorMsg"
              testId="stateReqErrorMsg"
              errorMessage="County/State/Province is required."
            ></pofo-error-message>
            }
          </div>
          }

          <div
            [ngClass]="{ 'w-full': true, 'md:w-1/2': stateOptions.length > 0 }"
          >
            <pofo-input
              name="postCode"
              label="ZIP code"
              type="text"
              formControlName="postCode"
              [hasError]="postCodeHasError"
              data-test="postCodeInput"
              errorMsgId="postCodeErrorMsg"
            ></pofo-input>

            @if (postCodeHasError) {
            <pofo-error-message
              errorId="postCodeErrorMsg"
              testId="postCodeReqErrorMsg"
              [errorMessage]="postCodeErrorMessage"
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <p class="text-sm mb-2 md:mb-3">Contact Information</p>

        <div class="flex flex-col md:flex-row justify-between mb-4">
          <div class="mb-3 md:mb-0 w-full md:w-1/2 md:mr-3">
            <pofo-dropdown
              label="Country code"
              placeholder="Select Country Code"
              field="phoneCountryCode"
              [value]="shippingForm.get('phoneCountryCode')?.value"
              [optionItems]="countryCodes"
              [hasError]="shippingForm.dirty && shippingForm.get('phoneCountryCode')?.errors?.['required']"
              (valueChange)="onPhoneCountryCodeChange($event)"
              formControlName="phoneCountryCode"
              data-test="phoneCountryCodeDropDown"
              errorMsgId="phoneCountryCodeErrorMsg"
            ></pofo-dropdown>

            @if (shippingForm.dirty &&
            shippingForm.get('phoneCountryCode')?.errors?.['required']) {
            <pofo-error-message
              errorId="phoneCountryCodeErrorMsg"
              testId="phoneCountryCodeReqErrorMsg"
              errorMessage="Country code is required."
            ></pofo-error-message>
            }
          </div>

          <div class="w-full md:w-1/2">
            <pofo-input
              name="phoneNumber"
              [label]="phoneNumberLabel"
              type="tel"
              [hasError]="
                !!(shippingForm.dirty && shippingForm.get('phoneNumber')?.errors) ||
                !!(
                  shippingForm.get('phoneNumber')?.touched &&
                  shippingForm.get('phoneNumber')?.errors?.['minlength']
                )
              "
              formControlName="phoneNumber"
              data-test="phoneNumberInput"
              errorMsgId="phoneNumberErrorMsg"
            ></pofo-input>

            @if (shippingForm.dirty ||
            shippingForm.get('phoneNumber')?.errors?.['minlength'] ||
            shippingForm.get('phoneNumber')?.errors?.['pattern'] ) { @if
            (shippingForm.get('phoneNumber')?.touched &&
            shippingForm.get('phoneNumber')?.errors?.['required']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberReqErrorMsg"
              [errorMessage]="phoneNumberLabel + ' is required.'"
            ></pofo-error-message>
            } @else if (shippingForm.get('phoneNumber')?.touched &&
            shippingForm.get('phoneNumber')?.errors?.['minlength']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberLengthErrorMsg"
              [errorMessage]="
                'Must have at least ' +
                (shippingForm.get('phoneCountryCode')?.value === '+1'
                  ? '10'
                  : '7') +
                ' digits.'
              "
            ></pofo-error-message>
            } @else if (shippingForm.get('phoneNumber')?.errors?.['pattern']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberNoSpecialCharsErrorMsg"
              errorMessage="Please do not use any special characters or spaces."
            ></pofo-error-message>
            } }
          </div>
        </div>

        <pofo-checkbox
          name="consent"
          formControlName="consent"
          identifier="consent"
          iconName="check-tick-sm"
        >
          <p class="text-sm">
            I'd like to receive reminders and other informational or
            account-related information by text. Message and data rates may
            apply. Message frequency varies. Please review the SMS Terms of
            Service in our
            <a
              href="https://www.letsgetchecked.com/privacy-policy/"
              target="_blank"
              rel="noopener"
              data-test="privacy"
              class="underline"
              >Privacy Policy</a
            >
          </p>
        </pofo-checkbox>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="mt-4">
          <button
            class="btn btn-primary btn-medium w-full"
            type="submit"
            data-test="confirmAndContinueBtn"
            [disabled]="isDisabled()"
          >
            Confirm & continue
          </button>
        </div>
      </div>
    </form>
  </pofo-container>
</div>
