import {
  Appointment,
  AppointmentInterface
} from '../../models/appointment.model';
import {
  AppointmentCancelResponse,
  AppointmentResponse,
  DaysInMonthResponse,
  ModalityResponse,
  ModalityType,
  Provider,
  SlotsInDayResponse
} from '../../@types/appointment.interface';
import {
  AppointmentType,
  AppointmentTypeInterface
} from '../../models/appointment-type.model';
import { Injectable, inject } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { UrlParamsService } from '../url-params/url-params.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private _http = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _urlParamsService = inject(UrlParamsService);
  private _environmentService = inject(EnvironmentService);

  public readonly currentProgram = this._workflowService.currentProgram;
  public participationId? = this._urlParamsService.get('participationId');
  public modalityId? = this._urlParamsService.get('modalityId');
  public appointmentId? = this._urlParamsService.get('appointmentId');
  public appointmentTypeId = this._urlParamsService.get('appointmentTypeId');
  public modalityType: ModalityType;
  public provider: Provider = 'Lgc';
  public modalityAppointmentTypeId: string;
  public appointment: Appointment;
  public selectedLocation: AppointmentType;
  public isReschedule = false;
  public isCancel = false;
  public isWalkin = false;

  public setLocation(value?: AppointmentTypeInterface) {
    this.selectedLocation = new AppointmentType(value);
  }

  public setAppointment(value?: AppointmentInterface) {
    this.appointment = new Appointment(value);
    this.appointment.setTimezone(this.selectedLocation?.timezone);
  }

  public reset(): void {
    this.setLocation();
    this.setAppointment();
    this.isReschedule = false;
    this.isCancel = false;
    this.isWalkin = false;
  }

  public getAppointmentConfigs$(): Observable<LoaderState<ModalityResponse>> {
    return this._http
      .get<ModalityResponse, ModalityResponse>(
        `${this._environmentService.properties.apiGateway}/program/${this.currentProgram}/modality/${this.modalityId}`,
        this._responseParser<ModalityResponse>
      )
      .pipe(
        tap((res) => {
          if (res.value) {
            this.provider = res.value?.provider;
            this.modalityType = res.value?.modalityType;
            this.modalityAppointmentTypeId = res.value?.firstAppointmentTypeId;
          }
        })
      );
  }

  public appointmentConfirmation$<T, M>(
    payload: T
  ): Observable<LoaderState<M>> {
    return this._http.post<M, M>(
      `${this._environmentService.properties.apiGateway}/program/${this.currentProgram}/modality/${this.modalityId}/appointment-type/${this.selectedLocation.appointmentTypeId}/appointment`,
      payload,
      this._responseParser<M>
    );
  }

  public rescheduleAppointment$<T, M>(payload: T): Observable<LoaderState<M>> {
    return this._http.put<M, M>(
      `${this._environmentService.properties.apiGateway}/program/${this.currentProgram}/modality/${this.modalityId}/appointment-type/${this.selectedLocation.appointmentTypeId}/appointment/${this.appointmentId}`,
      payload,
      this._responseParser<M>
    );
  }

  public cancelAppointment$(): Observable<
    LoaderState<AppointmentCancelResponse>
  > {
    return this._http.delete<
      AppointmentCancelResponse,
      AppointmentCancelResponse
    >(
      `${this._environmentService.properties.apiGateway}/program/${this.currentProgram}/modality/${this.modalityId}/appointment-type/${this.selectedLocation.appointmentTypeId}/appointment/${this.appointmentId}/${this.participationId}`,
      this._responseParser<AppointmentCancelResponse>
    );
  }

  public getAvailableDaysInMonth$(
    year: string,
    month: string
  ): Observable<LoaderState<DaysInMonthResponse>> {
    return this._http.get<DaysInMonthResponse, DaysInMonthResponse>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this.modalityId}/appointment-type/${this.selectedLocation.appointmentTypeId}/slots/status?year=${year}&month=${month}`,
      this._responseParser<DaysInMonthResponse>
    );
  }

  public getAvailableSlotsInDay$(
    year: string,
    month: string,
    day: string
  ): Observable<LoaderState<SlotsInDayResponse[]>> {
    return this._http.get<SlotsInDayResponse[], SlotsInDayResponse[]>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this.modalityId}/appointment-type/${this.selectedLocation.appointmentTypeId}/slots?year=${year}&month=${month}&day=${day}`,
      this._responseParser<SlotsInDayResponse[]>
    );
  }

  public getAppointment$(
    showTimeZone = true
  ): Observable<LoaderState<AppointmentResponse>> {
    return this._http
      .get<AppointmentResponse, AppointmentResponse>(
        `${this._environmentService.properties.apiGateway}/program/${this.currentProgram}/modality/${this.modalityId}/appointment-type/${this.appointmentTypeId}/appointment/${this.appointmentId}`,
        this._responseParser<AppointmentResponse>
      )
      .pipe(
        map((res) => {
          const _res = res.value;

          if (_res) {
            this.setLocation({
              zip: _res.appointmentType.address?.postalCode,
              city: _res.appointmentType.address?.city,
              addressLine1: _res.appointmentType.address?.addressLine1,
              addressLine2: _res.appointmentType.address?.addressLine2,
              name: _res.appointmentType.title,
              appointmentTypeId: _res.appointmentType.id,
              timezone: showTimeZone
                ? _res.appointmentType.localTimeZone?.ianaCode
                : undefined,
              calendarProvider: _res.appointmentType.calendarProvider,
              startDate: _res.startDate,
              isFullyBooked: _res.appointmentType.isFullyBooked
            });

            this.setAppointment({
              startDate: new Date(_res.startDate),
              endDate: new Date(_res.endDate),
              resourceId: undefined
            });
          }

          return res;
        })
      );
  }

  public parseSlotTime(date: Date, formatString: string): string {
    return this.selectedLocation.timezone
      ? formatInTimeZone(date, this.selectedLocation.timezone, formatString)
      : format(date, formatString);
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
