<div class="my-12 md:my-16 md:w-[540px] l:w-[900px] mx-4 md:mx-auto">
  @if (currentPage) {
  <div
    class="flex flex-col justify-center items-center l:flex-row l:justify-start l:items-start"
  >
    <pofo-icon
      class="mb-6 l:mt-8 text-center"
      iconName="exclamation-alt-icon"
      [size]="64"
    ></pofo-icon>
    <pofo-container
      [border]="false"
      [containerTitle]="currentPage.title"
      [containerWidth]="360"
      background="transparent"
    >
      <p class="text-base text-slate text-center md:text-left mb-8">
        {{ currentPage.subtitle }}
      </p>
      @if (currentPage.cta) {
      <a
        href="{{ currentPage.cta.url }}"
        class="btn btn-primary w-full md:w-48 mb-8"
        >{{ currentPage.cta.label }}</a
      >
      }
      <p class="text-base text-slate text-center md:text-left font-bold mb-5">
        Quick links
      </p>
      <div
        class="mb-3 flex flex-col justify-center items-center md:items-start md:flex-row md:flex-wrap md:justify-start"
      >
        @for (gridGroup of quickLinksGrid; track gridGroup; let i = $index) {
        <div class="basis-full w-full md:basis-1/2">
          @for (link of gridGroup; track link; let i = $index) {
          <a
            href="{{ link.url }}"
            class="text-sm flex justify-center items-center text-ink mb-2 md:justify-start"
          >
            {{ link.title }}
            <pofo-icon iconName="chevron-right-sm" [size]="20"></pofo-icon>
          </a>
          }
        </div>
        }
      </div>
    </pofo-container>
    <img
      src="{{ assetPath }}images/end-of-journey-footer.png"
      alt="End of journey footer"
      class="flex"
    />
  </div>
  }
</div>
