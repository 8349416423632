<pofo-container containerTitle="Primary care provider">
  <div class="content">
    <p class="text-base text-slate text-center mb-4 md:text-left">
      Search for your provider details here so that a copy of your results can
      be shared.
    </p>
    <p class="text-base text-slate text-center mb-4 md:text-left">
      <b>Please confirm your provider's details:</b>
    </p>

    <div class="pt-2">
      <p class="text-base text-slate text-center mb-4 md:text-left">
        Your Saved Provider:
      </p>
      <pofo-pcp-details-item
        [details]="currentPCP"
        [tabable]="false"
        (changePCP)="changePCP()"
      ></pofo-pcp-details-item>
    </div>
  </div>
  <div class="border-t border-platinum my-6 mx-auto"></div>
  <div class="sticky bottom-0 bg-off-white">
    <div class="px-1 mx-[-4px]">
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="confirmPCPItemBtn"
        (click)="confirmPCP()"
        [disabled]="!currentPCP"
      >
        Confirm & Continue
      </button>
    </div>
    <div class="pt-4 px-1 mx-[-4px]">
      <button
        class="btn btn-secondary btn-medium w-full"
        data-test="skipStepBtn"
        (click)="skipStep()"
      >
        Skip this step
      </button>
    </div>
  </div>
</pofo-container>
