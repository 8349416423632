import {
  ACCOUNT_ROUTE_LEGACY,
  APPOINTMENT_CONFIRMATION_ROUTE,
  APPOINTMENT_ROUTE,
  AUTH_DIRECT_ROUTE,
  AUTH_ROUTE,
  AUTO_VERIFICATION_ROUTE,
  CONSENT_ROUTE,
  CONSENT_ROUTE_ADDITIONAL,
  CONTACT_DETAILS_ROUTE,
  IMMUNIZATIONAPPOINTMENT_ROUTE,
  INSURANCE_ROUTE,
  NOT_FOUND,
  ORDER_CONFIRMED_ROUTE,
  ORDER_REQUEST_ROUTE,
  PAYMENT_ROUTE,
  PCP_DETAILS_ROUTE,
  PERSONAL_INFORMATION_ROUTE,
  PHONE_NUMBER_ROUTE,
  PHYSICIANFORM_ROUTE,
  QUANTITY_SET_ROUTE,
  QUESTIONNAIRE,
  SHIPPING_INFORMATION_ROUTE,
  SHOP_LOGIN_ROUTE_LEGACY,
  SHOP_REGISTRATION_ROUTE_LEGACY,
  START_JOURNEY_ROUTE,
  STATE_CONFIRMATION_ROUTE,
  WELCOME_ROUTE
} from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentGuardService } from 'src/app/modules/appointment/guards/appointment.guard';
import { AuthCheckGuard } from '../auth/guard/auth-check.guard';

import { GetWorkflowConfigResolverService } from 'src/app/shared/resolvers/get-workflow-config.resolver';
import { IsManageAppointmentGuardService } from 'src/app/shared/guards/is-manage-appointment/is-manage-appointment.guard';
import { NgModule } from '@angular/core';
import { PhysicianFormGuardService } from 'src/app/shared/guards/physician-form/physician-form.guard';

const routes: Routes = [
  {
    path: `:type/:programId`,
    loadComponent: () =>
      import('./flow-container.component').then(
        (m) => m.FlowContainerComponent
      ),
    children: [
      {
        path: `${AUTH_ROUTE}`,
        loadChildren: () =>
          import('../auth/auth.module').then((m) => m.AuthModule)
      },
      {
        path: ORDER_REQUEST_ROUTE,
        loadChildren: () =>
          import('../order-request/order-request.module').then(
            (m) => m.OrderRequestModule
          )
      },
      {
        path: AUTO_VERIFICATION_ROUTE,
        loadChildren: () =>
          import('../auto-verification/auto-verification.module').then(
            (m) => m.AutoVerificationModule
          )
      }
    ]
  },
  {
    path: ':type/:programId',
    loadComponent: () =>
      import('./flow-container.component').then(
        (m) => m.FlowContainerComponent
      ),
    canActivate: [AuthCheckGuard],
    children: [
      {
        path: START_JOURNEY_ROUTE,
        loadChildren: () =>
          import('../start-journey/start-journey.module').then(
            (m) => m.StartJourneyModule
          )
      },
      {
        path: CONSENT_ROUTE,
        loadChildren: () =>
          import('../consent/consent.module').then((m) => m.ConsentModule)
      },
      {
        path: STATE_CONFIRMATION_ROUTE,
        loadChildren: () =>
          import('../state/state-confirmation.module').then(
            (m) => m.StateModule
          )
      },
      {
        path: PHONE_NUMBER_ROUTE,
        loadChildren: () =>
          import('../phone-number/phone-number-confirmation.module').then(
            (m) => m.ShippingModule
          )
      },
      {
        path: QUESTIONNAIRE,
        loadChildren: () =>
          import('../questionnaire/questionnaire.module').then(
            (m) => m.QuestionnaireModule
          )
      },
      {
        path: SHIPPING_INFORMATION_ROUTE,
        loadChildren: () =>
          import('../shipping/shipping.module').then((m) => m.ShippingModule)
      },
      {
        path: ORDER_CONFIRMED_ROUTE,
        loadChildren: () =>
          import('../order-confirmed/order-confirmed.module').then(
            (m) => m.OrderConfirmedModule
          )
      },
      {
        path: QUANTITY_SET_ROUTE,
        loadChildren: () =>
          import('../order-review/order-review.module').then(
            (m) => m.QuantitySetModule
          )
      },
      {
        path: PERSONAL_INFORMATION_ROUTE,
        loadChildren: () =>
          import('../personal-information/personal-information.module').then(
            (m) => m.PersonalInformationModule
          )
      },
      {
        path: INSURANCE_ROUTE,
        loadChildren: () =>
          import('../insurance/insurance.module').then((m) => m.InsuranceModule)
      },
      {
        path: PCP_DETAILS_ROUTE,
        loadChildren: () =>
          import('../pcp-details/pcp-details.module').then(
            (m) => m.PcpDetailsModule
          )
      },
      {
        path: APPOINTMENT_ROUTE,
        canLoad: [AppointmentGuardService],
        resolve: {
          config: GetWorkflowConfigResolverService
        },
        loadChildren: () =>
          import('../appointment/appointment.module').then(
            (m) => m.AppointmentModule
          )
      },
      {
        path: APPOINTMENT_CONFIRMATION_ROUTE,
        loadChildren: () =>
          import('../appointment-confirmed/appointment-confirmed.module').then(
            (m) => m.AppointmentConfirmedModule
          )
      },
      {
        path: PHYSICIANFORM_ROUTE,
        canActivate: [PhysicianFormGuardService],
        loadChildren: () =>
          import('../physician-form/physician-form.module').then(
            (m) => m.PhysicianFormModule
          )
      },
      {
        path: IMMUNIZATIONAPPOINTMENT_ROUTE,
        canActivate: [IsManageAppointmentGuardService],
        loadChildren: () =>
          import(
            '../immunization-appointment/immunization-appointment.module'
          ).then((m) => m.ImmunizationAppointmentModule)
      },
      {
        path: CONTACT_DETAILS_ROUTE,
        loadChildren: () =>
          import('../contact-details/contact-details.module').then(
            (m) => m.ContactDetailsModule
          )
      },
      {
        path: PAYMENT_ROUTE,
        loadChildren: () =>
          import('../payments/payments.module').then((m) => m.PaymentsModule)
      },
      {
        path: WELCOME_ROUTE,
        loadChildren: () =>
          import('../welcome/welcome.module').then((m) => m.WelcomeModule)
      },
      // FIXME: DO NOT REMOVE THIS UNLESS A DISCUSSION IS HAD
      // THIS IS FOR LEGACY PURPOSES! IT SHOULD BE REMOVED ONE DAY
      {
        path: `${ACCOUNT_ROUTE_LEGACY}/${CONSENT_ROUTE}`,
        redirectTo: `${CONSENT_ROUTE}/account/${CONSENT_ROUTE}`,
        pathMatch: 'full'
      },
      {
        path: `${ACCOUNT_ROUTE_LEGACY}/${CONSENT_ROUTE_ADDITIONAL}`,
        redirectTo: `${CONSENT_ROUTE}/account/${CONSENT_ROUTE_ADDITIONAL}`,
        pathMatch: 'full'
      },
      {
        path: `${ACCOUNT_ROUTE_LEGACY}/${SHOP_REGISTRATION_ROUTE_LEGACY}`,
        redirectTo: `${AUTH_ROUTE}/${AUTH_DIRECT_ROUTE}`,
        pathMatch: 'full'
      },
      {
        path: `${ACCOUNT_ROUTE_LEGACY}/${SHOP_LOGIN_ROUTE_LEGACY}`,
        redirectTo: `${AUTH_ROUTE}/${AUTH_DIRECT_ROUTE}`,
        pathMatch: 'full'
      },
      {
        path: '',
        redirectTo: ORDER_REQUEST_ROUTE,
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: `/${NOT_FOUND}`,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: `/${NOT_FOUND}`,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlowContainerRoutingModule {}
