@if (question) {
<p class="mb-4 font-medium text-base">
  {{ question.text }}
</p>
@for (choice of choices; track trackFn(i, choice); let i = $index) {
<input
  id="{{ choice.index }}"
  type="radio"
  [checked]="choice.isChecked"
  [value]="choice"
  (change)="handleOnChange(choice, i)"
  (focus)="handleOnChange(choice, i)"
/>
<label
  class="label-container"
  for="{{ choice.index }}"
  [ngClass]="{ selected: choice.isChecked }"
  attr.data-test="questionnaireLabel{{ choice.index }}"
>
  {{ choice.text }}
  @if (choice.isChecked) {
  <pofo-icon iconName="check-tick-sm"></pofo-icon>
  }
</label>
}
<div class="line"></div>
<button
  class="btn btn-primary btn-medium w-full"
  data-test="questionnaire-submit-button"
  [disabled]="submitButtonDisabled"
  (click)="onSubmit()"
>
  @if (isLastQuestion) {
  <span>Confirm & continue</span>
  } @if (!isLastQuestion) {
  <span>Next</span>
  }
</button>
}
