import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { StartJourneyRoutingModule } from './start-journey-routing.module';
import { StartJourneyService } from './services/start-journey.service';

@NgModule({
  imports: [ComponentsModule, StartJourneyRoutingModule],
  providers: [StartJourneyService]
})
export class StartJourneyModule {}
