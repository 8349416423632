<div class="consent-component">
  @if (!isLoading) { @if (programType === 'program') { @if
  (getProgramConsentConfig$ | async; as res) { @if (res.error) {
  <pofo-alert type="error" data-test="acceptConsentErrorMsg">{{
    res.errorMessage || 'Sorry, something went wrong, please try that again.'
  }}</pofo-alert>
  } @if (!res.error && res.value) {
  <iframe
    [src]="res.value.stepConfiguration.consentUrl | safe"
    sandbox="allow-scripts allow-same-origin allow-popups"
    title="Consent form"
    aria-labelledby="Account Consent form Iframe"
    #iFrame
  >
  </iframe>
  <div class="sticky bottom-0 bg-white">
    <div class="toolbar-container">
      <div class="toolbar-inner">
        <button
          class="btn btn-primary btn-medium w-full"
          [disabled]="isLoading"
          (click)="handleProgramOnClick(res.value.stepConfiguration)"
          data-test="consentAgreementBtn"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
  } } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  } } @if (programType !== 'program') { @if (getResellerConsentConfig$ | async;
  as res) { @if (res.error) {
  <pofo-alert type="error" data-test="acceptConsentErrorMsg">{{
    res.errorMessage || 'Sorry, something went wrong, please try that again.'
  }}</pofo-alert>
  } @if (!res.error && res.value) {
  <iframe
    [src]="res.value[consentIndex].consentUrl | safe"
    sandbox="allow-scripts allow-same-origin allow-popups"
    title="Consent form"
    aria-labelledby="Account Consent form Iframe"
    #iFrame
  >
  </iframe>
  <div class="sticky bottom-0 bg-white">
    <div class="toolbar-container">
      <div class="toolbar-inner">
        <button
          class="btn btn-primary btn-medium w-full"
          [disabled]="isLoading"
          (click)="handleResellerOnClick(res.value[consentIndex])"
          data-test="consentAgreementBtn"
        >
          Accept
        </button>
        @if (res.value[consentIndex].isSkippable) {
        <button
          class="btn btn-secondary btn-medium w-full mt-3"
          [disabled]="isLoading"
          (click)="handleResellerOnClick(res.value[consentIndex], true)"
          data-test="consentAgreementBtn"
        >
          Decline
        </button>
        }
      </div>
    </div>
  </div>
  } } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  } } } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  }
</div>
