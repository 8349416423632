import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionComponent } from './accordion/accordion.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DateTimePickerComponent } from './datetime-picker/datetime-picker.component';
import { DropdownComponent } from 'src/app/components/dropdown/dropdown.component';
import { DropdownItemComponent } from './searchable-dropdown/dropdown-item/dropdown-item.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ModalComponent } from './modal/modal.component';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { QuantitySelectorComponent } from './quantity-selector/quantity-selector.component';
import { SearchableDropdownComponent } from './searchable-dropdown/searchable-dropdown.component';
import { SharedModule } from '../shared/shared.module';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    MatDatepickerModule,
    AngularSvgIconModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    OverlayModule,
    InputComponent,
    IconComponent,
    DropdownComponent,
    ContainerComponent,
    AlertComponent,
    FooterComponent,
    HeaderComponent,
    ProgressBarComponent,
    QuantitySelectorComponent,
    CardComponent,
    AccordionComponent,
    CheckboxComponent,
    LoadingSpinnerComponent,
    SearchableDropdownComponent,
    DropdownItemComponent,
    ModalComponent,
    DateTimePickerComponent,
    TooltipComponent,
    FileUploadComponent
  ],
  exports: [
    InputComponent,
    IconComponent,
    DropdownComponent,
    ContainerComponent,
    AlertComponent,
    FooterComponent,
    HeaderComponent,
    ProgressBarComponent,
    QuantitySelectorComponent,
    CardComponent,
    AccordionComponent,
    CheckboxComponent,
    LoadingSpinnerComponent,
    SearchableDropdownComponent,
    DropdownItemComponent,
    ModalComponent,
    DateTimePickerComponent,
    FileUploadComponent
  ]
})
export class ComponentsModule {}
