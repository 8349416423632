import { Component, Input, inject } from '@angular/core';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { take } from 'rxjs';
import { RecordEvent } from 'src/app/awsRumEvents';

@Component({
  selector: 'pofo-auth-component',
  templateUrl: './auth.component.html',
  standalone: true,
  imports: [ContainerComponent, LoadingSpinnerComponent, AlertComponent]
})
export class AuthComponent {
  private _oidcService = inject(OidcSecurityService);

  @Input() public stepMessage: string | undefined;
  @Input() public errorMessage: string | undefined;

  public login() {
    this._oidcService
      .checkAuth()
      .pipe(take(1))
      .subscribe((auth) => {
        sessionStorage.setItem('auth_state', 'logging_in');

        RecordEvent('auth.auth_state.logging_in', {
          message: `Authed state: logging_in`
        });

        this._oidcService.authorize(undefined, {
          customParams: {
            prompt:
              auth.userData?.accessMethod === 'direct' ||
              (auth.userData?.accessMethod !== 'sso' && this.errorMessage)
                ? 'login'
                : 'none'
          }
        });
      });
  }
}
