<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!errorMessage) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ errorMessage }}
  </pofo-alert>
  }
  <pofo-container containerTitle="State selection">
    <p class="text-base text-slate text-center md:text-left">
      Which state are you currently in?
    </p>
    @if (data$ | async; as data) {
    <div class="mb-4 w-full">
      <div class="border-t border-platinum my-4 mx-auto"></div>
      <pofo-dropdown
        label="State"
        field="states"
        placeholder="Select State"
        [optionItems]="data.states"
        [value]="data.patientStateIso"
        data-test="StatesDropDown"
        (valueChange)="selectedState($event)"
      ></pofo-dropdown>
    </div>
    <div class="border-t border-platinum my-4 mx-auto"></div>
    <div class="mt-4">
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="confirmAndContinueBtn"
        [disabled]="isLoading"
        (click)="onSubmit()"
      >
        Confirm selection
      </button>
    </div>
    } @else {
    <div class="flex justify-center p-4">
      <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
    </div>
    }
  </pofo-container>
</div>
