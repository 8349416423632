<div class="consent-component">
  @if (immunizationResponse$ | async; as res) { @if (res.error || errorMessage)
  {
  <pofo-alert type="error" data-test="acceptConsentErrorMsg">{{
    res.errorMessage || errorMessageFallback
  }}</pofo-alert>
  } @if ( isGetImmunizationDefinition(res) && !res.error && res.value &&
  res.value.consent.length > 0 ) {
  <iframe
    [src]="res.value.consent[0].url | safe"
    sandbox="allow-scripts allow-same-origin allow-popups"
    title="Immunization appointment Consent Form"
    aria-labelledby="Immunization appointment Consent Form Iframe"
    #iFrame
  >
  </iframe>
  <div class="sticky bottom-0 bg-white">
    <div class="toolbar-container">
      <div class="toolbar-inner">
        <button
          class="btn btn-primary btn-medium w-full"
          data-test="consentAgreementBtn"
          [disabled]="isLoading"
          (click)="handleAgreeOnClick()"
        >
          I accept
        </button>
      </div>
    </div>
  </div>
  } } @else {
  <div class="flex justify-center p-4">
    <pofo-loading-spinner class="w-12"></pofo-loading-spinner>
  </div>
  }
</div>
