import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { ImmunizationService } from '../../services/immunization.service';
import { ProgramService } from 'src/app/services/program/program.service';

@Component({
  selector: 'pofo-immunization-appointment-confirmed',
  templateUrl: './confirmed.component.html',
  styleUrls: ['./confirmed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, ContainerComponent]
})
export class AppointmentConfirmedComponent implements OnInit {
  private _appointmentService = inject(AppointmentService);
  private _immunizationService = inject(ImmunizationService);
  private _environmentService = inject(EnvironmentService);
  private _programService = inject(ProgramService);
  private _document = inject<Document>(DOCUMENT);

  public readonly assetPath = this._environmentService.properties.assetPath;
  public readonly appointment = this._appointmentService.appointment;
  public readonly location = this._appointmentService.selectedLocation;
  public readonly isCancel = this._appointmentService.isCancel;
  public readonly parsedDate = this.appointment.parsedStartDate(
    this._appointmentService.isWalkin
  );
  public readonly title = `Appointment ${
    this.isCancel ? 'canceled' : 'confirmed'
  }`;
  public readonly titleText = `Your appointment has been ${
    this.isCancel ? 'canceled' : 'confirmed'
  }. We’ll send the details to your email address.`;
  public readonly subTitle = this.isCancel
    ? `Your canceled appointment details`
    : `Your appointment details`;

  constructor() {
    this._programService.showSteps$.next(false);
  }

  ngOnInit(): void {
    this._appointmentService.reset();
    this._immunizationService.reset();
    this._appointmentService.modalityId = undefined;
    this._appointmentService.appointmentId = undefined;
  }

  public toDashboard(): void {
    this._document.location.href =
      this._environmentService.properties.patientDashboardURL;
  }
}
