<pofo-container
  background="{{ tabable ? 'focus:bg-teal-100' : '' }}"
  [border]="true"
  [tabable]="tabable"
  borderStyling="
    border rounded-md ring-0 outline-none {{
    tabable ? 'hover:border-teal-400 focus:border-teal-400' : ''
  }}
  "
  shadowStyling="shadow-xs"
  spacingStyles="mb-3 p-4"
>
  <div class="mb-3">
    <div class="flex align-center justify-between">
      <h1 class="text-navy text-base-lg capitalize">
        {{ parsedName }}
      </h1>
      <button
        class="btn btn-secondary btn-xsmall"
        data-test="changePCPBtn"
        (click)="changeClick()"
      >
        Change
      </button>
    </div>
  </div>
  <div class="mb-1 text-storm text-sm">
    <p>{{ details.providerAddressLine1 }}</p>
    <p>
      {{ details.providerCity }}, {{ details.providerState }}
      {{ details.providerZipCode }}
    </p>
  </div>
  <div class="text-storm text-sm">
    <p>
      <span>Phone: {{ details.providerPhone }}</span>
      <span>{{
        details.providerFax ? ' | Fax: ' + details.providerFax : ''
      }}</span>
    </p>
  </div>
</pofo-container>
