import { Component, Input } from '@angular/core';

export interface InputImage {
  alt?: string;
  src: string;
}
@Component({
  selector: 'pofo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true
})
export class CardComponent {
  @Input() image?: InputImage = undefined;
  @Input() title?: string = undefined;
  @Input() textPosition: 'center' | 'left' | 'right' = 'center';
  @Input() lastItem = false;
}
