import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'pofo-modal',
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ModalComponent implements AfterViewInit {
  private _renderer = inject(Renderer2);
  private _document = inject<Document>(DOCUMENT);

  @ViewChild('Modal') private _modalRef: ElementRef<HTMLElement>;
  @Input() public cancelText = 'Cancel';
  @Input() public confirmText = 'Continue';
  @Output() public canceled: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public confirmed: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() public showButtons = true;
  @Input() public imageSrc: Observable<string>;

  ngAfterViewInit(): void {
    this._renderer.addClass(document.body, 'modal-open');
    this._renderer.appendChild(
      this._document.body,
      this._modalRef.nativeElement
    );

    this._modalRef.nativeElement.addEventListener('click', (el: MouseEvent) => {
      if ((el.target as HTMLElement).className === 'modal-container') {
        this.cancel();
      }
    });
  }

  cancel(): void {
    this._removeModal();
    this.canceled.emit(true);
  }

  confirm(): void {
    this._removeModal();
    this.confirmed.emit(true);
  }

  private _removeModal(): void {
    this._renderer.removeClass(document.body, 'modal-open');
    this._renderer.removeChild(
      this._document.body,
      this._modalRef.nativeElement
    );
  }
}
