import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class LoadingSpinnerComponent {
  @Input() size: 'regular' | 'small' = 'regular';
}
