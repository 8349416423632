export const userLocale =
  navigator?.language || navigator?.language[0] || 'en-US';

export const displayFormat: {
  [key: string]: {
    dateInput: string;
    monthYearLabel: string;
    dateA11yLabel: string;
    monthYearA11yLabel: string;
  };
} = {
  ['en-US']: {
    dateInput: 'MM/dd/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'yyyy',
    monthYearA11yLabel: 'MMMM yyyy'
  },
  other: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'yyyy',
    monthYearA11yLabel: 'MMMM yyyy'
  }
};

export const MY_DATE_FORMATS = (locale = userLocale) => ({
  parse: {
    dateInput: (displayFormat[locale] || displayFormat['other']).dateInput
  },
  display: {
    ...(displayFormat[locale] || displayFormat['other'])
  }
});
