<div
  class="flex relative"
  [ngClass]="{
    'flex-row-reverse flex-end': position === 'right',
    'flex-row flex-start': position === 'left',
    'cursor-pointer': !isDisabled,
    'cursor-not-allowed': isDisabled
  }"
>
  <input
    type="checkbox"
    class="checkbox h-6 w-6"
    [ngClass]="{
      selected: !isDisabled && isChecked,
      disabled: isDisabled,
      error: error
    }"
    [tabindex]="isDisabled ? -1 : 0"
    id="{{ identifier }}"
    [(ngModel)]="value"
    [disabled]="isDisabled"
  />

  @if (isChecked && iconName) {
  <pofo-icon
    class="absolute pointer-events-none text-white"
    iconName="{{ iconName }}"
    [size]="24"
  ></pofo-icon>
  }

  <label
    class="text-base shrink"
    for="{{ identifier }}"
    [ngClass]="{
      'mr-4': position === 'right',
      'ml-4': position === 'left',
      'cursor-pointer': !isDisabled,
      'cursor-not-allowed': isDisabled
    }"
  >
    <ng-content></ng-content>
  </label>
</div>
