import { Component, Input, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ],
  standalone: true,
  imports: [NgClass, FormsModule, IconComponent]
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() public identifier: string;
  @Input() public iconName: string;
  @Input() public position: 'left' | 'right' = 'left';
  @Input() public isDisabled = false;
  @Input() public error = false;
  @Input() public set checked(value: boolean) {
    this.value = value || false;
  }

  public isChecked = false;

  /* istanbul ignore next */
  onChange: (isChecked: boolean) => void = () => {};
  /* istanbul ignore next */
  onTouch: (isChecked: boolean) => void = () => {};

  public set value(isChecked: boolean) {
    this.isChecked = isChecked;
    this.onChange(isChecked);
    this.onTouch(isChecked);
  }

  registerOnChange(fn: (isChecked: boolean) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.value = checked;
  }
}
