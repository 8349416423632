import * as amplitude from '@amplitude/analytics-browser';
import { Component, inject } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { skip, take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AmplitudeDirective } from '../../../../shared/amplitude/amplitude.directive';
import { CardComponent } from 'src/app/components/card/card.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { DOCUMENT } from '@angular/common';
import { EnvironmentService } from 'src/environments/environment.service';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { PhysicianFormService } from 'src/app/shared/services/physician-form/physician-form.service';
import { Router } from '@angular/router';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';

@Component({
  selector: 'pofo-physician-form-confirmed',
  templateUrl: './physician-form-confirmed.component.html',
  styleUrls: ['./physician-form-confirmed.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    ContainerComponent,
    AmplitudeDirective,
    AlertComponent,
    LoadingSpinnerComponent,
    CardComponent
  ]
})
export class PhysicianFormConfirmedComponent {
  private readonly environmentService = inject(EnvironmentService);
  private readonly _urlParamsService = inject(UrlParamsService);
  private readonly _physicianFormService = inject(PhysicianFormService);
  private readonly _router = inject(Router);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly _document = inject<Document>(DOCUMENT);

  public readonly isUpload =
    !this._physicianFormService.isCancel &&
    this._urlParamsService.get('physicianFormId');
  public readonly isCancel =
    this._physicianFormService.isCancel &&
    this._urlParamsService.get('physicianFormId');

  public readonly assetPath = this.environmentService.properties.assetPath;
  public readonly downloadFormFileName = 'physicianForm.pdf';
  public selectedFileBLOB: SafeUrl;
  public isDocumentReady = false;
  public isError = false;
  private readonly _modalityId = this._urlParamsService.get('modalityId');

  public nextStepsUpload = [
    {
      title: 'View results',
      image: {
        alt: 'Lady looking at the phone',
        src: `${this.assetPath}images/next-step-5.png`
      },
      content:
        'Your results will soon be available on your dashboard with explanations and next steps for each result.'
    },
    {
      title: 'Track your health over time',
      image: {
        alt: 'Mobile Application',
        src: `${this.assetPath}images/next-step-8.png`
      },
      content: `Your results will help you and your healthcare team take steps to reduce your risk of long-term complications and improve your overall health.`
    }
  ];
  public nextStepsDownload = [
    {
      title: 'Download the form',
      image: {
        alt: 'Lady looking at the phone',
        src: `${this.assetPath}images/next-step-5.png`
      },
      content: `Download your form to have it ready for your physician. If you have not already done so, book an appointment with them.`
    },
    {
      title: 'Share your form',
      image: {
        alt: 'Mobile Application',
        src: `${this.assetPath}images/next-step-6.png`
      },
      content: `Share your form with your physician by email or online portal, or you can print it out and bring it to your appointment.`
    },
    {
      title: 'Return the form',
      image: {
        alt: 'Lady looking at the phone',
        src: `${this.assetPath}images/next-step-7.png`
      },
      content: `Your physician should fax the completed form to LetsGetChecked, or give you back the completed form for you to upload to your account.`
    },
    {
      title: 'View your results',
      image: {
        alt: 'Mobile Application',
        src: `${this.assetPath}images/next-step-8.png`
      },
      content: `We will process your form, and your results will be available to view on your dashboard, with explanations and next steps provided.`
    }
  ];

  constructor() {
    if (!this.isUpload && !this.isCancel) {
      this.setupForm();
    }
  }

  public goToDashboard(): void {
    amplitude.track('click go to dashboard', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'physician forms (pofo)'
    });

    this._document.location.href =
      this.environmentService.properties.patientDashboardURL;
  }

  public setupForm(): void {
    if (!this._modalityId || !this._physicianFormService.getPhysicianFormId()) {
      amplitude.track('setup form', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)',
        errorMessage: 'missing modalityId'
      });

      this._router.navigate(['/something-went-wrong']);
      return;
    }

    this._physicianFormService
      .getForm$()
      .pipe(
        skip(1),
        take(2),
        tap((res) => {
          this.isError = res.error ? true : false;
        })
      )
      .subscribe((res) => {
        if (res.value && res.value.content && res.value.contentType) {
          const byteArray = new Uint8Array(
            atob(res.value.content)
              .split('')
              .map((char) => char.charCodeAt(0))
          );
          const blob = new Blob([byteArray], { type: res.value.contentType });
          const url = window.URL.createObjectURL(blob);

          this.selectedFileBLOB = this.sanitizer.bypassSecurityTrustUrl(url);
          this.isDocumentReady = true;
        }
      });
  }
}
