<div class="my-12 md:my-16 md:w-[540px] l:w-[550px] mx-4 md:mx-auto">
  <pofo-container
    [attr.data-test]="'results-pofo-container'"
    containerTitle="{{
      labels && labels['resultsTitle']
        ? labels['resultsTitle']
        : 'Your personalized tests'
    }}"
  >
    @if (labels && labels['resultsSubtitle']) {
    <div>
      <p
        class="pb-4 mb-4 shadow-inset shadow-cloudy"
        [str2Html]="labels['resultsSubtitle']"
      ></p>
    </div>
    } @else {
    <p class="pb-4 mb-4">
      Based on your answers, we suggest the following test(s).
    </p>
    <p class="pb-4 mb-4 shadow-inset shadow-cloudy">
      Please note, you must be over 18 to order this test.
    </p>
    }

    <p class="mb-4 text-storm text-xs font-bold">ORDER TEST(S):</p>

    <ul class="p-4 border rounded-md border-platinum bg-white">
      @for (result of results; track trackFn($index, result)) {
      <li
        class="flex items-center justify-start mb-4 shadow-inset shadow-cloudy pb-4 last:shadow-transparent last:pb-0 last:mb-0"
      >
        <div class="w-12 pr-2">
          <img
            src="{{
              result.image ? result.image : '/assets/icons/testkit.svg'
            }}"
            alt="{{ result.title }}"
          />
        </div>
        <div class="w-4/5">
          <div class="text-navy text-base font-bold">
            {{ result.title }}
          </div>
          <div class="text-storm text-sm font-normal">
            @for ( biomarker of result.biomarkers; track trackFn(i, biomarker);
            let i = $index) {
            <span>
              {{
                i < result.biomarkers.length - 1 ? biomarker + ', ' : biomarker
              }}
            </span>
            }
          </div>
        </div>
        @if (result.url) {
        <a
          href="{{ result.url }}"
          target="_blank"
          rel="noopener noreferrer"
          class="flex items-center justify-end w-1/4"
        >
          <span class="hidden md:block">Learn more</span>
          <pofo-icon iconName="chevron-right-sm" [size]="16"></pofo-icon
        ></a>
        }
      </li>
      }
    </ul>

    <div class="line"></div>

    @if (!!errorMessage) {
    <pofo-alert type="error" data-test="error-message">{{
      errorMessage
    }}</pofo-alert>
    }

    <button
      class="btn btn-primary btn-medium w-full"
      data-test="results-submit-button"
      [disabled]="isLoading"
      (click)="onResults()"
    >
      Complete order now
    </button>
  </pofo-container>
</div>
