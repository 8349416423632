export const AUTH_ROUTE = 'auth';
export const AUTH_SSO_ROUTE = 'sso';
export const AUTH_DIRECT_ROUTE = 'direct';
export const AUTH_CALLBACK_ROUTE = 'callback';
export const ORDER_REQUEST_ROUTE = 'order';
export const VERIFICATION_ROUTE = 'verification';
export const CONSENT_ROUTE = 'consent';
export const CONSENT_ROUTE_ADDITIONAL = 'consent-2';
export const SHIPPING_INFORMATION_ROUTE = 'shipping';
export const ORDER_CONFIRMED_ROUTE = 'confirmation';
export const NOT_FOUND = 'not-found';
export const UNAUTHORIZED = 'unauthorized';
export const LIMITS_REACHED = 'limits-reached';
export const QUESTIONNAIRE = 'questionnaire';
export const QUESTIONNAIRE_ELIGIBILITY = 'quiz';
export const QUESTIONNAIRE_RESULTS = 'results';
export const ENDED = 'ended';
export const SOMETHING_WENT_WRONG = 'something-went-wrong';
export const WRONG_GEO_ROUTE = 'wrong-geo';
export const QUANTITY_SET_ROUTE = 'your-order';
export const INSURANCE_ROUTE = 'insurance';
export const PERSONAL_INFORMATION_ROUTE = 'personal-information';
export const PCP_DETAILS_ROUTE = 'pcp-details';
export const APPOINTMENT_ROUTE = 'appointment';
export const APPOINTMENT_CONFIRMATION_ROUTE = 'appointment-confirmation';
export const PHYSICIANFORM_ROUTE = 'physician-form';
export const PHYSICIANFORM_UPLOAD_ROUTE = 'upload';
export const PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE = 'upload-confirm';
export const PHYSICIANFORM_MANAGE_ROUTE = 'manage';
export const PHYSICIANFORM_CONFIRM_ROUTE = 'confirm';
export const PHYSICIANFORM_CONFIRMATION_ROUTE = 'confirmation';
export const CONTACT_DETAILS_ROUTE = 'contact-details';
export const PAYMENT_ROUTE = 'checkout';
export const LOGOUT_ROUTE = 'logout';
export const WELCOME_ROUTE = 'welcome';
export const IMMUNIZATIONAPPOINTMENT_ROUTE = 'immunization';
export const IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE = 'appointment';
export const IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE = 'confirmation';
export const AUTO_VERIFICATION_ROUTE = 'auto-verification';
export const START_JOURNEY_ROUTE = 'start';
export const PHONE_NUMBER_ROUTE = 'phone-number';
export const STATE_CONFIRMATION_ROUTE = 'state-confirmation';

// Legacy routes
export const ACCOUNT_ROUTE_LEGACY = 'account';
export const SHOP_REGISTRATION_ROUTE_LEGACY = 'register';
export const SHOP_LOGIN_ROUTE_LEGACY = 'login';
