<div
  class="relative h-[48px] flex justify-center items-center"
  [ngClass]="{
    'bg-off-white': isDisabled,
    'bg-white': !isDisabled,
    'rounded-sides': isRoundedSides
  }"
>
  @if (icon) {
  <pofo-icon
    class="absolute top-3 left-3 cursor-pointer z-1 text-concrete"
    iconName="{{ icon }}"
    [size]="24"
    (mousedown)="preventBlur($event)"
    (mouseup)="!isDisabled && togglePassword()"
  ></pofo-icon>
  } @if (type.toLocaleLowerCase() === 'date') {
  <input
    [matDatepicker]="picker"
    type="text"
    dateMask
    matInput
    [placeholder]="dateFormats.display.dateInput.toLocaleLowerCase()"
    [disabled]="isDisabled"
    [ngClass]="{
      'ng-valid': !hasError,
      'ng-invalid': hasError,
      error: hasError,
      hasIcon: icon,
      'rounded-sides': isRoundedSides
    }"
    [name]="name"
    (input)="onInputDateChange()"
    (change)="onInputDateChange()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (dateChange)="onDatepickerChange($event)"
    [(ngModel)]="value"
    [id]="name"
    [attr.aria-invalid]="hasError"
    [attr.aria-errormessage]="errorMsgId"
  />

  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
    class="absolute right-0 z-1 !text-[theme('colors.teal.600')] customToggleDatePicker mr-1"
  ></mat-datepicker-toggle>
  <mat-datepicker
    panelClass="customDatePicker"
    [startView]="value ? 'month' : 'year'"
    [calendarHeaderComponent]="datePickerHeaderComponent"
    [startAt]="value || getDateMinus18Years()"
    #picker
  ></mat-datepicker>

  } @else {
  <input
    type="{{ type === 'search' ? 'text' : type }}"
    minlength="{{ minlen }}"
    maxlength="{{ maxlen }}"
    [disabled]="isDisabled"
    [ngClass]="{
      'ng-valid': !hasError,
      'ng-invalid': hasError,
      error: hasError,
      hasIcon: icon,
      'rounded-sides': isRoundedSides
    }"
    [name]="name"
    (input)="onValueChanged()"
    (change)="onValueChanged()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    [(ngModel)]="value"
    [id]="name"
    [attr.aria-invalid]="hasError"
    [attr.aria-errormessage]="errorMsgId"
  />
  }
  <label
    class="z-0 absolute items-center left-3 transition-top duration-300"
    [for]="name"
    [ngClass]="{
      'text-smoke': isDisabled,
      'text-storm': !isFocused && !isDisabled,
      'top-2 text-input font-bold text-storm':
        isFocused ||
        (value && type !== 'date' && value.trim().length > 0) ||
        type === 'date',
      'top-3 text-base':
        !isFocused &&
        (!value || (type !== 'date' && value.trim().length === 0)) &&
        type !== 'date',
      'text-red': hasError,
      'pl-8': icon
    }"
    >{{ label }}</label
  >
  @if (type === 'password' || (type === 'text' && !hidePassword)) {
  <pofo-icon
    [ngClass]="{
      'text-concrete': hidePassword
    }"
    class="absolute top-3 right-3 cursor-pointer z-1"
    iconName="{{ hidePassword ? 'hidden' : 'visible' }}"
    [size]="24"
    (mousedown)="preventBlur($event)"
    (mouseup)="!isDisabled && togglePassword()"
  ></pofo-icon>
  } @if (type === 'search') {
  <pofo-icon
    class="absolute top-3 right-3 cursor-pointer z-1 text-slate {{
      value.length > 0 ? 'block' : 'hidden'
    }}"
    iconName="cross-cancel-lg"
    [size]="24"
    (mousedown)="preventBlur($event)"
    (mouseup)="inputClear()"
  ></pofo-icon>
  }
</div>
