import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { catchError, iif, of, switchMap, take } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'pofo-auth-logout',
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ContainerComponent, LoadingSpinnerComponent, AlertComponent]
})
export class AuthLogoutComponent implements OnInit {
  oidcSecurityService = inject(OidcSecurityService);

  public logoutStatus = 'We are logging you out...';
  isLoading = true;
  hasError = false;

  ngOnInit(): void {
    this.oidcSecurityService.isAuthenticated$
      .pipe(
        take(1),
        switchMap(({ isAuthenticated }) =>
          iif(
            () => isAuthenticated,
            this.oidcSecurityService.logoffAndRevokeTokens().pipe(take(1)), //this.oidcSecurityService.checkAuth(),
            of(isAuthenticated)
          )
        ),
        catchError(() => {
          return of({
            error:
              'There was an error logging you out. Please reload to try again.'
          });
        })
      )
      .subscribe((response) => {
        localStorage.removeItem('0-pofoClient');
        const subDomainPrefix = /^(order)/;

        this._removeCookie(
          'x-lgc-patient-geo',
          document.location.hostname.replace(subDomainPrefix, '')
        );
        this._removeCookie(
          'x-lgc-pp',
          document.location.hostname.replace(subDomainPrefix, '')
        );
        this.isLoading = false;
        this.hasError = !!response.error;
        this.logoutStatus = response.error ?? 'You have been logged out.';
      });
  }

  private _removeCookie(cookie: string, domain: string, path = '/'): void {
    if (document?.cookie) {
      document.cookie = `${cookie}=; domain=${domain}; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }
  }
}
