<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!errorMessage) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ errorMessage }}
  </pofo-alert>
  }

  <pofo-container [containerTitle]="title">
    <form
      #phoneNumberFormElement
      [formGroup]="phoneNumberForm"
      (ngSubmit)="onSubmitForm()"
    >
      <div>
        <p class="text-sm mb-2 md:mb-3">
          Which phone number would you like us to call for your consultation?
        </p>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="flex flex-col md:flex-row justify-between">
          <div class="mb-3 md:mb-0 w-full md:w-1/2 md:mr-3">
            <pofo-dropdown
              [isDisabled]="true"
              label="Country code"
              placeholder="Select Country Code"
              field="phoneCountryCode"
              [optionItems]="countryCodes"
              [value]="phoneNumberForm.get('phoneCountryCode')?.value ?? '+1'"
              formControlName="phoneCountryCode"
              [hasError]="phoneNumberForm.get('phoneCountryCode')?.errors?.['required']"
              data-test="phoneCountryCodeDropDown"
              (valueChange)="onPhoneCountryCodeChange($event)"
            ></pofo-dropdown>
          </div>

          <div class="w-full md:w-1/2">
            <pofo-input
              name="phoneNumber"
              [label]="phoneNumberLabel"
              type="tel"
              [hasError]="
                !!phoneNumberForm.get('phoneNumber')?.touched &&
                !!phoneNumberForm.get('phoneNumber')?.errors
              "
              formControlName="phoneNumber"
              data-test="phoneNumberInput"
              errorMsgId="phoneNumberErrorMsg"
            ></pofo-input>

            @if (phoneNumberForm.get('phoneNumber')?.touched &&
            phoneNumberForm.get('phoneNumber')?.errors?.['required']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberReqErrorMsg"
              [errorMessage]="phoneNumberLabel + ' is required.'"
            ></pofo-error-message>
            } @else if (phoneNumberForm.get('phoneNumber')?.touched &&
            phoneNumberForm.get('phoneNumber')?.errors?.['minlength'] ||
            phoneNumberForm.get('phoneNumber')?.touched &&
            phoneNumberForm.get('phoneNumber')?.errors?.['maxlength']) {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberLengthErrorMsg"
              [errorMessage]="
                'Must have ' +
                (phoneNumberForm.get('phoneCountryCode')?.value === '+1'
                  ? '10'
                  : '7') +
                ' digits.'
              "
            ></pofo-error-message>
            } @else if (phoneNumberForm.get('phoneNumber')?.errors?.['pattern'])
            {
            <pofo-error-message
              errorId="phoneNumberErrorMsg"
              testId="phoneNumberNoSpecialCharsErrorMsg"
              errorMessage="Please do not use any special characters or spaces."
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="mt-4">
          <button
            type="submit"
            class="btn btn-primary btn-medium w-full"
            data-test="confirmAndContinueBtn"
            [disabled]="isLoading || !phoneNumberForm.valid"
          >
            Confirm & continue
          </button>
        </div>
      </div>
    </form>
  </pofo-container>
</div>
