import {
  PHYSICIANFORM_CONFIRMATION_ROUTE,
  PHYSICIANFORM_CONFIRM_ROUTE,
  PHYSICIANFORM_MANAGE_ROUTE,
  PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE,
  PHYSICIANFORM_UPLOAD_ROUTE
} from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: PHYSICIANFORM_UPLOAD_ROUTE,
    loadComponent: () =>
      import('./pages/upload/upload.component').then(
        (m) => m.PhysicianUploadComponent
      )
  },
  {
    path: PHYSICIANFORM_UPLOAD_CONFIRM_ROUTE,
    loadComponent: () =>
      import('./pages/upload-confirm/upload-confirm.component').then(
        (m) => m.PhysicianFormConfirmUploadComponent
      )
  },
  {
    path: PHYSICIANFORM_CONFIRM_ROUTE,
    loadComponent: () =>
      import('./pages/confirm/confirm.component').then(
        (m) => m.PhysicianFormConfirmComponent
      )
  },
  {
    path: PHYSICIANFORM_MANAGE_ROUTE,
    loadComponent: () =>
      import('./pages/manage/manage.component').then(
        (m) => m.PhysicianManageComponent
      )
  },
  {
    path: PHYSICIANFORM_CONFIRMATION_ROUTE,
    loadComponent: () =>
      import('./pages/confirmed/physician-form-confirmed.component').then(
        (m) => m.PhysicianFormConfirmedComponent
      )
  },
  {
    path: '',
    redirectTo: PHYSICIANFORM_CONFIRM_ROUTE,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhysicianFormRoutingModule {}
