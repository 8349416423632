/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Observable,
  catchError,
  map,
  of,
  retry,
  startWith,
  throwError,
  timer
} from 'rxjs';
import { LoaderState } from '../../models/loading.model';
import { WorkflowService } from '../workflow/workflow.service';

const initialState: Omit<LoaderState<unknown>, 'value'> = {
  loading: true,
  success: false,
  error: false
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private http = inject(HttpClient);
  private workflowService = inject(WorkflowService);

  public errorStatus?: HttpErrorResponse;
  private _headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  public appendCustomHeader(key: string, value: string): void {
    this._headers = this._headers.append(key, value);
  }

  get<T, M>(
    url: string,
    normalizer: (response: T) => M,
    headers?: HttpHeaders
  ): Observable<LoaderState<M>> {
    return this.http
      .get<HttpResponse<T>>(url, {
        headers: headers || this._headers,
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((response) =>
          this.handleSuccess<T, M>(response.body || response, normalizer)
        ),
        retry({
          count: 1,
          delay: (error: HttpErrorResponse) => this.checkForCookieError$(error)
        }),
        catchError((error: HttpErrorResponse) => of(this.handleError(error))),
        startWith(initialState)
      );
  }

  post<T, M>(
    url: string,
    data: any,
    normalizer: (response: T) => M,
    headers?: HttpHeaders
  ): Observable<LoaderState<M>> {
    return this.http
      .post<HttpResponse<T>>(url, data, {
        headers: headers || this._headers,
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((response) =>
          this.handleSuccess<T, M>(
            { ...(response.body || response), status: response.status },
            normalizer
          )
        ),
        retry({
          count: 1,
          delay: (error: HttpErrorResponse) => this.checkForCookieError$(error)
        }),
        catchError((error: HttpErrorResponse) => of(this.handleError(error))),
        startWith(initialState)
      );
  }

  put<T, M>(
    url: string,
    data: any,
    normalizer: (response: T) => M,
    headers?: HttpHeaders
  ): Observable<LoaderState<M>> {
    return this.http
      .put<HttpResponse<T>>(url, data, {
        headers: headers || this._headers,
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((response) =>
          this.handleSuccess<T, M>(
            { ...(response.body || response), status: response.status },
            normalizer
          )
        ),
        retry({
          count: 1,
          delay: (error: HttpErrorResponse) => this.checkForCookieError$(error)
        }),
        catchError((error: HttpErrorResponse) => of(this.handleError(error))),
        startWith(initialState)
      );
  }

  delete<T, M>(
    url: string,
    normalizer: (response: T) => M,
    headers?: HttpHeaders
  ): Observable<LoaderState<M>> {
    return this.http
      .delete<HttpResponse<T>>(url, {
        headers: headers || this._headers,
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((response) =>
          this.handleSuccess<T, M>(response.body || response, normalizer)
        ),
        retry({
          count: 1,
          delay: (error: HttpErrorResponse) => this.checkForCookieError$(error)
        }),
        catchError((error: HttpErrorResponse) => of(this.handleError(error))),
        startWith(initialState)
      );
  }

  private checkForCookieError$(err: HttpErrorResponse): Observable<unknown> {
    const isServerError = err.status === HttpStatusCode.PreconditionRequired;

    return isServerError ? timer(200) : throwError(() => err);
  }

  private handleSuccess<T, M>(
    response: any,
    normalizer: (response: T) => M
  ): LoaderState<M> {
    const workflowResponse =
      this.workflowService.handleSuccessfulApiResponse(response);

    return {
      status: response.status,
      loading: false,
      success: true,
      error: false,
      value: normalizer(workflowResponse.payload),
      onCompleteCallback: workflowResponse.onCompleteCallback
    };
  }

  private handleError(
    response: HttpErrorResponse
  ): Omit<LoaderState<unknown>, 'value'> {
    const workflowResponse = this.workflowService.handleApiErrorResponse(
      response.error,
      response.status,
      response.url
    );

    return {
      loading: false,
      error: true,
      errorMessage: workflowResponse.error,
      success: false
    };
  }
}
