/// <reference types="grecaptcha" />
import { Injectable, inject } from '@angular/core';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';

@Injectable({
  providedIn: 'root'
})
export class SyncVisitService {
  private _urlParamsService = inject(UrlParamsService);

  public readonly modalityId = this._urlParamsService.get('modalityId');
  public syncVisitId: string;
  public stateIso: string;
  public countryIso: string;
  public phoneNumber: string;
}
