import { AbstractControl, ValidationErrors } from '@angular/forms';
import { StateData } from 'src/app/models/shipping-config.model';

export const validatePostCode = (
  control: AbstractControl,
  states?: StateData[]
): ValidationErrors | null => {
  const postCode = control?.get('postCode');

  if (!postCode?.value) {
    return null;
  }

  const selectedState = control.get('state')?.value;
  const stateData = states?.find((state) => state.value === selectedState);

  if (!stateData?.postCodes) return null;

  const isValidPostCode = stateData?.postCodes?.includes(postCode?.value);

  return isValidPostCode ? null : { invalidPostCode: true };
};
