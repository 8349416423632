import { AngularSvgIconModule } from 'angular-svg-icon';
import { AutoVerificationComponent } from './auto-verification.component';
import { AutoVerificationRoutingModule } from './auto-verification-routing.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    AutoVerificationRoutingModule,
    AngularSvgIconModule,
    TranslateModule,
    AutoVerificationComponent
  ]
})
export class AutoVerificationModule {}
