<pofo-container containerTitle="Your physician"
  ><div class="content">
    <p class="text-base text-slate text-center mb-1 md:text-left">
      Select a physician from the result list below.
    </p>
    <button
      class="btn btn-inline btn-xsmall"
      data-test="goBackBtn"
      (click)="onGoBack()"
    >
      Change details
    </button>
    <div class="mt-3">
      <p class="text-sm font-bold mb-3">
        Showing all results based on your search
      </p>
      <div class="care-provider-list">
        @for ( careProvider of results.results; track trackFn(i, careProvider);
        let i = $index) {
        <pofo-pcp-item
          [details]="careProvider"
          [isSelected]="careProvider.number === selectedPCP?.number"
          (click)="togglePCPSelect(careProvider)"
          (keydown.enter)="togglePCPSelect(careProvider)"
        ></pofo-pcp-item>
        }
      </div>
    </div>
  </div>
  <div class="border-t border-platinum my-6 mx-auto"></div>
  <div class="sticky bottom-0 bg-off-white">
    <div class="px-1 mx-[-4px]">
      <button
        class="btn btn-primary btn-medium w-full"
        data-test="confirmPCPItemBtn"
        (click)="confirmPCP()"
        [disabled]="!selectedPCP"
      >
        Confirm selection
      </button>
    </div>
    <div class="pt-4 px-1 mx-[-4px]">
      <button
        class="btn btn-secondary btn-medium w-full"
        data-test="skipStepBtn"
        (click)="skipStep()"
      >
        Skip this step
      </button>
    </div>
  </div>
</pofo-container>
