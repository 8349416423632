import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ImmunizationAppointmentModule } from '../immunization-appointment.module';
import { ImmunizationService } from '../services/immunization.service';

@Injectable({
  providedIn: ImmunizationAppointmentModule
})
export class HasImmunizationIdGuard {
  private _immunizationService = inject(ImmunizationService);
  private _appointmentService = inject(AppointmentService);
  private _router = inject(Router);

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (
      this._immunizationService.immunizationId ||
      state.url.includes('manage') ||
      this._appointmentService.isReschedule
    ) {
      return true;
    } else {
      return this._router.createUrlTree([
        state.url.slice(0, state.url.lastIndexOf('appointment'))
      ]);
    }
  }
}
