import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';
import { VerificationService } from 'src/app/services/verification/verification.service';
import { RecordEvent } from 'src/app/awsRumEvents';

@Injectable({
  providedIn: null
})
export class AuthCheckGuard {
  private _oidcService = inject(OidcSecurityService);
  private _urlParamService = inject(UrlParamsService);
  private _verificationService = inject(VerificationService);

  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._oidcService.checkAuth().pipe(
      take(1),
      map((auth) => {
        const sessionAuthState = sessionStorage.getItem('auth_state');

        if (
          (auth.isAuthenticated && sessionAuthState === 'logged') ||
          this._verificationService.isVerification
        ) {
          RecordEvent('auth_check.authed.success', {
            message: `User was Authed in POFO and in IDP.`
          });
          return true;
        }

        sessionStorage.setItem(
          'idpReturnUrl',
          `${
            window.location.origin
          }${this._urlParamService.recreateUrlWithParams(state)}`
        );

        RecordEvent('auth_check.authed.failed', {
          message: `User was sent to IDP to auth.`
        });

        sessionStorage.setItem('auth_state', 'logging_in_direct');

        RecordEvent('auth_check.auth_state.logging_in_direct', {
          message: `Authed state: logging_in_direct`
        });

        this._oidcService.authorize();

        return false;
      })
    );
  }
}
