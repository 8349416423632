/// <reference types="grecaptcha" />
import {
  ConsentResponse,
  SaveConsentResponse
} from '../../@types/consent.interface';
import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { Observable } from 'rxjs';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Injectable({
  providedIn: null
})
export class ConsentService {
  private _httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  public getConsent$(
    modalityId: string
  ): Observable<LoaderState<ConsentResponse[]>> {
    return this._httpService.get<ConsentResponse[], ConsentResponse[]>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/consent`,
      this._responseParser<ConsentResponse[]>
    );
  }

  public setConsent$(
    modalityId: string,
    domainSpecificId: string,
    consentKey: string,
    requirementType: string
  ): Observable<LoaderState<SaveConsentResponse>> {
    return this._httpService.post<SaveConsentResponse, SaveConsentResponse>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${modalityId}/consent`,
      {
        domainSpecificId,
        consentKey,
        requirementType
      },
      this._responseParser<SaveConsentResponse>
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
