import {
  AUTH_CALLBACK_ROUTE,
  AUTH_DIRECT_ROUTE,
  AUTH_SSO_ROUTE,
  LOGOUT_ROUTE,
  NOT_FOUND
} from 'src/app/app-routes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OidcAuthCheckGuard } from './guard/oidc-auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OidcAuthCheckGuard],
    children: [
      {
        path: AUTH_SSO_ROUTE,
        loadComponent: () =>
          import('./pages/sso/sso.component').then((m) => m.AuthSSOComponent)
      },
      {
        path: AUTH_DIRECT_ROUTE,
        loadComponent: () =>
          import('./pages/direct-url/direct-url.component').then(
            (m) => m.AuthDirectUrlComponent
          )
      }
    ]
  },
  {
    path: AUTH_CALLBACK_ROUTE,
    loadComponent: () =>
      import('./pages/callback/callback.component').then(
        (m) => m.AuthCallbackComponent
      ),
    pathMatch: 'full'
  },
  {
    path: LOGOUT_ROUTE,
    loadComponent: () =>
      import('./pages/logout/logout.component').then(
        (m) => m.AuthLogoutComponent
      )
  },
  { path: '**', redirectTo: `/${NOT_FOUND}`, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
