import { Component, Input } from '@angular/core';
import { AlertType } from 'src/app/models/alert.model';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [NgClass, IconComponent]
})
export class AlertComponent {
  @Input() type: AlertType = 'information';

  get background() {
    switch (this.type) {
      case 'error':
        return 'bg-pale-red';
      case 'success':
        return 'bg-pale-aqua-dark';
      case 'warning':
        return 'bg-pale-yellow';
      default:
        return 'bg-off-white';
    }
  }

  get iconColor() {
    switch (this.type) {
      case 'error':
        return 'text-red';
      case 'success':
        return 'text-green';
      case 'warning':
        return 'text-yellow';
      default:
        return 'text-smoke';
    }
  }

  get icon() {
    switch (this.type) {
      case 'error':
        return 'cross-cancel-circle';
      case 'success':
        return 'check-tick-circle';
      default:
        return 'alert-warning-circle';
    }
  }
}
