import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { OrderReviewComponent } from './order-review.component';
import { QuantitySetRoutingModule } from './order-review-routing.module';

@NgModule({
  imports: [
    CommonModule,
    QuantitySetRoutingModule,
    ComponentsModule,
    OrderReviewComponent
  ]
})
export class QuantitySetModule {}
