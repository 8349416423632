import {
  APPOINTMENT_ROUTE,
  AUTH_ROUTE,
  CONSENT_ROUTE,
  NOT_FOUND,
  ORDER_CONFIRMED_ROUTE,
  ORDER_REQUEST_ROUTE,
  PCP_DETAILS_ROUTE,
  PHONE_NUMBER_ROUTE,
  QUANTITY_SET_ROUTE,
  QUESTIONNAIRE,
  SHIPPING_INFORMATION_ROUTE,
  START_JOURNEY_ROUTE,
  STATE_CONFIRMATION_ROUTE,
  VERIFICATION_ROUTE,
  WRONG_GEO_ROUTE
} from '../app-routes';
import { Injectable, inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { WorkflowService } from '../services/workflow/workflow.service';

@Injectable({ providedIn: 'root' })
export class UrlFragmentsGuardService {
  private _router = inject(Router);
  private _workflowService = inject(WorkflowService);

  private pages = [
    AUTH_ROUTE,
    ORDER_REQUEST_ROUTE,
    VERIFICATION_ROUTE,
    CONSENT_ROUTE,
    SHIPPING_INFORMATION_ROUTE,
    ORDER_CONFIRMED_ROUTE,
    QUESTIONNAIRE,
    WRONG_GEO_ROUTE,
    QUANTITY_SET_ROUTE,
    APPOINTMENT_ROUTE,
    PCP_DETAILS_ROUTE,
    START_JOURNEY_ROUTE,
    PHONE_NUMBER_ROUTE,
    STATE_CONFIRMATION_ROUTE
  ];

  canLoad(_route: Route, segments: UrlSegment[]): boolean {
    // TODO: Remove defaulting to ORDER_REQUEST_ROUTE, it is no longer a mandatory route on programs.
    // We should refactor logic to take that into account or consider rerouting to 404 page
    // if url only includes 1 segment and it is a program -> should append default region and "program" identifier
    if (segments.length === 1 && this.isProgram(segments[0].path)) {
      // if /slug
      this._workflowService.setSegments([
        new UrlSegment('us', {}),
        new UrlSegment('program', {}),
        segments[0]
      ]);
      this._router.navigate([
        'us',
        'program',
        segments[0].path,
        ORDER_REQUEST_ROUTE
      ]);
      return false;
    }

    if (segments.length === 2) {
      if (this.isRegion(segments[0].path) && this.isProgram(segments[1].path)) {
        // if region/slug
        this._workflowService.setSegments([
          segments[0],
          new UrlSegment('program', {}),
          segments[1]
        ]);
        this._router.navigate([segments[0].path, 'program', segments[1].path]);
      } else if (
        this.isProgramType(segments[0].path) &&
        this.isProgram(segments[1].path)
      ) {
        // if type/slug -> add region
        this._workflowService.setSegments([
          new UrlSegment('us', {}),
          segments[0],
          segments[1]
        ]);
        this._router.navigate(['us', segments[0].path, segments[1].path]);
      } else if (
        this.isProgram(segments[0].path) &&
        this.isPage(segments[1].path)
      ) {
        // if slug/page -> add region + program
        this._workflowService.setSegments([
          new UrlSegment('us', {}),
          new UrlSegment('program', {}),
          segments[0],
          segments[1]
        ]);
        this._router.navigate([
          'us',
          'program',
          segments[0].path,
          segments[1].path
        ]);
      } else if (
        this.isRegion(segments[0].path) &&
        segments[1].path === 'client'
      ) {
        // if region/client -> client sso page
        this._workflowService.setSegments(segments);
        return true;
      } else {
        this._router.navigate([NOT_FOUND]);
      }
      return false;
    }

    // if url has more than 2 segments, if first is page, it is invalid
    if (segments.length > 2 && !this.isPage(segments[0].path)) {
      const [first, second, third, ...rest] = segments.map((item) => item.path);
      // if SSO page
      if (
        this.isRegion(segments[0].path) &&
        second === 'client' &&
        third === 'sso'
      ) {
        this._workflowService.setSegments([segments[0], segments[1]]);
        return true;
      }
      // if region/type/slug -> return true
      if (
        this.isRegion(segments[0].path) &&
        this.isProgramType(second) &&
        this.isProgram(third)
      ) {
        this._workflowService.setSegments(segments);
        return true;
      } else {
        if (this.isProgram(first) && this.isPage(second)) {
          // slug/page/sub-page -> add region and type
          this._workflowService.setSegments([
            new UrlSegment('us', {}),
            new UrlSegment('program', {}),
            segments[0]
          ]);
          this._router.navigate([
            'us',
            'program',
            first,
            second,
            third,
            ...rest
          ]);
        } else if (
          this.isRegion(first) &&
          this.isProgram(second) &&
          this.isPage(third)
        ) {
          // region/slug/page -> add program type
          this._workflowService.setSegments([
            segments[0],
            new UrlSegment('program', {}),
            segments[1]
          ]);
          this._router.navigate([first, 'program', second, third, ...rest]);
        } else if (
          this.isProgramType(first) &&
          this.isProgram(second) &&
          this.isPage(third)
        ) {
          // type/slug/page -> add region
          this._workflowService.setSegments([
            new UrlSegment('us', {}),
            segments[0],
            segments[1]
          ]);
          this._router.navigate(['us', first, second, third, ...rest]);
        } else {
          this._router.navigate([NOT_FOUND]);
        }
        return false;
      }
    }
    this._router.navigate([NOT_FOUND]);
    return false;
  }

  isRegion(segment: string) {
    return segment.length === 2;
  }

  isProgramType(segment: string) {
    return segment === 'client' || segment === 'program';
  }

  isPage(segment: string) {
    return this.pages.includes(segment);
  }

  isProgram(segment: string) {
    return (
      !this.isRegion(segment) &&
      !this.isProgramType(segment) &&
      !this.isPage(segment)
    );
  }
}
