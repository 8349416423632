<div
  [ngClass]="[
    spacingStyles,
    border ? borderStyling : '',
    shadowStyling,
    background
  ]"
  tabindex="{{ tabable ? 0 : -1 }}"
>
  <ng-content select="[slot=aboveHeader]"></ng-content>
  @if (containerTitle && containerTitle.length) {
  <h1 class="text-navy text-xl text-center md:text-left">
    {{ containerTitle }}
  </h1>
  <div class="border-t w-[32px] border-teal-400 my-4 mx-auto md:mx-0"></div>
  }
  <ng-content></ng-content>
</div>
