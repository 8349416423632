<div class="flex">
  <button
    [ngClass]="removeBtnClasses"
    (click)="handleRemove()"
    data-test="qtySubtractBtn"
    attr.title="Decrease Quantity{{ accLabel ? ' of ' + accLabel : '' }}"
    attr.aria-label="Decrease Quantity{{ accLabel ? ' of ' + accLabel : '' }}"
  >
    <pofo-icon
      [outerSize]="32"
      [class]="
        canRemove
          ? 'text-aqua-darker hover:text-white active:text-white'
          : 'text-concrete'
      "
      iconName="minus-subtract-sm"
    ></pofo-icon>
  </button>
  <div class="flex items-center justify-center mx-2 w-[16px]">{{ value }}</div>
  <button
    [ngClass]="addBtnClasses"
    (click)="handleAdd()"
    data-test="qtyAddBtn"
    attr.title="Increase Quantity{{ accLabel ? ' of ' + accLabel : '' }}"
    attr.aria-label="Increase Quantity{{ accLabel ? ' of ' + accLabel : '' }}"
  >
    <pofo-icon
      [outerSize]="32"
      [ngClass]="[
        maxReached
          ? 'text-concrete'
          : 'text-aqua-darker hover:text-white active:text-white'
      ]"
      iconName="plus-add-sm"
    ></pofo-icon>
  </button>
</div>
