import {
  AppointmentLabCorpResponse,
  AppointmentTypeLabCorpResponse,
  LabCorpSlot
} from '../@types/appointment.interface';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { AppointmentType } from 'src/app/shared/models/appointment-type.model';
import { AppointmentTypes } from 'src/app/shared/@types/appointment.interface';
import { DateList } from 'src/app/components/datetime-picker/@types/datetime-picker.interface';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from 'src/app/services/http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { zonedTimeToUtc } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class AppointmentLabcorpService {
  private _http = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _appointmentService = inject(AppointmentService);
  private _environmentService = inject(EnvironmentService);

  public getLocations$(
    searchQ: string,
    page: number
  ): Observable<LoaderState<AppointmentTypes>> {
    return this._http.get<AppointmentTypeLabCorpResponse[], AppointmentTypes>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/labcorp/locations?zipCode=${searchQ}&radius=${page}`,
      (response) => ({
        totalCount: response.length,
        results: response.map(
          (result) =>
            new AppointmentType({
              id: result.id,
              zip: result.address.zip,
              city: result.address.city,
              addressLine1: result.address.line1,
              addressLine2: result.address.line2,
              name: result.name,
              appointmentTypeId:
                this._appointmentService.modalityAppointmentTypeId,
              timezone: result.address.timeZone,
              locationType: result.locationType
            })
        )
      })
    );
  }

  public getAppointment$(): Observable<
    LoaderState<AppointmentLabCorpResponse>
  > {
    return this._http
      .get<AppointmentLabCorpResponse, AppointmentLabCorpResponse>(
        `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/modality/${this._appointmentService.modalityId}/appointment-type/${this._appointmentService.appointmentTypeId}/appointment/${this._appointmentService.appointmentId}`,
        this._responseParser<AppointmentLabCorpResponse>
      )
      .pipe(
        map((res) => {
          const _res = res.value;

          if (_res) {
            this._appointmentService.setLocation({
              id: _res.location.id.toString(),
              zip: _res.location.address.zip,
              city: _res.location.address.city,
              addressLine1: _res.location.address.line1,
              addressLine2: _res.location.address.line2,
              name: _res.location.name,
              appointmentTypeId: _res.appointmentType.id,
              timezone: _res.location.address.timeZone
            });

            this._appointmentService.setAppointment({
              startDate: new Date(_res.startDate),
              endDate: new Date(_res.endDate),
              resourceId: _res.location.id.toString()
            });
          }

          return res;
        })
      );
  }

  public getAvailableDaysAndSlots$(
    startDate: string,
    numberOfDays: number
  ): Observable<LoaderState<DateList[]>> {
    return this._http.get<LabCorpSlot[], DateList[]>(
      `${this._environmentService.properties.apiGateway}/program/${this._workflowService.currentProgram}/labcorp/location/${this._appointmentService.selectedLocation.locationId}/slots?startDate=${startDate}&numberOfDays=${numberOfDays}`,
      (response) =>
        response.map((slot) => ({
          id: slot.id,
          start: zonedTimeToUtc(
            [
              slot.start.split('T').shift(),
              slot.start.split('T').pop()?.split('-').shift()
            ].join('T'),
            this._appointmentService.selectedLocation.timezone || ''
          ),
          end: zonedTimeToUtc(
            [
              slot.end.split('T').shift(),
              slot.end.split('T').pop()?.split('-').shift()
            ].join('T'),
            this._appointmentService.selectedLocation.timezone || ''
          )
        }))
    );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
