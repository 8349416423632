import {
  Participant,
  ParticipantData,
  ParticipantShopData
} from 'src/app/models/participant.model';
import {
  PersonalInformation,
  PersonalInformationForm
} from 'src/app/models/personal-information.model';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

const defaultUser: Participant = {
  pofoData: null,
  shopData: null,
  personalData: null
};

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  private participantSubject: BehaviorSubject<Participant> =
    new BehaviorSubject<Participant>(defaultUser);

  get participantValue(): Participant {
    return this.participantSubject.value;
  }

  clearParticipantFormData() {
    this.participantSubject.next({
      ...this.participantValue,
      shopData: null,
      personalData: null
    });
  }

  updateParticipantData(data: ParticipantData) {
    this.participantSubject.next({
      ...this.participantValue,
      pofoData: data
    });
  }

  updateParticipantShopData(data: ParticipantShopData) {
    this.participantSubject.next({
      ...this.participantValue,
      shopData: data
    });
  }

  updateParticipantPersonalData(
    data: PersonalInformationForm | PersonalInformation
  ) {
    const participantData: PersonalInformation = {
      firstName: this.participantValue.personalData?.firstName || '',
      lastName: this.participantValue.personalData?.lastName || '',
      ...data
    };

    this.participantSubject.next({
      ...this.participantValue,
      personalData: participantData
    });
  }
}
