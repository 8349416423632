import * as amplitude from '@amplitude/analytics-browser';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { CountryData, StateData } from 'src/app/models/shipping-config.model';
import {
  DropdownComponent,
  OptionItem
} from 'src/app/components/dropdown/dropdown.component';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { NgClass, ViewportScroller } from '@angular/common';
import { Order, OrderRequest } from 'src/app/models/order.model';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CheckboxComponent } from 'src/app/components/checkbox/checkbox.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { ErrorMessageComponent } from 'src/app/components/error-message/error-message.component';
import { InputComponent } from 'src/app/components/input/input.component';
import { LoaderState } from 'src/app/models/loading.model';
import { OrderService } from 'src/app/services/order/order.service';
import { ParticipantService } from 'src/app/services/participant/participant.service';
import { countryCodes as countryList } from 'src/app/utils/country-codes';
import { validatePostCode } from 'src/app/utils/validate-post-code';

@Component({
  selector: 'pofo-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CheckboxComponent,
    ContainerComponent,
    DropdownComponent,
    ErrorMessageComponent,
    FormsModule,
    InputComponent,
    NgClass,
    ReactiveFormsModule
  ]
})
export class DetailsComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  private participantService = inject(ParticipantService);
  private orderService = inject(OrderService);
  private viewportScroller = inject(ViewportScroller);

  @ViewChild('shippingFormElement')
  public shippingFormElement: ElementRef<HTMLFormElement>;

  readonly title = 'Shipping details';
  readonly defaultPhoneLengthValidator = Validators.minLength(7);
  readonly usPhoneLengthValidator = Validators.minLength(10);
  public countryCodes: OptionItem[] = [];

  public isLoading = true;
  public stateOptions: OptionItem[] = [];
  public states: StateData[] = [];
  public selectedCountry: CountryData;
  public allowedCountries: CountryData[] = [];
  public allowedPhoneCountries: string[] = [];
  public allowedCountriesDropdownOptions: OptionItem[] = [];
  public phoneNumberLabel = 'Phone number';

  private usingPhoneNumberValidatorForUS = false;

  programParticipantId?: number;
  shippingForm = this.fb.group(
    {
      fullName: ['', [Validators.required]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      countryName: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      postCode: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      phoneCountryCode: ['', [Validators.required]],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]*$/),
          this.defaultPhoneLengthValidator
        ]
      ],
      consent: [false]
    },
    {
      validators: (control) => validatePostCode(control, this.states)
    }
  );

  serverError: string | null = null;

  get postCodeErrorMessage(): string {
    const errorMap = [
      {
        formController: 'postCode',
        errorType: 'invalidPostCode',
        text: 'You have entered invalid ZIP code.'
      },
      {
        formController: 'postCode',
        errorType: 'required',
        text: 'ZIP code is required.'
      }
    ];

    for (const error of errorMap) {
      if (
        this.shippingForm?.errors?.[error.errorType] ||
        this.shippingForm.get(error.formController)?.errors?.[error.errorType]
      ) {
        return error.text;
      }
    }

    return '';
  }

  get postCodeHasError(): boolean {
    return (
      (!!this.shippingForm.get('postCode')?.errors?.['required'] ||
        !!this.shippingForm.errors?.['invalidPostCode']) &&
      !!this.shippingForm.controls['postCode']?.touched
    );
  }

  ngOnInit(): void {
    this.programParticipantId =
      this.participantService.participantValue.pofoData?.participantId;
    const fullName = this.getFullNameFromParticipantData();
    if (fullName) {
      this.shippingForm.get('fullName')?.setValue(fullName);
    }

    this.getShippingConfig();
  }

  getFullNameFromParticipantData(): string {
    if (this.participantService.participantValue.personalData) {
      const personalInformation =
        this.participantService.participantValue.personalData;
      if (personalInformation.firstName && personalInformation.lastName) {
        return `${personalInformation.firstName} ${personalInformation.lastName}`;
      }
    }

    return '';
  }

  getShippingConfig() {
    this.orderService
      .getShippingConfiguration()
      .pipe(
        take(2),
        tap((res) => (this.isLoading = res.loading || false))
      )
      .subscribe((response) => {
        if (response.value?.countries && response.value?.countries.length) {
          this.allowedCountries = response.value.countries;
          this.allowedCountriesDropdownOptions = this.allowedCountries.map(
            (entry) => {
              return {
                label: entry.name,
                value: entry.code
              };
            }
          );

          if (this.allowedCountries.length === 1) {
            this.selectedCountry = this.allowedCountries[0];
            this.shippingForm
              .get('countryCode')
              ?.setValue(this.selectedCountry.code);
            this.shippingForm
              .get('countryName')
              ?.setValue(this.selectedCountry.name);
            const phoneCode = countryList.find(
              (country) => country.isoCode === this.selectedCountry.code
            )?.dialCode;

            this.setPhoneNumberValidators(phoneCode ?? '');
            this.updateStatesOptions();
          }
        }

        if (response.value?.contactRestrictions) {
          if (response.value.contactRestrictions.allowedCountries.length > 0) {
            this.allowedPhoneCountries =
              response.value.contactRestrictions.allowedCountries;
          }
          if (response.value.contactRestrictions.phoneLabel) {
            this.phoneNumberLabel =
              response.value.contactRestrictions.phoneLabel;
          }
        }

        if (response.value?.shippingDetails) {
          const sd = response.value.shippingDetails;

          if (!sd.fullName) {
            sd.fullName = this.getFullNameFromParticipantData();
          }

          // check if saved state is present on states
          // if present, leave selected
          // if not present, clear state, address lines and city
          const allowedInProgram = this.stateOptions.some(
            (state) => state.value === sd.state
          );
          if (!allowedInProgram) {
            sd.state = '';
            sd.addressLine1 = '';
            sd.addressLine2 = '';
            sd.postCode = '';
            sd.city = '';
          }

          if (this.stateOptions.length === 1) {
            sd.state = this.stateOptions[0].value;
          }

          if (!sd.countryCode) {
            if (this.allowedCountries.length > 0) {
              if (this.allowedCountries.length === 1) {
                sd.countryCode = this.selectedCountry.code;
                sd.countryName = this.selectedCountry.name;
              }
            } else {
              this.serverError =
                'Invalid data stored (country name or code). Please try again or contact the LetsGetChecked support team.';
            }
          } else if (!sd.countryName) {
            const country = countryList.find(
              (country) => country.isoCode === sd.countryCode
            );

            if (country) {
              sd.countryName = country.name;
            } else {
              this.serverError =
                'Invalid data stored (country name or code). Please try again or contact the LetsGetChecked support team.';
            }
          }

          this.shippingForm.setValue({ ...sd, consent: false });
          this.setPhoneNumberValidators(sd.phoneCountryCode);
        }

        this.parseCountryCodes();
      });
  }

  setPhoneNumberValidators(phoneCountryCode: string): void {
    this.shippingForm.get('phoneCountryCode')?.setValue(phoneCountryCode);
    if (phoneCountryCode === '+1') {
      if (this.usingPhoneNumberValidatorForUS) {
        return;
      }
      this.shippingForm.get('phoneNumber')?.clearValidators();
      this.shippingForm
        .get('phoneNumber')
        ?.setValidators([
          Validators.required,
          this.usPhoneLengthValidator,
          Validators.pattern(/^[0-9]*$/)
        ]);
      this.shippingForm.get('phoneNumber')?.updateValueAndValidity();
      this.usingPhoneNumberValidatorForUS = true;
    } else if (this.usingPhoneNumberValidatorForUS) {
      this.shippingForm.get('phoneNumber')?.clearValidators();
      this.shippingForm
        .get('phoneNumber')
        ?.setValidators([
          Validators.required,
          this.defaultPhoneLengthValidator,
          Validators.pattern(/^[0-9]*$/)
        ]);
      this.shippingForm.get('phoneNumber')?.updateValueAndValidity();
      this.usingPhoneNumberValidatorForUS = false;
    }
  }

  parseCountryCodes(): void {
    // If there is a set on allowed countries, filter countryCodes to only include allowed countries
    if (this.allowedPhoneCountries.length > 0) {
      this.countryCodes = countryList.reduce((accumulator, currentValue) => {
        if (this.allowedPhoneCountries.includes(currentValue.isoCode)) {
          return [
            ...accumulator,
            {
              label: `${currentValue.name} (${currentValue.dialCode})`,
              value: currentValue.dialCode
            }
          ];
        }
        return accumulator;
      }, [] as OptionItem[]);
      if (this.countryCodes.length === 1) {
        this.shippingForm
          .get('phoneCountryCode')
          ?.setValue(this.countryCodes[0].value);
      }
    } else {
      // parse full country codes list
      this.countryCodes = countryList.map((entry) => {
        return {
          label: `${entry.name} (${entry.dialCode})`,
          value: entry.dialCode
        };
      });
    }
  }

  onShipingCountryChange(countryCode: string): void {
    // options come from the allowedCountries array, so selectedCountryIndex will always exist
    const selectedCountryIndex = this.allowedCountries.findIndex(
      (country) => country.code === countryCode
    );
    this.selectedCountry = this.allowedCountries[selectedCountryIndex];
    this.shippingForm.get('countryCode')?.setValue(this.selectedCountry.code);
    this.shippingForm.get('countryName')?.setValue(this.selectedCountry.name);
    this.shippingForm.get('state')?.setValue('');
    this.updateStatesOptions();
  }

  onPhoneCountryCodeChange(phoneCountryCode: string): void {
    this.shippingForm.get('phoneCountryCode')?.setValue(phoneCountryCode);
    this.setPhoneNumberValidators(phoneCountryCode);
  }

  updateStatesOptions() {
    if (this.selectedCountry) {
      this.states = this.selectedCountry.states;
      this.stateOptions = this.selectedCountry.states.map((state) => ({
        label: state.label,
        value: state.value
      }));
      if (this.selectedCountry.states.length === 0) {
        this.shippingForm.get('state')?.clearValidators();
        this.shippingForm.get('state')?.updateValueAndValidity();
      } else {
        this.shippingForm.get('state')?.setValidators([Validators.required]);
        if (this.selectedCountry.states.length === 1) {
          const selectedState = this.selectedCountry.states[0];
          this.shippingForm.get('state')?.setValue(selectedState.value);
        }
      }
    }
  }

  onSubmitForm() {
    this.serverError = null;

    if (this.shippingForm.valid) {
      const order: OrderRequest = {
        programParticipantId: this.programParticipantId,
        ...this.shippingForm.value
      };

      this.orderService
        .createOrder(order)
        .pipe(
          take(2),
          tap((res) => (this.isLoading = res.loading || false))
        )
        .subscribe((response: LoaderState<Order>) => {
          if (response.error) {
            this.serverError =
              response.errorMessage ||
              'Sorry, something went wrong, please try that again.';
            this.viewportScroller.scrollToPosition([0, 0]);
          }

          if (response.onCompleteCallback) {
            amplitude.track('click confirm and continue on shipping', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'order forms (pofo)'
            });
            response.onCompleteCallback();
          }
        });
    } else {
      const firstInvalid = this.shippingFormElement.nativeElement.querySelector(
        'form .ng-invalid input, form .ng-invalid select'
      ) as HTMLElement;

      if (firstInvalid) {
        firstInvalid.focus();
      }
    }
  }

  public isDisabled(): boolean {
    return this.shippingForm.invalid || this.isLoading;
  }
}
