import * as amplitude from '@amplitude/analytics-browser';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { CheckboxComponent } from 'src/app/components/checkbox/checkbox.component';
import { Component } from '@angular/core';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { PhysicianFormService } from 'src/app/shared/services/physician-form/physician-form.service';
import { ProgramService } from 'src/app/services/program/program.service';
import { UrlParamsService } from 'src/app/shared/services/url-params/url-params.service';

@Component({
  selector: 'pofo-physician-form-upload-confirm',
  templateUrl: './upload-confirm.component.html',
  styleUrls: ['./upload-confirm.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CheckboxComponent,
    ContainerComponent,
    IconComponent,
    ReactiveFormsModule,
    RouterLink
  ]
})
export class PhysicianFormConfirmUploadComponent {
  private readonly _physicianFormId =
    this._urlParamsService.get('physicianFormId');
  private readonly _modalityId = this._urlParamsService.get('modalityId');
  public readonly patient$ = this._physicianFormService.getPatient$();
  public isLoading = false;
  public errorMessage?: string;

  public images: {
    index: number;
    name: string;
    image: string | ArrayBuffer | null | undefined;
    fileType: string;
  }[] = [];

  public contactDetailsForm = this._fb.group({
    consent: [false, [Validators.requiredTrue]]
  });

  constructor(
    private readonly _physicianFormService: PhysicianFormService,
    private readonly _fb: UntypedFormBuilder,
    private readonly _urlParamsService: UrlParamsService,
    private readonly _programService: ProgramService,
    private readonly _router: Router
  ) {
    this._physicianFormService.filesToUpload.forEach((file, index) => {
      const fileType = RegExp(/\.[0-9a-z]+$/i).exec(file.name);

      if (fileType?.[0]) {
        if (fileType[0].includes('pdf')) {
          this.images.push({
            index: index,
            name: file.name,
            image: 'pdf-file',
            fileType: fileType[0]
          });
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            this.images.push({
              index: index,
              name: file.name,
              image: e.target?.result,
              fileType: fileType[0]
            });
          };

          reader.readAsDataURL(file);
        }
      }
    });
  }

  public isDisabled(): boolean {
    return (
      !this.contactDetailsForm.get('consent')?.value ||
      this.errorMessage !== undefined ||
      this.isLoading
    );
  }

  public confirm(): void {
    if (!this._modalityId || !this._physicianFormId) {
      amplitude.track('click confirm and send my result', {
        location: window?.location.href,
        origin: 'pofo',
        category: 'physician forms (pofo)',
        errorMessage: `missing modalityId or physicianFormId`
      });

      this._router.navigate(['/something-went-wrong']);
      return;
    }

    amplitude.track('click confirm and send my result', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'physician forms (pofo)'
    });

    this._physicianFormService
      .uploadForm$()
      .pipe(
        take(2),
        tap((_res) => {
          this.errorMessage = _res.error ? _res.errorMessage : undefined;
          this.isLoading = _res.loading || false;
        })
      )
      .subscribe((res) => {
        if (!res.loading && res.onCompleteCallback) {
          this._programService.showSteps$.next(false);
          res.onCompleteCallback();
        }
      });
  }
}
