/* eslint-disable sort-imports */
import { i18nHttpLoaderFactory } from './app/app.module';
import { EnvironmentService } from './environments/environment.service';
import {
  enableProdMode,
  APP_INITIALIZER,
  importProvidersFrom
} from '@angular/core';
import { initializeAwsRum } from './app/initializeAwsRum';
import { environment } from 'src/environments/environment';
import {
  initializeAppFactory,
  updateBearerTokenFactory,
  getUrlParamsFactory
} from './app/app.init';
import { AppInitService } from './app/app.init.service';
import { UrlParamsService } from './app/shared/services/url-params/url-params.service';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient
} from '@angular/common/http';
import {
  AuthInterceptor,
  AbstractSecurityStorage,
  AbstractLoggerService,
  provideAuth,
  LogLevel
} from 'angular-auth-oidc-client';
import { PatientInterceptor } from './app/shared/services/patient/patient.interceptor';
import { DefaultLocalStorageService } from './app/modules/auth/services/default-local-storage.service';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ComponentsModule } from 'src/app/components/components.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { AppComponent } from 'src/app/app.component';
import { OIDCLoggerService } from './app/modules/auth/services/logger.service';

const environmentService = new EnvironmentService();
initializeAwsRum();

if (environmentService.properties.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      CommonModule,
      AngularSvgIconModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: i18nHttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      ComponentsModule,
      MatNativeDateModule
    ),
    provideAuth({
      config: {
        authority: environment.get().authorityURL,
        redirectUrl: `${window.location.origin}/auth/callback`,
        postLogoutRedirectUri: `${window.location.origin}/logout`,
        clientId: 'pofoClient',
        scope: 'openid offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        triggerAuthorizationResultEvent: true,
        logLevel: LogLevel.Warn,
        secureRoutes: [
          environment.get().apiGateway,
          environment.get().apiGatewayV2,
          environment.get().healthGateway
        ]
      }
    }),
    { provide: 'googleTagManagerId', useValue: environment.get().gtmId },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: updateBearerTokenFactory,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: getUrlParamsFactory,
      deps: [UrlParamsService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PatientInterceptor, multi: true },
    {
      provide: AbstractSecurityStorage,
      useClass: DefaultLocalStorageService
    },
    { provide: AbstractLoggerService, useClass: OIDCLoggerService },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch((err) => console.error(err));
