import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { IconComponent } from '../icon/icon.component';
import { NgClass } from '@angular/common';
import { OptionFilterPipe } from 'src/app/shared/pipes/option-filter.pipe';
import { OptionItem } from '../dropdown/dropdown.component';

@Component({
  selector: 'pofo-searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableDropdownComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [NgClass, IconComponent, DropdownItemComponent, OptionFilterPipe]
})
export class SearchableDropdownComponent
  implements ControlValueAccessor, AfterViewInit
{
  @ViewChildren(DropdownItemComponent) items: QueryList<DropdownItemComponent>;
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('optionsContainer') optionsContainer: ElementRef;
  @Input() optionItems: OptionItem[] = [];
  @Input() name = '';
  @Input() label = '';
  @Input() placeholder?: string;
  @Input() hasError = false;
  @Input() isDisabled = false;
  @Input() value = '';
  @Input() errorMsgId: string;
  private keyManager: ActiveDescendantKeyManager<DropdownItemComponent>;
  private hasClickSelected = false;
  isFocused = false;
  isOpen = false;
  selectedLabel = '';

  ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.items)
      .withWrap()
      .withTypeAhead();
  }

  public trackFn(i: number, option: OptionItem): string {
    return `option-${i}-${option.value}`;
  }

  onNavKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.value = this.keyManager.activeItem?.item?.value || '';
        this.selectedLabel = this.keyManager.activeItem?.item?.label || '';
        this.onChange(this.value);
        this.isOpen = false;
        break;
      case 'ArrowDown':
        if (this.isOpen) {
          this.keyManager.onKeydown(event);
          this.scrollIfOverflowed();
        } else {
          this.isOpen = true;
        }
        break;
      case 'Escape':
        this.isOpen = false;
        break;
      default:
        this.keyManager.onKeydown(event);
        this.scrollIfOverflowed();
        break;
    }
  }

  scrollIfOverflowed(): void {
    const element = this.optionsContainer?.nativeElement.querySelector(
      `[data-identifier="SelectOption-${this.keyManager.activeItem?.item.value}"`
    );
    if (element) {
      const eleTop = element.offsetTop;
      const eleBottom = eleTop + element.clientHeight;

      const containerTop = this.optionsContainer.nativeElement.scrollTop;
      const containerBottom =
        containerTop + this.optionsContainer.nativeElement.clientHeight;

      // The element is fully visible in the container
      const isVisible =
        (eleTop >= containerTop && eleBottom <= containerBottom) ||
        // Some part of the element is visible in the container
        (eleTop < containerTop && containerTop < eleBottom) ||
        (eleTop < containerBottom && containerBottom < eleBottom);

      if (!isVisible) {
        this.optionsContainer.nativeElement.scrollTop = eleTop;
      }
    }
  }

  /* istanbul ignore next */
  onChange = (_: string) => {};
  /* istanbul ignore next */
  onTouch = () => {};

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  onValueChanged() {
    this.onChange(this.value.trim());
  }

  onContainerClick(): void {
    if (!this.isDisabled && (!this.isFocused || !this.isOpen)) {
      this.searchField.nativeElement.focus();
      this.onFocus();
    }
  }

  onFocus(): void {
    this.isFocused = true;
    this.isOpen = true;
  }

  onBlur(): void {
    if (this.hasClickSelected) {
      this.searchField.nativeElement.focus();
    } else {
      this.isFocused = false;
    }
    this.isOpen = false;
    this.hasClickSelected = false;
    this.onTouch();
  }

  onOptionClicked(option: OptionItem): void {
    this.isOpen = false;
    this.value = option.value;
    this.onChange(this.value);
    this.selectedLabel = option.label;
    this.hasClickSelected = true;
  }

  onOptionKeyUp(): void {
    this.searchField.nativeElement.focus();
  }
}
