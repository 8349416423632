import {
  ContactDetailsResponse,
  ContactDetailsWithConsent
} from 'src/app/models/contact-details.model';
import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from 'src/app/models/loading.model';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {
  private httpService = inject(HttpService);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  public getContactDetails(): Observable<LoaderState<ContactDetailsResponse>> {
    return this.httpService
      .get<ContactDetailsResponse, ContactDetailsResponse>(
        `${this._environmentService.properties.apiGateway}/reseller/${this._workflowService.currentProgram}/contact-details`,
        this._responseParser<ContactDetailsResponse>
      )
      .pipe(take(2));
  }

  public submitDetails(
    formData: ContactDetailsWithConsent
  ): Observable<LoaderState<null>> {
    return this.httpService
      .post<null, null>(
        `${this._environmentService.properties.apiGateway}/reseller/${this._workflowService.currentProgram}/contact-details`,
        formData,
        this._responseParser<null>
      )
      .pipe(
        take(2),
        map((response) => {
          if (!response.loading && response.success) {
            if (response.onCompleteCallback) {
              response?.onCompleteCallback();
            }
          }

          return response;
        })
      );
  }

  private _responseParser<T>(response: T): T {
    return response;
  }
}
