import { Environment } from './environment';

declare const APPVEYOR_BUILD_VERSION: string;

export const environment: Environment = {
  production: true,
  origin: 'https://www.letsgetchecked.com',
  apiGateway: 'https://order-flow-<region>.letsgetchecked.com/api/v1',
  apiGatewayV2: 'https://order-flow-<region>.letsgetchecked.com/api/v2',
  healthGateway:
    'https://health-gateway-mobile.letsgetchecked.com/us/api/v2/patient/ensure-created',
  assetPath: getAssetPath(),
  oneTrustId: '198b51f6-5b37-4b63-a601-810a86d648f0',
  gtmId: 'GTM-WVQ23Q',
  appIconPath: getAppIconPath(),
  recaptchaSiteKey: '6Lc1ko4iAAAAAJ93wyVOOek90PCqg4Nxgw4E9k2O',
  authorityURL: 'https://identityprovider.letsgetchecked.com',
  patientDashboardURL: 'https://www.letsgetchecked.com/account',
  geneticsHubURL: 'https://www.letsgetchecked.com/genetics-hub',
  stripe: {
    us: 'pk_live_38AlyTPqucaJMFZg2V5AN3qi',
    eu: 'pk_live_yq1ElUxviQnS26IrAvqzcooU'
  },
  amplitudeKey: '23830495c9a2463784525e3bf55f1047',
  isDev: false
};

function getAssetPath() {
  try {
    return decodeURIComponent(`${APPVEYOR_BUILD_VERSION}/assets/`).replace(
      /\s/g,
      ''
    );
  } catch {
    return 'unittests'; // hacky fix  - wasted hours trying to get testing framework to work with webpack. instead made it default to this
  }
}

function getAppIconPath() {
  try {
    return decodeURIComponent(`${APPVEYOR_BUILD_VERSION}/`).replace(/\s/g, '');
  } catch {
    return 'unittests'; // hacky fix  - wasted hours trying to get testing framework to work with webpack. instead made it default to this
  }
}
