import { Injectable, inject } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import {
  ProductQuantityConfiguration,
  QuantitySubmit
} from '../../models/quantity.model';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpService } from '../http/http.service';
import { LoaderState } from '../../models/loading.model';
import { Router } from '@angular/router';
import { WorkflowService } from '../workflow/workflow.service';

@Injectable({
  providedIn: 'root'
})
export class QuantityService {
  private _httpService = inject(HttpService);
  private _router = inject(Router);
  private _workflowService = inject(WorkflowService);
  private _environmentService = inject(EnvironmentService);

  private _testKitQuantities: QuantitySubmit = { testQuantities: [] };

  public get testKits(): QuantitySubmit {
    return this._testKitQuantities;
  }

  public getProducts(): Observable<ProductQuantityConfiguration[]> {
    return this._httpService
      .get<ProductQuantityConfiguration[], ProductQuantityConfiguration[]>(
        `${this._environmentService.properties.apiGateway}/testkit/${this._workflowService.currentProgram}/availability`,
        (response: ProductQuantityConfiguration[]) => response
      )
      .pipe(
        take(2),
        map((response: LoaderState<ProductQuantityConfiguration[]>) => {
          if (response.value) {
            this._testKitQuantities.testQuantities = [];
            response.value.forEach((product) => {
              this._testKitQuantities.testQuantities.push({
                testKitCode: product.testKit.code,
                quantity: product.minLimit
              });
            });
            return response.value;
          }

          return [];
        })
      );
  }

  public submitQuantities(
    quantities: QuantitySubmit
  ): Observable<LoaderState<boolean>> {
    return this._httpService
      .post(
        `${this._environmentService.properties.apiGateway}/testkit/${this._workflowService.currentProgram}/save-test-quantities`,
        quantities,
        (response: boolean) => response
      )
      .pipe(
        take(2),
        map((response) => {
          if (!response.loading && response.success && response.value) {
            if (response.onCompleteCallback) {
              response?.onCompleteCallback();
            }
          }

          return response;
        })
      );
  }
}
