import * as amplitude from '@amplitude/analytics-browser';
import {
  AppointmentConfirmationResponse,
  AppointmentCreatePayload,
  AppointmentRescheduleResponse
} from 'src/app/shared/@types/appointment.interface';
import { Component, inject } from '@angular/core';
import { take, tap } from 'rxjs';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { IconComponent } from 'src/app/components/icon/icon.component';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SyncVisitService } from 'src/app/shared/services/sync-visit/sync-visit.service';

@Component({
  selector: 'pofo-appointment-sync-visit-confirm',
  templateUrl: './confirm.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    IconComponent,
    AlertComponent,
    NgTemplateOutlet,
    RouterLink
  ]
})
export class AppointmentSyncVisitConfirmComponent {
  private _appointmentService = inject(AppointmentService);
  private _syncVisitService = inject(SyncVisitService);

  public readonly title = 'Your Appointment';
  public readonly description =
    'Review and confirm all details of your appointment.';
  public readonly appointment = this._appointmentService.appointment;
  public readonly selectedLocation = this._appointmentService.selectedLocation;
  public readonly phoneNumber = this._syncVisitService.phoneNumber;
  public readonly parsedDate =
    this._appointmentService.appointment.parsedStartDate();
  public isLoading = false;
  public errorMessage?: string;

  public isBtnDisable(): boolean {
    return this.errorMessage !== undefined || this.isLoading;
  }

  public finishBooking(): void {
    amplitude.track('click finish sync visit booking', {
      location: window?.location.href,
      origin: 'pofo',
      category: 'appointments (pofo)'
    });

    const appointmentService$ = this._appointmentService.isReschedule
      ? this._appointmentService.rescheduleAppointment$<
          AppointmentCreatePayload,
          AppointmentRescheduleResponse
        >(this._appointmentService.appointment.create())
      : this._appointmentService.appointmentConfirmation$<
          AppointmentCreatePayload,
          AppointmentConfirmationResponse
        >(
          this._appointmentService.appointment.create(
            this._syncVisitService.syncVisitId
          )
        );

    appointmentService$
      .pipe(
        take(2),
        tap((res) => {
          if (res.error) {
            this.errorMessage = res.errorMessage ?? 'There was an error';

            amplitude.logEvent('error', {
              location: window?.location.href,
              origin: 'pofo',
              category: 'appointments (pofo)',
              errorMessage: this.errorMessage
            });
          } else {
            this.errorMessage = undefined;
          }

          this.isLoading = res.loading ?? false;
        })
      )
      .subscribe((res) => {
        if (res.onCompleteCallback) {
          amplitude.track('sync visit appointment booked', {
            location: window?.location.href,
            origin: 'pofo',
            category: 'appointments (pofo)'
          });

          res.onCompleteCallback();
        }
      });
  }
}
