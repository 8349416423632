import {
  APPOINTMENT_CONFIRMATION_ROUTE,
  APPOINTMENT_ROUTE,
  AUTH_DIRECT_ROUTE,
  AUTH_ROUTE,
  AUTH_SSO_ROUTE,
  AUTO_VERIFICATION_ROUTE,
  CONSENT_ROUTE,
  CONSENT_ROUTE_ADDITIONAL,
  CONTACT_DETAILS_ROUTE,
  IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE,
  IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE,
  IMMUNIZATIONAPPOINTMENT_ROUTE,
  INSURANCE_ROUTE,
  LIMITS_REACHED,
  LOGOUT_ROUTE,
  NOT_FOUND,
  ORDER_CONFIRMED_ROUTE,
  ORDER_REQUEST_ROUTE,
  PAYMENT_ROUTE,
  PCP_DETAILS_ROUTE,
  PERSONAL_INFORMATION_ROUTE,
  PHONE_NUMBER_ROUTE,
  PHYSICIANFORM_CONFIRMATION_ROUTE,
  PHYSICIANFORM_ROUTE,
  QUANTITY_SET_ROUTE,
  QUESTIONNAIRE,
  QUESTIONNAIRE_ELIGIBILITY,
  SHIPPING_INFORMATION_ROUTE,
  START_JOURNEY_ROUTE,
  STATE_CONFIRMATION_ROUTE,
  UNAUTHORIZED,
  WELCOME_ROUTE
} from 'src/app/app-routes';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import { ApiResponseModel } from 'src/app/models/api-response.model';
import { EnvironmentService } from 'src/environments/environment.service';
import { HttpStatusCode } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { WorkflowResponseModel } from 'src/app/models/workflow-response-model';
import { WorkflowStepsEnum } from './workflow-steps.enum';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private router = inject(Router);
  private _environment = inject(EnvironmentService);

  public currentProgram = '';
  public programId = '';
  public programRegion = 'us';
  public programType = 'program';
  public currentFlow$: ReplaySubject<string> = new ReplaySubject(1);
  public currentFlow: string;
  public currentStep: WorkflowStepsEnum;
  private _eojCMSPages = {
    authenticationError: `${this._environment.properties.origin}/authentication-error`,
    notProgramEligibleOptum: `${this._environment.properties.origin}/patient-non-eligible-optum`,
    notProgramEligibleJPMC: `${this._environment.properties.origin}/sso-non-eligible-jpmc`
  };

  constructor() {
    this.currentFlow$.subscribe(
      (currentFlow) => (this.currentFlow = currentFlow)
    );

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const url = e.urlAfterRedirects.split('?')[0];
        const urlFragments = url.split('/');
        this.currentFlow$.next(urlFragments.slice(4).join('/'));
        if (
          urlFragments.length > 3 &&
          urlFragments[3] != 'sso' &&
          this.currentProgram !== urlFragments[3]
        ) {
          this.currentProgram = this.router.url.split('/')[3];
        }
      }
    });
  }

  public isJpmc(): boolean {
    return (
      this.programId.toLowerCase() === '5b99a9c8-7b2a-4dfe-934e-c0b178f6e83d' ||
      this.programId.toLowerCase() === '97a4b030-fc51-4f2b-abe0-1f3f357e411a'
    );
  }

  public setSegments(segments: UrlSegment[]) {
    this.programRegion = segments[0].path;
    this.programType = segments[1].path;
    if (segments.length > 2) {
      this.currentProgram = segments[2].path;
    }
  }

  public handleSuccessfulApiResponse(
    response: null | ApiResponseModel
  ): WorkflowResponseModel {
    let onCompleteCallback: (() => void) | undefined;

    if (response) {
      if ('stepConfiguration' in response) {
        return {
          payload: response
        };
      } else if (response.redirectToExternalUrl) {
        const redirectToExternalUrl = response.redirectToExternalUrl;

        onCompleteCallback = () =>
          this.redirectToExternalUrl(redirectToExternalUrl);
      } else if (response.nextStep) {
        const nextStep = response.nextStep;

        if (
          response.payload &&
          response.payload['resellerSlug'] &&
          response.payload['resellerSlug'] !== this.currentProgram
        ) {
          onCompleteCallback = () =>
            this.redirectToRoute(
              nextStep,
              undefined,
              false,
              response.payload['resellerSlug'],
              'client'
            );
        } else {
          onCompleteCallback = () => this.redirectToRoute(nextStep);
        }
      }
    }

    return {
      onCompleteCallback: onCompleteCallback,
      payload: response?.payload ? response.payload : response
    };
  }

  public handleApiErrorResponse(
    response: ApiResponseModel,
    status: number,
    reqUrl: string | null
  ): WorkflowResponseModel {
    let errorMessage: string | undefined = 'An unexpected error occurred.';
    try {
      if (response?.redirectToExternalUrl) {
        this.redirectToExternalUrl(response.redirectToExternalUrl);
        errorMessage = undefined;
      } else if (response?.nextStep) {
        if (
          status === HttpStatusCode.Unauthorized &&
          response.payload &&
          response.payload['resellerSlug']
        ) {
          if (response.payload['resellerSlug'] === this.currentProgram) {
            this.redirectToRoute(response.nextStep);
          } else {
            const externalProgramUrl = `${location.origin}/${this.getNextRoute(
              response.nextStep,
              response.payload['resellerSlug'],
              'client'
            ).join('/')}`;
            this.redirectToExternalUrl(externalProgramUrl);
          }
        } else {
          let replaceUrl = false;

          if (
            response.nextStep == WorkflowStepsEnum.NotFound ||
            response.nextStep == WorkflowStepsEnum.Forbidden
          ) {
            replaceUrl = true;
          }

          this.redirectToRoute(response.nextStep, response?.error, replaceUrl);
        }
      } else if (
        reqUrl === this._environment.properties.healthGateway &&
        status === HttpStatusCode.Forbidden
      ) {
        const notProgramEligibleUrl = this.isJpmc()
          ? this._eojCMSPages.notProgramEligibleJPMC
          : this._eojCMSPages.notProgramEligibleOptum;

        this.redirectToExternalUrl(notProgramEligibleUrl);
      } else if (status === HttpStatusCode.Unauthorized) {
        this.redirectToExternalUrl(this._eojCMSPages.authenticationError);
      } else if (status === HttpStatusCode.Forbidden) {
        this.redirectToRoute(
          WorkflowStepsEnum.Forbidden,
          response?.error,
          true
        );
      } else if (status === HttpStatusCode.NotFound) {
        this.redirectToRoute(WorkflowStepsEnum.NotFound, response?.error, true);
      } else if (response?.error) {
        errorMessage = response.error;
      }
    } catch {}

    return {
      error: errorMessage
    };
  }

  public redirectToRoute(
    nextStep: WorkflowStepsEnum,
    errorMessage?: string,
    replaceUrl = false,
    externalProgramSlug?: string,
    externalProgramType?: 'client' | 'program'
  ): void {
    this.router.navigate(
      [
        ...this.getNextRoute(nextStep, externalProgramSlug, externalProgramType)
      ],
      {
        replaceUrl,
        state: { errorMessage }
      }
    );
  }

  /* istanbul ignore next */
  public redirectToExternalUrl(externalUrl: string): void {
    window.location.href = externalUrl;
  }

  private getNextRoute(
    step: WorkflowStepsEnum,
    program?: string,
    type?: 'client' | 'program'
  ) {
    this.currentStep = step;

    const programDetails = [
      this.programRegion,
      type || this.programType,
      program || this.currentProgram
    ];

    switch (step) {
      case WorkflowStepsEnum.Verification:
        return [...programDetails, ORDER_REQUEST_ROUTE];
      case WorkflowStepsEnum.ShopRegistration:
        return [...programDetails, AUTH_ROUTE, AUTH_DIRECT_ROUTE];
      case WorkflowStepsEnum.Authentication:
        return [this.programRegion, AUTH_ROUTE, AUTH_SSO_ROUTE];
      case WorkflowStepsEnum.StartJourney:
        return [...programDetails, START_JOURNEY_ROUTE];
      case WorkflowStepsEnum.DomainConsent:
        return [...programDetails, CONSENT_ROUTE];
      case WorkflowStepsEnum.Consent:
        return [...programDetails, CONSENT_ROUTE, 'account', CONSENT_ROUTE];
      case WorkflowStepsEnum.Consent1:
        return [
          ...programDetails,
          CONSENT_ROUTE,
          'account',
          CONSENT_ROUTE_ADDITIONAL
        ];
      case WorkflowStepsEnum.PatientArea:
        return [...programDetails, STATE_CONFIRMATION_ROUTE];
      case WorkflowStepsEnum.PatientPhoneNumber:
        return [...programDetails, PHONE_NUMBER_ROUTE];
      case WorkflowStepsEnum.ShippingInformation:
        return [...programDetails, SHIPPING_INFORMATION_ROUTE];
      case WorkflowStepsEnum.OrderConfirmation:
        return [...programDetails, ORDER_CONFIRMED_ROUTE];
      case WorkflowStepsEnum.AssessmentQuestionnaire:
        return [...programDetails, QUESTIONNAIRE, QUESTIONNAIRE_ELIGIBILITY];
      case WorkflowStepsEnum.Questionnaire:
        return [...programDetails, QUESTIONNAIRE];
      case WorkflowStepsEnum.QuantitySelection:
        return [...programDetails, QUANTITY_SET_ROUTE];
      case WorkflowStepsEnum.Insurance:
        return [...programDetails, INSURANCE_ROUTE];
      case WorkflowStepsEnum.PersonalInformation:
        return [...programDetails, PERSONAL_INFORMATION_ROUTE];
      case WorkflowStepsEnum.Appointment:
        return [...programDetails, APPOINTMENT_ROUTE];
      case WorkflowStepsEnum.AppointmentConfirmation:
        return [...programDetails, APPOINTMENT_CONFIRMATION_ROUTE];
      case WorkflowStepsEnum.PhysicianForm:
        return [...programDetails, PHYSICIANFORM_ROUTE];
      case WorkflowStepsEnum.PhysicianFormConfirmation:
        return [
          ...programDetails,
          PHYSICIANFORM_ROUTE,
          PHYSICIANFORM_CONFIRMATION_ROUTE
        ];
      case WorkflowStepsEnum.PCPDetails:
        return [...programDetails, PCP_DETAILS_ROUTE];
      case WorkflowStepsEnum.ContactDetails:
        return [...programDetails, CONTACT_DETAILS_ROUTE];
      case WorkflowStepsEnum.Payment:
        return [...programDetails, PAYMENT_ROUTE];
      case WorkflowStepsEnum.Welcome:
        return [...programDetails, WELCOME_ROUTE];
      case WorkflowStepsEnum.ImmunizationConsent:
        return [
          ...programDetails,
          IMMUNIZATIONAPPOINTMENT_ROUTE,
          CONSENT_ROUTE
        ];
      case WorkflowStepsEnum.ImmunizationAppointment:
        return [
          ...programDetails,
          IMMUNIZATIONAPPOINTMENT_ROUTE,
          IMMUNIZATIONAPPOINTMENT_APPOINTMENT_ROUTE
        ];
      case WorkflowStepsEnum.ImmunizationAppointmentConfirmation:
        return [
          ...programDetails,
          IMMUNIZATIONAPPOINTMENT_ROUTE,
          IMMUNIZATIONAPPOINTMENT_CONFIRMATION_ROUTE
        ];
      case WorkflowStepsEnum.AutoVerification:
        return [...programDetails, AUTO_VERIFICATION_ROUTE];
      case WorkflowStepsEnum.Forbidden:
        return [UNAUTHORIZED];
      case WorkflowStepsEnum.LimitsReached:
        return [LIMITS_REACHED];
      case WorkflowStepsEnum.NotFound:
        return [NOT_FOUND];
      case WorkflowStepsEnum.WorkflowFailure:
        return [];
      case WorkflowStepsEnum.Logout:
        return [AUTH_ROUTE, LOGOUT_ROUTE];
      default:
        throw new Error('An unexpected routing error has occurred.');
    }
  }
}
