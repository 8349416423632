<div class="my-12 md:my-16 md:w-[540px] mx-4 md:mx-auto">
  @if (!!serverError) {
  <pofo-alert type="error" data-test="serverErrorMsg">
    {{ serverError }}
  </pofo-alert>
  }

  <pofo-container containerTitle="Check your insurance coverage">
    <p class="text-base text-slate text-center md:text-left">
      Confirm your insurance status and eligibility to order this test.
    </p>

    <div>
      <form
        #insuranceFormElement
        [formGroup]="insuranceForm"
        (ngSubmit)="onSubmitForm()"
      >
        <div formGroupName="patient">
          <div
            class="flex flex-col md:flex-row items-center justify-between mt-4"
          >
            <div class="w-full md:w-1/2 mb-3 md:mr-3">
              <pofo-input
                name="firstName"
                label="First name"
                type="text"
                [hasError]="formHasErrors && !!insuranceForm.get('firstName')?.errors?.['required']"
                formControlName="firstName"
                data-test="firstNameInput"
                errorMsgId="firstNameErrorMsg"
              ></pofo-input>

              @if (formHasErrors &&
              insuranceForm.get('firstName')?.errors?.['required']) {
              <pofo-error-message
                errorId="firstNameErrorMsg"
                testId="firstNameReqErrorMsg"
                errorMessage="First name is required."
              ></pofo-error-message>
              }
            </div>

            <div class="w-full md:w-1/2 mb-3">
              <pofo-input
                label="Last name"
                type="text"
                minlength="1"
                [hasError]="formHasErrors && insuranceForm.get('lastName')?.errors?.['required']"
                formControlName="lastName"
                data-test="lastNameInput"
                errorMsgId="lastNameErrorMsg"
              ></pofo-input>

              @if (formHasErrors &&
              insuranceForm.get('lastName')?.errors?.['required']) {
              <pofo-error-message
                errorId="lastNameErrorMsg"
                testId="lastNameReqErrorMsg"
                errorMessage="Last name is required."
              ></pofo-error-message>
              }
            </div>
          </div>

          <div class="mb-3">
            <pofo-input
              name="dateOfBirth"
              label="Date of Birth"
              type="date"
              [hasError]="
              formHasErrors &&
              !!insuranceForm.get('dateOfBirth')?.errors?.['required']
            "
              formControlName="dateOfBirth"
              data-test="dateOfBirthInput"
              errorMsgId="dateOfBirthErrorMsg"
            ></pofo-input>

            @if (formHasErrors &&
            insuranceForm.get('dateOfBirth')?.errors?.['required']) {
            <pofo-error-message
              errorId="dateOfBirthErrorMsg"
              testId="DateOfBirthReqErrorMsg"
              errorMessage="Date of Birth is required."
            ></pofo-error-message>
            }
          </div>
        </div>

        <div class="mb-3 mt-4">
          <pofo-searchable-dropdown
            label="Insurance Provider"
            field="payerId"
            [optionItems]="carrierOptions"
            placeholder="Please Select"
            [hasError]="formHasErrors && insuranceForm.get('payerId')?.errors?.['required']"
            [isDisabled]="carrierOptions.length === 0"
            formControlName="payerId"
            data-test="payerIdsDropDown"
            errorMsgId="payerIdErrorMsg"
          ></pofo-searchable-dropdown>

          @if (formHasErrors &&
          insuranceForm.get('payerId')?.errors?.['required']) {
          <pofo-error-message
            errorId="payerIdErrorMsg"
            testId="PayerIdReqErrorMsg"
            errorMessage="Insurance Provider is required."
          ></pofo-error-message>
          }
        </div>

        <div class="mb-3">
          <pofo-input
            name="memberId"
            label="Policy Number/Member ID"
            type="text"
            [hasError]="
              formHasErrors &&
              !!insuranceForm.get('memberId')?.errors?.['required']
            "
            formControlName="memberId"
            data-test="memberIdInput"
            errorMsgId="memberIdErrorMsg"
          ></pofo-input>

          @if (formHasErrors &&
          insuranceForm.get('memberId')?.errors?.['required']) {
          <pofo-error-message
            errorId="memberIdErrorMsg"
            testId="MemberIdReqErrorMsg"
            errorMessage="Policy Number is required."
          ></pofo-error-message>
          }
        </div>

        <div class="border-t border-platinum my-4 mx-auto"></div>

        <div class="mt-4">
          <button
            class="btn btn-primary btn-medium w-full"
            type="submit"
            data-test="ValidateInsuranceDetailsBtn"
            [disabled]="loading$ | async"
          >
            Validate Insurance details
          </button>
        </div>
      </form>
    </div>
  </pofo-container>
</div>
