import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pofo-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class ContainerComponent {
  @Input() spacingStyles = 'p-6 md:p-8';
  @Input() border = true;
  @Input() tabable = false;
  @Input() borderStyling = 'border rounded-md border-platinum';
  @Input() shadowStyling = '';
  @Input() background = 'bg-off-white';
  @Input() containerWidth = 540;
  @Input() containerTitle?: string;
}
