import { Component, OnInit, inject } from '@angular/core';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { ContainerComponent } from 'src/app/components/container/container.component';
import { LoadingSpinnerComponent } from 'src/app/components/loading-spinner/loading-spinner.component';
import { Location } from '@angular/common';
import { VerificationService } from 'src/app/services/verification/verification.service';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { WorkflowStepsEnum } from 'src/app/services/workflow/workflow-steps.enum';
import { take } from 'rxjs';

@Component({
  selector: 'pofo-auto-verification',
  templateUrl: './auto-verification.component.html',
  styleUrls: ['./auto-verification.component.scss'],
  standalone: true,
  imports: [ContainerComponent, LoadingSpinnerComponent, AlertComponent]
})
export class AutoVerificationComponent implements OnInit {
  verificationService = inject(VerificationService);
  private location = inject(Location);
  _workflowService = inject(WorkflowService);

  readonly authLoadingMessage = 'Verifying';
  readonly autoVerificationErrorMessage =
    'There was an error with your verification process';
  hasError = false;
  isLoading = true;
  isVerified = false;
  loadingMessage = this.authLoadingMessage;
  errorMessage = this.autoVerificationErrorMessage;

  constructor() {
    this.verificationService.isVerification = true;
  }

  ngOnInit(): void {
    this.verificationService
      .verifyParticipantForm(
        {
          factor1: this._workflowService.currentProgram,
          factor2: null,
          factor3: null,
          factor4: null,
          factor5: null,
          factor6: null
        },
        'CODE',
        WorkflowStepsEnum.AutoVerification
      )
      .pipe(take(2))
      .subscribe((response) => {
        if ('loading' in response && response.loading !== undefined) {
          this.isLoading = response.loading;
        }

        if (response.error) {
          this.hasError = true;
          this.errorMessage =
            response.errorMessage || this.autoVerificationErrorMessage;
        } else if (response.value?.id) {
          this.isVerified = true;
        }
        if (response.onCompleteCallback) {
          response?.onCompleteCallback();
        }
      });
  }
}
