<div class="modal-container" #Modal>
  <div class="modal-body">
    <ng-content></ng-content>
    @if (imageSrc) {
    <img src="{{ imageSrc }}" />
    } @if (showButtons) {
    <div class="flex flex-col-reverse md:flex-row w-full">
      <button class="btn btn-secondary btn-medium md:w-2/4" (click)="cancel()">
        {{ cancelText }}
      </button>
      <button
        class="btn btn-primary btn-medium mb-5 md:w-2/4 md:ml-3 md:mb-0"
        (click)="confirm()"
      >
        {{ confirmText }}
      </button>
    </div>
    }
  </div>
</div>
