import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { PhysicianFormRoutingModule } from './physician-form-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ComponentsModule,
    PhysicianFormRoutingModule
  ]
})
export class PhysicianFormModule {}
