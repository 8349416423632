import { AppointmentComponentModule } from '../../../components/appointment-components.module';
import { AppointmentLabcorpRoutingModule } from './appointment-labcorp-routing.module';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    AppointmentComponentModule,
    AppointmentLabcorpRoutingModule,
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class AppointmentLabcorpModule {}
