import {
  AUTH_ROUTE,
  ENDED,
  LIMITS_REACHED,
  LOGOUT_ROUTE,
  NOT_FOUND,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED
} from './app-routes';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RedirectToExternalWellFargoUrl } from './guards/redirect-external-well-fargo-url.guard';
import { UrlFragmentsGuardService } from './guards/url-fragments.guard';

const loadEoJModule = () =>
  import('./modules/end-of-journey/end-of-journey.module').then(
    (m) => m.EndOfJourneyModule
  );

export const mainRoutes: Routes = [
  {
    path: NOT_FOUND,
    loadChildren: loadEoJModule
  },
  {
    path: UNAUTHORIZED,
    loadChildren: loadEoJModule
  },
  {
    path: LIMITS_REACHED,
    loadChildren: loadEoJModule
  },
  {
    path: ENDED,
    loadChildren: loadEoJModule
  },
  {
    path: SOMETHING_WENT_WRONG,
    loadChildren: loadEoJModule
  },
  {
    // NOTE: DO NOT REMOVE THIS UNLESS A DISCUSSION IS HAD
    // https://letsgetchecked.atlassian.net/browse/DVM-1606?focusedCommentId=706886
    path: 'wf-covid',
    canActivate: [RedirectToExternalWellFargoUrl],
    loadChildren: loadEoJModule
  },
  {
    path: AUTH_ROUTE,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    // TODO: Backwards compatibility for old URLs
    path: LOGOUT_ROUTE,
    redirectTo: `${AUTH_ROUTE}/${LOGOUT_ROUTE}`,
    pathMatch: 'full'
  },
  {
    path: ':region',
    // NOTE: DO NOT REMOVE THIS UNLESS A DISCUSSION IS HAD,
    // THIS IS FOR LEGACY PURPOSES! IT SHOULD BE REMOVED ONE DAY
    canLoad: [UrlFragmentsGuardService],
    loadChildren: () =>
      import('./modules/flow-container/flow-container.module').then(
        (m) => m.FlowContainerModule
      )
  },
  { path: '', redirectTo: NOT_FOUND, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(mainRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
